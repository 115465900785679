import React from 'react';
import { Button } from '@atomic';
import handPhone from '../../../../../assets/icons/hand-phone-small.svg';
import addToHomeScreenIcon from '../../../../../assets/icons/ios-add-to-home-screen-full.svg';
import moreButtonsIcon from '../../../../../assets/icons/ios-chrome-more-button.svg';
import { ArrowIndicationCenter, ModalOverlay, ModalContent, TutorialContainer, ListContainer, ListItem, Icon, ButtonContainer, IconSmall, TitleContainer, Text, TooltipBottom, TooltipBottomBubblePointer, IconBig, } from '../../styles';
export var AddToHomeScreenPopup = function (_a) {
    var onIgnoreButton = _a.onIgnoreButton, onRemindMeLaterButton = _a.onRemindMeLaterButton;
    return (React.createElement(ModalOverlay, { style: { zIndex: 100 } },
        React.createElement(ModalContent, null,
            React.createElement(TutorialContainer, null,
                React.createElement(TitleContainer, null,
                    React.createElement("img", { src: handPhone, alt: "m\u00E3o segurando celular", style: { width: 'fitContent' } }),
                    React.createElement(Text, null,
                        React.createElement("span", { style: { fontSize: '1.5rem' } }, "Adicione o APP na sua tela inicial."),
                        React.createElement("br", null),
                        React.createElement("span", { style: { fontSize: '1rem' } }, "\u00C9 r\u00E1pido e f\u00E1cil!"))),
                React.createElement(ListContainer, null,
                    React.createElement(ListItem, null,
                        React.createElement("span", null, "Passo 1 - Clique no \u00EDcone"),
                        React.createElement(Icon, { src: moreButtonsIcon, alt: "Ios Chrome Icon" })),
                    React.createElement(ListItem, null,
                        React.createElement("span", null, "Passo 2 - Clique em"),
                        React.createElement("br", null),
                        React.createElement(IconBig, { src: addToHomeScreenIcon, alt: "Adicionar a tela inicial" }),
                        React.createElement("br", null),
                        React.createElement("span", { style: { color: 'gray' } }, "Voc\u00EA pode ter que arrastar para baixo para visualizar o \u00EDcone.")),
                    React.createElement(ListItem, null,
                        React.createElement("span", null, "Passo 3 - Clique em 'Adicionar'")))),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { onClick: function () { return onRemindMeLaterButton(); }, variant: "primary", expanded: true, style: { borderRadius: 15 } }, "Lembrar mais tarde"),
                React.createElement("br", null),
                React.createElement(Button, { onClick: function () { return onIgnoreButton(); }, variant: "secondary", expanded: true, style: { borderRadius: 15 } }, "N\u00E3o quero adicionar"))),
        React.createElement(ArrowIndicationCenter, null,
            React.createElement(TooltipBottom, null,
                React.createElement("div", { style: { display: 'flex', alignItems: 'flex-end' } },
                    React.createElement("span", null, "Passo 1 - Clicar no \u00EDcone abaixo"),
                    React.createElement(IconSmall, { src: moreButtonsIcon, alt: "Ios Chrome Icon" })),
                React.createElement(TooltipBottomBubblePointer, null)))));
};
