var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { BlackText } from '@atomic';
export var QuestionAlternative = styled(BlackText)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 1rem;\n"], ["\n  padding-left: 1rem;\n"])));
export var QuestionCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  border: ", ";\n  border-radius: 16px;\n  padding: 16px 24px;\n  min-height: 54px;\n  height: fit-content;\n  gap: 16px;\n  width: 100%;\n\n  :nth-child(n + 1) {\n    margin-bottom: 6px;\n  }\n\n  :hover {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  border: ", ";\n  border-radius: 16px;\n  padding: 16px 24px;\n  min-height: 54px;\n  height: fit-content;\n  gap: 16px;\n  width: 100%;\n\n  :nth-child(n + 1) {\n    margin-bottom: 6px;\n  }\n\n  :hover {\n    cursor: pointer;\n  }\n"])), function (props) { return (props.active ? '2px solid #008425' : '1px solid #d9d9d9'); });
export var Textarea = styled.textarea(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 65px;\n  height: fit-content;\n  padding: 10px;\n  border-radius: 8px;\n  border-color: #d9d9d9;\n"], ["\n  width: 100%;\n  min-height: 65px;\n  height: fit-content;\n  padding: 10px;\n  border-radius: 8px;\n  border-color: #d9d9d9;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
