import * as React from 'react';
import { FontFamily } from '@atomic';
export var Points = function (_a) {
    var points = _a.points;
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("p", { style: { fontFamily: FontFamily.Secondary, fontSize: '1.8rem', margin: 0 } }, "Voc\u00EA ganhou"),
        React.createElement("p", { style: { fontFamily: FontFamily.Secondary, alignSelf: 'flex-end', fontSize: '4.5rem', margin: 0 } },
            "+",
            points,
            React.createElement("span", { style: { fontSize: '1.6rem', fontFamily: FontFamily.Secondary } }, " PTS"))));
};
