var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic/obj.constants';
export var TrackHeaderContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  flex-flow: row wrap;\n  width: min(100%, 480px);\n  z-index: 10;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  flex-flow: row wrap;\n  width: min(100%, 480px);\n  z-index: 10;\n"])));
export var LeftContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 8px;\n"])));
export var RightContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 70%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 70%;\n"])));
export var ButtonChevron = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 50px;\n  :hover {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 50px;\n  :hover {\n    cursor: pointer;\n  }\n"])));
// export const UserImage = styled.img`
//   width: 4rem;
//   height: 3.5rem;
//   border-radius: 100%;
//   width: 3.9rem;
//   border: 1px solid '#49443C';
//   cursor: pointer;
// `;
export var CenterContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 4rem;\n  font-weight: bold;\n  border-radius: 50%;\n  overflow: hidden;\n"], ["\n  display: flex;\n  width: 100%;\n  height: 4rem;\n  font-weight: bold;\n  border-radius: 50%;\n  overflow: hidden;\n"])));
export var UserImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 4.2rem;\n  height: 4.2rem;\n  border: 1px solid #49443c;\n  object-fit: cover;\n  border-radius: 50%;\n  cursor: pointer;\n"], ["\n  width: 4.2rem;\n  height: 4.2rem;\n  border: 1px solid #49443c;\n  object-fit: cover;\n  border-radius: 50%;\n  cursor: pointer;\n"])));
export var UserInfos = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  height: calc(100% - 32px);\n  justify-content: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  height: calc(100% - 32px);\n  justify-content: center;\n"])));
export var LogoutButtonBox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: right;\n  padding-top: 15px;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: right;\n  padding-top: 15px;\n"])));
export var UserInfo = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: right;\n  align-items: end;\n\n  img {\n    margin: 0 5px;\n    width: 18px;\n    height: 18px;\n  }\n\n  span {\n    font-family: ", ";\n    font-size: 18px;\n  }\n\n  &:last-child {\n    /*img {\n      margin-right: 0;\n    }*/\n  }\n\n  .ranking-link {\n    height: 24px;\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: right;\n  align-items: end;\n\n  img {\n    margin: 0 5px;\n    width: 18px;\n    height: 18px;\n  }\n\n  span {\n    font-family: ", ";\n    font-size: 18px;\n  }\n\n  &:last-child {\n    /*img {\n      margin-right: 0;\n    }*/\n  }\n\n  .ranking-link {\n    height: 24px;\n    display: flex;\n    align-items: center;\n  }\n"])), FontFamily.Secondary);
export var StarBox = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n"])));
export var LabelBox = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  min-height: 2.3rem;\n  text-align: center;\n  padding: 10px 25px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  margin-top: 5px;\n  background: #fff;\n  transition: all 2s ease-out;\n"], ["\n  position: absolute;\n  min-height: 2.3rem;\n  text-align: center;\n  padding: 10px 25px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  margin-top: 5px;\n  background: #fff;\n  transition: all 2s ease-out;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var UserName = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 4px;\n  font-size: 0.8rem;\n"], ["\n  margin-top: 4px;\n  font-size: 0.8rem;\n"])));
export var CourseName = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  text-align: center;\n  font-size: 1.2rem;\n  border-top: 2px dashed rgba(73, 68, 60, 0.6);\n  padding-block: 8px;\n\n  span {\n    font-family: ", ";\n    font-size: 1rem;\n    margin-right: 0.5rem;\n  }\n\n  button {\n    padding: 0;\n    width: unset;\n  }\n"], ["\n  width: 100%;\n  text-align: center;\n  font-size: 1.2rem;\n  border-top: 2px dashed rgba(73, 68, 60, 0.6);\n  padding-block: 8px;\n\n  span {\n    font-family: ", ";\n    font-size: 1rem;\n    margin-right: 0.5rem;\n  }\n\n  button {\n    padding: 0;\n    width: unset;\n  }\n"])), FontFamily.Secondary);
export var FinishedCourseMsg = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: green;\n  font-size: 12px;\n"], ["\n  color: green;\n  font-size: 12px;\n"])));
export var UserRankingPosition = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding-top: 2px;\n\n  span {\n    font-size: 1rem;\n    padding-left: 3px;\n    color: #00000094;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding-top: 2px;\n\n  span {\n    font-size: 1rem;\n    padding-left: 3px;\n    color: #00000094;\n  }\n"])));
export var ImageFromUser = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  max-width: 120px !important;\n"], ["\n  max-width: 120px !important;\n"])));
export var NewIcon = styled.img(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 43px !important;\n  z-index: 10;\n\n  :hover {\n    cursor: pointer;\n  }\n"], ["\n  width: 43px !important;\n  z-index: 10;\n\n  :hover {\n    cursor: pointer;\n  }\n"])));
export var NonClicableIcon = styled.img(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 43px !important;\n  z-index: 10;\n"], ["\n  width: 43px !important;\n  z-index: 10;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
