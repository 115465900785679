import * as React from 'react';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { Header } from '@app/components/mol.header';
import { Button, Col, Row, StyledContainer } from '@atomic';
import { ListaDeChamados } from './components/lista-de-chamados';
import { ModalNovoChamado } from './components/modal-novo-chamado';
var GET_CHAMADOS_ABERTOS_URL = 'chamado';
export var ChamadosPage = function () {
    var _a = React.useState(false), isModalAbrirChamadoVisible = _a[0], setIsModalAbrirChamadoVisible = _a[1];
    var _b = React.useState(), previewModeChamado = _b[0], setPreviewModeChamado = _b[1];
    var handleOnModalAbrirChamadoClose = function () {
        setIsModalAbrirChamadoVisible(false);
        setPreviewModeChamado(undefined);
    };
    var handleOnItemClick = function (chamado) {
        setPreviewModeChamado(chamado);
        setIsModalAbrirChamadoVisible(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalNovoChamado, { isOpened: isModalAbrirChamadoVisible, chamado: previewModeChamado, handleOnClose: handleOnModalAbrirChamadoClose }),
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors" },
            React.createElement(Header, { withBackButton: true, title: "Chamados" })),
        React.createElement(Row, { style: { display: 'flex', justifyContent: 'center', margin: '1.8rem 0 1rem 0' } },
            React.createElement(Col, null,
                React.createElement(Button, { onClick: function () { return setIsModalAbrirChamadoVisible(true); } }, "Abrir Chamado"))),
        React.createElement(DottedSeparator, { style: { marginTop: '2rem' } }),
        React.createElement(StyledContainer, { withPadding: true, style: { paddingTop: '1.2rem' } },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { textAlign: 'center' } },
                    React.createElement("h3", { style: { marginTop: 0 } }, "SUA FILA:")),
                React.createElement(Col, { xs: 12 },
                    React.createElement(ListaDeChamados, { type: "abertos", url: GET_CHAMADOS_ABERTOS_URL, handleOnItemClick: handleOnItemClick }))))));
};
