import * as React from 'react';
import { Container } from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
export var useUserStore = function () {
    var authStorageService = Container.get(AuthStorageService);
    var _a = React.useState(authStorageService === null || authStorageService === void 0 ? void 0 : authStorageService.getUser()), userData = _a[0], setUserData = _a[1];
    var _b = React.useState(null), authToken = _b[0], setAuthToken = _b[1];
    React.useEffect(function () {
        if (authStorageService) {
            setUserData(authStorageService.getUser());
            setAuthToken(authStorageService.getToken());
        }
    }, [authStorageService]);
    authStorageService.addObserver({
        // AuthStorageObserver
        hasLoggedIn: function (loggedUserData, token) {
            setUserData(loggedUserData);
            setAuthToken(token);
        },
        hasLoggedOut: function () {
            setUserData(undefined);
        },
    });
    var isLogged = function () {
        if (typeof localStorage === 'undefined') {
            return false;
        }
        return userData != null;
    };
    return { userData: userData, logged: isLogged(), logout: authStorageService.logout, authToken: authToken };
};
