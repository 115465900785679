var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Header = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n"])));
export var CoinBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: end;\n  width: 80px;\n  height: 80px;\n  div {\n    margin-top: 20px;\n    margin-right: 20px;\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n  }\n  span {\n    font-weight: bold;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: end;\n  width: 80px;\n  height: 80px;\n  div {\n    margin-top: 20px;\n    margin-right: 20px;\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n  }\n  span {\n    font-weight: bold;\n  }\n"])));
export var ReturnButtonBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 80px;\n  height: 80px;\n"], ["\n  width: 80px;\n  height: 80px;\n"])));
export var ReturnButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  background: none;\n  font-size: 16px;\n  margin-top: 20px;\n  margin-left: 20px;\n  cursor: pointer;\n"], ["\n  border: none;\n  background: none;\n  font-size: 16px;\n  margin-top: 20px;\n  margin-left: 20px;\n  cursor: pointer;\n"])));
export var CoinIcon = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-right: 5px;\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-right: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
