import * as React from 'react';
import classNames from 'classnames';
import crown1icon from '@assets/icons/crown1icon.png';
import crown2icon from '@assets/icons/crown2icon.png';
import crown3icon from '@assets/icons/crown3icon.png';
import { Col, Row } from '@atomic';
import { RankingStars } from '@atomic/atm.stars';
import { NameLayout, PlaceLayout, PointsLayout, RankingName, RankingPlace, RankingPoints, RowWrapper, } from '../ranking-table/ranking-table.components.styled';
export var RankingTableItem = function (_a) {
    var isCurrentUser = _a.isCurrentUser, rankingEntry = _a.rankingEntry;
    return (React.createElement(Row, { style: { minHeight: '2.5rem', marginBottom: '0.4rem' }, className: classNames({
            'use-inverse-colors user-ranking-entry': isCurrentUser,
        }) },
        React.createElement(RowWrapper, null,
            React.createElement(Col, { xs: 2 },
                React.createElement(PlaceLayout, null,
                    React.createElement(RankingPlace, null, rankingEntry.posicao === 1 ? (React.createElement("img", { src: crown1icon, alt: "", style: { width: '2.2rem' } })) : rankingEntry.posicao === 2 ? (React.createElement("img", { src: crown2icon, alt: "", style: { width: '2.2rem' } })) : rankingEntry.posicao === 3 ? (React.createElement("img", { src: crown3icon, alt: "", style: { width: '2.2rem' } })) : (rankingEntry.posicao)))),
            React.createElement(Col, { xs: 4 },
                React.createElement(NameLayout, null,
                    React.createElement(RankingName, null, rankingEntry.nome))),
            React.createElement(Col, { xs: 3 },
                React.createElement(RankingStars, { points: rankingEntry.pontos, size: "0.7rem" })),
            React.createElement(Col, { xs: 3 },
                React.createElement(PointsLayout, null,
                    React.createElement(RankingPoints, null,
                        rankingEntry.pontos,
                        " pts."))))));
};
