var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { LogoCopyright } from '@app/components/atm.logo-copyright/logo-copyright';
import { Logo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { PhoneRegisterFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { ContainerStyled, ContentStyled, FormStyled, SmallTitle, } from '@app/modules/authentication/authentication.styled';
import { AppPath } from '@app/routes';
import { unformatPhoneNumber } from '@app/utils/shared-functions.utils';
import { Validators, Separator, TextField, Button, FlashMessageEnum, StyledContainer, Form } from '@atomic';
var ButtonTextStr = AuthStrings.CelphonRegister.ButtonTextStr;
export var PersonalDataUpdatePage = function () {
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var company = useStore('company')[0];
    var _a = useStore('usuario'), usuario = _a[0], setUsuario = _a[1];
    var _b = React.useState(''), phoneValue = _b[0], setPhoneValue = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var sendPUTRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var PUT_USUARIO_URL, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    PUT_USUARIO_URL = "usuario/" + usuario.id;
                    return [4 /*yield*/, axiosClient.put(PUT_USUARIO_URL, __assign(__assign({}, usuario), { celular: unformatPhoneNumber(phoneValue) }))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var onSuccess = function (responseData) {
        setUsuario(responseData.data);
        history.push(AppPath.Map);
    };
    var onError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Erro. Confira as informações e tente novamente.'); };
    var handleSubmit = function (formData) {
        setIsLoading(true);
        var hasFormError = Object.keys(formData.error).length > 0;
        if (!hasFormError) {
            sendPUTRequest()
                .then(onSuccess)
                .catch(onError)
                .finally(function () { return setIsLoading(false); });
        }
    };
    return (React.createElement(ContainerStyled, { style: { flexDirection: 'column' } },
        React.createElement(ContentStyled, { style: { position: 'relative' } },
            React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, centered: true }),
            React.createElement(SmallTitle, null, "Voc\u00EA precisa completar seus dados"),
            React.createElement(Separator, null),
            React.createElement(FormStyled, null,
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Phone, label: 'CELULAR', validators: [Validators.Required()], onValueChange: setPhoneValue },
                        React.createElement(TextField, { variant: "cel-phone" })),
                    React.createElement(Separator, null),
                    React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading, disabled: !/^\s*\((\d{2}|\d{0})\)[-. ]?(\d{5})[-. ]?(\d{4})[-. ]?\s*$/.test(phoneValue) }, ButtonTextStr)))),
        React.createElement(Separator, null),
        React.createElement(StyledContainer, { justifyCenter: true },
            React.createElement(LogoCopyright, null))));
};
