import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import entCoinsIcon from '@assets/icons/ENTCoins.svg';
import { ButtonBox, ButtonBuy } from '../../store.page.styled';
var BuyEntCoinsButton = function (_a) {
    var valor = _a.valor, disabled = _a.disabled, onClick = _a.onClick;
    var _b = useState(false), isTooltipOpen = _b[0], setIsTooltipOpen = _b[1];
    var handleOnTooltipClick = function () {
        setIsTooltipOpen(true);
    };
    return (React.createElement(ButtonBox, null, disabled ? (React.createElement(Tooltip, { open: isTooltipOpen, onClose: function () { return setIsTooltipOpen(false); }, onOpen: function () { return setIsTooltipOpen(true); }, title: "Volte a jogar e conquiste moedas", onClick: handleOnTooltipClick },
        React.createElement(ButtonBuy, { style: { background: '#cccccca6' } },
            React.createElement("img", { src: entCoinsIcon, alt: "Bot\u00E3o desativado - Comprar EntCoins" }),
            React.createElement("span", null, valor)))) : (React.createElement(ButtonBuy, { onClick: onClick },
        React.createElement("img", { src: entCoinsIcon, alt: "Bot\u00E3o Comprar EntCoins" }),
        React.createElement("span", null, valor)))));
};
export default BuyEntCoinsButton;
