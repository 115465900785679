var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useQuery } from 'react-query';
import { axiosClient } from '@app/configure-app';
import dollar from '@assets/icons/dollar.svg';
import { Button, FaIcon } from '@atomic';
import { Modal } from '@atomic/obj.modal';
import { StatusBox, StatusItemBox, TaskName, Title } from './track-task-modal.styled';
export var TrackTaskModal = function (_a) {
    var button = _a.button, close = _a.close, isOpenModal = _a.isOpenModal, task = _a.task;
    var Aproveitamento;
    var acertos;
    var erros;
    var pontos;
    var tarefaUrl = task.faseId + "/tarefa/" + task.id + "/status";
    var tarefaStatus = useQuery('tarefa', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(tarefaUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    if (tarefaStatus !== undefined) {
        pontos = tarefaStatus.pontos;
        Aproveitamento = ((tarefaStatus.totalAcertos / tarefaStatus.totalPerguntas) * 100).toFixed(0);
        acertos = tarefaStatus.totalAcertos;
        erros = tarefaStatus.totalPerguntas - acertos;
    }
    return (React.createElement(Modal, { opened: isOpenModal, onClose: function () { return close(false); } },
        React.createElement(Title, null, "DESEMPENHO"),
        React.createElement(TaskName, null, task.nome),
        React.createElement(StatusBox, null,
            React.createElement(StatusItemBox, null,
                React.createElement(FaIcon.ClipboardCheck, { style: { fontSize: '20px', width: '20px', margin: 'auto 5px' } }),
                React.createElement("span", null,
                    "Aproveitamento: ",
                    Aproveitamento,
                    "%")),
            React.createElement(StatusItemBox, null,
                React.createElement("img", { src: dollar, alt: "pontos" }),
                React.createElement("span", null,
                    "Pontos: ",
                    pontos)),
            React.createElement(StatusItemBox, null,
                React.createElement(FaIcon.Check, { style: { fontSize: '20px', width: '20px', margin: 'auto 5px', color: 'green' } }),
                React.createElement("span", null,
                    "Acertos: ",
                    acertos)),
            React.createElement(StatusItemBox, null,
                React.createElement(FaIcon.Close, { style: { fontSize: '20px', width: '20px', margin: 'auto 5px', color: 'red' } }),
                React.createElement("span", null,
                    "Erros: ",
                    erros))),
        React.createElement(Button, { expanded: true, onClick: button }, "Refazer")));
};
