import React, { useEffect } from 'react';
import { axiosClient } from '@app/configure-app';
import { CourseLibraryItem } from '@app/modules/course-library/components/course-item';
import { DottedLine, GroupSection, GroupTitle } from '@app/modules/map/course-list/course-list.component.style';
var GROUP_ENDPOINT_URL = 'filial/cursos-filias';
export var CourseLibraryList = function () {
    var _a = React.useState(), groups = _a[0], setGroups = _a[1];
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = React.useState(false), isError = _c[0], setIsError = _c[1];
    // const { data: groupList, isLoading, isError } = useQuery<GroupListResponse>('course-group', async () => {
    //   try {
    //     const response = await axiosClient.get(groupUrl);
    //     return response.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // });
    useEffect(function () {
        if (!groups) {
            setIsLoading(true);
            axiosClient
                .get(GROUP_ENDPOINT_URL)
                .then(function (_a) {
                var coursesGroupsResponse = _a.data;
                setGroups(coursesGroupsResponse === null || coursesGroupsResponse === void 0 ? void 0 : coursesGroupsResponse.data);
            })
                .catch(function (error) {
                console.log(error);
                setIsError(true);
            })
                .finally(function () {
                setIsLoading(false);
            });
        }
    }, [groups]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement("h5", null, "Carregando..."),
        isError && React.createElement("h5", null, "Erro ao processuar sua solicita\u00E7\u00E3o. Recarregue a p\u00E1gina e tente novamente"), groups === null || groups === void 0 ? void 0 :
        groups.map(function (group, idx) {
            return (React.createElement(GroupSection, { key: idx },
                React.createElement(GroupTitle, null,
                    React.createElement("span", { key: idx, className: "group-title" }, group.nome)),
                group.cursos.concat(group.cursosSequencias).map(function (course, idx_c) { return (React.createElement(CourseLibraryItem, { key: idx_c, course: course, courseIndex: idx_c })); }),
                React.createElement(DottedLine, null)));
        })));
};
