var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import moment from 'moment';
import { FaCamera, FaExclamationTriangle, FaFlag } from 'react-icons/fa';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import HTMLDisplayToQuestion from '@app/components/htmlToText';
import { EnviarFoto, EnviarFotoSubmittedInfo, EnviarObservacao, EnviarObservacaoSubmittedInfo, } from '@app/modules/routine-checklist/components/checklist-item/checklist-item.styled';
import { getUserLocation } from '@app/services/location';
import { Button, FaIcon } from '@atomic';
import { ActionPlan, ActionPlanSubmittedInfo, ActionsWrapper, ItemButtonsWrapper, RightAnswer, } from './checklist-item.styled';
export var ChecklistItem = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var checklistItem = _a.checklistItem, handleOnActionPlanClick = _a.handleOnActionPlanClick, respostasGestor = _a.respostasGestor, setRespostasGestor = _a.setRespostasGestor, usuarioId = _a.usuarioId, isActionPlanRegistered = _a.isActionPlanRegistered, postOrPutChecklistResponse = _a.postOrPutChecklistResponse, handleOnEnviarFotoClick = _a.handleOnEnviarFotoClick, handleOnObservacaoClick = _a.handleOnObservacaoClick, isObservacaoEnviada = _a.isObservacaoEnviada, isFotoEnviada = _a.isFotoEnviada;
    var hasPreviewAnswer = function () {
        return respostasGestor.findIndex(function (_resposta) { return _resposta.quizId === checklistItem.id; }) > -1;
    };
    var registerItemAnswer = function (isEfetivo, coordenadas) {
        if (hasPreviewAnswer()) {
            var RespostastoSave = respostasGestor.map(function (resposta) {
                return resposta.quizId === checklistItem.id
                    ? {
                        id: checklistItem.answerId || 0,
                        gestorId: usuarioId,
                        quizId: checklistItem.id,
                        checklistId: 0,
                        efetivo: isEfetivo,
                        dataCadastro: '2022-01-25T00:01:10.000Z',
                        dataResposta: moment().format(),
                        latitudeResposta: coordenadas ? coordenadas.latitude : null,
                        longitudeResposta: coordenadas ? coordenadas.longitude : null,
                    }
                    : resposta;
            });
            setRespostasGestor(RespostastoSave);
        }
        else {
            setRespostasGestor(function (respostas) { return __spreadArrays(respostas, [
                {
                    id: checklistItem.answerId || 0,
                    gestorId: usuarioId,
                    quizId: checklistItem.id,
                    checklistId: 0,
                    efetivo: isEfetivo,
                    dataCadastro: '2022-01-25T00:01:10.000Z',
                    dataResposta: moment().format(),
                    latitudeResposta: coordenadas ? coordenadas.latitude : null,
                    longitudeResposta: coordenadas ? coordenadas.longitude : null,
                },
            ]); });
        }
    };
    var addOrReplaceRespostaGestor = function (isEfetivo) {
        getUserLocation()
            .then(function (coordenadas) {
            registerItemAnswer(isEfetivo, coordenadas);
        })
            .catch(function () {
            registerItemAnswer(isEfetivo);
        });
    };
    var isCheckedAs = function (isEfetivo, checklistItemId) {
        var resposta = respostasGestor.find(function (_resposta) { return _resposta.quizId === checklistItemId; });
        if (resposta) {
            return resposta.efetivo === isEfetivo;
        }
        return false;
    };
    var isWrongAnswer = !((_d = (_c = (_b = postOrPutChecklistResponse === null || postOrPutChecklistResponse === void 0 ? void 0 : postOrPutChecklistResponse.data) === null || _b === void 0 ? void 0 : _b.respostasGestor) === null || _c === void 0 ? void 0 : _c.find(function (resposta) { return resposta.quizId === checklistItem.id; })) === null || _d === void 0 ? void 0 : _d.efetivo);
    return (React.createElement(React.Fragment, null,
        React.createElement(HTMLDisplayToQuestion, { htmlContent: checklistItem.pergunta }),
        React.createElement(RightAnswer, null, (_f = (_e = checklistItem.alternativas) === null || _e === void 0 ? void 0 : _e.find(function (alternativa) { return alternativa.valor === true; })) === null || _f === void 0 ? void 0 : _f.descricao),
        ((_g = respostasGestor[0]) === null || _g === void 0 ? void 0 : _g.planoAcao) ? React.createElement(FaExclamationTriangle, null) : null,
        React.createElement(ItemButtonsWrapper, null,
            React.createElement(Button, { disabled: !!postOrPutChecklistResponse, variant: "neutral", style: {
                    backgroundColor: isCheckedAs(false, checklistItem.id) ? 'red' : '#00000029',
                    minWidth: '120px',
                    minHeight: '37px',
                    textAlign: 'center',
                    borderColor: 'transparent',
                }, onClick: function () { return addOrReplaceRespostaGestor(false); } },
                React.createElement(FaIcon.Close, { style: { paddingLeft: '10px', color: 'white' } })),
            React.createElement(Button, { disabled: !!postOrPutChecklistResponse, variant: "neutral", style: {
                    backgroundColor: isCheckedAs(true, checklistItem.id) ? '#11C728' : '#00000029',
                    minWidth: '120px',
                    minHeight: '37px',
                    textAlign: 'center',
                    borderColor: 'transparent',
                }, onClick: function () { return addOrReplaceRespostaGestor(true); } },
                React.createElement(FaIcon.Check, { style: { paddingLeft: '10px', color: 'white' } }))),
        !!postOrPutChecklistResponse && (React.createElement(ActionsWrapper, null,
            isWrongAnswer && (React.createElement(ActionPlan, { onClick: handleOnActionPlanClick }, isActionPlanRegistered ? (React.createElement(ActionPlanSubmittedInfo, null,
                React.createElement("span", null, "Plano de A\u00E7\u00E3o Enviado"),
                " ",
                React.createElement(FaIcon.Check, { style: { color: 'green' } }))) : (React.createElement(React.Fragment, null,
                React.createElement(FaExclamationTriangle, null),
                " ",
                React.createElement("span", null, "Plano de A\u00E7\u00E3o"))))),
            React.createElement(EnviarFoto, { onClick: handleOnEnviarFotoClick }, isFotoEnviada ? (React.createElement(EnviarFotoSubmittedInfo, null,
                React.createElement("span", null, "Foto Enviada"),
                " ",
                React.createElement(FaCamera, { style: { color: 'green' } }))) : (React.createElement(React.Fragment, null,
                React.createElement(FaCamera, null),
                " ",
                React.createElement("span", null, "Enviar Foto")))),
            React.createElement(EnviarObservacao, { onClick: handleOnObservacaoClick }, isObservacaoEnviada ? (React.createElement(EnviarObservacaoSubmittedInfo, null,
                React.createElement("span", null, "Observa\u00E7\u00E3o Enviada"),
                " ",
                React.createElement(FaFlag, { style: { color: 'green' } }))) : (React.createElement(React.Fragment, null,
                React.createElement(FaFlag, null),
                " ",
                React.createElement("span", null, "Enviar Observa\u00E7\u00E3o")))))),
        React.createElement(DottedSeparator, { style: { marginBottom: '1.2rem' } })));
};
