var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Slider } from '@mui/material';
import styled from 'styled-components';
import { Color } from '@atomic';
import { Button, Col, H2, H3, StyledContainer } from '@atomic';
export var InfoClimate = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 1rem;\n  text-align: center;\n  h2 {\n    margin-top: 0;\n    margin-bottom: 0.5rem;\n  }\n"], ["\n  padding-top: 1rem;\n  text-align: center;\n  h2 {\n    margin-top: 0;\n    margin-bottom: 0.5rem;\n  }\n"])));
export var Footer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1rem;\n"], ["\n  padding: 1rem;\n"])));
export var ButtonGray = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  :hover {\n    background-color: grey;\n    color: black;\n  }\n\n  background-color: #2d2d2d;\n  border: 1px solid #fff;\n"], ["\n  :hover {\n    background-color: grey;\n    color: black;\n  }\n\n  background-color: #2d2d2d;\n  border: 1px solid #fff;\n"])));
export var ColRadioButton = styled(Col)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flex-start;\n  padding: 1.1rem 0 0 0;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flex-start;\n  padding: 1.1rem 0 0 0;\n"])));
export var StyledSlider = styled(Slider)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", " !important;\n"], ["\n  color: ", " !important;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ThemeColorText = styled(H2)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var PreviewLabel = styled(H3)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  color: ", ";\n"], ["\n  font-size: 1.2rem;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, themeFontColor = _a.themeFontColor;
    return (themeFontColor ? theme.corPrimaria : '');
});
export var PreviewImage = styled(Col)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-height: 30vh;\n  padding-top: 10px;\n\n  ", " {\n    height: 100%;\n  }\n"], ["\n  max-height: 30vh;\n  padding-top: 10px;\n\n  ", " {\n    height: 100%;\n  }\n"])), StyledContainer);
export var AnswerResponseWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  H4 {\n    color: ", ";\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  svg {\n    fontsize: '1.5rem';\n    marginright: '0.4rem';\n    color: ", ";\n  }\n\n  Button {\n    background: ", ";\n    border-color: ", ";\n  }\n"], ["\n  margin-bottom: 20px;\n\n  H4 {\n    color: ", ";\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  svg {\n    fontsize: '1.5rem';\n    marginright: '0.4rem';\n    color: ", ";\n  }\n\n  Button {\n    background: ", ";\n    border-color: ", ";\n  }\n"])), function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
