var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic/obj.constants';
export var TelaDeLogin = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 100vh;\n  padding: 0 6%;\n  background-color: ", ";\n  background-image: ", ";\n  background-size: cover;\n  label {\n    color: whitesmoke;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 100vh;\n  padding: 0 6%;\n  background-color: ", ";\n  background-image: ",
    ";\n  background-size: cover;\n  label {\n    color: whitesmoke;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var src = _a.src, theme = _a.theme;
    return src ? "url(" + src + ")" : "linear-gradient(to top, rgba(0, 0, 0.3, 0.5) 11%, " + theme.corPrimaria + ")";
});
export var LogoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 33%;\n  display: flex;\n  justify-content: center;\n  align-items: end;\n  img {\n    width: 48% !important;\n    border-radius: 50%;\n  }\n"], ["\n  height: 33%;\n  display: flex;\n  justify-content: center;\n  align-items: end;\n  img {\n    width: 48% !important;\n    border-radius: 50%;\n  }\n"])));
export var FormWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  color: white;\n  height: 68%;\n  justify-content: space-evenly;\n\n  h3 {\n    text-align: center;\n  }\n\n  label:first-of-type {\n    color: white !important;\n    font-size: 16px !important;\n    font-family: ", " !important;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  color: white;\n  height: 68%;\n  justify-content: space-evenly;\n\n  h3 {\n    text-align: center;\n  }\n\n  label:first-of-type {\n    color: white !important;\n    font-size: 16px !important;\n    font-family: ", " !important;\n  }\n"])), FontFamily.Third);
export var DivLink = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 5px;\n  display: flex;\n  justify-content: end;\n  text-decoration-line: underline;\n"], ["\n  margin-top: 5px;\n  display: flex;\n  justify-content: end;\n  text-decoration-line: underline;\n"])));
export var ButtonsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n  padding-top: 15%;\n\n  button,\n  a {\n    margin-bottom: 12px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n  padding-top: 15%;\n\n  button,\n  a {\n    margin-bottom: 12px;\n  }\n"])));
export var LoadingScreen = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n  background-color: ", ";\n  background-image: linear-gradient(to top, rgba(0, 0, 0.3, 0.3) 15%, ", ");\n\n  .barraCarregamento {\n    width: 60%;\n  }\n\n  .logoFooter {\n    width: 90%;\n    left: 0;\n    bottom: 0;\n  }\n"], ["\n  height: 100%;\n  background-color: ", ";\n  background-image: linear-gradient(to top, rgba(0, 0, 0.3, 0.3) 15%, ", ");\n\n  .barraCarregamento {\n    width: 60%;\n  }\n\n  .logoFooter {\n    width: 90%;\n    left: 0;\n    bottom: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Titulo = styled.h3(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: whitesmoke;\n  font-weight: bold;\n"], ["\n  color: whitesmoke;\n  font-weight: bold;\n"])));
export var SubTitulo = styled.h4(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-bottom: 3px solid;\n  border-radius: 5px;\n  border-color: #f5f5f5e4;\n  color: #f5f5f5e4;\n  font-weight: bold;\n\n  :hover {\n    cursor: pointer;\n    color: #fff;\n  }\n"], ["\n  border-bottom: 3px solid;\n  border-radius: 5px;\n  border-color: #f5f5f5e4;\n  color: #f5f5f5e4;\n  font-weight: bold;\n\n  :hover {\n    cursor: pointer;\n    color: #fff;\n  }\n"])));
export var TelaCadastroCelular = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: max-content;\n  padding: 0 6%;\n  background-image: ", ";\n  background-size: cover;\n\n  label {\n    font-size: 14px;\n  }\n\n  input {\n    border-radius: 50px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: max-content;\n  padding: 0 6%;\n  background-image: ",
    ";\n  background-size: cover;\n\n  label {\n    font-size: 14px;\n  }\n\n  input {\n    border-radius: 50px;\n  }\n"])), function (_a) {
    var src = _a.src, theme = _a.theme;
    return src ? "url(" + src + ")" : "linear-gradient(to top, rgba(0, 0, 0.3, 0.5) 11%, " + theme.corPrimaria + ")";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
