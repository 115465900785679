var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic';
export var CourseContentInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var CourseInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  flex-flow: column;\n\n  span {\n    font-size: 1.7rem;\n    font-family: ", ";\n  }\n"], ["\n  display: inline-flex;\n  flex-flow: column;\n\n  span {\n    font-size: 1.7rem;\n    font-family: ", ";\n  }\n"])), FontFamily.Secondary);
export var Info = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 3rem;\n  display: inline-flex;\n  flex-flow: column;\n"], ["\n  margin-top: 3rem;\n  display: inline-flex;\n  flex-flow: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
