var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { CheckTypes } from '@app/modules/checklist/components/checklist-card';
import { Button, Divider } from '@atomic';
import { Modal } from '@atomic/obj.modal/modal.component';
import { FlexRowTop, QuickChecklistTitle, TextareaImpossibilita } from './styles';
export var ImpossibilidadeResposta = function (_a) {
    var _b, _c;
    var open = _a.open, onClose = _a.onClose, checklist = _a.checklist, checklistImpossibilitado = _a.checklistImpossibilitado;
    var _d = React.useState({
        checklistUnidadeId: (_b = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _b === void 0 ? void 0 : _b.id,
        unidadeEmpresaId: 0,
        observacao: '',
    }), formState = _d[0], setFormState = _d[1];
    var _e = React.useState(false), loading = _e[0], setLoading = _e[1];
    var showFlashMessage = useFlashMessage()[0];
    var addNewQuickChecklist = function () { return __awaiter(void 0, void 0, void 0, function () {
        var checkListUnidadeId, checklistItem, response, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setLoading(true);
                    checkListUnidadeId = checklist.type === CheckTypes.PENDING_APPROVAL_TYPE ? checklist === null || checklist === void 0 ? void 0 : checklist.id : (_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _a === void 0 ? void 0 : _a.id;
                    checklistItem = {
                        unidadeEmpresaId: (_b = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _b === void 0 ? void 0 : _b.id,
                        checklistUnidadeId: checkListUnidadeId,
                        observacao: formState === null || formState === void 0 ? void 0 : formState.observacao,
                        checklistUnidadePendenteId: ((_c = checklist === null || checklist === void 0 ? void 0 : checklist.type) === null || _c === void 0 ? void 0 : _c.startsWith('pending-today')) ? checklist === null || checklist === void 0 ? void 0 : checklist.id : undefined,
                    };
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axiosClient.post('/checklistunidade/sinalizar-impossibilidade-resposta', checklistItem)];
                case 2:
                    response = _e.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        showFlashMessage('success', 'Sucesso, checklist com resposta impossibilitada!');
                        setFormState(__assign(__assign({}, formState), { observacao: '' }));
                        checklistImpossibilitado();
                        handleClose();
                        setLoading(false);
                    }
                    else {
                        showFlashMessage('warning', (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.errors[0]);
                        setLoading(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    if (error_1.response && error_1.response.status == 400) {
                        showFlashMessage('warning', error_1.response.data.errors);
                    }
                    else {
                        showFlashMessage('warning', error_1);
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        setFormState(__assign(__assign({}, formState), { observacao: '' }));
        onClose();
    };
    return (React.createElement(Modal, { opened: open, closeOnOutsideClick: false, onClose: handleClose },
        React.createElement(FlexRowTop, { style: { marginBottom: '1.2rem' } },
            React.createElement(QuickChecklistTitle, null,
                React.createElement("h2", null, "Impossibilidade de Resposta"))),
        React.createElement(Divider, null),
        React.createElement("p", null, "Justificativa:*"),
        React.createElement(TextareaImpossibilita, { placeholder: "Justifique porque esse checklist n\u00E3o pode ser respondido.", onChange: function (e) {
                return setFormState(__assign(__assign({}, formState), { observacao: e.target.value }));
            } }, formState === null || formState === void 0 ? void 0 : formState.observacao),
        React.createElement(Button, { type: "submit", variant: "primary", expanded: true, disabled: ((_c = formState === null || formState === void 0 ? void 0 : formState.observacao) === null || _c === void 0 ? void 0 : _c.length) < 3, onClick: addNewQuickChecklist, loading: loading }, "Enviar")));
};
