import React, { useEffect, useState } from 'react';
import { useStore } from 'react-context-hook';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
export var ProfileHeader = function (_a) {
    var user = _a.user;
    var _b = useState(user === null || user === void 0 ? void 0 : user.imagem), image = _b[0], setImage = _b[1];
    var company = useStore('company')[0];
    useEffect(function () {
        setImage(user === null || user === void 0 ? void 0 : user.imagem);
    }, [user === null || user === void 0 ? void 0 : user.imagem]);
    return React.createElement(HeaderV2, { withBackButton: true, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro });
};
