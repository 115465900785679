var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import set from 'lodash.set';
import * as Scroll from 'react-scroll';
import { FormField } from './form-field.component';
import { FormContext } from './form.context';
/**
 * A component for form creation. If you follow its convention it has some out of the box behaviours.
 * When the user submits a form, it iterates over all inner "Form.Field"
 * elements and call their validation. After that, it returns a "formData"
 * object with all found errors and the final data
 *
 * The goal is to enable more declarative forms (no need to have refs, call
 * validation and collect data manually, build the submission data manually)
 *
 */
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form(props) {
        var _this = _super.call(this, props) || this;
        _this.fields = {};
        _this.handleRegister = function (field) {
            if (field.props.name !== undefined) {
                _this.fields[field.props.name] = field;
            }
        };
        _this.handleUnregister = function (field) {
            if (field.props.name !== undefined) {
                delete _this.fields[field.props.name];
            }
        };
        _this.handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var formData, data, key, fieldName;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        e.preventDefault();
                        return [4 /*yield*/, this.mapFormData()];
                    case 1:
                        formData = _c.sent();
                        if (Object.keys(formData.error).length > 0) {
                            data = formData.error;
                            key = Object.keys(data)[0];
                            fieldName = this.getFieldName(key, data);
                            this.scrollToField(fieldName);
                        }
                        (_b = (_a = this.props).onSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, formData);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.scrollToField = function (name) {
            var scroller = Scroll.scroller;
            scroller.scrollTo(name, {
                smooth: true,
                offset: -30,
            });
        };
        _this.state = {
            register: _this.handleRegister,
            unregister: _this.handleUnregister,
        };
        return _this;
    }
    Form.prototype.render = function () {
        return (React.createElement(FormContext.Provider, { value: this.state },
            React.createElement("form", { onSubmit: this.handleSubmit, name: this.props.name, noValidate: true, autoComplete: this.props.autoComplete }, this.props.children)));
    };
    Form.prototype.getFieldName = function (key, obj) {
        var nextObj = obj[key];
        if (typeof nextObj !== 'object') {
            return '';
        }
        var nextKey = Object.keys(nextObj)[0];
        var suffix = this.getFieldName(nextKey, nextObj);
        return "" + key + (suffix.length ? '.' : '') + suffix;
    };
    Form.prototype.mapFormData = function () {
        var _this = this;
        var formData = { data: {}, other: {}, error: {} };
        return Promise.all(
        // Trigger validation on all the fields
        Object.keys(this.fields)
            .filter(function (fieldName) { return _this.fields[fieldName].validate; })
            .map(function (fieldName) { return _this.fields[fieldName]; })
            .map(function (field) { return field.validate(field.state.value); })).then(function () {
            Object.keys(_this.fields)
                .map(function (fieldName) { return _this.fields[fieldName]; })
                .forEach(function (field) {
                var _a;
                set(formData.data, field.props.name, field.state.value);
                set(formData.other, field.props.name, field.state.other);
                (_a = field.state.errors) === null || _a === void 0 ? void 0 : _a.forEach(function (error) {
                    set(formData.error, field.props.name, {
                        name: error.name,
                        message: error.message,
                    });
                });
            });
            return formData;
        });
    };
    Form.Field = FormField;
    return Form;
}(React.Component));
export { Form };
