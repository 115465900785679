var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Fade } from '@atomic/obj.animation/animation.component.style';
import { FlashMessageCloseStyled, FlashMessageContentStyled, FlashMessageStyled, } from './flash-message.component.style';
var FlashIconTypes = {
    alert: FaIcon.FlashAlert,
    info: FaIcon.FlashInfo,
    success: FaIcon.FlashSuccess,
    warning: FaIcon.FlashWarning,
};
var timeToHide = 10 * 1000;
/**
 * This is an uncontrolled flash message.
 * It starts NOT hidden and after "timeToHide" seconds it is removed from DOM.
 * The user can also close it manually
 */
var FlashMessage = /** @class */ (function (_super) {
    __extends(FlashMessage, _super);
    function FlashMessage(props) {
        var _this = _super.call(this, props) || this;
        _this.timeoutList = [];
        _this.handleCloseClick = function () {
            _this.startRemoveFromDomAnimation();
        };
        _this.startCloseTimer = function () {
            if (_this.props.autoClose) {
                var timeout = +setTimeout(function () { return _this.startRemoveFromDomAnimation(); }, timeToHide);
                _this.timeoutList.push(timeout);
            }
        };
        _this.startRemoveFromDomAnimation = function () {
            _this.setState({ hidden: true }, function () {
                var timeout = +setTimeout(function () { return _this.removeFromDom(); }, 300);
                _this.timeoutList.push(timeout);
            });
        };
        _this.removeFromDom = function () {
            _this.setState({ remove: true }, function () {
                var _a, _b;
                _this.clearTimeoutList();
                (_b = (_a = _this.props).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
        };
        _this.clearTimeoutList = function () {
            _this.timeoutList.forEach(function (element) { return clearTimeout(element); });
        };
        _this.state = {
            hidden: false,
            remove: false,
        };
        return _this;
    }
    FlashMessage.prototype.componentDidMount = function () {
        this.startCloseTimer();
    };
    FlashMessage.prototype.componentWillUnmount = function () {
        this.clearTimeoutList();
    };
    FlashMessage.prototype.render = function () {
        var Icon = FlashIconTypes[this.props.type];
        var _a = this.props, children = _a.children, dismissible = _a.dismissible, other = __rest(_a, ["children", "dismissible"]);
        return this.state.remove ? null : (React.createElement(Fade, { show: !this.state.hidden },
            React.createElement(FlashMessageStyled, __assign({}, other),
                React.createElement(Icon, null),
                React.createElement(FlashMessageContentStyled, null, children),
                dismissible ? (React.createElement(FlashMessageCloseStyled, __assign({ onClick: this.handleCloseClick }, other),
                    React.createElement(FaIcon.Close, null))) : null)));
    };
    FlashMessage.defaultProps = {
        type: 'info',
        dismissible: true,
        outlined: false,
        autoClose: true,
    };
    return FlashMessage;
}(React.PureComponent));
export { FlashMessage };
