/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Typography } from '@mui/material';
import entCoinsIcon from '@assets/icons/ENTCoins.svg';
import lightning from '@assets/img/lightning.svg';
import rankNew from '@assets/img/rank-new.svg';
import { FontFamily } from '@atomic';
import { GameInfoItem, GameInfoWrapper } from './game-info.styled';
export var GameInfo = function (_a) {
    var userRank = _a.userRank, qtdeEntCoins = _a.qtdeEntCoins, qtdePontos = _a.qtdePontos, qtdeEnergias = _a.qtdeEnergias, open = _a.open;
    return (React.createElement(GameInfoWrapper, { open: open },
        (userRank === null || userRank === void 0 ? void 0 : userRank.posicao) && (React.createElement(GameInfoItem, null,
            React.createElement("img", { src: rankNew, alt: "ranking", style: { width: '22px', height: '22px' } }),
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '14px', color: 'rgba(73, 68, 60, 1)' }, variant: "body1", component: "div" }, open ? (userRank === null || userRank === void 0 ? void 0 : userRank.posicao) + "\u00BA lugar" : (userRank === null || userRank === void 0 ? void 0 : userRank.posicao) + "\u00BA"))),
        React.createElement(GameInfoItem, null,
            React.createElement("img", { src: entCoinsIcon, alt: "ranking", style: { width: '22px', height: '22px' } }),
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '14px', color: 'rgba(73, 68, 60, 1)' }, variant: "body1", component: "div" }, open ? qtdePontos + " pontos" : qtdePontos)),
        React.createElement(GameInfoItem, null,
            React.createElement("img", { src: lightning, alt: "ranking", style: { width: '22px', height: '22px' } }),
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '14px', color: 'rgba(73, 68, 60, 1)' }, variant: "body1", component: "div" }, open ? qtdeEnergias + " energias" : qtdeEnergias))));
};
