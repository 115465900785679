var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Container from 'typedi';
import { LogoCopyright } from '@app/components/atm.logo-copyright/logo-copyright';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { PhoneRegisterFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { ContainerStyled, ContentStyled, FormLabel, FormStyled, Logo, SimpleInput, SmallTitle, } from '@app/modules/authentication/authentication.styled';
import { AppPath } from '@app/routes';
import { getUsuarioIdFromClaims } from '@app/utils/shared-functions.utils';
import { Button, FlashMessageEnum, Form, PasswordField, Separator, TextField, Validators } from '@atomic';
import { POST_LOGIN_URL } from './login.page';
var Password = AuthStrings.Commom.Password;
var PUT_USUARIO_SEM_EMAIL = 'usuario/usuariosememail/primeiro-acesso';
var FRASES_SECRETAS_OPTIONS = [
    { value: 1, label: 'Nome do seu primeiro animal de estimação.' },
    { value: 2, label: 'Cidade onde sua mãe nasceu.' },
    { value: 3, label: 'Cidade onde seu pai nasceu.' },
    { value: 4, label: 'Seu Apelido de infância.' },
    { value: 5, label: 'Nome da sua primeira escola.' },
];
export var FirstAccessWithCPFPage = function (props) {
    var _a, _b, _c, _d;
    var usuarioId = (_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.usuarioId;
    var usuarioCpf = (_d = (_c = props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.usuarioCpf;
    var authStorageService = Container.get(AuthStorageService);
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var company = useStore('company')[0];
    var domain = useStore('domain')[0];
    var _e = React.useState(), password = _e[0], setPassword = _e[1];
    // const [isAccepted, setIsAccepted] = React.useState(false);
    var _f = React.useState(''), dataNascimento = _f[0], setDataNascimento = _f[1];
    // const [showModalTermosDeUso, setShowModalTermosDeUso] = React.useState(false);
    // const [currentPerguntSecr, setCurrentPerguntSecr] = React.useState<{ label: any; value: any }>(
    //   FRASES_SECRETAS_OPTIONS[0],
    // );
    var loginMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(POST_LOGIN_URL, __assign(__assign({}, params), { empresaIdentificador: domain }))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    var onLoginSuccess = function (response) {
        var _a, _b, _c, _d;
        axiosClient.defaults.headers['Authorization'] = "Bearer " + response.data.accessToken;
        var hasUseTerms = !!((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.userToken) === null || _b === void 0 ? void 0 : _b.termosDeUso) === null || _c === void 0 ? void 0 : _c.id);
        if (((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.recebeuEnergiasDoDia) && !hasUseTerms) {
            showFlashMessage(FlashMessageEnum.success, 'Parabéns! Você ganhou energias!');
        }
        if (hasUseTerms) {
            history.push({
                pathname: AppPath.UseTerms,
                state: { data: response === null || response === void 0 ? void 0 : response.data },
            });
        }
        else {
            showFlashMessage(FlashMessageEnum.success, 'Bem vindo! Aproveite o jogo!');
            var decodedToken = parseJwt(response.data.accessToken);
            authStorageService.login({
                id: response.data.userToken.id,
                email: response.data.userToken.email,
                usuarioId: getUsuarioIdFromClaims(response.data.userToken.claims),
                roles: decodedToken.roles,
                possuiAcessoAdmin: decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.possuiAcessoAdmin,
            }, response.data.accessToken);
            history.push({ pathname: AppPath.Tutorial });
        }
    };
    var onLoginError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Usuário ou senha não encontrados'); };
    var _g = useMutation(loginMutation, {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
    }), mutateLogin = _g.mutate, isLoginLoading = _g.isLoading;
    var completePersonalInfoMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.put(PUT_USUARIO_SEM_EMAIL, params)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var onSuccess = function (response) {
        var _a;
        if (!!(response === null || response === void 0 ? void 0 : response.data)) {
            mutateLogin({
                login: (_a = response.data) === null || _a === void 0 ? void 0 : _a.email,
                password: password,
            });
        }
        else {
            showFlashMessage(FlashMessageEnum.alert, 'Não foi possível validar seus dados. Confira-os e tente novamente');
        }
    };
    var onError = function () {
        return showFlashMessage(FlashMessageEnum.alert, 'Não foi possível validar seus dados. Confira-os e tente novamente.');
    };
    var _h = useMutation(completePersonalInfoMutation, {
        onSuccess: onSuccess,
        onError: onError,
    }), mutate = _h.mutate, isLoading = _h.isLoading;
    var handleSubmit = function (formData) {
        var hasFormError = Object.keys(formData.error).length > 0;
        if (!hasFormError) {
            mutate({
                dataNascimento: dataNascimento,
                cpf: usuarioCpf,
                senha: formData.data.password,
                // nomeCompleto: formData.data.fullname,
                // numeroDeCelular: unformatPhoneNumber(formData.data.phone),
                // pergunta: currentPerguntSecr.value,
                // respostaSecreta: formData.data.respostaSecreta,
                usuarioId: usuarioId,
            });
        }
    };
    var CPF = AuthStrings.Commom.CPF;
    return (React.createElement(ContainerStyled, { smallPaddingTop: true, style: { flexDirection: 'column' } },
        React.createElement(ContentStyled, { style: { position: 'relative' } },
            React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, centered: true }),
            React.createElement(SmallTitle, null, "Complete seus dados e crie sua senha"),
            React.createElement(Separator, null),
            React.createElement(FormStyled, null,
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(Form.Field, { name: 'cpf', label: CPF.Label },
                        React.createElement(TextField, { variant: "cpf", value: usuarioCpf, disabled: true })),
                    React.createElement("div", null,
                        React.createElement(FormLabel, null, "DATA DE NASCIMENTO"),
                        React.createElement(SimpleInput, { name: "dataNascimento", onChange: function (event) { var _a; return setDataNascimento((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, type: "date" })),
                    React.createElement(Form.Field, { onValueChange: function (value) { return setPassword(value); }, name: PhoneRegisterFormFields.Password, label: Password.NewPasswordLabel, validators: [Validators.Required()] },
                        React.createElement(PasswordField, null)),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Password, label: Password.SamePasswordLabel, validators: [Validators.EqualsValue(password, Password.SamePasswordError), Validators.Required()] },
                        React.createElement(PasswordField, null)),
                    React.createElement(Separator, null),
                    React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading || isLoginLoading }, "ENTRAR")))),
        React.createElement("div", { style: { alignSelf: 'center' } },
            React.createElement(LogoCopyright, null))));
};
