var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import Bowser from 'bowser';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import Container from 'typedi';
import AddToHomePopup from '@app/components/add-to-home-pop-up';
import { Logo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AuthPath } from '@app/modules/authentication/auth-path';
import { LoginFormFields, LoginFormPlaceholders } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { AppPath } from '@app/routes';
import { getUsuarioIdFromClaims, getValueFromClaimsByType, unformatCPF } from '@app/utils/shared-functions.utils';
import { Button, FlashMessageEnum, Form, PasswordFieldV2, Separator, TextField, Validators } from '@atomic';
import { ButtonsWrapper, DivLink, FormWrapper, LogoWrapper, TelaDeLogin } from './login.page-v2.styled';
var Login = AuthStrings.Commom.Login;
var _a = AuthStrings.Login, ButtonText = _a.ButtonText, RecoverPasswordLink = _a.RecoverPasswordLink;
export var POST_LOGIN_URL = 'auth/login';
export var POST_CPF_LOGIN_URL = 'auth/cpf-login';
export var LoginPage = function () {
    var authStorageService = Container.get(AuthStorageService);
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var company = useStore('company')[0];
    var _a = useState(''), browserName = _a[0], setBrowserName = _a[1];
    var _b = useState(''), browserOSName = _b[0], setBrowserOSName = _b[1];
    var _c = useState(false), showPopup = _c[0], setShowPopup = _c[1];
    useEffect(function () {
        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        var showAddToHomeScreen = localStorage.getItem('showAddToHomeScreen') !== 'false';
        if (isMobile && showAddToHomeScreen) {
            var browser = Bowser.getParser(window.navigator.userAgent);
            setBrowserName(browser.getBrowserName());
            setBrowserOSName(browser.getOSName());
            setShowPopup(true);
        }
    }, []);
    var loginMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(params.cpf ? POST_CPF_LOGIN_URL : POST_LOGIN_URL, {
                        login: params.login,
                        cpf: params.cpf,
                        password: params.password,
                        empresaId: company.id,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    var onSuccess = function (response) {
        var _a, _b, _c, _d;
        axiosClient.defaults.headers['Authorization'] = "Bearer " + response.data.accessToken;
        var hasUseTerms = !!((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.userToken) === null || _b === void 0 ? void 0 : _b.termosDeUso) === null || _c === void 0 ? void 0 : _c.id);
        if (((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.recebeuEnergiasDoDia) && !hasUseTerms) {
            showFlashMessage(FlashMessageEnum.success, 'Parabéns! Você ganhou energias!');
        }
        if (hasUseTerms) {
            history.push({
                pathname: AppPath.UseTerms,
                state: { data: response === null || response === void 0 ? void 0 : response.data },
            });
        }
        else {
            var decodedToken = parseJwt(response.data.accessToken);
            authStorageService.login({
                id: response.data.userToken.id,
                email: response.data.userToken.email,
                usuarioId: getUsuarioIdFromClaims(response.data.userToken.claims),
                nomePerfil: getValueFromClaimsByType(response.data.userToken.claims, 'role'),
                roles: decodedToken.roles,
                possuiAcessoAdmin: decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.possuiAcessoAdmin,
            }, response.data.accessToken);
            history.push({ pathname: AppPath.Map });
        }
    };
    var onError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Usuário ou senha não encontrados'); };
    var _d = useMutation(loginMutation, {
        onSuccess: onSuccess,
        onError: onError,
    }), mutate = _d.mutate, isLoading = _d.isLoading;
    var handleSubmit = function (formData) {
        var hasCpf = true;
        var hasFormError = Object.keys(formData.error).length > 0;
        if (formData.data.login.includes('@'))
            hasCpf = false;
        if (!hasFormError) {
            var requestData = {
                login: !hasCpf ? formData.data.login : undefined,
                cpf: hasCpf ? unformatCPF(formData.data.login) : undefined,
                password: formData.data.password,
            };
            mutate(requestData);
        }
    };
    var handleRemindMeLaterButton = function () {
        setShowPopup(false);
    };
    var handleIgnoreButton = function () {
        setShowPopup(false);
        localStorage.setItem('showAddToHomeScreen', 'false');
    };
    return (React.createElement(TelaDeLogin, { src: company === null || company === void 0 ? void 0 : company.imgBackgroundLogin },
        React.createElement(LogoWrapper, null,
            React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro })),
        React.createElement("div", null, showPopup && (React.createElement(AddToHomePopup, { browserOSName: browserOSName, browserName: browserName, onRemindMeLaterButton: handleRemindMeLaterButton, onIgnoreButton: handleIgnoreButton }))),
        React.createElement(FormWrapper, null,
            (company === null || company === void 0 ? void 0 : company.slogan) && React.createElement("h3", null, company === null || company === void 0 ? void 0 : company.slogan),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement("div", { className: "formularioInput" },
                    React.createElement(Form.Field, { name: LoginFormFields.Login, validators: [Validators.Required(), Validators.LoginPatternRegex(Login.Error)] },
                        React.createElement(TextField, { type: "text", style: { borderRadius: 15 }, placeholder: LoginFormPlaceholders.Login }))),
                React.createElement(Separator, null),
                React.createElement(Form.Field, { name: LoginFormFields.Password, validators: [Validators.Required()] },
                    React.createElement(PasswordFieldV2, null)),
                React.createElement(DivLink, null,
                    React.createElement(Link, { to: AuthPath.RecoverPassword },
                        " ",
                        RecoverPasswordLink,
                        " ")),
                React.createElement(ButtonsWrapper, null,
                    React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading, style: { borderRadius: 15 } }, ButtonText))))));
};
