var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { Logo as AuthLogo } from '@app/components/atm.logo/logo.component';
import { H3, H4 } from '@atomic';
import { Label } from '../checklist/components/action-plan-modal/action-plan-modal.styled';
import { BackButtonArrow } from '../course-library/components/course-library-header/course-library-header.component.style';
var appearFromLeft = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nfrom {\n  opacity: 0;\n  transform: translateX(-50px);\n}\nto {\n  opacity: 1;\n  transform: translateX(0);\n}\n"], ["\nfrom {\n  opacity: 0;\n  transform: translateX(-50px);\n}\nto {\n  opacity: 1;\n  transform: translateX(0);\n}\n"])));
export var ContainerStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  ", "\n"], ["\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  ", "\n"])), function (props) { return (props.smallPaddingTop ? 'padding-top: 1rem;' : 'padding-top: 3rem;'); });
export var ContentStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 1rem;\n  padding-right: 1rem;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  ", "\n  width: 100%;\n  max-width: 760px;\n\n  animation: ", " 1s;\n"], ["\n  padding-left: 1rem;\n  padding-right: 1rem;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  ", "\n  width: 100%;\n  max-width: 760px;\n\n  animation: ", " 1s;\n"])), function (props) { return !props.top && "place-content: center;"; }, appearFromLeft);
export var FormStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 90%;\n"], ["\n  width: 90%;\n"])));
export var FormStyledWithFontColorBlack = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  label {\n    color: black;\n  }\n"], ["\n  width: 100%;\n  label {\n    color: black;\n  }\n"])));
export var ArrowLeftStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 16px;\n  padding-top: 24px;\n"], ["\n  margin-left: 16px;\n  padding-top: 24px;\n"])));
export var UseTermsLink = styled.a.attrs(function () { return ({ target: '_blank', rel: 'noreferrer' }); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  text-decoration: revert;\n  position: relative;\n  top: -2px;\n  margin-left: 0.3rem;\n  font-weight: 500;\n  text-transform: uppercase;\n"], ["\n  font-size: 14px;\n  text-decoration: revert;\n  position: relative;\n  top: -2px;\n  margin-left: 0.3rem;\n  font-weight: 500;\n  text-transform: uppercase;\n"])));
export var AlreadyRegisteredLinkWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: white;\n  display: flex;\n  justify-content: end;\n"], ["\n  color: white;\n  display: flex;\n  justify-content: end;\n"])));
export var WithoutEmailLinkWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: white;\n  display: flex;\n  justify-content: end;\n"], ["\n  color: white;\n  display: flex;\n  justify-content: end;\n"])));
export var Title = styled(H3)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-bottom: 0;\n"], ["\n  margin-bottom: 0;\n"])));
export var SmallTitle = styled(H4)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 70%;\n"], ["\n  width: 70%;\n"])));
export var SubTitle = styled(H3)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
export var BackButtonWrapper = styled(BackButtonArrow)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  padding-left: 10%;\n  color: ", ";\n\n  svg {\n    color: ", ";\n  }\n  span {\n    padding-left: 6px;\n  }\n"], ["\n  width: 100%;\n  padding-left: 10%;\n  color: ", ";\n\n  svg {\n    color: ", ";\n  }\n  span {\n    padding-left: 6px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var CustomAnchor = styled.a(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  text-decoration: underscore;\n  color: blue;\n  font-weight: 700;\n"], ["\n  text-decoration: underscore;\n  color: blue;\n  font-weight: 700;\n"])));
export var Logo = styled(AuthLogo)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  max-height: 24vh;\n  width: unset !important;\n  border-radius: 50%;\n"], ["\n  max-height: 24vh;\n  width: unset !important;\n  border-radius: 50%;\n"])));
export var FormLabel = styled(Label)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: #ffffff;\n  font-size: 14px;\n  margin-bottom: 4px;\n  font-weight: bold;\n  padding-bottom: 4px;\n"], ["\n  color: #ffffff;\n  font-size: 14px;\n  margin-bottom: 4px;\n  font-weight: bold;\n  padding-bottom: 4px;\n"])));
export var SimpleInput = styled.input(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 100%;\n  height: 3.1rem;\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: #ebebeb;\n"], ["\n  width: 100%;\n  height: 3.1rem;\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: #ebebeb;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
