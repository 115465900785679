var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { axiosClient } from '@app/configure-app';
import { CloseModalButton } from '@app/modules/track/modal/modal.component.style';
import { H1 } from '@atomic';
import { Modal } from '@atomic/obj.modal';
import { CloseIconStyled } from '@atomic/obj.modal/modal.component.style';
import { ButtonAvaliacao, ButtonAvaliar, CenterModal, CommentInput } from './avaliation-modal.styled';
export var AvaliationModal = function (_a) {
    var isOpenModal = _a.isOpenModal, setIsOpenModal = _a.setIsOpenModal, tarefa = _a.tarefa;
    var _b = React.useState(11), avaliacaoValue = _b[0], setAvaliacaoValue = _b[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _c = React.useState(false), isOpenComment = _c[0], setOpenComment = _c[1];
    var _d = React.useState(''), comment = _d[0], setComment = _d[1];
    var colors = [
        'red',
        '#ff3a00',
        '#ff5d00',
        '#ff9800',
        '#ffd300',
        '#d9ff00',
        '#b6ff00',
        '#92ff00',
        '#57ff00',
        '#28ff00',
        '#00ff1e',
    ];
    var mutate = useMutation(function (axiosData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post('avaliacao', {
                        faseId: axiosData.faseId,
                        nota: axiosData.nota,
                        comentario: axiosData.comentario,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }).mutate;
    // const navigateToMap = () => {
    //   history.push({
    //     pathname: `${AppPath.Map}`,
    //   });
    // };
    // const navigateToTrack = () => {
    //   history.push(AppPath.Track.replace(':courseCode', `${loadedCourse.codigo}`));
    // };
    // const goToNextPage = () => {
    //   if (hasNextQuiz) {
    //     navigateToTrack();
    //   } else {
    //     navigateToMap();
    //   }
    // };
    // const handleExitModal = () => {
    //   setIsOpenModal(false);
    //   // goToNextPage();
    // };
    var handleOnAvaliarClick = function () {
        mutate({
            faseId: tarefa.faseId,
            nota: avaliacaoValue,
            comentario: comment,
        });
        // goToNextPage();
        setIsOpenModal(false);
    };
    var handleOnValueClick = function (index) {
        setAvaliacaoValue(index);
        setOpenComment(true);
    };
    return (React.createElement(Modal, { opened: isOpenModal },
        React.createElement(CloseModalButton, null,
            React.createElement(CloseIconStyled, { onClick: function () { return setIsOpenModal(false); } })),
        React.createElement(CenterModal, null,
            React.createElement(H1, { style: { fontWeight: 'bold' } }, "AVALIE ESTA FASE"),
            React.createElement("p", { style: { paddingLeft: '18px', paddingRight: '30px' } }, "A sua opini\u00E3o \u00E9 muito importante para n\u00F3s!"),
            React.createElement("div", { style: { display: 'flex', width: '100%', justifyContent: 'center' } }, colors.map(function (_color, index) {
                return (React.createElement(ButtonAvaliacao, { key: index, onClick: function () { return handleOnValueClick(index); }, style: { backgroundColor: colors[index] }, className: classNames({
                        'button-selected': index === avaliacaoValue,
                    }) }, index));
            })),
            isOpenComment && avaliacaoValue <= 6 ? (React.createElement("div", null,
                React.createElement("p", { style: { textAlign: 'center', marginBottom: '10px' } }, "No que podemos melhorar?"),
                React.createElement(CommentInput, { placeholder: "Insira o texto aqui...", onChange: function (e) {
                        setComment(e.target.value);
                    } }))) : null,
            React.createElement(ButtonAvaliar, { onClick: handleOnAvaliarClick }, "AVALIAR"))));
};
