var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Slider } from '@mui/material';
import styled from 'styled-components';
export var StyledErrorMessageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: red;\n  font-size: 0.8rem;\n"], ["\n  color: red;\n  font-size: 0.8rem;\n"])));
export var StyledSwitchWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-left: 20px;\n  margin-top: 20px;\n"], ["\n  display: flex;\n  margin-left: 20px;\n  margin-top: 20px;\n"])));
export var StyledSwitchLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
export var StyledSliderWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding-left: 20px;\n  padding-right: 20px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding-left: 20px;\n  padding-right: 20px;\n"])));
export var CommentCounter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var GradientSlider = styled(Slider)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 300px;\n\n  .MuiSlider-rail {\n    opacity: 1;\n    background: linear-gradient(to right, #ff0000, #00ff00);\n    height: 8px;\n    border-radius: 4px;\n  }\n\n  .MuiSlider-track {\n    height: 8px;\n    border-radius: 4px;\n  }\n\n  .MuiSlider-thumb {\n    width: 16px;\n    height: 16px;\n    background-color: white;\n    border: 2px solid #fff;\n    &:hover,\n    &.Mui-focusVisible {\n      box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.26);\n    }\n  }\n\n  .MuiSlider-mark {\n    display: none;\n  }\n\n  .MuiSlider-track {\n    background-color: transparent;\n    border-color: transparent;\n  }\n"], ["\n  width: 300px;\n\n  .MuiSlider-rail {\n    opacity: 1;\n    background: linear-gradient(to right, #ff0000, #00ff00);\n    height: 8px;\n    border-radius: 4px;\n  }\n\n  .MuiSlider-track {\n    height: 8px;\n    border-radius: 4px;\n  }\n\n  .MuiSlider-thumb {\n    width: 16px;\n    height: 16px;\n    background-color: white;\n    border: 2px solid #fff;\n    &:hover,\n    &.Mui-focusVisible {\n      box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.26);\n    }\n  }\n\n  .MuiSlider-mark {\n    display: none;\n  }\n\n  .MuiSlider-track {\n    background-color: transparent;\n    border-color: transparent;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
