var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Circle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100px;\n  border-radius: 50%;\n  padding: 13px;\n  background: radial-gradient(#a4a1ff, #2542ff);\n"], ["\n  width: 100px;\n  border-radius: 50%;\n  padding: 13px;\n  background: radial-gradient(#a4a1ff, #2542ff);\n"])));
export var CenterCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"])));
export var ContainerControll = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n"])));
export var Line = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 15px;\n  border-bottom: 3px dotted #666666;\n  opacity: 0.5;\n  width: 100%;\n  align-self: center;\n"], ["\n  margin-top: 15px;\n  border-bottom: 3px dotted #666666;\n  opacity: 0.5;\n  width: 100%;\n  align-self: center;\n"])));
export var Pts = styled.b(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #fff;\n"], ["\n  font-size: 16px;\n  color: #fff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
