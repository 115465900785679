import * as React from 'react';
import { FaIcon } from '@atomic';
import { HelpButton } from './learning-button.styled';
export var LearningButton = function (_a) {
    var handleOnLearningBtnClick = _a.handleOnLearningBtnClick, learningData = _a.learningData;
    var hasLearningContent = !!(learningData === null || learningData === void 0 ? void 0 : learningData.arquivo) || !!(learningData === null || learningData === void 0 ? void 0 : learningData.video) || !!(learningData === null || learningData === void 0 ? void 0 : learningData.conteudoHtml);
    return (React.createElement(React.Fragment, null, hasLearningContent && (React.createElement(HelpButton, { outlined: true, onClick: handleOnLearningBtnClick },
        React.createElement(FaIcon.LightBulb, null),
        React.createElement("span", null, "AJUDA")))));
};
