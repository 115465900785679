var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@app/components/atm.card/card.styled';
export var StyledChamadoCard = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 0.5rem 0.5rem 1rem 0.5rem;\n\n  p {\n    line-height: 1rem;\n    font-size: 0.8rem;\n  }\n"], ["\n  cursor: pointer;\n  padding: 0.5rem 0.5rem 1rem 0.5rem;\n\n  p {\n    line-height: 1rem;\n    font-size: 0.8rem;\n  }\n"])));
export var Summary = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"])));
export var DataCadastro = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"], ["\n  display: inline-block;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"])));
export var TopRightPositionWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 8px;\n  right: 8px;\n\n  svg {\n    cursor: pointer;\n  }\n"], ["\n  position: absolute;\n  top: 8px;\n  right: 8px;\n\n  svg {\n    cursor: pointer;\n  }\n"])));
export var LinkToDetails = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: 5px;\n  bottom: 5px;\n  font-size: 0.9rem;\n  color: ", ";\n"], ["\n  position: absolute;\n  right: 5px;\n  bottom: 5px;\n  font-size: 0.9rem;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
