var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useStore } from 'react-context-hook';
import { BsLightningChargeFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import useSound from 'use-sound';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import entCoinsIcon from '@assets/icons/ENTCoins.svg';
import rocket from '@assets/img/rocket-solid.svg';
import shield from '@assets/img/shield-alt-solid.svg';
import { ActivityIndicator, StyledContainer } from '@atomic';
import OptionalCourseItem from './components/optional-course-item/optional-course-item.component';
import { StoreHeader } from './components/store-header';
import { ButtonBox, ButtonBuy, Content, Icon, List, ListsWrapper, LoadingWrapper, StoreItem, TextBox, } from './store.page.styled';
var GET_USER_URL = 'usuario';
var GET_OPTIONAL_COURSES_URL = 'loja/curso';
var GET_EXTRAS_URL = 'loja/extra';
export var Store = function () {
    var _a, _b, _c;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    var wrongAnswerSound = require('@assets/sounds/wrong-answer-sound.mp3');
    var isMute = useStore('mute')[0];
    var showFlashMessage = useFlashMessage()[0];
    var playPurchaseSound = useSound(rightAnswerSound, { volume: 0.5 })[0];
    var playFailedPurchaseSound = useSound(wrongAnswerSound, { volume: 0.5 })[0];
    var _d = useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_USER_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), user = _d.data, refetchUserInfo = _d.refetch, removeUserQueryCache = _d.remove;
    var _e = useQuery('getOptionalsCourses', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_OPTIONAL_COURSES_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    console.error(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), getOptionalCoursesResponse = _e.data, isCoursesLoading = _e.isLoading, refetchOptionalCourses = _e.refetch;
    var _f = useQuery('getExtras', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_EXTRAS_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    console.error(error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), getExtrasResponse = _f.data, isExtrasLoading = _f.isLoading;
    var getExtrasId = function (skin) {
        var _a, _b;
        return (_b = (_a = getExtrasResponse === null || getExtrasResponse === void 0 ? void 0 : getExtrasResponse.data) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.nome == skin; })) === null || _b === void 0 ? void 0 : _b.id;
    };
    var getCost = function (skin) {
        var _a, _b;
        return (_b = (_a = getExtrasResponse === null || getExtrasResponse === void 0 ? void 0 : getExtrasResponse.data) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.nome == skin; })) === null || _b === void 0 ? void 0 : _b.valor;
    };
    var _g = useMutation(function (skin) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post('loja', {
                        dataCadastro: '2022-02-05T15:10:09.795Z',
                        cursoId: 0,
                        extraId: getExtrasId(skin),
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            if (!isMute) {
                playPurchaseSound();
            }
            showFlashMessage('success', 'Parabéns pela compra!');
        },
        onError: function () {
            showFlashMessage('alert', 'Erro, tente novamente mais tarde');
        },
        onSettled: function () {
            removeUserQueryCache();
            refetchUserInfo();
        },
    }), mutateSkin = _g.mutate, isPurchaseLoading = _g.isLoading;
    var handleBuySkinClick = function (skin) {
        var _a;
        if (((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.moedas) >= getCost(skin)) {
            if (isExtrasLoading) {
                return;
            }
            mutateSkin(skin);
        }
        else {
            if (!isMute) {
                playFailedPurchaseSound();
            }
            showFlashMessage('alert', 'Você não possui ENT coins para esta compra...');
        }
    };
    var hasEntcoinsEnough = function (optionalCourse) {
        var _a;
        return ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.moedas) >= (optionalCourse === null || optionalCourse === void 0 ? void 0 : optionalCourse.valor);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors" },
            React.createElement(StoreHeader, { coins: (_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.moedas })),
        React.createElement(Content, { style: { paddingBottom: '66px', position: 'relative' } },
            isPurchaseLoading && (React.createElement(LoadingWrapper, null,
                React.createElement(ActivityIndicator, { type: "spinner" }))),
            React.createElement(ListsWrapper, null,
                React.createElement(List, null,
                    React.createElement("h2", null, "ITENS"),
                    React.createElement(StoreItem, null,
                        React.createElement(Icon, null,
                            React.createElement("div", null,
                                React.createElement(BsLightningChargeFill, { style: { color: 'black', fontSize: '1.6rem' } }))),
                        React.createElement(TextBox, null,
                            React.createElement("h3", null, "Energia"),
                            React.createElement("p", null, "Compre um combo de 6 energias")),
                        React.createElement(ButtonBox, null,
                            React.createElement(ButtonBuy, { onClick: function () { return handleBuySkinClick('Energia'); } },
                                React.createElement("img", { src: entCoinsIcon, alt: "" }),
                                React.createElement("span", null, getCost('Energia'))))),
                    React.createElement(StoreItem, null,
                        React.createElement(Icon, null,
                            React.createElement("div", null,
                                React.createElement("img", { src: shield, alt: "Escudo de Jogo" }))),
                        React.createElement(TextBox, null,
                            React.createElement("h3", null, "Escudo"),
                            React.createElement("p", null, "Tenha um escudo e n\u00E3o perca suas ofensivas ao n\u00E3o jogar por 1 dia")),
                        React.createElement(ButtonBox, null,
                            React.createElement(ButtonBuy, { onClick: function () { return handleBuySkinClick('Defensiva'); } },
                                React.createElement("img", { src: entCoinsIcon, alt: "" }),
                                React.createElement("span", null, getCost('Defensiva'))))),
                    React.createElement(StoreItem, null,
                        React.createElement(Icon, null,
                            React.createElement("div", null,
                                React.createElement("img", { src: rocket, alt: "Turbo" }))),
                        React.createElement(TextBox, null,
                            React.createElement("h3", null, "Turbo de Moedas"),
                            React.createElement("p", null, "Ganhe o dobre de moedas por 24 horas a partir da compra")),
                        React.createElement(ButtonBox, null,
                            React.createElement(ButtonBuy, { onClick: function () { return handleBuySkinClick('Turbo'); } },
                                React.createElement("img", { src: entCoinsIcon, alt: "" }),
                                React.createElement("span", null, getCost('Turbo')))))),
                React.createElement(List, null,
                    React.createElement("h2", null, "PR\u00D3XIMO N\u00CDVEL"),
                    isCoursesLoading && (React.createElement(StyledContainer, { withPadding: true, alignCenter: true, justifyCenter: true },
                        React.createElement(ActivityIndicator, { size: "2x", type: "spinner" }))),
                    ((_b = getOptionalCoursesResponse === null || getOptionalCoursesResponse === void 0 ? void 0 : getOptionalCoursesResponse.data) === null || _b === void 0 ? void 0 : _b.length) === 0 && !isCoursesLoading && (React.createElement(StyledContainer, { withPadding: true, alignCenter: true, justifyCenter: true }, "Voc\u00EA j\u00E1 possui todos os n\u00EDveis que estavam disponiveis!")), (_c = getOptionalCoursesResponse === null || getOptionalCoursesResponse === void 0 ? void 0 : getOptionalCoursesResponse.data) === null || _c === void 0 ? void 0 :
                    _c.map(function (optionalCourse, idx) { return (React.createElement(OptionalCourseItem, { key: idx, optionalCourse: optionalCourse, refetchOptionalCourses: refetchOptionalCourses, hasEntcoinsEnough: hasEntcoinsEnough(optionalCourse) })); })))),
        React.createElement(BottomNav, null)));
};
