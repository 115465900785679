var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { BsCamera, BsCheckCircle, BsJournalText, BsPlus, BsQuestionCircle, BsXCircle } from 'react-icons/all';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import ImagePreview from '@app/components/image-preview/image-preview';
import { axiosClient } from '@app/configure-app';
import { CloseIconStyled } from '@app/modules/track/modal/modal.component.style';
import { Button, FaIcon, Row } from '@atomic';
import { ActionPlan, ActionPlanSubmittedInfo, ActionsWrapper, EnviarFotoSubmittedInfo, EnviarObservacaoSubmittedInfo, ItemButtonsWrapper, Question, } from '../components/checklist-item/checklist-item.styled';
import { EnviarFoto, EnviarObservacao } from '../components/checklist-item/checklist-item.styled';
var styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
export var ChecklistRecusadoItemDetalhado = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var checklistItem = _a.checklistItem, isDisabled = _a.isDisabled, handleOnClick = _a.handleOnClick, itemState = _a.itemState, handleOnActionPlanClick = _a.handleOnActionPlanClick, handleOnEnviarFotoClick = _a.handleOnEnviarFotoClick, handleOnObservacaoClick = _a.handleOnObservacaoClick, isActionPlanRegistered = _a.isActionPlanRegistered, isFotoEnviada = _a.isFotoEnviada, isObservacaoEnviada = _a.isObservacaoEnviada, fotoPreview = _a.fotoPreview, removeFotoPreview = _a.removeFotoPreview, boxBackgroundColor = _a.boxBackgroundColor;
    var _v = useState(false), showForms = _v[0], setShowForms = _v[1];
    var _w = useState(undefined), itemStateLocal = _w[0], setItemStateLocal = _w[1];
    var _x = useState([]), fotoUrls = _x[0], setFotoUrls = _x[1];
    var addOrReplaceRespostaGestor = function (isEfetivo) {
        setItemStateLocal(isEfetivo);
        setShowForms(true);
        handleOnClick(checklistItem.id, isEfetivo, checklistItem);
    };
    var _y = useState(false), mostrarModalConformidade = _y[0], setmostrarModalConformidade = _y[1];
    var conformidadeHandler = function () {
        setmostrarModalConformidade(true);
    };
    var fecharModalConformidadeHandler = function () {
        setmostrarModalConformidade(false);
    };
    var removerFoto = function () {
        //no-op
        //mutate();
    };
    var _z = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post("checklistunidade/item/resposta/" + checklistItem.respostas[0].id + "/upload-foto", { ArquivoFotoBase64: '' })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            if (data === null || data === void 0 ? void 0 : data.succeeded) {
                removeFotoPreview();
            }
        },
        onError: function (data) {
            console.log('erro', data);
        },
    }), mutate = _z.mutate, isLoading = _z.isLoading;
    useEffect(function () {
        var _a, _b, _c, _d;
        var urls = ((_c = (_b = (_a = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _a === void 0 ? void 0 : _a.planoAcaoChecklistUnidade) === null || _b === void 0 ? void 0 : _b.resposta) === null || _c === void 0 ? void 0 : _c.urlFotoRespostaList) || ((_d = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _d === void 0 ? void 0 : _d.urlFotoRespostaList);
        setFotoUrls(urls || []);
    }, [checklistItem]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: mostrarModalConformidade, onClose: fecharModalConformidadeHandler, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: styleModal },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                    React.createElement(CloseIconStyled, { onClick: fecharModalConformidadeHandler })), fotoUrls === null || fotoUrls === void 0 ? void 0 :
                fotoUrls.map(function (url, idx) { return (React.createElement("img", { key: idx, src: url, alt: "", style: {
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        maxHeight: '300px',
                    } })); }),
                React.createElement(Typography, { id: "modal-modal-title", variant: "h6", component: "h2", style: { display: 'flex', justifyContent: 'center' } }, "Informa\u00E7\u00F5es Adicionais"),
                React.createElement(Typography, { id: "modal-modal-description", sx: { mt: 2 } }, checklistItem.conformidade),
                React.createElement("img", { src: checklistItem.imagemConformidade }),
                React.createElement(Button, { onClick: fecharModalConformidadeHandler }, "Ok"))),
        React.createElement("div", { className: "isRequired", style: {
                borderTop: '1px solid rgba(73, 68, 60, 0.7)',
                borderBottom: '1px solid rgba(73, 68, 60, 0.7)',
                background: boxBackgroundColor !== null && boxBackgroundColor !== void 0 ? boxBackgroundColor : 'none',
                marginBottom: '5px',
            } },
            React.createElement(Question, null,
                checklistItem.titulo,
                React.createElement("button", { onClick: conformidadeHandler, style: {
                        background: 'none',
                        border: 'none',
                        paddingLeft: '5px',
                    } },
                    React.createElement(BsQuestionCircle, { fontSize: 20 }))),
            React.createElement(Box, null, fotoUrls === null || fotoUrls === void 0 ? void 0 : fotoUrls.map(function (url, idx) { return (React.createElement("img", { key: idx, src: url, alt: "", style: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '10px',
                    maxHeight: '300px',
                } })); })),
            React.createElement(Box, null,
                React.createElement(Row, { style: { marginLeft: '0px' } },
                    "Resposta anterior:",
                    React.createElement("div", { style: { marginLeft: '2px', marginTop: '2px' } }, ((_b = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _b === void 0 ? void 0 : _b.isEfetivo) === true ? (React.createElement(FaCheckCircle, { color: "green" })) : ((_c = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _c === void 0 ? void 0 : _c.isEfetivo) === false ? (React.createElement(FaTimesCircle, { color: "red" })) : (React.createElement(FaTimesCircle, { color: "grey" }))))),
            React.createElement(Box, null,
                "Quando:", " " + moment((_h = (((_f = (_e = (_d = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _d === void 0 ? void 0 : _d.planoAcaoChecklistUnidade) === null || _e === void 0 ? void 0 : _e.resposta) === null || _f === void 0 ? void 0 : _f.dataResposta) || ((_g = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _g === void 0 ? void 0 : _g.dataResposta))) !== null && _h !== void 0 ? _h : '').format('DD/MM/YYYY H:mm')),
            ((_j = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _j === void 0 ? void 0 : _j.observacao) && (React.createElement(Box, null,
                "Observa\u00E7\u00E3o:", " " + ((_k = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _k === void 0 ? void 0 : _k.observacao))),
            ((_m = (_l = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _l === void 0 ? void 0 : _l.planoAcaoChecklistUnidade) === null || _m === void 0 ? void 0 : _m.descricaoResolucao) && (React.createElement(Box, null,
                "Plano de a\u00E7\u00E3o enviado: ", " " + ((_p = (_o = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _o === void 0 ? void 0 : _o.planoAcaoChecklistUnidade) === null || _p === void 0 ? void 0 : _p.descricaoResolucao))),
            ((_r = (_q = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _q === void 0 ? void 0 : _q.auditorias[0]) === null || _r === void 0 ? void 0 : _r.justificativaAuditor) && (React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '18px',
                    columnGap: '4px',
                } },
                React.createElement(Box, null, "Justificativa:"),
                React.createElement("p", { style: { fontWeight: 'bold', fontSize: '18px' } }, " " + ((_t = (_s = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _s === void 0 ? void 0 : _s.auditorias[0]) === null || _t === void 0 ? void 0 : _t.justificativaAuditor)))),
            React.createElement(ItemButtonsWrapper, { style: { marginTop: '16px' } },
                React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                        backgroundColor: itemStateLocal === false ? '#C70000' : '#ffffff',
                        border: '2px solid #C70000',
                        borderRadius: '8px',
                        width: '100%',
                        minHeight: '37px',
                        textAlign: 'center',
                    }, onClick: function () { return addOrReplaceRespostaGestor(false); } },
                    React.createElement(BsXCircle, { fontSize: 24, style: {
                            color: itemStateLocal === false ? '#ffffff' : '#C70000',
                        } })),
                React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                        backgroundColor: itemStateLocal === true ? '#008425' : '#ffffff',
                        width: '100%',
                        border: '2px solid #008425',
                        borderRadius: '8px',
                        minHeight: '37px',
                        textAlign: 'center',
                        color: '#008425',
                    }, onClick: function () { return addOrReplaceRespostaGestor(true); } },
                    React.createElement(BsCheckCircle, { fontSize: 24, style: {
                            color: itemStateLocal === true ? '#ffffff' : '#008425',
                        } }))),
            showForms && (React.createElement(React.Fragment, null,
                React.createElement(ActionsWrapper, null,
                    React.createElement(EnviarFoto, { onClick: handleOnEnviarFotoClick },
                        React.createElement(EnviarFotoSubmittedInfo, null,
                            isFotoEnviada ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsCamera, { style: { fontSize: '20px', color: '#49443C' } })),
                            React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } },
                                "Imagem ",
                                checklistItem.fotoObrigatoria ? '*' : ''))),
                    React.createElement(EnviarObservacao, { onClick: handleOnObservacaoClick },
                        React.createElement(EnviarObservacaoSubmittedInfo, null,
                            isObservacaoEnviada ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsJournalText, { style: { fontSize: '20px', color: '#49443C' } })),
                            React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } }, "Observa\u00E7\u00E3o")))),
                React.createElement(ActionsWrapper, null, itemStateLocal === false && (React.createElement(ActionPlan, { onClick: handleOnActionPlanClick },
                    React.createElement(ActionPlanSubmittedInfo, { style: { borderColor: isActionPlanRegistered ? '#008425' : '#49443C' } },
                        isActionPlanRegistered ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsPlus, { style: { fontSize: '25px', color: '#49443C' } })),
                        React.createElement("span", { style: {
                                color: isActionPlanRegistered ? '#008425' : '#49443C',
                                fontWeight: 'bold',
                            } }, "Plano de A\u00E7\u00E3o"))))))), (_u = fotoPreview === null || fotoPreview === void 0 ? void 0 : fotoPreview.fotoUrlList) === null || _u === void 0 ? void 0 :
            _u.map(function (url, idx) { return (React.createElement(ImagePreview, { key: idx, imageUrl: url })); }))));
};
