var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { ListItemComponent } from '@app/components/obj.list-item';
import { axiosClient } from '@app/configure-app';
import { OuterDiv } from '@app/modules/questionnarie/questionnaire.styled';
import { AppPath } from '@app/routes';
var GET_CATEG_QUESTIONARIOS_URL = 'questionario-empresa';
var FilterTypeResearch;
(function (FilterTypeResearch) {
    FilterTypeResearch[FilterTypeResearch["RESEARCH"] = 0] = "RESEARCH";
    FilterTypeResearch[FilterTypeResearch["RESEARCH_STARTED"] = 1] = "RESEARCH_STARTED";
    FilterTypeResearch[FilterTypeResearch["RESEARCH_NOT_STARTED"] = 2] = "RESEARCH_NOT_STARTED";
    FilterTypeResearch[FilterTypeResearch["RESEARCH_PERMANENT"] = 3] = "RESEARCH_PERMANENT";
    FilterTypeResearch[FilterTypeResearch["RESEARCH_OUTDATE"] = 4] = "RESEARCH_OUTDATE";
})(FilterTypeResearch || (FilterTypeResearch = {}));
var FilterLabels = (_a = {},
    _a[FilterTypeResearch.RESEARCH] = 'Lista de Pesquisas',
    _a[FilterTypeResearch.RESEARCH_STARTED] = 'Pesquisas Iniciadas',
    _a[FilterTypeResearch.RESEARCH_NOT_STARTED] = 'Pesquisas Novas',
    _a[FilterTypeResearch.RESEARCH_PERMANENT] = 'Pesquisas Permanentes',
    _a[FilterTypeResearch.RESEARCH_OUTDATE] = 'Pesquisas Atrasadas',
    _a);
export var Researches = function () {
    var company = useStore('company')[0];
    var history = useHistory();
    var _a = React.useState([]), researches = _a[0], setResearches = _a[1];
    useQuery('get-researches', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, researchesData, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient(GET_CATEG_QUESTIONARIOS_URL)];
                case 1:
                    response = _b.sent();
                    researchesData = (_a = response.data.data) !== null && _a !== void 0 ? _a : [];
                    setResearches(researchesData);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _b.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); });
    var handleNavigateToCategory = function (id) {
        history.push("/pesquisa/categoria/" + id);
    };
    var handleNavigateToQuestion = function (id) {
        history.push({ pathname: AppPath.Question + "/" + id });
    };
    var getStatusResearchLabel = function (research) {
        if (research.diasAtraso > 0) {
            return {
                filterType: FilterTypeResearch.RESEARCH_OUTDATE,
                label: 'Atrasado',
                color: '#C72000',
            };
        }
        if (research.permanente) {
            return {
                filterType: FilterTypeResearch.RESEARCH_PERMANENT,
                label: 'Permanente',
                color: '#FF8730',
            };
        }
        if (research.quantidadePerguntasRespondidas === 0) {
            return {
                filterType: FilterTypeResearch.RESEARCH_NOT_STARTED,
                label: 'Novo',
                color: '#008425',
            };
        }
        if (research.quantidadePerguntasRespondidas > 0) {
            return {
                filterType: FilterTypeResearch.RESEARCH_STARTED,
                label: 'Em andamento',
                color: '#F4C317',
            };
        }
    };
    var buildResearchObject = function (researchData) {
        if (!researchData) {
            return [];
        }
        return researchData.map(function (research) {
            var statusResearch = getStatusResearchLabel(research);
            return {
                title: research.titulo,
                filterType: statusResearch.filterType,
                status: {
                    categoryLabel: research.categoria.nome,
                    statusLabel: statusResearch.label,
                    statusLabelColor: statusResearch.color,
                },
                onClick: function () { return handleNavigateToQuestion(research.id); },
            };
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OuterDiv, null,
            React.createElement(HeaderV2, { withLogoutButton: true, withBackButton: true, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, backButtonFn: function () { return history.push('/'); } }),
            React.createElement(ListItemComponent, { title: "Pesquisas", filters: {
                    filtersLabels: FilterLabels,
                    defaultFilter: FilterTypeResearch.RESEARCH,
                }, items: buildResearchObject(researches) })),
        React.createElement(BottomNav, null)));
};
