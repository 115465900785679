import * as React from 'react';
import { SwitchBackgroundStyled, SwitchHandleStyled, SwitchRootStyled, } from './switch.component.style';
export var Switch = function (props) {
    var _a = React.useState(false), checked = _a[0], setChecked = _a[1];
    React.useEffect(function () {
        setChecked(props.checked);
    }, [props.checked]);
    var handleClick = function (event) {
        var onChange = props.onChange, id = props.id;
        onChange === null || onChange === void 0 ? void 0 : onChange(!checked, id, event);
        setChecked(!checked);
    };
    return (React.createElement(SwitchRootStyled, { disabled: props.disabled, onClick: props.disabled ? undefined : handleClick },
        React.createElement(SwitchBackgroundStyled, { checked: checked }, "\u00A0"),
        React.createElement(SwitchHandleStyled, { checked: checked, disabled: props.disabled })));
};
