import { useEffect, useState } from 'react';
import { useStore } from 'react-context-hook';
var useThemeDarkProps = function () {
    var _a = useStore('darkTheme', null), darkThemeStorage = _a[0], setDarkThemeStorage = _a[1];
    var _b = useState(false), darkThemeInheritance = _b[0], setDarkThemeInheritance = _b[1];
    var _c = useState(false), currentDarkMode = _c[0], setCurrentDarkMode = _c[1];
    useEffect(function () {
        if (darkThemeStorage !== null) {
            localStorage.setItem('darkTheme', darkThemeStorage ? 'true' : 'false');
        }
    }, [darkThemeStorage]);
    useEffect(function () {
        var itemDarkTheme = localStorage.getItem('darkTheme');
        if (itemDarkTheme === null) {
            return;
        }
        if (itemDarkTheme === 'true') {
            setDarkThemeStorage(true);
        }
        else if (itemDarkTheme === 'false') {
            setDarkThemeStorage(false);
        }
    }, [darkThemeStorage, setDarkThemeStorage]);
    useEffect(function () {
        var mediaQueryDark = window.matchMedia('(prefers-color-scheme: dark)');
        var darkMode = mediaQueryDark.matches;
        setDarkThemeInheritance(darkMode);
        var handleChange = function (e) {
            setDarkThemeInheritance(e.matches);
        };
        mediaQueryDark.addEventListener('change', handleChange);
        return function () {
            mediaQueryDark.removeEventListener('change', handleChange);
        };
    }, [setDarkThemeInheritance]);
    useEffect(function () {
        if (darkThemeStorage !== null) {
            setCurrentDarkMode(darkThemeStorage);
        }
        else {
            setCurrentDarkMode(darkThemeInheritance);
        }
    }, [darkThemeStorage, darkThemeInheritance]);
    // disable for now
    // return currentDarkMode;
    return false;
};
export default useThemeDarkProps;
