import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { Header } from '@app/components/mol.header';
import { AppPath } from '@app/routes';
import { StyledContainer } from '@atomic';
import { RankingTable } from '../components/ranking-table/ranking-table.component';
export var RankingPage = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var history = useHistory();
    var usuario = useStore('usuario')[0];
    var ocultarBotaoConcluir = !!((_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.ocultarBotaoConcluir);
    var isProfile = !!((_d = (_c = props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.isProfile);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' }, (usuario === null || usuario === void 0 ? void 0 : usuario.isUsuarioExterno) ? (React.createElement(Header, { withBackButton: true, title: "Ranking", backButtonFn: function () { return history.push(AppPath.PlayerProfile); } })) : (React.createElement(Header, { withBackButton: true, title: "Ranking" }))),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(RankingTable, { data: (_e = props.location.state) === null || _e === void 0 ? void 0 : _e.quizResult, tarefa: (_f = props.location.state) === null || _f === void 0 ? void 0 : _f.tarefa, ocultarBotaoConcluir: ocultarBotaoConcluir, isProfile: isProfile }))));
};
