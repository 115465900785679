var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontFamily, ZIndex } from '@atomic/obj.constants';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 0;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  flex-direction: row;\n  height: 5rem;\n  background-color: ", ";\n  color: ", ";\n  width: 100%;\n  max-width: 480px;\n  z-index: ", ";\n"], ["\n  position: fixed;\n  bottom: 0;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  flex-direction: row;\n  height: 5rem;\n  background-color: ", ";\n  color: ", ";\n  width: 100%;\n  max-width: 480px;\n  z-index: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, ZIndex.second);
export var StyledActiveLink = styled(NavLink)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: none;\n  width: 100%;\n  height: 100%;\n  border: none;\n  &:hover {\n    color: #000;\n  }\n\n  h3 {\n    margin-top: 0.2rem;\n    margin-bottom: 0;\n    font-family: ", ";\n    font-size: 14px;\n    font-weight: 400;\n    text-decoration: none;\n    &:hover {\n      color: #000;\n      font-weight: 700;\n    }\n  }\n\n  svg {\n    height: auto;\n  }\n\n  img {\n    height: 22px;\n    &:hover {\n      color: #000;\n      font-weight: 700;\n    }\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: none;\n  width: 100%;\n  height: 100%;\n  border: none;\n  &:hover {\n    color: #000;\n  }\n\n  h3 {\n    margin-top: 0.2rem;\n    margin-bottom: 0;\n    font-family: ", ";\n    font-size: 14px;\n    font-weight: 400;\n    text-decoration: none;\n    &:hover {\n      color: #000;\n      font-weight: 700;\n    }\n  }\n\n  svg {\n    height: auto;\n  }\n\n  img {\n    height: 22px;\n    &:hover {\n      color: #000;\n      font-weight: 700;\n    }\n  }\n"])), FontFamily.Secondary);
export var Line = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-self: center;\n  border-right: 3px dotted #fff;\n  height: 75%;\n  opacity: 0.3;\n"], ["\n  align-self: center;\n  border-right: 3px dotted #fff;\n  height: 75%;\n  opacity: 0.3;\n"])));
export var IconOption = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 50%;\n  background-color: #ff9d56;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  width: 40px;\n  height: 40px;\n  padding: 2px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  &:hover {\n    cursor: pointer;\n  }\n"], ["\n  border-radius: 50%;\n  background-color: #ff9d56;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  width: 40px;\n  height: 40px;\n  padding: 2px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  &:hover {\n    cursor: pointer;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
