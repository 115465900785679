var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var TelaDeCarregamento = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Line = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 90%;\n  border-bottom: 5px dotted #fff;\n  margin: 10px 0 20px;\n  opacity: 0.6;\n  align-self: center;\n"], ["\n  width: 90%;\n  border-bottom: 5px dotted #fff;\n  margin: 10px 0 20px;\n  opacity: 0.6;\n  align-self: center;\n"])));
var templateObject_1, templateObject_2;
