var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ColorFunc from 'color';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Border, Color, Spacing, FontFamily, FontSize } from '@atomic/obj.constants';
var btnColors = function (variant, outlined, theme) {
    if (outlined) {
        var outlinedColors = {
            primary: { bg: Color.White, border: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, text: theme === null || theme === void 0 ? void 0 : theme.corPrimaria },
            secondary: { bg: Color.GrayXLight, border: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, text: theme === null || theme === void 0 ? void 0 : theme.corPrimaria },
            neutral: { bg: Color.GrayXLight, border: Color.Neutral, text: Color.Neutral },
            callToAction: { bg: Color.GrayXLight, border: Color.CallToAction, text: Color.CallToAction },
            accessory: { bg: Color.GrayXLight, border: Color.Accessory, text: Color.Accessory },
            alert: { bg: Color.GrayXLight, border: Color.Alert, text: Color.Alert },
            link: { bg: 'transparent', border: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, text: theme === null || theme === void 0 ? void 0 : theme.corPrimaria },
            redDark: { bg: '#7C051F', border: '#7C051F', text: Color.White },
            greenDark: { bg: '#355C26', border: '#355C26', text: Color.White },
        };
        return outlinedColors[variant];
    }
    var solidColors = {
        primary: { bg: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, border: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, text: Color.White },
        secondary: { bg: Color.White, border: theme === null || theme === void 0 ? void 0 : theme.corPrimaria, text: theme === null || theme === void 0 ? void 0 : theme.corPrimaria },
        neutral: { bg: Color.Neutral, border: Color.Neutral, text: Color.White },
        callToAction: { bg: Color.CallToAction, border: Color.CallToAction, text: Color.White },
        accessory: { bg: Color.Accessory, border: Color.Accessory, text: Color.White },
        alert: { bg: Color.Alert, border: Color.Alert, text: Color.White },
        link: { bg: 'transparent', border: 'transparent', text: theme === null || theme === void 0 ? void 0 : theme.corPrimaria },
        redDark: { bg: '#7C051F', border: '#7C051F', text: Color.White },
        greenDark: { bg: '#355C26', border: '#355C26', text: Color.White },
    };
    return solidColors[variant];
};
export var buttonHeight = '44px';
var paddingModifier = {
    small: Spacing.Small,
    medium: Spacing.Medium,
    large: Spacing.Large,
};
export var ButtonStyledCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  cursor: ", ";\n  opacity: ", ";\n\n  ", "\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  ", "\n"], ["\n  position: relative;\n  width: ", ";\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  cursor: ", ";\n  opacity: ", ";\n\n  ",
    "\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  ",
    "\n"])), function (props) { return (props.expanded ? '100%' : 'auto'); }, buttonHeight, buttonHeight, function (_a) {
    var _b = _a.padding, padding = _b === void 0 ? 'large' : _b;
    return paddingModifier[padding];
}, FontFamily.Primary, FontSize.Medium, function (props) { return (props.expanded ? 'center' : 'left'); }, function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return (props.disabled || props.loading ? 0.5 : 1); }, media.lessThan('small')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0 ", ";\n  "], ["\n    padding: 0 ", ";\n  "])), Spacing.Medium), function (props) { return btnColors(props.variant, props.outlined, props.theme).text; }, function (props) { return btnColors(props.variant, props.outlined, props.theme).bg; }, function (props) { return btnColors(props.variant, props.outlined, props.theme).border; }, Border.Width, Border.RadiusLarge, function (props) {
    return !props.disabled &&
        "\n    &:hover {\n      color: " + ColorFunc(btnColors(props.variant, props.outlined, props.theme).text).darken(0.2).hsl().string() + ";\n      background-color: " + ColorFunc(btnColors(props.variant, props.outlined, props.theme).bg)
            .darken(0.1)
            .hsl()
            .string() + ";\n      opacity: 0.8;\n    }\n\n    &:active {\n      color: " + ColorFunc(btnColors(props.variant, props.outlined, props.theme).text).darken(0.3).hsl().string() + ";\n      background-color: " + ColorFunc(btnColors(props.variant, props.outlined, props.theme).bg)
            .darken(0.2)
            .hsl()
            .string() + ";\n      opacity: 0.7;\n    }\n  ";
});
export var ButtonStyled = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  ", "\n\n  &:focus {\n    outline: 0;\n  }\n"])), ButtonStyledCss);
export var LinkButtonStyled = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n\n  // This style improves how links are shown inside texts\n  p > &,\n  label > & {\n    padding: 0;\n    min-height: ", ";\n  }\n"], ["\n  ", "\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n\n  // This style improves how links are shown inside texts\n  p > &,\n  label > & {\n    padding: 0;\n    min-height: ", ";\n  }\n"])), ButtonStyledCss, FontSize.Medium);
export var ButtonContentStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  vertical-align: bottom;\n  line-height: ", ";\n\n  & .fa,\n  & img,\n  & svg {\n    width: auto !important;\n    padding-right: ", ";\n    vertical-align: inherit;\n  }\n"], ["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  vertical-align: bottom;\n  line-height: ", ";\n\n  & .fa,\n  & img,\n  & svg {\n    width: auto !important;\n    padding-right: ", ";\n    vertical-align: inherit;\n  }\n"])), function (props) { return (props.processing ? 0 : 1); }, Spacing.Medium, Spacing.Small);
export var ButtonSpinnerStyled = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"], ["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"])), function (props) { return (props.processing ? 'inline-block' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
