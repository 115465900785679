/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Typography } from '@mui/material';
import { FontFamily } from '@atomic';
import { QuantityBadge, SubmenuCategoryCardContainer } from './styles';
export var SubmenuCategoryCard = function (_a) {
    var category = _a.category, onClick = _a.onClick;
    return (React.createElement(SubmenuCategoryCardContainer, { onClick: function () { return onClick(category.id); } },
        React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '20px' }, gutterBottom: true, variant: "h5", component: "div" }, category.nome),
        category.quantidadeNaoLida >= 1 ? (React.createElement(QuantityBadge, null,
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "div" }, category.quantidadeNaoLida))) : null));
};
