import * as React from 'react';
import { CustomRadioBtnStyled, CheckedCustomRadioBtnStyled } from './custom-radio-button.component.styled';
export var CustomRadioButton = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'default' : _b;
    return (React.createElement(React.Fragment, null, radioButton[variant]));
};
var radioButton = {
    checked: React.createElement(CheckedCustomRadioBtnStyled, null),
    default: React.createElement(CustomRadioBtnStyled, null),
};
