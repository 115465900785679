import * as React from 'react';
import { useStore } from '@app/core/services';
import { FlashMessageTargetName, useFlashStore } from '@app/providers';
export var useFlashMessage = function (target) {
    if (target === void 0) { target = FlashMessageTargetName.APP; }
    var _a = useStore(useFlashStore), flash = _a.flash, showMessage = _a.showMessage, hideMessage = _a.hideMessage;
    var data = React.useMemo(function () {
        return (flash === null || flash === void 0 ? void 0 : flash[target]) || { type: 'info', content: '', hidden: true };
    }, [flash, target]);
    var show = React.useCallback(function (type, content) {
        showMessage(target, type, content);
    }, [showMessage, target]);
    var hide = React.useCallback(function () {
        hideMessage(target);
    }, [hideMessage, target]);
    return [show, hide, data];
};
