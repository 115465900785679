var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var LoadMoreInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n  text-decoration: underline;\n  cursor: pointer;\n  margin-top: 10px;\n  color: ", ";\n\n  font-size: 0.9rem;\n  font-weight: bold;\n"], ["\n  text-align: center;\n  text-decoration: underline;\n  cursor: pointer;\n  margin-top: 10px;\n  color: ", ";\n\n  font-size: 0.9rem;\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1;
