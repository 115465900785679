var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useMutation } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { FlashMessageEnum } from '@atomic';
import { ButtonBox, StoreItem, TextBox } from '../../store.page.styled';
import BuyEntCoinsButton from '../buy-entcoins-button/buy-entcoins-button.component';
import { ItemCircle } from './optional-course-item.component.styled';
var OptionalCourseItem = function (_a) {
    var optionalCourse = _a.optionalCourse, refetchOptionalCourses = _a.refetchOptionalCourses, hasEntcoinsEnough = _a.hasEntcoinsEnough;
    var showFlashMessage = useFlashMessage()[0];
    // const [loadingPurchaseCourseId, setLoadingPurchaseCourseId] = useState<number>();
    var mutate = useMutation(function (courseId) { return __awaiter(void 0, void 0, void 0, function () {
        var POST_COURSE_PURCHASE, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    POST_COURSE_PURCHASE = 'loja';
                    return [4 /*yield*/, axiosClient.post(POST_COURSE_PURCHASE, {
                            cursoId: courseId,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, "Parab\u00E9ns! Seu curso " + optionalCourse.nome.toUpperCase() + " j\u00E1 est\u00E1 dispon\u00EDvel em sua trilha!");
            refetchOptionalCourses();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, "N\u00E3o foi poss\u00EDvel comprar este desafio. Tente mais tarde.");
        },
    }).mutate;
    var handleOnBuyCourseClick = function (course) {
        mutate(course.id);
    };
    return (React.createElement(StoreItem, null,
        React.createElement(ItemCircle, null, "D+"),
        React.createElement(TextBox, null,
            React.createElement("h3", null, optionalCourse.nome),
            React.createElement("p", null, optionalCourse.descricao)),
        React.createElement(ButtonBox, null,
            React.createElement(BuyEntCoinsButton, { valor: optionalCourse.valor, disabled: !hasEntcoinsEnough, onClick: hasEntcoinsEnough ? function () { return handleOnBuyCourseClick(optionalCourse); } : null }))));
};
export default OptionalCourseItem;
