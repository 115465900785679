var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Select from '@mui/material/Select';
import styled from 'styled-components';
export var SelectContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: end;\n  margin-right: 10px;\n  margin-top: 10px;\n"], ["\n  display: flex;\n  justify-content: end;\n  margin-right: 10px;\n  margin-top: 10px;\n"])));
export var SelectStyled = styled(Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", " !important;\n\n  svg {\n    color: ", " !important;\n  }\n\n  fieldset {\n    border: none;\n  }\n\n  .MuiSelect-select {\n    padding: 5px 30px;\n  }\n"], ["\n  color: ", " !important;\n\n  svg {\n    color: ", " !important;\n  }\n\n  fieldset {\n    border: none;\n  }\n\n  .MuiSelect-select {\n    padding: 5px 30px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
});
var templateObject_1, templateObject_2;
