var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Badge, FaIcon } from '@atomic';
import { FontFamily, FontWeight } from '@atomic/obj.constants';
export var Ranking = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: left;\n  justify-content: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: left;\n  justify-content: center;\n"])));
export var RowWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  width: 100%;\n  border: none;\n  border-radius: 12px;\n  box-shadow: -1px -1px 1px #cacaca, 1px 1px 2px #757575;\n\n  Col {\n    padding: 0;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  width: 100%;\n  border: none;\n  border-radius: 12px;\n  box-shadow: -1px -1px 1px #cacaca, 1px 1px 2px #757575;\n\n  Col {\n    padding: 0;\n  }\n"])));
export var PointsLayout = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n  padding: 0 0.5rem 0 0;\n  width: 100%;\n  height: 100%;\n  border-top-right-radius: 12px;\n  border-bottom-right-radius: 12px;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n  padding: 0 0.5rem 0 0;\n  width: 100%;\n  height: 100%;\n  border-top-right-radius: 12px;\n  border-bottom-right-radius: 12px;\n"])));
export var PlaceLayout = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  border-bottom-left-radius: 12px;\n  border-top-left-radius: 12px;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  border-bottom-left-radius: 12px;\n  border-top-left-radius: 12px;\n"])));
export var NameLayout = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  height: 100%;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  height: 100%;\n"])));
export var RankingName = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 17px;\n  color: #333;\n\n  @media (max-width: 375px) {\n    font-size: 14px;\n  }\n\n  &.user-ranking-entry {\n    font-weight: ", ";\n  }\n"], ["\n  font-family: ", ";\n  font-size: 17px;\n  color: #333;\n\n  @media (max-width: 375px) {\n    font-size: 14px;\n  }\n\n  &.user-ranking-entry {\n    font-weight: ", ";\n  }\n"])), FontFamily.Primary, FontWeight.Bold);
export var RankingPoints = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 1rem;\n\n  &.user-ranking-entry {\n    font-weight: ", ";\n  }\n"], ["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 1rem;\n\n  &.user-ranking-entry {\n    font-weight: ", ";\n  }\n"])), FontFamily.Primary, FontWeight.Bold, FontWeight.Bold);
export var RankingPlace = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 1rem;\n"], ["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 1rem;\n"])), FontFamily.Primary, FontWeight.Bold);
export var ExtraRank = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  border-radius: 12px;\n\n  h1,\n  span {\n    font-family: ", ";\n    font-weight: ", ";\n    color: #ffffff;\n  }\n\n  h1 {\n    font-size: 1.5rem;\n    margin-left: 1.1rem;\n  }\n\n  span {\n    font-size: 1.3rem;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  border-radius: 12px;\n\n  h1,\n  span {\n    font-family: ", ";\n    font-weight: ", ";\n    color: #ffffff;\n  }\n\n  h1 {\n    font-size: 1.5rem;\n    margin-left: 1.1rem;\n  }\n\n  span {\n    font-size: 1.3rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, FontFamily.Primary, FontWeight.Bold);
export var StarsBox = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n"])));
export var Star = styled(FaIcon.Star)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  &.user-ranking-entry {\n    color: #fff;\n  }\n"], ["\n  &.user-ranking-entry {\n    color: #fff;\n  }\n"])));
export var FilterContainerStyled = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: block;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  padding: 8px 16px;\n  position: relative;\n  min-width: 200px;\n  z-index: 1;\n"], ["\n  display: block;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  padding: 8px 16px;\n  position: relative;\n  min-width: 200px;\n  z-index: 1;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var FilterOptionsStyled = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: absolute;\n  top: 36px;\n  left: 0px;\n  right: 0;\n  max-height: 222px;\n  background-color: white;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  overflow-y: auto;\n  padding: 8px 16px;\n\n  p {\n    text-align: center;\n    font-weight: bold;\n  }\n"], ["\n  position: absolute;\n  top: 36px;\n  left: 0px;\n  right: 0;\n  max-height: 222px;\n  background-color: white;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  overflow-y: auto;\n  padding: 8px 16px;\n\n  p {\n    text-align: center;\n    font-weight: bold;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var FiltersGroup = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-wrap: wrap;\n  justify-content: space-between;\n  div {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-wrap: wrap;\n  justify-content: space-between;\n  div {\n    margin-bottom: 8px;\n  }\n"])));
export var Filter = styled(Badge)(templateObject_15 || (templateObject_15 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
