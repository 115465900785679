import * as React from 'react';
import { Container } from '@app/modules/course-library/components/course-item/course-library-item.component.style';
import { CircleIconBox } from '@app/modules/map/course-list/course-item.component.style';
import { CourseTitle } from '@atomic';
import { PdfDownloadButton } from '../course-item';
export var CoursePhaseItem = function (_a) {
    var _b, _c;
    var phase = _a.phase, index = _a.index;
    return (React.createElement(Container, { style: { cursor: 'auto' } },
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement(CircleIconBox, null,
                React.createElement("span", null,
                    "F.",
                    index + 1)),
            React.createElement(CourseTitle, { style: { marginLeft: '1.2rem' } }, phase.titulo),
            ((_b = phase === null || phase === void 0 ? void 0 : phase.arquivo) === null || _b === void 0 ? void 0 : _b.url) && React.createElement(PdfDownloadButton, { pdfUrl: (_c = phase.arquivo) === null || _c === void 0 ? void 0 : _c.url }))));
};
