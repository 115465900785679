var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ColorFunc from 'color';
import styled from 'styled-components';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight, Lighten } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var flashMessageTypes = {
    warning: Color.Warning,
    success: Color.Success,
    info: Color.Accessory,
    alert: Color.Alert,
};
export var FlashMessageContentStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  margin: 0 ", ";\n"], ["\n  flex-grow: 1;\n  margin: 0 ", ";\n"])), Spacing.Small);
export var FlashMessageCloseStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n\n  &:hover > span {\n    color: ", ";\n  }\n"], ["\n  ", "\n\n  &:hover > span {\n    color: ", ";\n  }\n"])), highlightStyle, function (props) { return ColorFunc(flashMessageTypes[props.type]).darken(0.5).string(); });
var flashMessageLighten = {
    warning: Lighten.Warning,
    success: Lighten.Success,
    info: Lighten.Success,
    alert: Lighten.Alert,
};
export var FlashMessageStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity 0.3s ease-in-out;\n\n  p,\n  svg {\n    color: ", ";\n  }\n"], ["\n  background-color: ",
    ";\n  border-radius: ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity 0.3s ease-in-out;\n\n  p,\n  svg {\n    color: ", ";\n  }\n"])), function (props) {
    return ColorFunc(flashMessageTypes[props.type]).lighten(flashMessageLighten[props.type]).string();
}, Border.Radius, Spacing.Medium, FontFamily.Primary, FontWeight.Normal, FontSize.Large, function (props) { return flashMessageTypes[props.type]; });
var templateObject_1, templateObject_2, templateObject_3;
