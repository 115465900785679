import * as React from 'react';
import { useStore } from 'react-context-hook';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { RankingTable } from '@app/modules/ranking/components/ranking-table/ranking-table.component';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import { Button, FlashMessageEnum, Separator, StyledContainer } from '@atomic';
import { AvaliationModal } from '../components/phase-completed/components/avaliation-modal';
import { PhaseHeader } from '../components/phase-completed/components/phase-header';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var phaseCompletedSound = require('@assets/sounds/phase-completed.mp3');
export var PhaseCompletedPage = function (props) {
    var _a, _b;
    var history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _c = React.useContext(QuizContext), _ = _c[0], __ = _c[1], setQuestions = _c[2], setCurrentQuizzIndex = _c[3];
    var loadedCourse = useStore('currentCourse')[0];
    var isMute = useStore('mute')[0];
    var _d = React.useState(), nFase = _d[0], setNfase = _d[1];
    var _e = React.useState(false), isOpenModal = _e[0], setOpenModal = _e[1];
    var playPhaseCompletedSound = useSound(phaseCompletedSound, { volume: 0.6 })[0];
    var showFlashMessage = useFlashMessage()[0];
    var usuario = useStore('usuario')[0];
    var tarefa = props.location.state.tarefa;
    var REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
    React.useEffect(function () {
        for (var i = 0; i < loadedCourse.fases.length; i++) {
            if (loadedCourse.fases[i].id == tarefa.faseId) {
                setNfase(loadedCourse.fases[i].ordem);
            }
        }
    }, [loadedCourse.fases, tarefa.faseId]);
    React.useEffect(function () {
        if (!isMute) {
            playPhaseCompletedSound();
        }
    }, [isMute, playPhaseCompletedSound]);
    var hasNextQuiz = function () {
        for (var i = 0; i < loadedCourse.fases.length; i++) {
            if (loadedCourse.fases[i].id === tarefa.faseId) {
                return loadedCourse.fases[i + 1] != undefined;
            }
        }
    };
    var handleReceberCertificadoClick = function () {
        try {
            window.open(REACT_APP_ENDPOINT + ("api/v1/jogo/curso/" + loadedCourse.codigo + "/certificadoconclusao/usuario/" + usuario.id), '_blank', 'noreferrer');
        }
        catch (error) {
            showFlashMessage(FlashMessageEnum.alert, (error === null || error === void 0 ? void 0 : error.stack) || error);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Countdown, { date: Date.now() + 3000, renderer: function () { return React.createElement("span", null); }, onComplete: function () { return setOpenModal(true); } }),
        React.createElement(PhaseHeader, { nFase: nFase, quizResult: props.location.state.quizResult }),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(RankingTable, { data: (_a = props.location.state) === null || _a === void 0 ? void 0 : _a.quizResult, tarefa: (_b = props.location.state) === null || _b === void 0 ? void 0 : _b.tarefa }),
            React.createElement(Separator, null),
            hasNextQuiz() ? (React.createElement(Button, { expanded: true, onClick: function () { return history.push(AppPath.Track.replace(':courseCode', loadedCourse.codigo)); }, variant: "primary" },
                React.createElement("span", null, "Ir para pr\u00F3xima fase"))) : (React.createElement(React.Fragment, null)),
            React.createElement(AvaliationModal, { isOpenModal: isOpenModal, setIsOpenModal: setOpenModal, tarefa: tarefa }))));
};
