var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Form, Formik } from 'formik';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { FlashMessageEnum, Separator } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Col, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Label, Textarea, Title } from './modal-novo-chamado.styled';
export var ModalNovoChamado = function (_a) {
    var isOpened = _a.isOpened, chamado = _a.chamado, handleOnClose = _a.handleOnClose;
    var showFlashMessage = useFlashMessage()[0];
    var _b = React.useState((chamado === null || chamado === void 0 ? void 0 : chamado.descricao) || ''), descricao = _b[0], setDescricao = _b[1];
    // const [categoriaSelecionada, setCategoriaSelecionada] = React.useState<{ label: any; value: any }>(
    //   !!chamado ? { label: chamado.categoriaChamadoId, value: chamado.categoriaChamadoId } : undefined,
    // );
    var _c = React.useState(), categoriaSelecionada = _c[0], setCategoriaSelecionada = _c[1];
    var POST_CHAMADO_URL = 'chamado';
    var GET_CATEGORIAS_CHAMADOS_URL = 'chamado/categoria';
    var optionsCategorias = useQuery('categoriasChamados', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, retorno, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_CATEGORIAS_CHAMADOS_URL)];
                case 1:
                    response = _a.sent();
                    retorno = response.data.data.map(function (categoria) {
                        return {
                            label: categoria.descricao,
                            value: categoria.id,
                        };
                    });
                    if (!!chamado) {
                        setCategoriaSelecionada(retorno.find(function (categ) { return parseInt(categ.value) === chamado.categoriaChamadoId; }));
                    }
                    return [2 /*return*/, retorno];
                case 2:
                    error_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        enabled: isOpened,
    }).data;
    var _d = useMutation(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = {
                        descricao: descricao,
                        categoriaChamadoId: categoriaSelecionada.value,
                    };
                    return [4 /*yield*/, axiosClient.post(POST_CHAMADO_URL, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Chamado cadastrado com sucesso.');
            handleOnClose();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar o Chamado');
        },
    }), mutate = _d.mutate, isLoading = _d.isLoading;
    var handleSubmit = function (formValues) {
        mutate(formValues);
    };
    var handleOnFilterInputChange = function (value) {
        setCategoriaSelecionada(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () {
                setCategoriaSelecionada(undefined);
                handleOnClose();
            } },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null),
                " ",
                !!chamado ? 'Detalhes do Chamado' : 'Abrir Chamado'),
            React.createElement(Separator, null),
            React.createElement(Formik, { initialValues: {}, onSubmit: handleSubmit },
                React.createElement(Form, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "\u00C1rea respons\u00E1vel:")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Select, { value: categoriaSelecionada, onChange: handleOnFilterInputChange, options: optionsCategorias, isDisabled: !!chamado }))),
                    React.createElement(Separator, null),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Detalhamento da Demanda:")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Textarea, { placeholder: "Coloque todos os detalhes que lembrar...", onChange: function (e) { return setDescricao(e.currentTarget.value); }, disabled: !!chamado }, chamado === null || chamado === void 0 ? void 0 : chamado.descricao))),
                    React.createElement(Separator, null),
                    !chamado && (React.createElement(ButtonsRow, null,
                        React.createElement(Col, null,
                            React.createElement(Button, { type: "submit", loading: isLoading, disabled: !categoriaSelecionada || !descricao }, "Registrar")))))))));
};
