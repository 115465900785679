var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { FlexColumn } from '@app/modules/routine-checklist/quick-checklist/styles';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { ActivityIndicator, Button, FlashMessageEnum } from '@atomic';
import { ActionPlanModal } from '../action-plan-modal';
import { ChecklistItem } from '../checklist-item';
import { ModalFoto } from '../modal-foto';
import { ModalObservacao } from '../modal-observacao';
export var Checklist = function (_a) {
    var id = _a.id, checklistItems = _a.checklistItems, jogadorId = _a.jogadorId, _b = _a.isDetailsMode, isDetailsMode = _b === void 0 ? false : _b, _c = _a.isPending, isPending = _c === void 0 ? false : _c, isLoadingChecklist = _a.isLoadingChecklist;
    var history = useHistory();
    var userData = useStore(useUserStore).userData;
    var _d = useState(false), isModalOpened = _d[0], setIsModalOpened = _d[1];
    var _e = useState(false), isModalObservacaoOpened = _e[0], setIsModalObservacaoOpened = _e[1];
    var _f = useState(false), isModalFotoOpened = _f[0], setIsModalFotoOpened = _f[1];
    var showFlashMessage = useFlashMessage()[0];
    var _g = useState([]), respostasGestor = _g[0], setRespostasGestor = _g[1];
    var _h = useState([]), fotosEnviadasChecklistItemsIds = _h[0], setFotosEnviadasChecklistItemsIds = _h[1];
    var _j = useState([]), observacaoItemsIds = _j[0], setObservacaoItemsIds = _j[1];
    var _k = useState([]), actionPlansChecklistItemsIds = _k[0], setActionPlansChecklistItemsIds = _k[1];
    var _l = useState(), currentRespostaItemId = _l[0], setCurrentRespostaItemId = _l[1];
    var _m = useState(), checklistPreenchidoResponse = _m[0], setChecklistPreenchidoResponse = _m[1];
    var _o = useState(undefined), itemFotos = _o[0], setItemFotos = _o[1];
    var postOrPutChecklistUrl = 'checklist';
    useEffect(function () {
        if (isDetailsMode) {
            setChecklistPreenchidoResponse({});
        }
    }, [isDetailsMode]);
    var postChecklistMutation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = {
                        JogadorId: jogadorId,
                        RespostasGestor: respostasGestor,
                    };
                    return [4 /*yield*/, axiosClient.post(postOrPutChecklistUrl, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var putChecklistMutation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = {
                        Id: id,
                        JogadorId: jogadorId,
                        RespostasGestor: respostasGestor,
                    };
                    return [4 /*yield*/, axiosClient.put(postOrPutChecklistUrl, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var _p = useMutation(isPending ? putChecklistMutation : postChecklistMutation, {
        onSuccess: function (response) {
            showFlashMessage(FlashMessageEnum.success, 'Checklist gravado com sucesso. Aproveite para cadastrar os planos de ação caso precise!');
            setChecklistPreenchidoResponse(response === null || response === void 0 ? void 0 : response.data);
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar o checklist');
        },
    }), postOrPutChecklistResponse = _p.data, mutate = _p.mutate, isLoading = _p.isLoading;
    var handleOnActionPlanModalClose = function () {
        setIsModalOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnConcluirClick = function () {
        mutate();
    };
    var getRespostaGestorId = function (quizId) {
        var respostaGestor = postOrPutChecklistResponse.data.respostasGestor.find(function (resposta) { return resposta.quizId === quizId; });
        return respostaGestor.id;
    };
    var handleOnActionPlanClick = function (quizId) {
        setCurrentRespostaItemId(getRespostaGestorId(quizId));
        setIsModalOpened(true);
    };
    var registerActionPlan = function () {
        setActionPlansChecklistItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var getRespostaItemIdByQuizId = function (quizId) {
        var _a, _b;
        return (_b = (_a = checklistPreenchidoResponse === null || checklistPreenchidoResponse === void 0 ? void 0 : checklistPreenchidoResponse.respostasGestor) === null || _a === void 0 ? void 0 : _a.find(function (r) { return r.quizId === quizId; })) === null || _b === void 0 ? void 0 : _b.id;
    };
    var handleObservacaoClick = function (quizId) {
        setCurrentRespostaItemId(quizId);
        setIsModalObservacaoOpened(true);
    };
    var handleOnEnviarFotoClick = function (quizId) {
        setCurrentRespostaItemId(getRespostaItemIdByQuizId(quizId));
        setItemFotos(undefined);
        setIsModalFotoOpened(true);
    };
    var handleOnFotoModalClose = function () {
        setIsModalFotoOpened(false);
        setCurrentRespostaItemId(undefined);
        setItemFotos(undefined);
    };
    var handleObservacaoModalClose = function () {
        setIsModalObservacaoOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var registerFotoEnviada = function () {
        setFotosEnviadasChecklistItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var registerObservacao = function () {
        setObservacaoItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    // console.log('checklistPreenchidoResponse', checklistPreenchidoResponse);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionPlanModal, { isOpened: isModalOpened, onSend: registerActionPlan, handleOnClose: handleOnActionPlanModalClose, currentRespostaItemId: currentRespostaItemId }),
        React.createElement(ModalFoto, { isOpened: isModalFotoOpened, onSend: registerFotoEnviada, handleOnClose: handleOnFotoModalClose, checklist: checklistPreenchidoResponse, currentRespostaItemId: currentRespostaItemId, itemFotos: itemFotos, isPermitidoImagemDaBiblioteca: checklistPreenchidoResponse === null || checklistPreenchidoResponse === void 0 ? void 0 : checklistPreenchidoResponse.isPermitidoImagemDaBiblioteca }),
        React.createElement(ModalObservacao, { isOpened: isModalObservacaoOpened, onSend: registerObservacao, handleOnClose: handleObservacaoModalClose, checklist: checklistPreenchidoResponse, currentRespostaItemId: currentRespostaItemId }),
        !isLoadingChecklist ? (React.createElement(React.Fragment, null, checklistItems === null || checklistItems === void 0 ? void 0 : checklistItems.map(function (checklistItem, idx) { return (React.createElement(ChecklistItem, { key: idx, checklistItem: checklistItem, postOrPutChecklistResponse: postOrPutChecklistResponse, respostasGestor: respostasGestor, setRespostasGestor: setRespostasGestor, handleOnActionPlanClick: function () { return handleOnActionPlanClick(checklistItem.id); }, handleOnEnviarFotoClick: function () { return handleOnEnviarFotoClick(checklistItem.id); }, handleOnObservacaoClick: function () { return handleObservacaoClick(checklistItem.id); }, usuarioId: userData.usuarioId, isActionPlanRegistered: !!actionPlansChecklistItemsIds.find(function (checklistItemId) { return checklistItemId === getRespostaItemIdByQuizId(checklistItem.id); }), isFotoEnviada: !!fotosEnviadasChecklistItemsIds.find(function (checklistItemId) { return checklistItemId === getRespostaItemIdByQuizId(checklistItem.id); }), isObservacaoEnviada: !!observacaoItemsIds.find(function (checklistItemId) { return checklistItemId == checklistItem.id; }) })); }))) : (React.createElement(FlexColumn, { style: { paddingBlock: '2rem' } },
            React.createElement(ActivityIndicator, { type: "spinner" }))),
        checklistPreenchidoResponse ? (React.createElement(Button, { onClick: function () { return history.push(AppPath.RoutineChecklist); }, expanded: true }, "Fechar")) : (React.createElement(Button, { loading: isLoading, disabled: (respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.length) < (checklistItems === null || checklistItems === void 0 ? void 0 : checklistItems.length), onClick: handleOnConcluirClick, expanded: true }, "Enviar"))));
};
