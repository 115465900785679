var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Col, FontFamily } from '@atomic';
export var ColRadioButton = styled(Col)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flexStart;\n  padding: 1.1rem 0 0 0;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flexStart;\n  padding: 1.1rem 0 0 0;\n"])));
export var ImagePreview = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 10px;\n  height: 200px;\n\n  img {\n    width: unset !important;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 10px;\n  height: 200px;\n\n  img {\n    width: unset !important;\n  }\n"])));
export var Padding = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 10px;\n  padding-right: 10px;\n"], ["\n  padding-left: 10px;\n  padding-right: 10px;\n"])));
export var SecondChanceInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  padding: 4px 8px;\n  border: 1px solid ", ";\n  border-radius: 16px;\n  margin-top: 16px;\n\n  span {\n    color: ", ";\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  padding: 4px 8px;\n  border: 1px solid ", ";\n  border-radius: 16px;\n  margin-top: 16px;\n\n  span {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Question = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n  font-weight: bold;\n  font-family: ", ";\n  font-size: 1.4rem;\n"], ["\n  text-align: center;\n  font-weight: bold;\n  font-family: ", ";\n  font-size: 1.4rem;\n"])), FontFamily.Secondary);
export var SpacedElementsWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  span {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  span {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
