import * as React from 'react';
import Container from 'typedi';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { FlashMessageEnum, H4, StyledContainer } from '@atomic';
import { ChangePassword } from '../authentication/change-password.component';
var authStorageService = Container.get(AuthStorageService);
export var ConfigurationsPage = function (_a) {
    var _b;
    var location = _a.location;
    var loggedUser = authStorageService === null || authStorageService === void 0 ? void 0 : authStorageService.getUser();
    var showFlashMessage = useFlashMessage()[0];
    var hideBackButton = (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.hideBackButton;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: "Alterar senha", withBackButton: !hideBackButton }),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(H4, { style: { color: 'black' } }, "Para a sua seguran\u00E7a, escolha uma senha com no m\u00EDnimo 6 caracteres."),
            React.createElement(ChangePassword, { endpointUrl: "usuario/" + loggedUser.usuarioId + "/senha", onError: function () {
                    showFlashMessage(FlashMessageEnum.alert, 'Não foi possivel alterar a senha, tente novamente e caso persista contate seu gestor.');
                }, usuarioId: loggedUser.usuarioId }))));
};
