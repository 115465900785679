var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Form } from 'formik';
import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Button } from '@atomic';
export var ButtonAvaliacao = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 22px;\n  width: 22px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  border: 0px solid #000;\n  &.button-selected {\n    border: 2px solid #000;\n  }\n  :hover {\n    cursor: pointer;\n  }\n  :not(:last-child) {\n    margin-right: 5px;\n  }\n"], ["\n  height: 22px;\n  width: 22px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  border: 0px solid #000;\n  &.button-selected {\n    border: 2px solid #000;\n  }\n  :hover {\n    cursor: pointer;\n  }\n  :not(:last-child) {\n    margin-right: 5px;\n  }\n"])));
export var ButtonAvaliar = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60%;\n  text-align: center;\n  margin-top: 20px;\n"], ["\n  width: 60%;\n  text-align: center;\n  margin-top: 20px;\n"])));
export var ButtonSquaredAvaliar = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  text-align: center;\n  margin-top: 20px;\n  border-radius: 10px;\n"], ["\n  width: 100%;\n  text-align: center;\n  margin-top: 20px;\n  border-radius: 10px;\n"])));
export var CenterModal = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n"], ["\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n"])));
export var CommentInput = styled.textarea(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 60px;\n  resize: none;\n  padding: 2px 10px;\n  border-radius: 5px;\n"], ["\n  width: 100%;\n  height: 60px;\n  resize: none;\n  padding: 2px 10px;\n  border-radius: 5px;\n"])));
export var CustomerInfo = styled(Form)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", " {\n    margin-top: 10px;\n  }\n  text-align: left;\n  input {\n    height: 30px;\n  }\n"], ["\n  ", " {\n    margin-top: 10px;\n  }\n  text-align: left;\n  input {\n    height: 30px;\n  }\n"])), Row);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
