var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@app/components/atm.card/card.styled';
export var StyledChecklistCard = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.5rem 0.5rem 1rem 0.5rem;\n  border-left: 12px solid ", ";\n  min-height: 60px;\n\n  p {\n    line-height: 1rem;\n    font-size: 0.8rem;\n  }\n\n  p:nth-child(1) {\n    color: ", ";\n  }\n"], ["\n  padding: 0.5rem 0.5rem 1rem 0.5rem;\n  border-left: 12px solid ", ";\n  min-height: 60px;\n\n  p {\n    line-height: 1rem;\n    font-size: 0.8rem;\n  }\n\n  p:nth-child(1) {\n    color: ", ";\n  }\n"])), function (props) { return props.cardColor; }, function (props) { return props.cardColor; });
export var BlockIconButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid #ccc;\n  padding: 4px;\n  border-radius: 12px;\n  align-items: center;\n"], ["\n  display: flex;\n  border: 1px solid #ccc;\n  padding: 4px;\n  border-radius: 12px;\n  align-items: center;\n"])));
export var Summary = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n  margin-right: 5px;\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n  margin-right: 5px;\n"])));
export var DataCadastro = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"], ["\n  display: inline-block;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"])));
export var TopRightPositionWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 4px;\n  right: 4px;\n\n  svg {\n    cursor: pointer;\n  }\n"], ["\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 4px;\n  right: 4px;\n\n  svg {\n    cursor: pointer;\n  }\n"])));
export var LinkToDetails = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 5px;\n  bottom: 5px;\n  font-size: 0.9rem;\n  color: ", ";\n"], ["\n  position: absolute;\n  right: 5px;\n  bottom: 5px;\n  font-size: 0.9rem;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var LateLabel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 80px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #f37500;\n  border-radius: 8px;\n\n  span {\n    color: #f37500;\n    display: table;\n    margin: 0 auto;\n  }\n"], ["\n  width: 80px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #f37500;\n  border-radius: 8px;\n\n  span {\n    color: #f37500;\n    display: table;\n    margin: 0 auto;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
