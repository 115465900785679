var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useStore, useStore as useStoreRCH } from 'react-context-hook';
import { AiFillLock, FiLogOut, GiDiploma, IoIosTrophy, IoLibrary, RiAdminFill } from 'react-icons/all';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import styled, { keyframes, useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import Container from 'typedi';
import { useStore as useS } from '@app/core/services';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import ModuleCardProfile from '@app/modules/player/player-profile/components/profile-content/profile-module-card';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import avatar from '@assets/img/avatar.png';
import { useThemeUpdate } from 'App';
import handCoin from '../../../../../../assets/img/hand-coin.png';
import podium from '../../../../../../assets/img/podium.png';
import ProfileModal from '../profile-modal';
export var ProfileContent = function (_a) {
    var _b, _c;
    var user = _a.user;
    var _d = useStore('mute'), isMute = _d[0], setIsMute = _d[1];
    var authStorageService = Container.get(AuthStorageService);
    var _e = useState(false), isOpenModal = _e[0], setOpenModal = _e[1];
    var _f = useState((_b = user === null || user === void 0 ? void 0 : user.data) === null || _b === void 0 ? void 0 : _b.imagem), image = _f[0], setImage = _f[1];
    var history = useHistory();
    var userData = useS(useUserStore).userData;
    var theme = useTheme();
    var updateTheme = useThemeUpdate();
    var _g = useStore('darkTheme', null), darkTheme = _g[0], setDarkTheme = _g[1];
    var _h = useStoreRCH('isUserAlreadyRepliedTermometroEmocionalToday'), _ = _h[0], setIsUserAlreadyRepliedTermometroEmocionalToday = _h[1];
    var resetEmotionTodayStatus = function () {
        setIsUserAlreadyRepliedTermometroEmocionalToday(false);
    };
    var generatePainelLink = function () {
        var token = authStorageService.getToken();
        var suffix = "external-login?token=" + token;
        var _a = window.location, origin = _a.origin, pathname = _a.pathname;
        var clientIdentification = pathname.split('/')[1];
        if (origin.includes('localhost')) {
            // developer is running the server locally
            // move to `localhost:8100`
            window.location.href = "http://localhost:3000/" + clientIdentification + "/" + suffix;
        }
        else {
            var originUrl = window.location.origin.replace('jogador', 'admin');
            var newURL = originUrl + "/" + clientIdentification + "/" + suffix;
            window.location.href = newURL;
        }
    };
    var handleImageClicked = function () {
        setOpenModal(true);
    };
    var getImage = function () {
        if (image === null || image === undefined) {
            return avatar;
        }
        else {
            return image;
        }
    };
    var handleClickLogout = function () {
        Swal.fire({
            target: '#root',
            title: 'Sair',
            text: 'Tem certeza que quer sair?',
            showCancelButton: true,
            confirmButtonText: 'Sim, eu quero.',
            cancelButtonText: 'Não, não quero.',
            reverseButtons: true,
        }).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (result.isConfirmed) {
                    resetEmotionTodayStatus();
                    authStorageService.logout();
                }
                return [2 /*return*/];
            });
        }); });
    };
    useEffect(function () {
        var _a;
        setImage((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.imagem);
    }, [(_c = user === null || user === void 0 ? void 0 : user.data) === null || _c === void 0 ? void 0 : _c.imagem]);
    var profileModules = [
        {
            order: 1,
            name: 'JogadorBiblioteca',
            title: 'Biblioteca',
            description: 'Acesse a biblioteca de jogos',
            iconComponent: React.createElement(IoLibrary, null),
            onClick: function () { return history.push(AppPath.Library); },
        },
        {
            order: 2,
            name: 'JogadorRanking',
            title: 'Ranking',
            description: 'Veja sua posição no ranking',
            iconComponent: React.createElement(IoIosTrophy, null),
            onClick: function () { return history.push({ pathname: AppPath.Ranking, state: { isProfile: true } }); },
        },
        {
            order: 3,
            name: 'JogadorValidacaoAprendizado',
            title: 'Validação de aprendizado',
            description: 'Valide seus conhecimentos',
            iconComponent: React.createElement(GiDiploma, null),
            onClick: function () { return history.push(AppPath.LearningValidation); },
        },
    ];
    var commonModules = [
        {
            order: 4,
            title: 'Painel Administrador',
            description: 'Acesse o painel administrador',
            iconComponent: React.createElement(RiAdminFill, null),
            filter: function (role) { return role === null || role === void 0 ? void 0 : role.possuiAcessoAdmin; },
            onClick: generatePainelLink,
        },
        {
            order: 5,
            title: 'Trocar Senha',
            description: 'Altere sua senha',
            iconComponent: React.createElement(AiFillLock, null),
            onClick: function () { return history.push(AppPath.Configurations); },
        },
        {
            order: 6,
            title: isMute ? 'Desativar Som' : 'Ativar Som',
            description: 'Ative ou desative o som',
            iconComponent: isMute ? React.createElement(BsFillVolumeMuteFill, null) : React.createElement(BsFillVolumeUpFill, null),
            onClick: function () { return setIsMute(!isMute); },
        },
        // {
        //   order: 7,
        //   title: theme.darkMode ? 'Modo Claro' : 'Modo Escuro',
        //   description: 'Mude o tema do sistema',
        //   iconComponent: theme.darkMode ? <BsLightbulbOff /> : <BsLightbulb />,
        //   onClick: () => setDarkTheme(!darkTheme),
        // },
        {
            order: 8,
            title: 'Sair',
            description: 'Faça logout',
            iconComponent: React.createElement(FiLogOut, null),
            onClick: handleClickLogout,
        },
    ];
    var buildComponent = function (module) {
        return (React.createElement(ModuleCardProfile, { gridMode: false, title: module.title, description: module.description, iconComponent: module.iconComponent, onClick: module.onClick }));
    };
    var buildCommonsModules = function () {
        return commonModules
            .filter(function (module) { return (module.filter ? module.filter(userData) : true); })
            .map(function (module) { return buildComponent(module); });
    };
    function RolesMenu(_a) {
        var rolesToMap = _a.rolesToMap;
        if (rolesToMap === undefined)
            return React.createElement(React.Fragment, null);
        if (typeof rolesToMap === 'string') {
            var roleValue = profileModules.find(function (mapping) { return mapping.name === rolesToMap; });
            return roleValue ? buildComponent(roleValue) : null;
        }
        var orderedRoles = __spreadArrays(rolesToMap).sort(function (a, b) {
            var _a, _b;
            var orderA = ((_a = profileModules.find(function (mapping) { return mapping.name === a; })) === null || _a === void 0 ? void 0 : _a.order) || 0;
            var orderB = ((_b = profileModules.find(function (mapping) { return mapping.name === b; })) === null || _b === void 0 ? void 0 : _b.order) || 0;
            if (orderA === orderB) {
                return 0;
            }
            return orderA - orderB;
        });
        return (React.createElement(React.Fragment, null, orderedRoles.map(function (role) {
            var roleValue = profileModules.find(function (mapping) { return mapping.name === role; });
            return roleValue ? buildComponent(roleValue) : null;
        })));
    }
    return (React.createElement(ProfileContentStyle, null,
        React.createElement(StyledContainer, { style: { height: '220px' } },
            React.createElement(Row, null,
                React.createElement(PersonalDataContainer, null,
                    React.createElement(ImageWrapper, { onClick: function () { return handleImageClicked(); } },
                        React.createElement("img", { className: "user-avatar", src: getImage(), alt: "Player" }),
                        React.createElement(ProfileModal, { isOpenModal: isOpenModal, setOpenModal: setOpenModal, setImage: setImage })),
                    React.createElement(LabelH3, { style: { marginTop: '16px', marginLeft: '10px' } }, user !== undefined ? React.createElement(React.Fragment, null, user === null || user === void 0 ? void 0 : user.data.nome) : React.createElement(SkeletonCard, null)))),
            React.createElement(Row, null,
                React.createElement(Label, null, user !== undefined ? React.createElement(React.Fragment, null, user === null || user === void 0 ? void 0 : user.data.email) : React.createElement(SkeletonCard, null))),
            React.createElement(Row, { style: { marginBottom: '10px', paddingTop: '15px' } },
                React.createElement(Column, { style: { flex: '0.5', paddingLeft: '8px', paddingBottom: '12px', margin: '8px' } },
                    React.createElement("img", { src: handCoin, style: { left: '0', position: 'absolute', marginLeft: '12px', marginTop: '-20px' } })),
                React.createElement(Column, null,
                    React.createElement(Row, { style: { justifyContent: 'flex-start' } },
                        React.createElement("img", { src: podium }),
                        React.createElement(Label, { style: { marginLeft: '12px', marginTop: '7px' } }, user !== undefined ? React.createElement(React.Fragment, null, user === null || user === void 0 ? void 0 :
                            user.data.posicao,
                            "\u00B0 lugar") : React.createElement(SkeletonCard, null)))))),
        React.createElement(MenuOptionsBox, null,
            React.createElement(RolesMenu, { rolesToMap: userData === null || userData === void 0 ? void 0 : userData.roles }),
            buildCommonsModules())));
};
var ProfileContentStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  /* calc header - footer */\n  height: calc(100% - 10vh - 10vh);\n  width: 100%;\n  position: absolute;\n  /** We need review margin-bottom from header **/\n  padding-top: 20px;\n  margin-top: -20px;\n\n  &&::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  overflow: auto;\n  /* calc header - footer */\n  height: calc(100% - 10vh - 10vh);\n  width: 100%;\n  position: absolute;\n  /** We need review margin-bottom from header **/\n  padding-top: 20px;\n  margin-top: -20px;\n\n  &&::-webkit-scrollbar {\n    display: none;\n  }\n"])));
var StyledContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border-radius: 30px;\n  background-color: #f6f1e5;\n  width: auto;\n  padding: 20px;\n  display: flex;\n  border: 2px solid #d9d9d9;\n  margin: 10px;\n  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.15), 10px 4px 20px 0 rgba(0, 0, 0, 0.07);\n"], ["\n  display: flex;\n  flex-direction: column;\n  border-radius: 30px;\n  background-color: #f6f1e5;\n  width: auto;\n  padding: 20px;\n  display: flex;\n  border: 2px solid #d9d9d9;\n  margin: 10px;\n  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.15), 10px 4px 20px 0 rgba(0, 0, 0, 0.07);\n"])));
export var UserAvatarWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"], ["\n  width: 100px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"])));
export var ImageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n\n  img.user-avatar {\n    background: #cdcdcd;\n    min-width: 60px;\n    min-height: 60px;\n    max-width: 60px;\n    max-height: 60px;\n    border-radius: 50%;\n    border: 5px solid #000;\n  }\n"], ["\n  cursor: pointer;\n\n  img.user-avatar {\n    background: #cdcdcd;\n    min-width: 60px;\n    min-height: 60px;\n    max-width: 60px;\n    max-height: 60px;\n    border-radius: 50%;\n    border: 5px solid #000;\n  }\n"])));
var Row = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var Column = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var PersonalDataContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var LabelH3 = styled.h3(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var Label = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var shimmerAnimation = keyframes(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    0% {\n        background-position: -200%;\n    }\n    100% {\n        background-position: 200%;\n    }\n"], ["\n    0% {\n        background-position: -200%;\n    }\n    100% {\n        background-position: 200%;\n    }\n"])));
var SkeletonCard = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 150px;\n  height: 20px;\n  border-radius: 5px;\n  background: linear-gradient(90deg, #d4d4d4 25%, #b8b8b8 50%, #d4d4d4 75%);\n  background-size: 200% 100%;\n  animation: ", " 1.5s infinite;\n"], ["\n  width: 150px;\n  height: 20px;\n  border-radius: 5px;\n  background: linear-gradient(90deg, #d4d4d4 25%, #b8b8b8 50%, #d4d4d4 75%);\n  background-size: 200% 100%;\n  animation: ", " 1.5s infinite;\n"])), shimmerAnimation);
var MenuOptionsBox = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: center;\n  padding-bottom: 5vh;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: center;\n  padding-bottom: 5vh;\n"])));
var MenuOption = styled(Link)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 90%;\n  height: 100px;\n  margin: 10px;\n  background-color: fffcfb;\n  border-radius: 30px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border: 2px solid #ddd;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 90%;\n  height: 100px;\n  margin: 10px;\n  background-color: fffcfb;\n  border-radius: 30px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border: 2px solid #ddd;\n  cursor: pointer;\n"])));
var MenuOptionNoLink = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 90%;\n  height: 100px;\n  margin: 10px;\n  background-color: fffcfb;\n  border-radius: 30px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border: 2px solid #ddd;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 90%;\n  height: 100px;\n  margin: 10px;\n  background-color: fffcfb;\n  border-radius: 30px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border: 2px solid #ddd;\n  cursor: pointer;\n"])));
var Icon = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-right: 30px;\n  margin-left: 50px;\n\n  svg {\n    color: #000;\n    font-size: 23px;\n    height: 100%;\n  }\n"], ["\n  margin-right: 30px;\n  margin-left: 50px;\n\n  svg {\n    color: #000;\n    font-size: 23px;\n    height: 100%;\n  }\n"])));
var LabelMenuOption = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; /* Align label text on the left */\n  /* Add styling for your label, e.g., font-size, color, etc. */\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; /* Align label text on the left */\n  /* Add styling for your label, e.g., font-size, color, etc. */\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
