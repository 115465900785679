var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Row } from '@atomic/obj.grid';
export var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"], ["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonsRow = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"])));
var templateObject_1, templateObject_2;
