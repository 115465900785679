var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: calc(100vh - 80px);\n  overflow-y: auto;\n"], ["\n  max-height: calc(100vh - 80px);\n  overflow-y: auto;\n"])));
export var ListsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 80%;\n  margin: 0 auto;\n"], ["\n  width: 80%;\n  margin: 0 auto;\n"])));
export var LoadingWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10;\n  background: #00000042;\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10;\n  background: #00000042;\n"])));
export var List = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  h2 {\n    text-align: center;\n    margin-bottom: 0;\n  }\n"], ["\n  h2 {\n    text-align: center;\n    margin-bottom: 0;\n  }\n"])));
export var StoreItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-top: 20px;\n  padding-bottom: 20px;\n  border-bottom: 4px dotted #cdcdcd;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-top: 20px;\n  padding-bottom: 20px;\n  border-bottom: 4px dotted #cdcdcd;\n"])));
export var Icon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  div {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    border: 4px solid ", ";\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n    justify-content: center;\n  }\n  img {\n    width: 25px;\n    height: 25px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  div {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    border: 4px solid ", ";\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n    justify-content: center;\n  }\n  img {\n    width: 25px;\n    height: 25px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var TextBox = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 20px;\n  text-transform: uppercase;\n  width: calc(100% - 130px);\n  h3 {\n    margin: 0;\n  }\n  p {\n    margin: 0;\n    font-size: 14px;\n  }\n"], ["\n  margin-left: 20px;\n  text-transform: uppercase;\n  width: calc(100% - 130px);\n  h3 {\n    margin: 0;\n  }\n  p {\n    margin: 0;\n    font-size: 14px;\n  }\n"])));
export var ButtonBox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-left: 10px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: 70px;\n"], ["\n  margin-left: 10px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: 70px;\n"])));
export var PrimaryColorText = styled.h3(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonBuy = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: none;\n  border: 1px solid #000;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  width: 70px;\n  padding: 5px 0;\n  border-radius: 20px;\n  transition: 0.1s ease-in;\n  img {\n    margin-right: 5px;\n    width: 15px;\n    height: 15px;\n  }\n\n  &:enabled {\n    cursor: pointer;\n\n    &:hover {\n      transform: scale(1.12);\n    }\n  }\n"], ["\n  background: none;\n  border: 1px solid #000;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  width: 70px;\n  padding: 5px 0;\n  border-radius: 20px;\n  transition: 0.1s ease-in;\n  img {\n    margin-right: 5px;\n    width: 15px;\n    height: 15px;\n  }\n\n  &:enabled {\n    cursor: pointer;\n\n    &:hover {\n      transform: scale(1.12);\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
