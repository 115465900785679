import React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { FaIcon, StyledContainer } from '@atomic';
import { BackButtonArrow, InfoHeader } from './course-library-header.component.style';
export var LibraryHeader = function (props) {
    var history = useHistory();
    var _a = useStore('currentLibraryCourse'), currentLibraryCourse = _a[0], setCurrentCourse = _a[1];
    var backButtonHandle = function () {
        if (currentLibraryCourse) {
            setCurrentCourse(null);
        }
        else {
            history.goBack();
        }
    };
    return (React.createElement(StyledContainer, { primaryColor: true, style: { display: 'flex' }, className: "use-inverse-colors" },
        React.createElement(BackButtonArrow, null,
            React.createElement(FaIcon.ArrowLeft, { onClick: backButtonHandle })),
        React.createElement(InfoHeader, null,
            React.createElement("h1", null, "Biblioteca"),
            React.createElement("h2", null, props.title))));
};
