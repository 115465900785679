import * as React from 'react';
import { format } from 'date-fns';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router';
import { NewQuestionBodyComponent } from '@app/components/atm.question-component-new/new-question-body.component';
import { axiosClient } from '@app/configure-app';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import { StyledContainer } from '@atomic';
import { LearningButton } from '../learning-button/learning-button.component';
import { ImagePreview, SpacedElementsWrapper } from './quiz.styled';
export var Quiz = function (_a) {
    var tarefa = _a.tarefa, answerResponse = _a.answerResponse;
    var history = useHistory();
    var _b = React.useContext(QuizContext), quizzes = _b[0], currentQuizIndex = _b[1];
    var _c = useStore('choosedAlternativeId'), choosedAlternativeId = _c[0], setChoosedAlternativeId = _c[1];
    var _d = React.useState([]), alternativas = _d[0], setAlternativas = _d[1];
    React.useEffect(function () {
        setAlternativas(randomizeQuizzes(quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].alternativas));
    }, [quizzes, currentQuizIndex]);
    React.useEffect(function () {
        setChoosedAlternativeId(undefined);
        var currentDate = format(new Date(), 'uuuu-M-dd');
        if (localStorage.getItem("retorno-ajuda-" + currentDate) === 'true') {
            axiosClient.post("registro-ajuda/saida/" + (quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].id));
            localStorage.removeItem("retorno-ajuda-" + currentDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var randomizeQuizzes = function (array) {
        var randomNumber;
        var tmp;
        for (var i = array.length; i;) {
            randomNumber = (Math.random() * i--) | 0;
            tmp = array[randomNumber];
            array[randomNumber] = array[i];
            array[i] = tmp;
        }
        return array;
    };
    var handleAnswerClick = function (alternativaId) {
        if (!answerResponse)
            setChoosedAlternativeId(alternativaId);
        window.scrollTo(0, document.body.scrollHeight);
    };
    var handleOnLearningBtnClick = function () {
        axiosClient.post("registro-ajuda/entrada/" + (quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].id));
        var quiz = tarefa;
        history.push({
            pathname: AppPath.Learning,
            state: { quiz: quiz, currentQuizIndex: currentQuizIndex, withTimeRegister: true },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { withPadding: true, noPaddingBottom: true },
            React.createElement(SpacedElementsWrapper, null,
                React.createElement(LearningButton, { handleOnLearningBtnClick: function () { return handleOnLearningBtnClick(); }, learningData: tarefa })),
            (quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].imagem) && (React.createElement(ImagePreview, null,
                React.createElement("img", { src: quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].imagem }))),
            React.createElement("div", { className: "list-inner", dangerouslySetInnerHTML: { __html: quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex].pergunta } }),
            React.createElement(NewQuestionBodyComponent, { alternatives: alternativas === null || alternativas === void 0 ? void 0 : alternativas.map(function (item) { return ({
                    id: item.id,
                    title: item.descricao,
                }); }), incluirOpcaoOutros: false, onSelectAlternative: function (questionId) { return handleAnswerClick(questionId.toString()); } }))));
};
