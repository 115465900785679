import * as React from 'react';
import { BiSupport } from 'react-icons/bi';
import { MdOutlineFeedback, MdOutlineOndemandVideo } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Header } from '@app/components/mol.header';
import { AppPath } from '@app/routes';
import { StyledContainer } from '@atomic';
import { HelpMenuItem, ItemIcon, ItemText } from './help.page.styled';
export var HelpPage = function () {
    var history = useHistory();
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
            React.createElement(Header, { title: "Ajuda", withBackButton: true })),
        React.createElement(StyledContainer, { withHPadding: true },
            React.createElement(HelpMenuItem, { onClick: function () { return history.push({ pathname: AppPath.Tutorial, state: { withBackButton: true } }); } },
                React.createElement(ItemIcon, null,
                    React.createElement("div", null,
                        React.createElement(MdOutlineOndemandVideo, null))),
                React.createElement(ItemText, null,
                    React.createElement("h3", null, "Aprendendo a usar o SuperApp"),
                    React.createElement("p", null, "Aproveite todos os benef\u00EDcios de sua plataforma assistindo esse v\u00EDdeo curtinho!"))),
            React.createElement(HelpMenuItem, { onClick: function () { return history.push(AppPath.ReportError); } },
                React.createElement(ItemIcon, null,
                    React.createElement("div", null,
                        React.createElement(MdOutlineFeedback, null))),
                React.createElement(ItemText, null,
                    React.createElement("h3", null, "Reportar Erro"),
                    React.createElement("p", null, "Aqui voc\u00EA pode relatar um comportamento inesperado ou erro encontrado."))),
            React.createElement(HelpMenuItem, null,
                React.createElement("a", { href: "https://linkwhats.app/dff824", rel: "noreferrer", target: "_blank", style: { display: 'flex' } },
                    React.createElement(ItemIcon, null,
                        React.createElement("div", null,
                            React.createElement(BiSupport, null))),
                    React.createElement(ItemText, null,
                        React.createElement("h3", null, "Falar com o Suporte"),
                        React.createElement("p", null, "Caso queira falar diretamente com a nossa equipe.")))))));
};
