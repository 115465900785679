var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Container, IconsBox, } from '@app/modules/course-library/components/course-item/course-library-item.component.style';
import { FontFamily } from '@atomic';
export var TitleTask = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 5px;\n  margin-left: 15px;\n  font-size: 1.5rem;\n  font-family: ", ";\n  font-weight: bold;\n  @media (max-width: 380px) {\n    font-size: 1.2rem;\n  }\n"], ["\n  margin-top: 5px;\n  margin-left: 15px;\n  font-size: 1.5rem;\n  font-family: ", ";\n  font-weight: bold;\n  @media (max-width: 380px) {\n    font-size: 1.2rem;\n  }\n"])), FontFamily.Primary);
export var IconsBoxTask = styled(IconsBox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  svg {\n    margin-right: 10px;\n  }\n"], ["\n  svg {\n    margin-right: 10px;\n  }\n"])));
export var ContainerTask = styled(Container)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.5rem 0;\n"], ["\n  padding: 0.5rem 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
