import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { Logo } from '@app/components/atm.logo/logo.component';
import { CourseContentInfo, CourseInfo, Info } from '@app/modules/quiz/pages/course-completed.page.styled';
import { AppPath } from '@app/routes';
import { Button, Separator, StyledContainer } from '@atomic';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var phaseCompletedSound = require('@assets/sounds/phase-completed.mp3');
export var CourseCompletedPage = function (props) {
    var history = useHistory();
    var isMute = useStore('mute')[0];
    var playPhaseCompletedSound = useSound(phaseCompletedSound, { volume: 0.6 })[0];
    var _a = useState(0), elapsedDate = _a[0], setElapsedData = _a[1];
    var course = props.location.state.course;
    var company = useStore('company')[0];
    React.useEffect(function () {
        if (!isMute) {
            playPhaseCompletedSound();
        }
    }, [isMute, playPhaseCompletedSound]);
    React.useEffect(function () {
        var startDate = moment(course === null || course === void 0 ? void 0 : course.dataInicio).startOf('day');
        var currentDate = moment().startOf('day');
        var elapsed = moment.duration(currentDate.diff(startDate)).asDays();
        setElapsedData(elapsed);
    }, [course]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(CourseContentInfo, null,
                React.createElement("div", null,
                    React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogo, centered: true, style: { width: '70%' } })),
                React.createElement(CourseInfo, null,
                    React.createElement(Info, null,
                        React.createElement("span", null,
                            "Parab\u00E9ns pela finaliza\u00E7\u00E3o do Desafio: ", course === null || course === void 0 ? void 0 :
                            course.nome)),
                    React.createElement(Info, null,
                        React.createElement("span", null, "Aproveitamento:"),
                        React.createElement("span", null, course === null || course === void 0 ? void 0 :
                            course.percentualAproveitamento,
                            "%")),
                    React.createElement(Info, null,
                        React.createElement("span", null, "Tempo de conclus\u00E3o:"),
                        React.createElement("span", null,
                            elapsedDate,
                            " dias")))),
            React.createElement(DottedSeparator, { style: { marginTop: '2.1rem' } }),
            React.createElement(Separator, null),
            React.createElement(Button, { expanded: true, onClick: function () {
                    history.push({
                        pathname: "" + AppPath.Map,
                    });
                }, variant: "primary" },
                React.createElement("span", null, "Ir para Menu")))));
};
