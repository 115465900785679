var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import cry from '@assets/img/cry.png';
import money from '@assets/img/Filled_Icons.svg';
import happy from '@assets/img/happy.png';
import { FlashMessageEnum, FontFamily } from '@atomic';
import { QuizWinContainer, QuizWinContent, ButtonBox, Points, RewardIconBox, MoneyIconBox, TextBox, Circle, ButtonAndamento, } from '../components/quiz-win/quiz-win.styled';
var RIGHT_ANSWER_TITLE = 'Uau!';
var WRONG_ANSWER_TITLE = 'Ops..';
var RIGHT_ANSWER_MSG = 'Você ganhou pontos, parabéns. ';
var WRONG_ANSWER_MSG = 'Eu acredito em você, vamos de novo? ';
export var isApprovedOnTask = function (percentage) {
    return percentage >= 50;
};
export var QuizWin = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _j = React.useContext(QuizContext), _ = _j[0], __ = _j[1], setQuestions = _j[2], setCurrentQuizzIndex = _j[3];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var loadedCourse = useStore('currentCourse')[0];
    var _k = useStore('taskSelected'), taskSelected = _k[0], setTaskSelected = _k[1];
    var isApproved = isApprovedOnTask((_b = (_a = props.location.state) === null || _a === void 0 ? void 0 : _a.quizResult) === null || _b === void 0 ? void 0 : _b.tarefaAcertoPercentual);
    var tarefa = props.location.state.tarefa;
    var userUrl = 'usuario';
    var getUserResponse = useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(userUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var handleTryAgainClick = function () {
        setCurrentQuizzIndex(0);
        history.push({
            pathname: AppPath.Quiz,
            state: { tarefa: tarefa },
        });
    };
    var randomizeQuizzes = function (quizzes) {
        var randomNumber;
        var tmp;
        for (var i = quizzes.length; i;) {
            randomNumber = (Math.random() * i--) | 0;
            tmp = quizzes[randomNumber];
            quizzes[randomNumber] = quizzes[i];
            quizzes[i] = tmp;
        }
        return quizzes;
    };
    var redirectToTrack = function () {
        history.push(AppPath.Track.replace(':courseCode', "" + loadedCourse.codigo));
    };
    var pontuacaoUsaurio = localStorage.getItem('pontosDoUsuarioNaRodada');
    var handleOKClick = function () {
        // If user already completed the task, redirect to phase list
        if (tarefa === null || tarefa === void 0 ? void 0 : tarefa.concluida) {
            localStorage.setItem('pontosDoUsuarioNaRodada', '0');
            redirectToTrack();
            return;
        }
        var proximaTarefa;
        for (var i = 0; i < loadedCourse.fases.length; i++) {
            if (loadedCourse.fases[i].id === tarefa.faseId) {
                proximaTarefa = loadedCourse.fases[i].tarefas[taskSelected + 1];
            }
        }
        if (proximaTarefa === null || proximaTarefa === void 0 ? void 0 : proximaTarefa.concluida) {
            redirectToTrack();
            return;
        }
        setCurrentQuizzIndex(0);
        if (!!(proximaTarefa === null || proximaTarefa === void 0 ? void 0 : proximaTarefa.quizzes)) {
            setTaskSelected(taskSelected + 1);
            setQuestions(randomizeQuizzes(proximaTarefa.quizzes));
            history.push({
                pathname: AppPath.Quiz,
                state: { tarefa: proximaTarefa },
            });
        }
        else {
            setTaskSelected(0);
            setQuestions(undefined);
            redirectToTrack();
        }
    };
    var handleBtnClick = function () {
        localStorage.setItem('pontosDoUsuarioNaRodada', '0');
        if (loadedCourse.podeGastarEnergiaJogador && getUserResponse.data.energias < 1) {
            history.push(AppPath.Store);
            showFlashMessage(FlashMessageEnum.alert, 'Você não possui Energias. Compre para voltar a jogar.');
        }
        else {
            isApproved ? handleOKClick() : handleTryAgainClick();
        }
    };
    return (React.createElement(QuizWinContainer, { Background: isApprovedOnTask((_d = (_c = props.location.state) === null || _c === void 0 ? void 0 : _c.quizResult) === null || _d === void 0 ? void 0 : _d.tarefaAcertoPercentual) },
        React.createElement(QuizWinContent, null,
            React.createElement("div", null,
                React.createElement(MoneyIconBox, null,
                    React.createElement("img", { src: money, alt: "money" })),
                React.createElement(Points, { style: { fontFamily: FontFamily.Secondary } },
                    "+",
                    pontuacaoUsaurio),
                React.createElement(RewardIconBox, null,
                    React.createElement(Circle, { Background: isApprovedOnTask((_f = (_e = props.location.state) === null || _e === void 0 ? void 0 : _e.quizResult) === null || _f === void 0 ? void 0 : _f.tarefaAcertoPercentual) },
                        React.createElement("img", { src: isApproved ? happy : cry }))))),
        React.createElement(TextBox, { style: { fontFamily: FontFamily.Primary } },
            React.createElement("h2", null, isApproved ? RIGHT_ANSWER_TITLE : WRONG_ANSWER_TITLE),
            React.createElement("p", null, isApproved ? RIGHT_ANSWER_MSG : WRONG_ANSWER_MSG)),
        React.createElement(ButtonBox, null,
            React.createElement("div", null,
                React.createElement(ButtonAndamento, { Background: isApprovedOnTask((_h = (_g = props.location.state) === null || _g === void 0 ? void 0 : _g.quizResult) === null || _h === void 0 ? void 0 : _h.tarefaAcertoPercentual), onClick: handleBtnClick }, isApproved ? 'CONTINUAR' : 'TENTAR NOVAMENTE')))));
};
