var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Typography } from '@mui/material';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { axiosClient } from '@app/configure-app';
import { SkeletonLoadingPage } from '@app/modules/map/skeleton-loading/skeleton-loading.component';
import { OuterDiv } from '@app/modules/questionnarie/questionnaire.styled';
import { FontFamily, StyledContainer } from '@atomic';
import handWorld from '../../../../../assets/img/hand-world.png';
import { SubmenuContainer, SubmenuHeader, SubmenuIcon } from '../../components/styles';
import { SubmenuContentCard } from '../../components/submenu-content-card';
import { FlexCol } from '../../components/submenu-content-card/styles';
var GET_CURSOS_UNIDADES_URL = 'unidade-empresa/cursos/por-usuario';
var GET_EXTRAS_URL = 'loja/extra';
export var KnowledgePathCategory = function () {
    var company = useStore('company')[0];
    var routeParams = useParams();
    var _a = React.useState([]), mergedContents = _a[0], setMergedContents = _a[1];
    var history = useHistory();
    var _b = useQuery(['get-courses-from-knowledge-path', routeParams === null || routeParams === void 0 ? void 0 : routeParams.id], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get("filial/trilhas/" + (routeParams === null || routeParams === void 0 ? void 0 : routeParams.id) + "/cursos")];
                case 1:
                    response = _d.sent();
                    return [2 /*return*/, (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.cursos) !== null && _c !== void 0 ? _c : []];
                case 2:
                    error_1 = _d.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), cursos = _b.data, isLoading = _b.isLoading;
    var _c = useQuery(['cursos-unidades', routeParams === null || routeParams === void 0 ? void 0 : routeParams.id], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, modifiedData, targetObject, newArray, newArray2, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_CURSOS_UNIDADES_URL)];
                case 1:
                    response = _c.sent();
                    modifiedData = response.data.data.map(function (item) {
                        var quantidadeCursosNaoConcluidos = item.quantidadeCursosNaoConcluidos, rest = __rest(item, ["quantidadeCursosNaoConcluidos"]);
                        return __assign({ quantidadeNaoLida: quantidadeCursosNaoConcluidos }, rest);
                    });
                    targetObject = modifiedData.find(function (item) { return item.id === Number(routeParams.id); });
                    newArray = (_a = targetObject === null || targetObject === void 0 ? void 0 : targetObject.cursos) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.curso; });
                    newArray2 = (_b = targetObject === null || targetObject === void 0 ? void 0 : targetObject.cursosSequenciais) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.curso; });
                    if (targetObject) {
                        return [2 /*return*/, __spreadArrays(newArray, newArray2)];
                    }
                    else {
                        return [2 /*return*/, []];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _c.sent();
                    console.error(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true }), cursosTrilha = _c.data, isLoadingTrilhaCourses = _c.isLoading;
    // const { data: extras, isLoading: extraLoading } = useQuery<any>(
    //   ['cursos-extras', routeParams.id],
    //   async () => {
    //     try {
    //       const response = await axiosClient.get(GET_EXTRAS_URL);
    //       return response?.data?.data ?? [];
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   },
    //   { refetchOnMount: true },
    // );
    var combinedData = function () {
        if (cursos) {
            var combinedCourses = __spreadArrays(cursos, cursosTrilha);
            setMergedContents(combinedCourses);
        }
    };
    React.useEffect(function () {
        combinedData();
    }, [cursos, cursosTrilha, routeParams.id]);
    var handleOnClick = function (id) {
        history.push("/track/" + id);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OuterDiv, null,
            React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
                React.createElement(HeaderV2, { withBackButton: true, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, backButtonFn: function () { return history.push('/trilha-de-aprendizado'); } })),
            React.createElement(SubmenuHeader, null,
                React.createElement(SubmenuIcon, { src: handWorld }),
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '20px', color: '#49443C' }, gutterBottom: true, variant: "h1", component: "div" }, "Caminhos do Conhecimento")),
            React.createElement(FlexCol, null,
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '20px', color: '#000' }, gutterBottom: true, variant: "h2", component: "span", textAlign: "center" })),
            React.createElement(SubmenuContainer, null, !isLoading && !isLoadingTrilhaCourses ? (React.createElement(React.Fragment, null, mergedContents === null || mergedContents === void 0 ? void 0 : mergedContents.map(function (category, index) { return (React.createElement(SubmenuContentCard, { key: index, content: category, onClick: handleOnClick })); }))) : (React.createElement(SkeletonLoadingPage, null)))),
        React.createElement(BottomNav, null)));
};
