var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Container from 'typedi';
import { axiosClient } from '@app/configure-app';
import { useStore as ServiceStore } from '@app/core/services';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { getUsuarioIdFromClaims, getValueFromClaimsByType } from '@app/utils/shared-functions.utils';
import { Form, Validators, Separator, PasswordField, Button, FlashMessageEnum, ActivityIndicator, } from '@atomic';
import { FormStyledWithFontColorBlack } from '../authentication/authentication.styled';
import { PasswordMinLength } from './authentication.rules';
import { POST_CPF_LOGIN_URL, POST_LOGIN_URL } from './login.page';
var Password = AuthStrings.Commom.Password;
export var ChangePassword = function (_a) {
    var usuarioId = _a.usuarioId, endpointUrl = _a.endpointUrl, onError = _a.onError, requestExtraParams = _a.requestExtraParams;
    var _b = React.useState(), password = _b[0], setPassword = _b[1];
    var _c = React.useState(), formDataState = _c[0], setFormDataState = _c[1];
    var authStorageService = Container.get(AuthStorageService);
    var company = useStore('company')[0];
    var userData = ServiceStore(useUserStore).userData;
    var history = useHistory();
    var onSuccess = function () { return handleMutation(); };
    var _d = useMutation(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.put(endpointUrl, data)];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.name) === 'Error')
                        throw response;
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: onSuccess,
        onError: onError,
    }), mutate = _d.mutate, isLoading = _d.isLoading;
    var handleSubmit = function (formData) {
        setFormDataState(formData);
        var hasFormError = Object.keys(formData.error).length > 0;
        if (!hasFormError) {
            mutate(__assign({ id: usuarioId, novaSenha: formData.data.novaSenha, confirmarNovaSenha: formData.data.novaSenha }, requestExtraParams));
        }
    };
    var loginMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(params.cpf ? POST_CPF_LOGIN_URL : POST_LOGIN_URL, {
                        login: userData.email,
                        cpf: params.cpf,
                        password: password,
                        empresaId: company.id,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    var onSuccessLogin = function (response) {
        var _a, _b, _c, _d;
        axiosClient.defaults.headers['Authorization'] = "Bearer " + response.data.accessToken;
        var hasUseTerms = !!((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.userToken) === null || _b === void 0 ? void 0 : _b.termosDeUso) === null || _c === void 0 ? void 0 : _c.id);
        if (((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.recebeuEnergiasDoDia) && !hasUseTerms) {
            showFlashMessage(FlashMessageEnum.success, 'Parabéns! Você ganhou energias!');
        }
        if (hasUseTerms) {
            history.push({
                pathname: AppPath.UseTerms,
                state: { data: response === null || response === void 0 ? void 0 : response.data },
            });
        }
        else {
            var decodedToken = parseJwt(response.data.accessToken);
            authStorageService.login({
                id: response.data.userToken.id,
                email: response.data.userToken.email,
                usuarioId: getUsuarioIdFromClaims(response.data.userToken.claims),
                nomePerfil: getValueFromClaimsByType(response.data.userToken.claims, 'role'),
                roles: decodedToken.roles,
                possuiAcessoAdmin: decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.possuiAcessoAdmin,
            }, response.data.accessToken);
            history.push({ pathname: AppPath.Map });
        }
    };
    var loginMutate = useMutation(loginMutation, {
        onSuccess: onSuccessLogin,
    }).mutate;
    var handleMutation = function () {
        if (requestExtraParams === null || requestExtraParams === void 0 ? void 0 : requestExtraParams.token) {
            history.push('/');
        }
        else {
            var requestData = {
                login: undefined,
                cpf: undefined,
                password: formDataState.data.password,
            };
            loginMutate(requestData);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormStyledWithFontColorBlack, null,
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(Form.Field, { onValueChange: function (value) { return setPassword(value); }, name: "novaSenha", label: "Nova Senha", validators: [Validators.Required(), Validators.MinLength(PasswordMinLength, Password.MinLengthError)] },
                    React.createElement(PasswordField, null)),
                React.createElement(Separator, null),
                React.createElement(Form.Field, { name: "confirmarNovaSenha", label: "Confirmar Senha", validators: [Validators.EqualsValue(password, Password.SamePasswordError), Validators.Required()] },
                    React.createElement(PasswordField, null)),
                React.createElement(Separator, null),
                React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading }, "CONFIRMAR"),
                isLoading && React.createElement(ActivityIndicator, { type: 'spinner' }),
                React.createElement(Separator, null)))));
};
function showFlashMessage(alert, arg1) {
    throw new Error('Function not implemented.');
}
