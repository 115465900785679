var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H4, H5 } from '@atomic';
import { Row } from '@atomic/obj.grid';
export var Title = styled(H4)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var SubTitle = styled(H4)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 0.8rem;\n  color: ", ";\n  margin-bottom: 1rem;\n"], ["\n  font-size: 0.8rem;\n  color: ", ";\n  margin-bottom: 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ImagePreviewWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var Label = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"], ["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonsRow = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"])));
export var ImagePreview = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-height: 90px;\n  margin-left: 5%;\n"], ["\n  max-height: 90px;\n  margin-left: 5%;\n"])));
export var CloseCircleIcon = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 33px;\n  height: 23px;\n  background: #c20000;\n  border-radius: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 700;\n  color: white;\n  position: relative;\n  left: -10px;\n  top: -10px;\n  border: 1px solid white;\n  font-size: 13px;\n"], ["\n  cursor: pointer;\n  width: 33px;\n  height: 23px;\n  background: #c20000;\n  border-radius: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 700;\n  color: white;\n  position: relative;\n  left: -10px;\n  top: -10px;\n  border: 1px solid white;\n  font-size: 13px;\n"])));
export var Subtitle = styled(H5)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
