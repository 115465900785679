import * as React from 'react';
import { useStore } from 'react-context-hook';
import { GrDocumentPdf, GrVideo } from 'react-icons/gr';
import { VideoPlayer } from '@app/components/atm.video-player';
import { Button } from '@atomic';
import { CourseTitle } from '@atomic/atm.typography/typography.component.style';
import { Modal } from '@atomic/obj.modal';
import { CircleIconBox, ColorThemeAnchor, Container, IconsBox } from './course-library-item.component.style';
export var PdfDownloadButton = function (_a) {
    var pdfUrl = _a.pdfUrl, padding = _a.padding;
    return (React.createElement("a", { target: "_blank", rel: "noreferrer", href: "" + pdfUrl },
        React.createElement(Button, { style: padding ? { padding: 2 } : {}, variant: "link", target: '_blank' },
            React.createElement(GrDocumentPdf, null))));
};
export var OpenVideoButton = function (_a) {
    var videoUrl = _a.videoUrl, padding = _a.padding;
    return (React.createElement("a", { target: "_blank", rel: "noreferrer", href: "" + videoUrl },
        React.createElement(Button, { style: padding ? { padding: 2 } : {}, variant: "link", target: '_blank' },
            React.createElement(GrVideo, null))));
};
export var CourseLibraryItem = function (_a) {
    var _b, _c, _d, _e;
    var course = _a.course, courseIndex = _a.courseIndex;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _f = useStore('currentLibraryCourse'), _ = _f[0], setCurrentLibraryCourse = _f[1];
    var _g = React.useState(false), isVideoPlayerModalOpen = _g[0], setIsVideoPlayerModalOpen = _g[1];
    var handleClick = function () {
        setCurrentLibraryCourse(course);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement("div", { style: { display: 'flex' }, onClick: handleClick },
                React.createElement(CircleIconBox, null,
                    React.createElement("span", null,
                        "D.",
                        courseIndex + 1)),
                React.createElement(CourseTitle, { style: { marginLeft: '1.2rem' } }, course === null || course === void 0 ? void 0 : course.nome)),
            React.createElement(IconsBox, null,
                ((_b = course === null || course === void 0 ? void 0 : course.arquivo) === null || _b === void 0 ? void 0 : _b.url) && (React.createElement("div", null,
                    React.createElement(PdfDownloadButton, { pdfUrl: (_c = course === null || course === void 0 ? void 0 : course.arquivo) === null || _c === void 0 ? void 0 : _c.url, padding: true }))),
                ((_d = course === null || course === void 0 ? void 0 : course.video) === null || _d === void 0 ? void 0 : _d.url) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { expanded: true, variant: "link", padding: "none", onClick: function () { return setIsVideoPlayerModalOpen(!isVideoPlayerModalOpen); } },
                        React.createElement(GrVideo, null)))),
                React.createElement(ColorThemeAnchor, { onClick: handleClick }, "ver fases")),
            React.createElement(Modal, { opened: isVideoPlayerModalOpen, onClose: function () { return setIsVideoPlayerModalOpen(false); } },
                React.createElement(VideoPlayer, { videoUrl: (_e = course === null || course === void 0 ? void 0 : course.video) === null || _e === void 0 ? void 0 : _e.url })))));
};
