var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import media from 'styled-media-query';
import { FieldHeight, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';
export var PasswordFieldToggleStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n"])), "calc((" + FieldHeight + " - " + Spacing.Medium + ") / 2)", Spacing.Medium);
export var PasswordHintsListStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 16px;\n"], ["\n  padding-left: 16px;\n"])));
export var PasswordHintsItemStyled = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  ", "\n"], ["\n  font-family: ", ";\n  ",
    "\n"])), FontFamily.Primary, media.lessThan('small')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), FontSize.XSmall));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
