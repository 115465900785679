var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
//import { ActionPlanModal } from '@app/modules/checklist/components/action-plan-modal-view';
import { ActionPlanModalRefactor } from '@app/modules/checklist/components/action-plan-modal-view/action-plan-modal-refactor.component';
import { ChecklistApprovalModal } from '@app/modules/checklist/components/checklist-approval-modal';
import { ModalFoto } from '@app/modules/checklist/components/modal-foto';
import { ModalObservacao } from '@app/modules/checklist/components/modal-observacao';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { getUserLocation } from '@app/services/location';
import { Button, FlashMessageEnum } from '@atomic';
import { ChecklistItemDetalhado } from '../checklist-item';
import { Span } from './checklist.styled';
export var ITEM_STATE = {
    NOT_ANSWERED: 'not_answered',
    RIGHT: 'right',
    WRONG: 'wrong',
};
export var Checklist = function (_a) {
    var _b, _c, _d;
    var checklist = _a.checklist;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var userData = useStore(useUserStore).userData;
    var _e = useState(false), isModalOpened = _e[0], setIsModalOpened = _e[1];
    var _f = useState(false), isModalObservacaoOpened = _f[0], setIsModalObservacaoOpened = _f[1];
    var _g = useState(false), isModalFotoOpened = _g[0], setIsModalFotoOpened = _g[1];
    var _h = useState([]), respostasGestor = _h[0], setRespostasGestor = _h[1];
    var _j = useState(), observacaoItemsIds = _j[0], setObservacaoItemsIds = _j[1];
    var _k = useState(), actionPlansChecklistItemsIds = _k[0], setActionPlansChecklistItemsIds = _k[1];
    var _l = useState(), fotosEnviadasChecklistItemsIds = _l[0], setFotosEnviadasChecklistItemsIds = _l[1];
    var _m = useState(), currentRespostaItemId = _m[0], setCurrentRespostaItemId = _m[1];
    var _o = useState(''), dataInicioResposta = _o[0], setDataInicioResposta = _o[1];
    var _p = useState(checklist), currentChecklist = _p[0], setCurrentChecklist = _p[1];
    var _q = useState(false), isAnyObjectWithRequiredPhotoSent = _q[0], setIsAnyObjectWithRequiredPhotoSent = _q[1];
    var _r = useState(false), isPhotoRequired = _r[0], setIsPhotoRequired = _r[1];
    var _s = useState(false), isApprovedModalOpened = _s[0], setIsApprovedModalOpened = _s[1];
    var _t = useState(), currentChecklistItem = _t[0], setCurrentChecklistItem = _t[1];
    var _u = useState(false), actionPlanSent = _u[0], setActionPlanSent = _u[1];
    var _v = useState(false), photoSent = _v[0], setPhotoSent = _v[1];
    var _w = useState((_c = (_b = currentChecklistItem === null || currentChecklistItem === void 0 ? void 0 : currentChecklistItem.itemOrigem) === null || _b === void 0 ? void 0 : _b.respostas[0]) === null || _c === void 0 ? void 0 : _c.isEfetivo), isEfetivoSelectionAfterModal = _w[0], setIsEfetivoSelectionAfterModal = _w[1];
    var _x = useState([]), fotoPreview = _x[0], setFotoPreview = _x[1];
    var _y = useState(undefined), itemFotos = _y[0], setItemFotos = _y[1];
    var _z = useState(undefined), currentCallback = _z[0], setCurrentCallback = _z[1];
    // const [showModalInfo, setShowModalInfo] = useState(false);
    var POST_ANSWERED_CHECKLIST = 'checklistunidade/preenchido-finalizar';
    var POST_ANSWERED_CHECKLIST_INDIVIDUAL = 'checklistunidade/preenchido-individual';
    var sortItemsByOrder = function (itens) {
        if (itens)
            return itens.slice().sort(function (a, b) { return a.ordem - b.ordem; });
        return [];
    };
    var isAnyObjectWithoutIsEfetivo = respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.some(function (item) { var _a, _b; return ((_a = respostasGestor[0]) === null || _a === void 0 ? void 0 : _a.efetivo) === undefined || ((_b = respostasGestor[0]) === null || _b === void 0 ? void 0 : _b.efetivo) === null; } /*|| item.efetivo === false*/);
    var postChecklistMutation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.put(POST_ANSWERED_CHECKLIST, {
                        id: currentChecklist.preenchidoId || currentChecklist.checklistPreenchidoId,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var _0 = useMutation(postChecklistMutation, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Checklist finalizado com sucesso!');
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao finalizar o checklist');
        },
    }), postChecklistResponse = _0.data, mutate = _0.mutate, isLoading = _0.isLoading;
    useEffect(function () {
        var _a;
        var itemsWithPhotos = (_a = checklist === null || checklist === void 0 ? void 0 : checklist.itens) === null || _a === void 0 ? void 0 : _a.filter(function (obj) {
            var _a, _b, _c;
            return ((_a = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _a === void 0 ? void 0 : _a.urlFotoRespostaList) !== undefined &&
                ((_b = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList) !== null &&
                ((_c = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _c === void 0 ? void 0 : _c.urlFotoRespostaList.length) > 0;
        }).map(function (obj) {
            var _a, _b;
            return ({
                id: obj.id,
                respostaItemId: (_a = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _a === void 0 ? void 0 : _a.id,
                fotoUrlList: (_b = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList,
            });
        });
        if (itemsWithPhotos) {
            itemsWithPhotos.forEach(function (item) {
                addFotoPreview(item);
            });
        }
    }, [checklist]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (dataInicioResposta === '') {
            setDataInicioResposta(moment().format());
        }
        if ((((_b = (_a = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _a === void 0 ? void 0 : _a.resposta) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _c === void 0 ? void 0 : _c.respostas[0]) === null || _d === void 0 ? void 0 : _d.id)) && ((_f = (_e = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _e === void 0 ? void 0 : _e.respostas[0]) === null || _f === void 0 ? void 0 : _f.urlFotoRespostaList) &&
            ((_h = (_g = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _g === void 0 ? void 0 : _g.respostas[0]) === null || _h === void 0 ? void 0 : _h.urlFotoRespostaList.length) > 0) {
            setFotosEnviadasChecklistItemsIds(((_k = (_j = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _j === void 0 ? void 0 : _j.resposta) === null || _k === void 0 ? void 0 : _k.id) || ((_m = (_l = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _l === void 0 ? void 0 : _l.respostas[0]) === null || _m === void 0 ? void 0 : _m.id));
            setPhotoSent(true);
        }
        setCurrentChecklist(checklist);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklist]);
    var handleOnSubmitClick = function () {
        mutate();
    };
    //const objectsWithRequiredPhoto = currentChecklist?.itens?.filter((obj) => obj.fotoObrigatoria);
    useEffect(function () {
        var _a;
        var isRequiredPhoto = (_a = checklist === null || checklist === void 0 ? void 0 : checklist.itens[0]) === null || _a === void 0 ? void 0 : _a.fotoObrigatoria; //objectsWithRequiredPhoto[0]?.fotoObrigatoria;
        var hasRequiredPhotoSent = false;
        if (isRequiredPhoto) {
            hasRequiredPhotoSent = !photoSent;
        }
        setIsAnyObjectWithRequiredPhotoSent(hasRequiredPhotoSent);
        /*
        if (objectsWithRequiredPhoto) {
          setIsPhotoRequired(!!objectsWithRequiredPhoto[0]?.fotoObrigatoria);
        }
        */
    }, [checklist, photoSent]);
    var hasPreviewAnswer = function (checklistItemId) {
        return respostasGestor.findIndex(function (_resposta) { return _resposta.id === checklistItemId; }) > -1;
    };
    useEffect(function () {
        var _a;
        var RespostastoSave = (_a = checklist === null || checklist === void 0 ? void 0 : checklist.itens) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            var resposta = item.respostas[0];
            return {
                id: item.id,
                checklistId: item.checklistUnidadeId,
                efetivo: resposta === null || resposta === void 0 ? void 0 : resposta.isEfetivo,
            };
        });
        setRespostasGestor(RespostastoSave);
    }, [checklist]);
    var registerItemAnswer = function (checklistItemId, isEfetivo, coordenadas) {
        var _a;
        if (hasPreviewAnswer(checklistItemId)) {
            var respostastoSave = respostasGestor.map(function (resposta) {
                return resposta.id === checklistItemId
                    ? __assign(__assign({}, resposta), { efetivo: isEfetivo, dataResposta: moment().format(), latitudeResposta: coordenadas ? coordenadas.latitude : null, longitudeResposta: coordenadas ? coordenadas.longitude : null }) : resposta;
            });
            setRespostasGestor(respostastoSave);
        }
        else {
            setRespostasGestor(function (respostas) { return __spreadArrays(respostas, [
                {
                    id: checklistItemId,
                    gestorId: userData.usuarioId,
                    checklistId: checklist.id,
                    efetivo: isEfetivo,
                    dataResposta: moment().format(),
                    dataCadastro: '2022-01-01T00:00:00.000Z',
                    latitudeResposta: coordenadas ? coordenadas.latitude : null,
                    longitudeResposta: coordenadas ? coordenadas.longitude : null,
                },
            ]); });
        }
        // if (checklistItem.preenchidoId) setShowModalInfo(true);
        var itemFiltrado;
        for (var _i = 0, _b = currentChecklist.itens; _i < _b.length; _i++) {
            var item = _b[_i];
            if (((_a = item.resposta) === null || _a === void 0 ? void 0 : _a.checklistUnidadeItemId) === checklistItemId) {
                itemFiltrado = item;
                break;
            }
        }
        var formattedData = {
            id: currentChecklist.preenchidoId ? currentChecklist.preenchidoId : 0,
            unidadeEmpresaId: checklist.unidadeEmpresaId,
            checklistUnidadeId: checklist.id,
            gestorResponsavelId: userData.usuarioId,
            dataInicioResposta: dataInicioResposta,
            resposta: {
                id: itemFiltrado ? itemFiltrado.resposta.id : 0,
                checklistUnidadeItemId: checklistItemId,
                isEfetivo: isEfetivo,
                dataResposta: moment().format(),
                latitudeResposta: coordenadas ? coordenadas.latitude : null,
                longitudeResposta: coordenadas ? coordenadas.longitude : null,
                checklistUnidadeRespostaItemPaiId: currentChecklist.itens[0].itemOrigem.respostas[0].planoAcaoChecklistUnidade.respostaId,
            },
        };
        return formattedData;
    };
    var handleOnChecklistItemAnswer = function (checklistItemId, isEfetivo) { return __awaiter(void 0, void 0, void 0, function () {
        var coordenadas, formattedData, response_1, error_1, formattedData, response_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, getUserLocation()];
                case 1:
                    coordenadas = _a.sent();
                    formattedData = registerItemAnswer(checklistItemId, isEfetivo, coordenadas);
                    formattedData = __assign(__assign({}, formattedData), { id: Number(currentChecklist.preenchidoId) || 0 });
                    return [4 /*yield*/, axiosClient.post(POST_ANSWERED_CHECKLIST_INDIVIDUAL, formattedData)];
                case 2:
                    response_1 = _a.sent();
                    setCurrentChecklist(function (oldChecklist) {
                        var updatedItems = oldChecklist.itens.map(function (item) {
                            if (item.id === checklistItemId) {
                                return __assign(__assign({}, item), { resposta: response_1.data.data.respostas.find(function (i) { return i.checklistUnidadeItemId === item.id; }) });
                            }
                            return item;
                        });
                        return __assign(__assign({}, oldChecklist), { preenchidoId: response_1.data.data.id, itens: updatedItems });
                    });
                    if (currentCallback) {
                        currentCallback();
                        setCurrentCallback(undefined);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setCurrentCallback(undefined);
                    formattedData = registerItemAnswer(checklistItemId, isEfetivo);
                    formattedData = __assign(__assign({}, formattedData), { id: Number(currentChecklist.preenchidoId) || 0 });
                    return [4 /*yield*/, axiosClient.post(POST_ANSWERED_CHECKLIST_INDIVIDUAL, formattedData)];
                case 4:
                    response_2 = _a.sent();
                    setCurrentChecklist(function (oldChecklist) {
                        var updatedItems = oldChecklist.itens.map(function (item) {
                            if (item.id === checklistItemId) {
                                return __assign(__assign({}, item), { resposta: response_2.data.data.respostas.find(function (i) { return i.checklistUnidadeItemId === item.id; }) });
                            }
                            return item;
                        });
                        return __assign(__assign({}, oldChecklist), { preenchidoId: response_2.data.data.id, itens: updatedItems });
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getItemState = function (checklistItemId) {
        var itemRespondido = respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.find(function (resposta) {
            return resposta.id == checklistItemId;
        });
        if ((itemRespondido === null || itemRespondido === void 0 ? void 0 : itemRespondido.efetivo) !== undefined) {
            return itemRespondido.efetivo ? ITEM_STATE.RIGHT : ITEM_STATE.WRONG;
        }
        else {
            return ITEM_STATE.NOT_ANSWERED;
        }
    };
    // const getRespostaGestorId = (quizId: number) => {
    //   const respostaGestor = postOrPutChecklistResponse.data.respostasGestor.find(
    //     (resposta: IRespostaGestor) => resposta.quizId === quizId,
    //   );
    //   return respostaGestor.id;
    // };
    var handleOnActionPlanClick = function (checklistUnidadeItemId) {
        setCurrentRespostaItemId(checklistUnidadeItemId);
        setIsModalOpened(true);
    };
    var handleObservacaoClick = function (checklistUnidadeRespostaId) {
        setCurrentRespostaItemId(checklistUnidadeRespostaId);
        setIsModalObservacaoOpened(true);
    };
    var handleOnEnviarFotoClick = function (checklistUnidadeRespostaId) {
        setCurrentRespostaItemId(checklistUnidadeRespostaId);
        setItemFotos(fotoPreview.find(function (preview) { return (preview === null || preview === void 0 ? void 0 : preview.respostaItemId) === checklistUnidadeRespostaId; }));
        setIsModalFotoOpened(true);
    };
    var handleOnFotoModalClose = function () {
        setIsModalFotoOpened(false);
        setCurrentRespostaItemId(undefined);
        setItemFotos(undefined);
    };
    var handleObservacaoModalClose = function () {
        setIsModalObservacaoOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnActionPlanModalClose = function () {
        setIsModalOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnCloseClick = function () {
        history.push(AppPath.RoutineChecklist);
    };
    var registerActionPlan = function () {
        setActionPlanSent(true);
        setActionPlansChecklistItemsIds(currentRespostaItemId);
    };
    var registerFotoEnviada = function () {
        setFotosEnviadasChecklistItemsIds(currentRespostaItemId);
        setPhotoSent(true);
    };
    var removerFotoEnviada = function () {
        setPhotoSent(false);
        setFotosEnviadasChecklistItemsIds(undefined);
        setFotoPreview(undefined);
    };
    var addFotoPreview = function (newItem) {
        var fotos = [];
        for (var i = 0; i < fotoPreview.length; i++) {
            var foto = fotoPreview[i];
            if (newItem.respostaItemId != foto.respostaItemId) {
                fotos.push(foto);
            }
        }
        fotos.push(newItem);
        setFotoPreview(fotos);
        setFotosEnviadasChecklistItemsIds((newItem === null || newItem === void 0 ? void 0 : newItem.fotoUrlList.length) > 0 ? newItem.respostaItemId : undefined);
        setPhotoSent((newItem === null || newItem === void 0 ? void 0 : newItem.fotoUrlList.length) > 0);
    };
    var getFotoPreview = function (id) {
        return fotoPreview.find(function (preview) { return (preview === null || preview === void 0 ? void 0 : preview.respostaItemId) === id; });
    };
    var registerObservacao = function () {
        setObservacaoItemsIds(currentRespostaItemId);
    };
    //const handleOpenModalApproval = (item: IUnitChecklistItem) => {
    var handleOpenModalApproval = function (item, isEfetivo, callback) {
        setCurrentChecklistItem(item);
        setIsApprovedModalOpened(true);
        setIsEfetivoSelectionAfterModal(isEfetivo);
        setCurrentCallback(callback);
    };
    var handleCloseModalApproval = function (approved) {
        setIsApprovedModalOpened(false);
        if (!approved) {
            setCurrentCallback(undefined);
        }
    };
    // console.log('currentChecklist1', currentChecklist);
    return (React.createElement(React.Fragment, null,
        React.createElement(ChecklistApprovalModal, { isOpened: isApprovedModalOpened, handleOnClose: handleCloseModalApproval, onChecklistItemAnswer: handleOnChecklistItemAnswer, checklistItemId: currentChecklistItem === null || currentChecklistItem === void 0 ? void 0 : currentChecklistItem.id, isEfetivo: isEfetivoSelectionAfterModal }),
        React.createElement(ActionPlanModalRefactor, { isOpened: isModalOpened, onSend: registerActionPlan, handleOnClose: handleOnActionPlanModalClose, currentPreenchidoId: currentRespostaItemId, isChecklistUnidade: true, currentRespostaGestorId: userData.usuarioId, item: currentChecklist, planoAcao: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.planoAcao }),
        React.createElement(ModalFoto, { isOpened: isModalFotoOpened, onSend: registerFotoEnviada, handleOnClose: handleOnFotoModalClose, isChecklistUnidade: true, checklist: currentChecklist, currentRespostaItemId: currentRespostaItemId, setFotosPreview: addFotoPreview, itemFotos: itemFotos, isPermitidoImagemDaBiblioteca: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.isPermitidoImagemDaBiblioteca }),
        React.createElement(ModalObservacao, { isOpened: isModalObservacaoOpened, onSend: registerObservacao, handleOnClose: handleObservacaoModalClose, isChecklistUnidade: true, checklist: currentChecklist, currentRespostaItemId: currentRespostaItemId }),
        React.createElement(Span, null, "Itens marcados com * s\u00E3o obrigat\u00F3rios."),
        (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) && (React.createElement(ChecklistItemDetalhado, { checklistItem: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0], handleOnClick: handleOpenModalApproval, itemState: getItemState((_d = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _d === void 0 ? void 0 : _d.id), handleOnActionPlanClick: function () { var _a; return handleOnActionPlanClick((_a = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _a === void 0 ? void 0 : _a.id); }, handleOnEnviarFotoClick: function () {
                var _a, _b, _c, _d;
                return handleOnEnviarFotoClick(((_b = (_a = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _a === void 0 ? void 0 : _a.resposta) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _c === void 0 ? void 0 : _c.respostas[0]) === null || _d === void 0 ? void 0 : _d.id));
            }, handleOnObservacaoClick: function () {
                var _a, _b, _c, _d;
                return handleObservacaoClick(((_b = (_a = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _a === void 0 ? void 0 : _a.resposta) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _c === void 0 ? void 0 : _c.respostas[0]) === null || _d === void 0 ? void 0 : _d.id));
            }, isDisabled: !!postChecklistResponse, actionPlanSent: actionPlanSent, isActionPlanRegistered: !!actionPlansChecklistItemsIds, isFotoEnviada: !!fotosEnviadasChecklistItemsIds, isObservacaoEnviada: !!observacaoItemsIds, fotoPreview: fotoPreview.find(function (preview) {
                var _a, _b, _c, _d;
                return (preview === null || preview === void 0 ? void 0 : preview.respostaItemId) === ((_b = (_a = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _a === void 0 ? void 0 : _a.resposta) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[0]) === null || _c === void 0 ? void 0 : _c.respostas[0]) === null || _d === void 0 ? void 0 : _d.id);
            }), removeFotoPreview: removerFotoEnviada, boxBackgroundColor: '#ECFDFD' })),
        postChecklistResponse ? (React.createElement(Button, { loading: isLoading, onClick: handleOnCloseClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Fechar")) : (React.createElement(Button, { loading: isLoading, disabled: isAnyObjectWithoutIsEfetivo || isAnyObjectWithRequiredPhotoSent, onClick: handleOnSubmitClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Enviar"))));
};
