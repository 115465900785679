import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Modal } from '@atomic/obj.modal';
import { ImageContainer, StyledImage, DeleteIcon } from './image-preview.styled';
import Loading from './loading';
var ImagePreview = function (_a) {
    var imageUrl = _a.imageUrl, onDelete = _a.onDelete;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), abrirModal = _c[0], setAbrirModal = _c[1];
    var fecharModalConformidadeHandler = function () {
        setAbrirModal(false);
    };
    var abrirModalConformidadeHandler = function () {
        setAbrirModal(true);
    };
    var handleImageLoad = function () {
        setLoading(false);
    };
    if (!imageUrl) {
        setLoading(false);
        return null;
    }
    return (React.createElement(ImageContainer, null,
        React.createElement(Modal, { small: false, opened: abrirModal, onClose: fecharModalConformidadeHandler, closeOnOutsideClick: true },
            React.createElement(Box, null,
                React.createElement(Typography, { id: "modal-modal-title", variant: "h6", component: "h2", style: { display: 'flex', justifyContent: 'center' } }, "Imagem enviada"),
                React.createElement("img", { src: imageUrl }),
                React.createElement(Button, { onClick: fecharModalConformidadeHandler }, "Fechar"))),
        loading && React.createElement(Loading, null),
        React.createElement(StyledImage, { src: imageUrl, alt: "Image", onLoad: handleImageLoad, style: { display: loading ? 'none' : 'block' }, onClick: abrirModalConformidadeHandler }),
        onDelete && React.createElement(React.Fragment, null, !loading && React.createElement(DeleteIcon, { onClick: onDelete }, "\u00D7"))));
};
export default ImagePreview;
