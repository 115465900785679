import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { AppPath } from '@app/routes';
import arrowLeftSrc from '@assets/icons/arrow-left.svg';
import { Col } from '@atomic/obj.grid';
import { HeaderLogoSmall, HeaderLogoWrapper } from '../atm.logo/logo.component';
import { LogoutButton } from '../atm.logout-button';
import { HeaderRow } from './header.styled';
export var Header = function (_a) {
    var title = _a.title, titleElement = _a.titleElement, companyLogoURL = _a.companyLogoURL, _b = _a.withBackButton, withBackButton = _b === void 0 ? true : _b, _c = _a.withLogoutButton, withLogoutButton = _c === void 0 ? true : _c, backButtonFn = _a.backButtonFn, backButtonColor = _a.backButtonColor, children = _a.children, _d = _a.toMap, toMap = _d === void 0 ? false : _d;
    var company = useStore('company')[0];
    var history = useHistory();
    var handleOnBackButtonClick = function () {
        if (toMap)
            return history.push({ pathname: AppPath.Map });
        history.goBack();
    };
    return (React.createElement(React.Fragment, null,
        companyLogoURL && (React.createElement(HeaderLogoWrapper, null,
            React.createElement("img", { src: companyLogoURL }))),
        React.createElement(HeaderRow, null,
            React.createElement(Col, { xs: 2 }, withBackButton && (React.createElement("div", { onClick: backButtonFn || handleOnBackButtonClick, style: { cursor: 'pointer', display: 'grid', rowGap: '2px', marginTop: '10px', justifyContent: 'start' } },
                React.createElement("img", { src: arrowLeftSrc, style: backButtonColor
                        ? { marginLeft: '0.3em', color: backButtonColor, width: '25px', height: '25px' }
                        : { marginLeft: '0.3em', width: '25px', height: '25px' } }),
                React.createElement("span", { style: { fontSize: '15px' } }, 'Voltar')))),
            React.createElement(Col, { xs: 8 },
                !companyLogoURL && React.createElement(HeaderLogoSmall, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, alt: "entLogo" }),
                titleElement && titleElement),
            React.createElement(Col, { xs: 2 }, withLogoutButton && React.createElement(LogoutButton, null))),
        children));
};
