var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleCard } from '@app/components/mol.card/module-card.component';
import { PendingQuantity } from './module-card-map.component.styled';
export var ModuleCardMap = function (props) {
    var history = useHistory();
    var handleOnJogarClick = function () {
        if (props.path) {
            history.push(props.path);
        }
        if (props.realPath) {
            window.open(props.realPath, '_blank');
        }
    };
    if (props.pendingQuantity < 0) {
        return null;
    }
    return (React.createElement(ModuleCard, __assign({}, props, { onClick: function () { return handleOnJogarClick(); } }),
        React.createElement(PendingQuantity, { style: { visibility: (props === null || props === void 0 ? void 0 : props.pendingQuantity) > 0 ? 'visible' : 'hidden' }, gridMode: props.gridMode }, "" + props.pendingQuantity)));
};
