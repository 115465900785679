var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var BacksideContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 3fr 0.3fr;\n\n  border-left: 12px solid ", ";\n  border-radius: 12px;\n  border-top: 1px solid #ccc;\n  border-right: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 6px;\n\n  :nth-child(n) {\n    margin-top: 8px;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 3fr 0.3fr;\n\n  border-left: 12px solid ", ";\n  border-radius: 12px;\n  border-top: 1px solid #ccc;\n  border-right: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 6px;\n\n  :nth-child(n) {\n    margin-top: 8px;\n  }\n"])), function (props) { return props.cardColor; });
export var BacksideContainerNew = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-template-columns: 3fr 0.3fr;\n  border: 1px solid ", ";\n  background-color: ", ";\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 12px;\n  padding-bottom: 0px;\n  cursor: pointer;\n"], ["\n  grid-template-columns: 3fr 0.3fr;\n  border: 1px solid ", ";\n  background-color: ", ";\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 12px;\n  padding-bottom: 0px;\n  cursor: pointer;\n"])), function (props) { return props.borderColor; }, function (props) { return props.backgroundColor; });
export var ChecklistCardContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-auto-rows: auto;\n  gap: 8px;\n"], ["\n  display: grid;\n  grid-auto-rows: auto;\n  gap: 8px;\n"])));
export var FlexColSimple = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-left: 4px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-left: 4px;\n"])));
export var FlexRowSimple = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var PrimaryCardText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n  margin-right: 5px;\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n  margin-right: 5px;\n"])));
export var SecondaryCardText = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: medium;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"], ["\n  display: inline-block;\n  font-weight: medium;\n  font-size: 0.9rem;\n  text-transform: uppercase;\n"])));
export var CardStatus = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  border-top-right-radius: 16px;\n  border-top-left-radius: 16px;\n  margin: 0 auto;\n  position: relative;\n  background-color: ", ";\n"], ["\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  border-top-right-radius: 16px;\n  border-top-left-radius: 16px;\n  margin: 0 auto;\n  position: relative;\n  background-color: ", ";\n"])), function (props) { return props.cardColor; });
export var CardStatusText = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 14px;\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  color: #ffffff;\n"], ["\n  display: inline-block;\n  font-size: 14px;\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  color: #ffffff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
