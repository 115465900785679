var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var NotificationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 60%;\n  display: flex;\n  right: ", ";\n  background-color: rgb(246, 241, 229);\n  padding: 10px 0 10px 20px;\n  border-radius: 20px 0 0 20px;\n  transition: right 0.3s ease-in-out;\n  z-index: 9999;\n"], ["\n  position: fixed;\n  top: 60%;\n  display: flex;\n  right: ", ";\n  background-color: rgb(246, 241, 229);\n  padding: 10px 0 10px 20px;\n  border-radius: 20px 0 0 20px;\n  transition: right 0.3s ease-in-out;\n  z-index: 9999;\n"])), function (_a) {
    var show = _a.show;
    return (show ? '0' : '-550px');
});
export var NotificationContainerFail = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 60%;\n  display: flex;\n  right: ", ";\n  background-color: #f4d9d9;\n  padding: 10px 10px 0 20px;\n  border-radius: 20px 0 0 20px;\n  transition: right 0.3s ease-in-out;\n  z-index: 9999;\n"], ["\n  position: fixed;\n  top: 60%;\n  display: flex;\n  right: ", ";\n  background-color: #f4d9d9;\n  padding: 10px 10px 0 20px;\n  border-radius: 20px 0 0 20px;\n  transition: right 0.3s ease-in-out;\n  z-index: 9999;\n"])), function (_a) {
    var show = _a.show;
    return (show ? '0' : '-550px');
});
export var MessageNotification = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 10px;\n  font-size: large;\n  font-weight: bolder;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 10px;\n  font-size: large;\n  font-weight: bolder;\n  align-items: flex-start;\n"])));
export var MessageNotificationFail = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 10px;\n  font-size: large;\n  font-weight: bolder;\n  align-items: flex-start;\n  padding-bottom: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 10px;\n  font-size: large;\n  font-weight: bolder;\n  align-items: flex-start;\n  padding-bottom: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
