var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Breakpoint, Spacing } from '@atomic/obj.constants';
var RowStyled = styled(FlexRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ", "\n"], ["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ",
    "\n"])), function (props) { return (props.mb ? Spacing.Medium : '0px'); }, function (props) { return (props.mt ? Spacing.Medium : '0px'); }, function (props) {
    if (props.noGutter) {
        return "overflow: hidden;";
    }
    else {
        return '';
    }
});
export var Grid = FlexGrid;
export var Col = FlexCol;
export var Row = RowStyled;
export var GridSettings = {
    flexboxgrid: {
        gridSize: 12,
        // Defaults
        gutterWidth: 1,
        outerMargin: 2,
        breakpoints: Breakpoint,
    },
};
export var rowMargin = GridSettings.flexboxgrid.outerMargin + GridSettings.flexboxgrid.gutterWidth / 2;
var templateObject_1;
