import React from 'react';
import { AddToHomeScreenPopup as AddToHomeScreenPopupAndroidChrome } from './android/android-chrome';
import { AddToHomeScreenPopup as AddToHomeScreenPopupAndroidSamsung } from './android/android-samsung';
import { AddToHomeScreenPopup as AddToHomeScreenPopupIoSChrome } from './ios/ios-chrome';
import { AddToHomeScreenPopup as AddToHomeScreenPopupIoSSafari } from './ios/ios-safari';
var AddToHomePopup = function (_a) {
    var browserOSName = _a.browserOSName, browserName = _a.browserName, onRemindMeLaterButton = _a.onRemindMeLaterButton, onIgnoreButton = _a.onIgnoreButton;
    var popup = null;
    var getAndroidPopup = function () {
        switch (browserName.toLocaleLowerCase()) {
            case 'chrome':
                return (React.createElement(AddToHomeScreenPopupAndroidChrome, { onRemindMeLaterButton: onRemindMeLaterButton, onIgnoreButton: onIgnoreButton }));
            case 'samsung internet for android':
                return (React.createElement(AddToHomeScreenPopupAndroidSamsung, { onRemindMeLaterButton: onRemindMeLaterButton, onIgnoreButton: onIgnoreButton }));
            default:
                return null;
        }
    };
    var getIoSPopup = function () {
        switch (browserName.toLocaleLowerCase()) {
            case 'chrome':
                return (React.createElement(AddToHomeScreenPopupIoSChrome, { onRemindMeLaterButton: onRemindMeLaterButton, onIgnoreButton: onIgnoreButton }));
            case 'safari':
                return (React.createElement(AddToHomeScreenPopupIoSSafari, { onRemindMeLaterButton: onRemindMeLaterButton, onIgnoreButton: onIgnoreButton }));
            default:
                return null;
        }
    };
    if (browserOSName.toLowerCase() === 'android') {
        popup = getAndroidPopup();
    }
    else if (browserOSName.toLowerCase() === 'ios') {
        popup = getIoSPopup();
    }
    return popup;
};
export default AddToHomePopup;
