var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { CheckboxCheckedStyled, CheckboxFieldBulletStyled, CheckboxFieldStyled, CheckboxFieldTextStyled, CheckboxUncheckedStyled, } from './checkbox-field.component.style';
var CheckboxField = /** @class */ (function (_super) {
    __extends(CheckboxField, _super);
    function CheckboxField(props) {
        var _a, _b;
        var _this = _super.call(this, props) || this;
        _this.handlePress = function () {
            var _a, _b, _c, _d, _e;
            (_b = (_a = _this.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a, _this.props.id);
            var checked = !_this.getCurrentValue();
            (_d = (_c = _this.props).onValueChange) === null || _d === void 0 ? void 0 : _d.call(_c, _this.props.id, checked);
            if (_this.isControlled()) {
                return;
            }
            (_e = _this.formFieldConsumer) === null || _e === void 0 ? void 0 : _e.onValueChange([_this.props.id], checked);
            _this.setState({ checked: checked });
        };
        _this.isControlled = function () {
            return _this.props.checked !== undefined;
        };
        _this.getCurrentValue = function () {
            var _a, _b, _c;
            if ((_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) {
                return ((_c = (_b = _this.formFieldConsumer.value) === null || _b === void 0 ? void 0 : _b.indexOf) === null || _c === void 0 ? void 0 : _c.call(_b, _this.props.id)) > -1;
            }
            return _this.state.checked;
        };
        _this.state = {
            checked: (_b = (_a = props.initialChecked) !== null && _a !== void 0 ? _a : props.checked) !== null && _b !== void 0 ? _b : false,
        };
        return _this;
    }
    CheckboxField.prototype.componentDidMount = function () {
        if (this.isControlled() && this.props.initialChecked !== undefined) {
            throw new Error('Use either the initialChecked prop, or the checked prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialChecked !== undefined) {
                throw new Error('Please, use value props in <Form.Field> instead of <CheckboxField> component.');
            }
        }
    };
    CheckboxField.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b, _c;
        if (prevProps.checked !== this.props.checked && this.props.checked !== this.state.checked) {
            this.setState({ checked: this.props.checked });
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.id, this.props.checked);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange([this.props.id], this.props.checked);
        }
    };
    CheckboxField.prototype.render = function () {
        var _this = this;
        return (React.createElement(FormFieldContext.Consumer, null, function (formFieldConsumer) {
            var _a, _b;
            _this.formFieldConsumer = formFieldConsumer;
            var name = (_b = (_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
            var checked = _this.getCurrentValue();
            return (React.createElement(CheckboxFieldStyled, { onClick: _this.handlePress, disabled: _this.props.disabled },
                React.createElement(CheckboxFieldBulletStyled, { name: name + '_' + _this.props.id, type: "checkbox", checked: checked, onChange: _this.handlePress, value: _this.props.id }),
                React.createElement(CheckboxCheckedStyled, { disabled: _this.props.disabled }),
                React.createElement(CheckboxUncheckedStyled, { disabled: _this.props.disabled }),
                React.createElement(CheckboxFieldTextStyled, { htmlFor: name + '_' + _this.props.id }, _this.props.children)));
        }));
    };
    return CheckboxField;
}(React.Component));
export { CheckboxField };
