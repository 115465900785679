var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from 'typedi';
import { LocalStorage } from '@app/core/storage';
import { TokenLocalStorageKey, UserLocalStorageKey } from '@app/model/login.model';
var AuthStorageService = /** @class */ (function () {
    function AuthStorageService() {
        var _this = this;
        this.observers = new Set();
        this.getUser = function () {
            return _this.user;
        };
        this.getToken = function () {
            return _this.token;
        };
        this.login = function (user, token) {
            _this.user = user;
            _this.token = token;
            _this.observers.forEach(function (observer) { return observer.hasLoggedIn(user, token); });
        };
        this.logout = function () {
            _this.user = null;
            _this.token = null;
            _this.observers.forEach(function (observer) { return observer.hasLoggedOut(); });
        };
    }
    AuthStorageService.prototype.addObserver = function (observer) {
        this.observers.add(observer);
    };
    AuthStorageService.prototype.removeObserver = function (observer) {
        this.observers.delete(observer);
    };
    __decorate([
        LocalStorage(UserLocalStorageKey)
    ], AuthStorageService.prototype, "user", void 0);
    __decorate([
        LocalStorage(TokenLocalStorageKey)
    ], AuthStorageService.prototype, "token", void 0);
    AuthStorageService = __decorate([
        Service()
    ], AuthStorageService);
    return AuthStorageService;
}());
export { AuthStorageService };
