var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { StyledContainer } from '@atomic';
import { Col, Row } from '@atomic/obj.grid';
import arrowLeftUrl from 'assets/img/arrow-left.png';
export var BackgroundStyled = styled.img.attrs(function () { return ({ src: arrowLeftUrl }); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var HeaderContainer = styled(StyledContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  padding-top: 20px;\n  min-height: 10vh;\n"], ["\n  position: relative;\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  padding-top: 20px;\n  min-height: 10vh;\n"])));
export var HeaderRow = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 1.2rem 1.8rem 0 1.8rem;\n  margin-left: 0;\n  margin-right: 0;\n  min-height: 3rem;\n\n  ", " {\n    padding: 0;\n  }\n\n  ", ":nth-child(2) {\n    text-align: center;\n  }\n\n  .header-title {\n    margin-top: -7px;\n    margin-bottom: 16px;\n  }\n\n  margin-bottom: 20px;\n"], ["\n  padding: 1.2rem 1.8rem 0 1.8rem;\n  margin-left: 0;\n  margin-right: 0;\n  min-height: 3rem;\n\n  ", " {\n    padding: 0;\n  }\n\n  ", ":nth-child(2) {\n    text-align: center;\n  }\n\n  .header-title {\n    margin-top: -7px;\n    margin-bottom: 16px;\n  }\n\n  margin-bottom: 20px;\n"])), Col, Col);
export var HeaderBackButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  position: absolute;\n  left: 15px;\n  bottom: 10px;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  display: flex;\n  position: absolute;\n  left: 15px;\n  bottom: 10px;\n  flex-direction: column;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
