var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
var shimmerAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-position: -200%;\n  }\n  100% {\n    background-position: 200%;\n  }\n"], ["\n  0% {\n    background-position: -200%;\n  }\n  100% {\n    background-position: 200%;\n  }\n"])));
var SkeletonCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 400px;\n  height: 150px;\n  margin: 16px;\n  border-radius: 8px;\n  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n  background-size: 200% 100%;\n  animation: ", " 1.5s infinite;\n"], ["\n  width: 400px;\n  height: 150px;\n  margin: 16px;\n  border-radius: 8px;\n  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n  background-size: 200% 100%;\n  animation: ", " 1.5s infinite;\n"])), shimmerAnimation);
var SkeletonRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-around;\n"], ["\n  display: flex;\n  justify-content: space-around;\n"])));
var SkeletonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var SkeletonLoadingPage = function () {
    return (React.createElement(SkeletonContainer, null,
        React.createElement(SkeletonRow, null,
            React.createElement(SkeletonCard, null)),
        React.createElement(SkeletonRow, null,
            React.createElement(SkeletonCard, null)),
        React.createElement(SkeletonRow, null,
            React.createElement(SkeletonCard, null)),
        React.createElement(SkeletonRow, null,
            React.createElement(SkeletonCard, null)),
        React.createElement(SkeletonRow, null,
            React.createElement(SkeletonCard, null))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
