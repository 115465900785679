import * as React from 'react';
import { useState } from 'react';
import { CustomRadioButton } from '@app/components/atm.custom-radio-button';
import { Textarea } from '@app/modules/map/emotion/emotion-today.style';
import { ColRadioButton } from '@app/modules/quiz/components/quiz/quiz.styled';
import { Col, Row } from '@atomic';
import { QuestionAlternative } from './question-body.style';
export var QuestionBodyComponent = function (props) {
    var _a;
    var _b = useState(0), currentAnswerId = _b[0], setCurrentAnswerId = _b[1];
    var _c = useState(false), isOpcaoOutrosEscolhida = _c[0], setIsOpcaoOutrosEscolhida = _c[1];
    var handleOnSelectAlternative = function (id) {
        setIsOpcaoOutrosEscolhida(false);
        setCurrentAnswerId(id);
        props.onSelectAlternative(id);
    };
    var onSelectOpcaoOutros = function () {
        setIsOpcaoOutrosEscolhida(true);
        setCurrentAnswerId(0);
        props.onSelectAlternative(0);
    };
    var onOpcaoOutrosTextValueChange = function (e) {
        props.onOpcaoOutrosTextValueChange(e.currentTarget.value);
    };
    return (React.createElement("div", null, (_a = props.alternatives) === null || _a === void 0 ? void 0 :
        _a.map(function (question) { return (React.createElement(Row, { key: question.id, style: { paddingLeft: '10px', paddingRight: '12px' }, onClick: function () {
                if (!props.disabled) {
                    handleOnSelectAlternative(question.id);
                }
            } },
            React.createElement(Col, { xs: 10, style: { paddingTop: '15px' } },
                React.createElement(QuestionAlternative, null, question.title)),
            React.createElement(ColRadioButton, { xs: 2 },
                React.createElement(CustomRadioButton, { variant: currentAnswerId === question.id ? 'checked' : 'default' })))); }),
        props.incluirOpcaoOutros && (React.createElement(React.Fragment, null,
            React.createElement(Row, { style: { paddingLeft: '10px', paddingRight: '12px' }, onClick: function () {
                    if (!props.disabled) {
                        onSelectOpcaoOutros();
                    }
                } },
                React.createElement(Col, { xs: 10, style: { paddingTop: '15px' } },
                    React.createElement(QuestionAlternative, null, "Outros")),
                React.createElement(ColRadioButton, { xs: 2 },
                    React.createElement(CustomRadioButton, { variant: isOpcaoOutrosEscolhida ? 'checked' : 'default' }))),
            isOpcaoOutrosEscolhida && (React.createElement(React.Fragment, null,
                React.createElement(Row, { style: { paddingLeft: '10px', paddingRight: '12px' } },
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Textarea, { placeholder: "Descreva...", onChange: onOpcaoOutrosTextValueChange, style: { width: '100%' } })))))))));
};
