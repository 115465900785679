var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var FlexRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: '100%';\n  column-gap: '8px';\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: '100%';\n  column-gap: '8px';\n"])));
export var FlexCol = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var SubmenuContentCardContainer = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  border: 1px solid #d9d9d9;\n  padding: 16px 24px;\n  background-color: white;\n\n  &:first-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    border-bottom: none;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n  }\n\n  &:not(:first-child):not(:last-child) {\n    border-bottom: none;\n  }\n\n  &:only-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n    border: 1px solid #d9d9d9 !important;\n  }\n\n  :hover {\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  border: 1px solid #d9d9d9;\n  padding: 16px 24px;\n  background-color: white;\n\n  &:first-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    border-bottom: none;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n  }\n\n  &:not(:first-child):not(:last-child) {\n    border-bottom: none;\n  }\n\n  &:only-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n    border: 1px solid #d9d9d9 !important;\n  }\n\n  :hover {\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"])));
export var CardBadge = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 12px;\n  background-color: ", ";\n  padding: 2px 6px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 12px;\n  background-color: ", ";\n  padding: 2px 6px;\n"])), function (props) { var _a; return (_a = props.color) !== null && _a !== void 0 ? _a : '#49443c'; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
