import React from 'react';
import gratitude from '@assets/icons/gratitude.png';
import { FontFamily, H4, StyledContainer } from '@atomic';
import { CenterCircle, Circle, ContainerControll, Line, Pts } from './phase-header.styled';
export var PhaseHeader = function (_a) {
    var nFase = _a.nFase, quizResult = _a.quizResult;
    return (React.createElement(StyledContainer, { withPadding: true, primaryColor: true, className: "use-inverse-colors" },
        React.createElement(ContainerControll, null,
            React.createElement("div", null,
                React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'center', marginBottom: 0, paddingBottom: '10px' } },
                    "+", quizResult === null || quizResult === void 0 ? void 0 :
                    quizResult.tarefaPontos,
                    React.createElement(Pts, null, "PTS")),
                React.createElement(CenterCircle, null,
                    React.createElement(Circle, null,
                        React.createElement("img", { src: gratitude })))),
            React.createElement("div", null,
                React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'center', marginBottom: 0, paddingBottom: '10px' } },
                    "Fase ",
                    nFase + 1,
                    " cumprida!"),
                React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'end', marginTop: '-10px', fontSize: '55px' } }, quizResult === null || quizResult === void 0 ? void 0 :
                    quizResult.faseAcertoPercentual,
                    "%"),
                React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'end', marginTop: '-70px', fontSize: '15px' } }, "de aproveitamento"))),
        React.createElement(Line, null),
        React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'center', marginBottom: 0 } }, "Ranking")));
};
