import * as React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-unassigned-import
import 'pure-react-carousel/dist/react-carousel.es.css';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { VideoPlayer } from '@app/components/atm.video-player';
import { Header } from '@app/components/mol.header';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { Button, Separator, StyledContainer } from '@atomic';
import { VideoPlayerWrapper } from './tutorial.styles';
export var TutorialPage = function (props) {
    var _a, _b;
    var history = useHistory();
    var withBackButton = !!((_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.withBackButton);
    var userData = useStore(useUserStore).userData;
    var _c = React.useState(''), urlVideo = _c[0], setUrlVideo = _c[1];
    var saveIsTutorialReadedLSToken = function () {
        localStorage.setItem('is-tutorial-readed', "is-tutorial-readed-" + userData.usuarioId);
    };
    var handleOnConfirmClick = function () {
        if (withBackButton) {
            history.goBack();
        }
        else {
            saveIsTutorialReadedLSToken();
            history.push(AppPath.ContagemRegressiva);
        }
    };
    var fetchConfigs = function () {
        axiosClient.get('configuracao/key/UrlVideoTutorial').then(function (response) {
            setUrlVideo(response.data.data.value);
        });
    };
    React.useEffect(function () {
        fetchConfigs();
    }, []);
    var Footer = function () { return (React.createElement(StyledContainer, { justifyEnd: true, alignCenter: true },
        React.createElement(Button, { expanded: true, onClick: handleOnConfirmClick }, "ENTENDI"))); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { withBackButton: withBackButton }),
        React.createElement(StyledContainer, { withPadding: true, noPaddingTop: true },
            React.createElement("h2", null, "Aprendendo a usar o SuperApp"),
            urlVideo && (React.createElement(VideoPlayerWrapper, null,
                React.createElement(VideoPlayer, { videoUrl: urlVideo }))),
            React.createElement(DottedSeparator, null),
            React.createElement(Separator, null),
            React.createElement(Footer, null))));
};
