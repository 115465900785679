var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  width: 100%;\n  overflow-y: auto;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  width: 100%;\n  overflow-y: auto;\n"])));
export var TrackQuestions = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  overflow: inherit;\n  padding-top: 20px;\n  width: 100%;\n  align-items: stretch;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  overflow: inherit;\n  padding-top: 20px;\n  width: 100%;\n  align-items: stretch;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n"])));
export var LogoBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 80%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin: 0 auto;\n\n  img {\n    margin: 1rem 0;\n  }\n"], ["\n  width: 80%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin: 0 auto;\n\n  img {\n    margin: 1rem 0;\n  }\n"])));
export var Line = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 4rem;\n  border-bottom: 3px dotted #666666;\n  opacity: 0.5;\n  width: 100%;\n  align-self: center;\n"], ["\n  margin-top: 4rem;\n  border-bottom: 3px dotted #666666;\n  opacity: 0.5;\n  width: 100%;\n  align-self: center;\n"])));
export var TrackTasksWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-wrap: wrap;\n  margin: 0 auto;\n  justify-content: space-evenly;\n  padding: 1rem 0 2rem 0;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-wrap: wrap;\n  margin: 0 auto;\n  justify-content: space-evenly;\n  padding: 1rem 0 2rem 0;\n"])));
export var LogoFooterBox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 20%;\n  height: 20%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin: 0 auto;\n"], ["\n  width: 20%;\n  height: 20%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin: 0 auto;\n"])));
export var TrackWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n"], ["\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
