var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { formatToPtBrDateTime } from '@app/utils/shared-functions.utils';
import { ActivityIndicator, FlashMessageEnum, StyledContainer } from '@atomic';
import { ChecklistDetailsItem } from '../checklist/components/checklist-item/checklist-details-item.component';
export var ChecklistUnidadeDetailsPage = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var id = useParams().id;
    var showFlashMessage = useFlashMessage()[0];
    var GET_CHECK_PREENCHIDO_URL = function () { return "checklistunidade/preenchido/" + id; };
    var _h = useQuery('getChecklistUnidadePreenchidoById', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_CHECK_PREENCHIDO_URL())];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), checklistResponse = _h.data, isLoading = _h.isLoading;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors" },
            React.createElement(Header, { withBackButton: true, title: "Detalhes" },
                React.createElement(StyledContainer, { withPadding: true, className: "use-inverse-colors" },
                    React.createElement("div", null,
                        "Checklist: ",
                        React.createElement("b", null, (_b = (_a = checklistResponse === null || checklistResponse === void 0 ? void 0 : checklistResponse.data) === null || _a === void 0 ? void 0 : _a.checklistUnidade) === null || _b === void 0 ? void 0 : _b.titulo),
                        React.createElement("br", null),
                        "Unidade: ",
                        React.createElement("b", null, (_d = (_c = checklistResponse === null || checklistResponse === void 0 ? void 0 : checklistResponse.data) === null || _c === void 0 ? void 0 : _c.unidadeEmpresa) === null || _d === void 0 ? void 0 : _d.nome),
                        React.createElement("br", null),
                        "Realizado em: ",
                        React.createElement("b", null, formatToPtBrDateTime((_e = checklistResponse === null || checklistResponse === void 0 ? void 0 : checklistResponse.data) === null || _e === void 0 ? void 0 : _e.dataEnvioChecklist)))))),
        React.createElement(StyledContainer, { withPadding: true },
            isLoading && React.createElement(ActivityIndicator, { type: "spinner" }), (_g = (_f = checklistResponse === null || checklistResponse === void 0 ? void 0 : checklistResponse.data) === null || _f === void 0 ? void 0 : _f.respostas) === null || _g === void 0 ? void 0 :
            _g.map(function (resposta, idx) { return (React.createElement(ChecklistDetailsItem, { key: idx, urlFotoResposta: resposta.urlFotoResposta, pergunta: resposta.checklistUnidadeItem.titulo, isEfetivo: resposta.isEfetivo, urlFotoRespostaList: resposta.urlFotoRespostaList, respostaItem: resposta })); }))));
};
