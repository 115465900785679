import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-unassigned-import
import 'assets/fonts/fonts.css';
// eslint-disable-next-line import/no-unassigned-import
import 'assets/global.css';
import { configure } from '@app/configure-app';
import App from './App';
configure();
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)), document.getElementById('root'));
