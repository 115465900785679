import * as React from 'react';
import { GrVideo } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import { CoursePhaseItem } from '@app/modules/course-library/components/course-phase-item';
import { CircleIconBox } from '@app/modules/map/course-list/course-item.component.style';
import { DottedLine } from '@app/modules/map/course-list/course-list.component.style';
import { AppPath } from '@app/routes';
import { Button } from '@atomic';
import { VideoModal } from '@atomic/obj.video-modal';
import { PdfDownloadButton } from '../course-item';
import { ContainerTask, IconsBoxTask, TitleTask } from './course-phase-list.component.style';
export var CoursePhaseList = function (props) {
    var history = useHistory();
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(null), selectedVideo = _b[0], setSelectedVideo = _b[1];
    var handleClick = function (task) {
        history.push({ pathname: "" + AppPath.Learning, state: { quiz: task, hideBackToQuizButton: true } });
    };
    var closeModal = function () {
        setSelectedVideo(null);
        setShowModal(false);
    };
    var handleVideoClick = function (videoURL) {
        setSelectedVideo(videoURL);
        setShowModal(true);
    };
    return (React.createElement(React.Fragment, null,
        showModal && React.createElement(VideoModal, { videoURL: selectedVideo, opened: showModal, onClose: closeModal }),
        props.course.fases.map(function (phase, idx_f) { return (React.createElement(React.Fragment, { key: phase.id },
            React.createElement(CoursePhaseItem, { phase: phase, index: idx_f }),
            React.createElement("div", null, phase.tarefas.map(function (task, idx_t) {
                var _a, _b, _c;
                return (React.createElement(ContainerTask, { key: idx_t },
                    React.createElement("div", { style: { display: 'flex', marginLeft: '20px' }, onClick: function () { return handleClick(task); } },
                        React.createElement(CircleIconBox, { style: { border: 'none' } },
                            React.createElement("span", null,
                                "T.",
                                idx_t + 1)),
                        React.createElement(TitleTask, null, task.nome)),
                    React.createElement(IconsBoxTask, null,
                        ((_a = task === null || task === void 0 ? void 0 : task.arquivo) === null || _a === void 0 ? void 0 : _a.url) && React.createElement(PdfDownloadButton, { pdfUrl: (_b = task === null || task === void 0 ? void 0 : task.arquivo) === null || _b === void 0 ? void 0 : _b.url, padding: true }),
                        ((_c = task === null || task === void 0 ? void 0 : task.video) === null || _c === void 0 ? void 0 : _c.url) && (React.createElement(React.Fragment, null,
                            React.createElement(Button, { expanded: true, variant: "link", padding: "none", onClick: function () { var _a; return handleVideoClick((_a = task === null || task === void 0 ? void 0 : task.video) === null || _a === void 0 ? void 0 : _a.url); } },
                                React.createElement(GrVideo, null)))))));
            })),
            React.createElement(DottedLine, null))); })));
};
