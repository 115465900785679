var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H4 } from '@atomic';
export var Checklist = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Title = styled(H4)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Span = styled(H4)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 0px;\n  font-size: 15px;\n  margin-bottom: 15px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  margin-top: 0px;\n  font-size: 15px;\n  margin-bottom: 15px;\n  font-weight: bold;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3;
