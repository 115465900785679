import * as React from 'react';
import { FontFamily } from '@atomic';
import { Points } from './points.component';
import { MoneyImage } from './quiz-result.styled';
export var QuizResult = function (_a) {
    var score = _a.score;
    return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', fontFamily: FontFamily.Secondary } },
        React.createElement(MoneyImage, { className: "invert-to-white" }),
        React.createElement(Points, { points: score })));
};
