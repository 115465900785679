/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useStore as useStoreRCH } from 'react-context-hook';
import { BsFillEmojiFrownFill, BsFillEmojiLaughingFill, BsFillEmojiNeutralFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { Button, FlashMessageEnum } from '@atomic';
import { ModalThermometer } from '@atomic/obj.modal-thermometer';
import coins from '../../../../assets/img/coinv2.png';
import { EmotionButtons, EmotionalInfo, FieldVent, ModalContent, TextareaFeedback, FeedbackSession, } from './emotion-today.style';
export var EmotionToday = function () {
    // const MAX_SECONDS = 0;
    // const MAX_TIME = MAX_SECONDS * 1000;
    var BASE_TERMOMETRO_EMOC_ENDPOINT = '/termometro-emocional';
    var location = useLocation();
    var showFlashMessage = useFlashMessage()[0];
    var _a = React.useState(''), myEmotion = _a[0], setMyEmotion = _a[1];
    var _b = React.useState(''), selectedEmotion = _b[0], setSelectedEmotion = _b[1];
    var _c = React.useState(''), myEmotionText = _c[0], setMyEmotionText = _c[1];
    var userData = useStore(useUserStore).userData;
    var _d = useStoreRCH('afterReplyTermometroEmocionalFunction'), afterReplyTermometroEmocionalFunction = _d[0], setAfterReplyTermometroEmocionalFunction = _d[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = useStoreRCH('isUserAlreadyRepliedTermometroEmocionalToday'), _ = _e[0], setIsUserAlreadyRepliedTermometroEmocionalToday = _e[1];
    var registerEmotionTodayReplyOnLS = function () {
        var currentDate = format(new Date(), 'uuuu-M-dd');
        localStorage.setItem('ent-termometro-emocional', "term-emoc-" + userData.usuarioId + "-" + currentDate);
    };
    var hasEmotionTodayLSCookie = function () {
        var currentDate = format(new Date(), 'uuuu-M-dd');
        var answeredToken = localStorage.getItem('ent-termometro-emocional');
        return answeredToken === "term-emoc-" + userData.usuarioId + "-" + currentDate;
    };
    useEffect(function () {
        if (hasEmotionTodayLSCookie()) {
            updateAlreadyRepliedAppStatus();
        }
        else {
            axiosClient.get(BASE_TERMOMETRO_EMOC_ENDPOINT).then(function (_a) {
                var isUserAnsweredResponse = _a.data;
                if (isUserAnsweredResponse.data) {
                    updateAlreadyRepliedAppStatus();
                }
                else {
                    setIsUserAlreadyRepliedTermometroEmocionalToday(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, userData.usuarioId, !!afterReplyTermometroEmocionalFunction]);
    var sendRequest = function (value) {
        if (value === void 0) { value = undefined; }
        registerTermometro();
        var emotion = value !== undefined ? value : myEmotion;
        axiosClient
            .post(BASE_TERMOMETRO_EMOC_ENDPOINT, {
            emotion: emotion,
            motivo: myEmotionText,
        })
            .catch(function (error) {
            var _a;
            showFlashMessage(FlashMessageEnum.alert, ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data.errors) || 'error');
        });
    };
    var registerTermometro = function () {
        registerEmotionTodayReplyOnLS();
        setIsUserAlreadyRepliedTermometroEmocionalToday(true);
        afterReplyTermometroEmocionalFunction();
        setAfterReplyTermometroEmocionalFunction(undefined);
    };
    var updateAlreadyRepliedAppStatus = function () {
        setIsUserAlreadyRepliedTermometroEmocionalToday(true);
        setAfterReplyTermometroEmocionalFunction(undefined);
        if (!hasEmotionTodayLSCookie()) {
            registerEmotionTodayReplyOnLS();
        }
    };
    var handleEmotion = function (e) {
        if (e.target.checked) {
            setSelectedEmotion(e.target.value);
        }
        setMyEmotion(e.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalThermometer, { opened: !!afterReplyTermometroEmocionalFunction, closeOnOutsideClick: false, small: true, onClose: registerTermometro },
            React.createElement(ModalContent, null,
                React.createElement(FieldVent, null,
                    React.createElement("h2", null, "TERM\u00D4METRO EMOCIONAL"),
                    React.createElement(EmotionalInfo, null,
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "coin" },
                                React.createElement("span", { style: { fontFamily: 'UniNeueRegular' } }, "Ganhe +30"),
                                React.createElement("span", { style: { marginBottom: '8px' } },
                                    React.createElement("img", { src: coins, alt: "moeda", style: { width: '25px', height: '25px' } }))))),
                    React.createElement("h3", null, "Como voc\u00EA est\u00E1 hoje?"),
                    React.createElement(EmotionButtons, { disabled: true },
                        React.createElement("label", { className: "sad " + (selectedEmotion === 'Triste' ? 'selectedButton' : '') },
                            React.createElement("input", { type: "radio", name: "emotion", value: 'Triste', onChange: handleEmotion }),
                            React.createElement(BsFillEmojiFrownFill, { color: "red", size: 80 })),
                        React.createElement("label", { className: "indif " + (selectedEmotion === 'Indiferente' ? 'selectedButton' : '') },
                            React.createElement("input", { type: "radio", name: "emotion", value: 'Indiferente', onChange: handleEmotion }),
                            React.createElement(BsFillEmojiNeutralFill, { color: "orange", size: 80 })),
                        React.createElement("label", { className: "happy " + (selectedEmotion === 'Feliz' ? 'selectedButton' : '') },
                            React.createElement("input", { type: "radio", name: "emotion", value: 'Feliz', onChange: handleEmotion }),
                            React.createElement(BsFillEmojiLaughingFill, { color: "green", size: 80 }))),
                    React.createElement(FeedbackSession, null,
                        React.createElement("div", null,
                            React.createElement(TextareaFeedback, { placeholder: "Gostaria de compartilhar algo conosco?", onChange: function (e) { return setMyEmotionText(e.currentTarget.value); } })),
                        React.createElement("div", null,
                            React.createElement(Button, { disabled: !selectedEmotion, onClick: function () { return sendRequest(); }, style: { width: '-webkit-fill-available', borderRadius: '15px', textAlign: 'center' } }, "ENVIAR"))))))));
};
