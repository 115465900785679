/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Button, StyledContainer } from '@atomic';
// import { FooterProps } from '../../models';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
// eslint-disable-next-line @typescript-eslint/no-var-requires
var wrongAnswerSound = require('@assets/sounds/wrong-answer-sound.mp3');
export var Footer = function (_a) {
    var btnText = _a.btnText, btnBgColor = _a.btnBgColor, bgColor = _a.bgColor, handleOnBtnClick = _a.handleOnBtnClick, isLoading = _a.isLoading, isBtnDisabled = _a.isBtnDisabled, children = _a.children;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { withPadding: true, bgColor: bgColor },
            children,
            React.createElement(Button, { expanded: true, onClick: handleOnBtnClick, loading: isLoading, disabled: isBtnDisabled, style: { backgroundColor: '#008425', borderColor: '#008425', opacity: isBtnDisabled ? '0.5' : 'inherit' } }, btnText === null || btnText === void 0 ? void 0 : btnText.toUpperCase()))));
};
