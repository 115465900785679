var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic';
export var GameInfoWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n  justify-content: ", ";\n  align-items: ", ";\n  padding-top: 10px;\n  gap: ", ";\n\n  img,\n  svg {\n    width: 22px;\n    height: 22px;\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n  justify-content: ", ";\n  align-items: ", ";\n  padding-top: 10px;\n  gap: ", ";\n\n  img,\n  svg {\n    width: 22px;\n    height: 22px;\n  }\n"])), function (props) { return (props.open ? 'column' : 'row'); }, function (props) { return (props.open ? 'flex-start' : 'flex-end'); }, function (props) { return (props.open ? 'flex-start' : 'center'); }, function (props) { return (props.open ? 'none' : '16px'); });
export var GameInfoItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 7px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 2px 5px;\n  margin-bottom: 2px;\n  margin-right: 5px;\n  gap: 8px;\n\n  div {\n    display: flex;\n    align-items: center;\n  }\n\n  span {\n    font-weight: 300;\n    font-family: ", ";\n    margin: 0;\n  }\n\n  span.game-item-desc {\n    padding-left: 3px;\n    font-size: small;\n    color: ", ";\n  }\n"], ["\n  border-radius: 7px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 2px 5px;\n  margin-bottom: 2px;\n  margin-right: 5px;\n  gap: 8px;\n\n  div {\n    display: flex;\n    align-items: center;\n  }\n\n  span {\n    font-weight: 300;\n    font-family: ", ";\n    margin: 0;\n  }\n\n  span.game-item-desc {\n    padding-left: 3px;\n    font-size: small;\n    color: ", ";\n  }\n"])), FontFamily.Secondary, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2;
