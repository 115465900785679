var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import { BsCamera, BsCheckCircle, BsJournalText, BsPlus, BsQuestionCircle, BsXCircle } from 'react-icons/all';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import ImagePreview from '@app/components/image-preview/image-preview';
import { axiosClient } from '@app/configure-app';
import { ModalAumentarFoto } from '@app/modules/checklist/components/modal-aumentar-foto';
import { ModalConformidade } from '@app/modules/checklist/components/modal-conformidade';
import { Button, FaIcon, Row } from '@atomic';
import { ITEM_STATE } from '../../checklist/checklist.component';
import { ActionPlan, ActionPlanSubmittedInfo, ActionsWrapper, EnviarFoto, EnviarFotoSubmittedInfo, EnviarObservacao, EnviarObservacaoSubmittedInfo, ItemButtonsWrapper, Question, } from '../checklist-item.styled';
export var ChecklistItemDetalhado = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var checklistItem = _a.checklistItem, isDisabled = _a.isDisabled, handleOnClick = _a.handleOnClick, itemState = _a.itemState, handleOnActionPlanClick = _a.handleOnActionPlanClick, handleOnEnviarFotoClick = _a.handleOnEnviarFotoClick, handleOnObservacaoClick = _a.handleOnObservacaoClick, isObservacaoEnviada = _a.isObservacaoEnviada, isActionPlanRegistered = _a.isActionPlanRegistered, isFotoEnviada = _a.isFotoEnviada, _x = _a.actionPlanSent, actionPlanSent = _x === void 0 ? false : _x, fotoPreview = _a.fotoPreview, removeFotoPreview = _a.removeFotoPreview, boxBackgroundColor = _a.boxBackgroundColor;
    var _y = useState(false), modalConformidade = _y[0], setModalConformidade = _y[1];
    var _z = useState(false), aumentarFoto = _z[0], setAumentarFoto = _z[1];
    var _0 = useState((checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== null && (checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== undefined), showForms = _0[0], setShowForms = _0[1];
    var _1 = useState([]), itemFotoUrls = _1[0], setItemFotoUrls = _1[1];
    var _2 = useState(undefined), currentUrl = _2[0], setCurrentUrl = _2[1];
    var addOrReplaceRespostaGestor = function (isEfetivo) {
        handleOnClick(checklistItem, isEfetivo, function () { return openForms; });
    };
    var openForms = function () {
        setShowForms(true);
    };
    var onClickConformidade = function () {
        setModalConformidade(true);
    };
    var onCloseModalConformidade = function () {
        setModalConformidade(false);
    };
    var onClickAumentarFoto = function (url) {
        setCurrentUrl(url);
        setAumentarFoto(true);
    };
    var onCloseAumentarFoto = function () {
        setCurrentUrl(undefined);
        setAumentarFoto(false);
    };
    var removerFoto = function () {
        //no-op
        //mutate();
    };
    var _3 = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post("checklistunidade/item/resposta/" + checklistItem.respostas[0].id + "/upload-foto", { ArquivoFotoBase64: '' })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            if (data.succeeded) {
                removeFotoPreview();
            }
        },
        onError: function (data) {
            console.log('erro', data);
        },
    }), mutate = _3.mutate, isLoading = _3.isLoading;
    useEffect(function () {
        var _a, _b;
        var fotoUrls = ((_b = (_a = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _a === void 0 ? void 0 : _a.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList) || [];
        setItemFotoUrls(fotoUrls);
    }, [checklistItem]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "isRequired", style: {
                borderTop: '1px solid rgba(73, 68, 60, 0.7)',
                borderBottom: '1px solid rgba(73, 68, 60, 0.7)',
                background: boxBackgroundColor !== null && boxBackgroundColor !== void 0 ? boxBackgroundColor : 'none',
                marginBottom: '5px',
            } },
            React.createElement(Question, null,
                checklistItem.titulo,
                React.createElement("button", { onClick: onClickConformidade, style: {
                        background: 'none',
                        border: 'none',
                        paddingLeft: '5px',
                    } },
                    React.createElement(BsQuestionCircle, { fontSize: 20 }))),
            React.createElement(Box, null, itemFotoUrls === null || itemFotoUrls === void 0 ? void 0 : itemFotoUrls.map(function (url, idx) { return (React.createElement("img", { key: idx, onClick: function () { return onClickAumentarFoto(url); }, src: url, alt: "", style: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '10px',
                    maxHeight: '300px',
                } })); })),
            React.createElement(Box, null,
                React.createElement(Row, { style: { marginLeft: '0px' } },
                    "Resposta anterior:",
                    React.createElement("div", { style: { marginLeft: '2px', marginTop: '2px' } }, ((_c = (_b = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _b === void 0 ? void 0 : _b.respostas[0]) === null || _c === void 0 ? void 0 : _c.isEfetivo) ? (React.createElement(FaCheckCircle, { color: "green" })) : (React.createElement(FaTimesCircle, { color: "red" }))))),
            React.createElement(Box, null,
                "Quando:", " " + moment((_g = (_f = (_e = (_d = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _d === void 0 ? void 0 : _d.respostas[0]) === null || _e === void 0 ? void 0 : _e.planoAcaoChecklistUnidade) === null || _f === void 0 ? void 0 : _f.dataCadastro) !== null && _g !== void 0 ? _g : '').format('DD/MM/YYYY H:mm')),
            React.createElement(Box, null,
                "Data limite:", " " + moment((_l = (_k = (_j = (_h = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _h === void 0 ? void 0 : _h.respostas[0]) === null || _j === void 0 ? void 0 : _j.planoAcaoChecklistUnidade) === null || _k === void 0 ? void 0 : _k.dataLimiteResolucao) !== null && _l !== void 0 ? _l : '').format('DD/MM/YYYY')),
            React.createElement(Box, null,
                "Observa\u00E7\u00E3o:", " " + ((_p = (_o = (_m = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _m === void 0 ? void 0 : _m.respostas[0]) === null || _o === void 0 ? void 0 : _o.observacao) !== null && _p !== void 0 ? _p : '-')),
            React.createElement(Box, null,
                "Plano de a\u00E7\u00E3o enviado:",
                ' ', " " + ((_t = (_s = (_r = (_q = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.itemOrigem) === null || _q === void 0 ? void 0 : _q.respostas[0]) === null || _r === void 0 ? void 0 : _r.planoAcaoChecklistUnidade) === null || _s === void 0 ? void 0 : _s.descricaoResolucao) !== null && _t !== void 0 ? _t : '-')),
            React.createElement(ItemButtonsWrapper, null,
                React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                        backgroundColor: itemState == ITEM_STATE.WRONG ? '#C70000' : '#ffffff',
                        border: '2px solid #C70000',
                        borderRadius: '8px',
                        width: '100%',
                        minHeight: '37px',
                        textAlign: 'center',
                    }, onClick: function () { return addOrReplaceRespostaGestor(false); } },
                    React.createElement(BsXCircle, { fontSize: 24, style: {
                            color: itemState == ITEM_STATE.WRONG ? '#ffffff' : '#C70000',
                        } })),
                React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                        backgroundColor: itemState == ITEM_STATE.RIGHT ? '#008425' : '#ffffff',
                        width: '100%',
                        border: '2px solid #008425',
                        borderRadius: '8px',
                        minHeight: '37px',
                        textAlign: 'center',
                        color: '#008425',
                    }, onClick: function () { return addOrReplaceRespostaGestor(true); } },
                    React.createElement(BsCheckCircle, { fontSize: 24, style: {
                            color: itemState == ITEM_STATE.RIGHT ? '#ffffff' : '#008425',
                        } }))),
            showForms && (React.createElement(React.Fragment, null,
                React.createElement(ActionsWrapper, null,
                    React.createElement(EnviarFoto, { onClick: handleOnEnviarFotoClick },
                        React.createElement(EnviarFotoSubmittedInfo, null,
                            isFotoEnviada || ((_v = (_u = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _u === void 0 ? void 0 : _u.urlFotoRespostaList) === null || _v === void 0 ? void 0 : _v.length) > 0 ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsCamera, { style: { fontSize: '20px', color: '#49443C' } })),
                            React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } },
                                "Imagem ",
                                checklistItem.fotoObrigatoria ? '*' : ''))),
                    React.createElement(EnviarObservacao, { onClick: handleOnObservacaoClick },
                        React.createElement(EnviarObservacaoSubmittedInfo, null,
                            isObservacaoEnviada || ((_w = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _w === void 0 ? void 0 : _w.observacao) ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsJournalText, { style: { fontSize: '20px', color: '#49443C' } })),
                            React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } }, "Observa\u00E7\u00E3o")))),
                React.createElement(ActionsWrapper, null, itemState == ITEM_STATE.WRONG && (React.createElement(ActionPlan, { onClick: handleOnActionPlanClick },
                    React.createElement(ActionPlanSubmittedInfo, { style: {
                            borderColor: actionPlanSent ? '#008425' : '#49443C',
                        } },
                        actionPlanSent ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsPlus, { style: { fontSize: '25px', color: '#49443C' } })),
                        React.createElement("span", { style: {
                                color: actionPlanSent ? '#008425' : '#49443C',
                                fontWeight: 'bold',
                            } }, "Plano de A\u00E7\u00E3o"))))))), fotoPreview === null || fotoPreview === void 0 ? void 0 :
            fotoPreview.fotoUrlList.map(function (url, idx) { return (React.createElement(ImagePreview, { key: idx, imageUrl: url })); })),
        React.createElement(ModalConformidade, { open: modalConformidade, onClose: onCloseModalConformidade, conformidade: checklistItem.conformidade, imagemConformidade: checklistItem.imagemConformidade }),
        React.createElement(ModalAumentarFoto, { open: aumentarFoto, onClose: onCloseAumentarFoto, imagemUrl: currentUrl })));
};
