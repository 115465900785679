var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var StatusBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: auto;\n"], ["\n  margin: auto;\n"])));
export var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 0;\n"], ["\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 0;\n"])));
export var TaskName = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 20px;\n  text-align: center;\n  margin: 0;\n"], ["\n  font-size: 20px;\n  text-align: center;\n  margin: 0;\n"])));
export var StatusItemBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin: 10px auto;\n  float: center;\n  width: 200px;\n  span {\n    font-size: 16px;\n  }\n  img {\n    width: 20px;\n    margin: auto 5px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin: 10px auto;\n  float: center;\n  width: 200px;\n  span {\n    font-size: 16px;\n  }\n  img {\n    width: 20px;\n    margin: auto 5px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
