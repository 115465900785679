var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Slider } from '@mui/material';
import styled from 'styled-components';
import { Button, Col } from '@atomic';
export var InfoClimate = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 1rem;\n  text-align: center;\n  h2 {\n    margin-top: 0;\n    margin-bottom: 0.5rem;\n  }\n"], ["\n  padding-top: 1rem;\n  text-align: center;\n  h2 {\n    margin-top: 0;\n    margin-bottom: 0.5rem;\n  }\n"])));
export var Footer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1rem;\n"], ["\n  padding: 1rem;\n"])));
export var ButtonGray = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  :hover {\n    background-color: grey;\n    color: black;\n  }\n\n  background-color: #2d2d2d;\n  border: 1px solid #fff;\n"], ["\n  :hover {\n    background-color: grey;\n    color: black;\n  }\n\n  background-color: #2d2d2d;\n  border: 1px solid #fff;\n"])));
export var ColRadioButton = styled(Col)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flex-start;\n  padding: 1.1rem 0 0 0;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: flex-start;\n  padding: 1.1rem 0 0 0;\n"])));
export var StyledSlider = styled(Slider)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &.MuiSlider-rail {\n    opacity: 0.5;\n    background-color: #d0d0d0;\n  }\n  & .MuiSlider-thumb {\n    height: 24px;\n    width: 24px;\n    background-color: #fff;\n    border: 2px solid currentColor;\n    &:focus,\n    &:hover,\n    &.Mui-active,\n    &.Mui-focusVisible {\n      box-shadow: inherit;\n    }\n    &::before {\n      display: none;\n    }\n  }\n"], ["\n  &.MuiSlider-rail {\n    opacity: 0.5;\n    background-color: #d0d0d0;\n  }\n  & .MuiSlider-thumb {\n    height: 24px;\n    width: 24px;\n    background-color: #fff;\n    border: 2px solid currentColor;\n    &:focus,\n    &:hover,\n    &.Mui-active,\n    &.Mui-focusVisible {\n      box-shadow: inherit;\n    }\n    &::before {\n      display: none;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
