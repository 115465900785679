var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStore } from 'react-context-hook';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Container from 'typedi';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { PhoneRegisterFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { Logo } from '@app/modules/authentication/authentication.styled';
import { IAcceptUseTerms } from '@app/modules/use-terms/components/i-accept';
import { HtmlBoxStyled } from '@app/modules/use-terms/html-box.styled';
import { AppPath } from '@app/routes';
import { getUsuarioIdFromClaims, removeSpecialChars, unformatPhoneNumber } from '@app/utils/shared-functions.utils';
import { Button, FlashMessageEnum, Form, PasswordField, TextField, Validators } from '@atomic';
import LogoFooter from '@atomic/obj.footer/LogoFooter';
import { Modal } from '@atomic/obj.modal';
import { POST_LOGIN_URL } from '../login.page';
import { SubTitulo, TelaCadastroCelular, Titulo } from '../login.page-v2.styled';
var _a = AuthStrings.Commom, CPF = _a.CPF, Password = _a.Password, Email = _a.Email;
var ButtonTextStr = AuthStrings.CelphonRegister.ButtonTextStr;
var POST_USUARIO_EXTERNO_URL = 'usuario/usuarioexterno';
export var PhoneRegisterPage = function () {
    var authStorageService = Container.get(AuthStorageService);
    var _a = React.useState(false), showModalTermosDeUso = _a[0], setShowModalTermosDeUso = _a[1];
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var company = useStore('company')[0];
    var domain = useStore('domain')[0];
    var _b = useParams(), grupoId = _b.grupoId, unidadeId = _b.unidadeId;
    var _c = React.useState(), password = _c[0], setPassword = _c[1];
    var _d = React.useState(), generatedEmail = _d[0], setGeneratedEmail = _d[1];
    var _e = React.useState(false), isUseTermsAccepted = _e[0], setIsUseTermsAccepted = _e[1];
    var _f = React.useState(true), isCommunicationsAccepted = _f[0], setIsCommunicationsAccepted = _f[1];
    var loginMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(POST_LOGIN_URL, __assign(__assign({}, params), { empresaIdentificador: domain }))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    var onLoginSuccess = function (response) {
        var _a, _b, _c, _d;
        axiosClient.defaults.headers['Authorization'] = "Bearer " + response.data.accessToken;
        var hasUseTerms = !!((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.userToken) === null || _b === void 0 ? void 0 : _b.termosDeUso) === null || _c === void 0 ? void 0 : _c.id);
        if (((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.recebeuEnergiasDoDia) && !hasUseTerms) {
            showFlashMessage(FlashMessageEnum.success, 'Parabéns! Você ganhou energias!');
        }
        showFlashMessage(FlashMessageEnum.success, 'Sua senha foi enviada para seu email. Aproveite o jogo!');
        if (hasUseTerms) {
            history.push({
                pathname: AppPath.UseTerms,
                state: { data: response === null || response === void 0 ? void 0 : response.data },
            });
        }
        else {
            var decodedToken = parseJwt(response.data.accessToken);
            authStorageService.login({
                id: response.data.userToken.id,
                email: response.data.userToken.email,
                usuarioId: getUsuarioIdFromClaims(response.data.userToken.claims),
                roles: decodedToken.roles,
                possuiAcessoAdmin: decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.possuiAcessoAdmin,
            }, response.data.accessToken);
            history.push({ pathname: AppPath.Map });
        }
    };
    var onLoginError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Usuário ou senha não encontrados'); };
    var _g = useMutation(loginMutation, {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
    }), mutateLogin = _g.mutate, isLoginLoading = _g.isLoading;
    var phoneRegisterMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(POST_USUARIO_EXTERNO_URL, __assign(__assign({}, params), { isCommunicationsAccepted: isCommunicationsAccepted }))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var onSuccess = function (response) {
        if (response.data.jaExiste) {
            showFlashMessage(FlashMessageEnum.success, 'Você já possui conta. Faça login.');
            history.push({ pathname: AppPath.Login });
        }
        else {
            mutateLogin({
                login: generatedEmail,
                password: password,
                isUsuarioExterno: true,
            });
        }
    };
    var onError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Falha ao finalizar cadastro.'); };
    var _h = useMutation(phoneRegisterMutation, {
        onSuccess: onSuccess,
        onError: onError,
    }), mutate = _h.mutate, isLoading = _h.isLoading;
    var GET_TERMOS_DE_USO_VIGENTE = "termos/vigente";
    var termoResponse = useQuery('getTermosDeUsoVigente', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_TERMOS_DE_USO_VIGENTE)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, (_a = response.data) === null || _a === void 0 ? void 0 : _a.data];
                case 2:
                    error_1 = _b.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var handleSubmit = function (formData) {
        var hasFormError = Object.keys(formData.error).length > 0;
        var email;
        if (!hasFormError) {
            if (Object.keys(formData.data.email).length > 0) {
                setGeneratedEmail(formData.data.email);
                email = formData.data.email;
            }
            else {
                setGeneratedEmail(unformatPhoneNumber(formData.data.phone) + "@externoentgames.com.br");
                email = unformatPhoneNumber(formData.data.phone) + "@externoentgames.com.br";
            }
            mutate({
                empresaId: company.id,
                perfilId: 4,
                cpf: removeSpecialChars(formData.data.cpf),
                // email: `21999999997@externoentgames.com.br`,
                email: email,
                password: formData.data.password,
                // nome: 'NOME TESTEEWWWWWWWWWW',
                nome: formData.data.fullname,
                isUsuarioExterno: true,
                // celular: '21999999997',
                celular: unformatPhoneNumber(formData.data.phone),
                dataAdmissao: new Date().toISOString(),
                filialIds: [grupoId],
                unidadesIds: [unidadeId],
            });
        }
    };
    return (React.createElement(TelaCadastroCelular, { src: company === null || company === void 0 ? void 0 : company.imgBackgroundLogin },
        React.createElement("div", { className: " tw-flex tw-flex-col tw-justify-center" },
            React.createElement(Modal, { opened: showModalTermosDeUso, onClose: function () { return setShowModalTermosDeUso(false); } },
                React.createElement(HtmlBoxStyled, { style: { marginTop: '60px' }, className: "list-inner", dangerouslySetInnerHTML: { __html: termoResponse === null || termoResponse === void 0 ? void 0 : termoResponse.conteudo } })),
            React.createElement("div", { className: "tw-h-2/6 tw-flex tw-flex-col tw-justify-center tw-self-center", style: { marginTop: '16px' } },
                React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro })),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } },
                React.createElement(Titulo, { style: { border: 'none' } }, "Aproveite para se cadastrar!"),
                React.createElement(SubTitulo, { onClick: function () { return history.push('/'); } }, "J\u00E1 tenho cadastro")),
            React.createElement("div", { className: "tw-h-4/6" },
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Phone, label: 'CELULAR', validators: [Validators.Required()] },
                        React.createElement(TextField, { variant: "cel-phone" })),
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Email, label: Email.Label },
                        React.createElement(TextField, { type: "email" })),
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Cpf, label: CPF.Label },
                        React.createElement(TextField, { variant: "cpf" })),
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.FullName, label: 'NOME COMPLETO', validators: [Validators.Required()] },
                        React.createElement(TextField, null)),
                    React.createElement(Form.Field, { onValueChange: function (value) { return setPassword(value); }, name: PhoneRegisterFormFields.Password, label: Password.Label, validators: [Validators.Required(), Validators.MinLength(6, Password.MinLengthError)] },
                        React.createElement(PasswordField, null)),
                    React.createElement(Form.Field, { name: PhoneRegisterFormFields.Password, label: Password.SamePasswordLabel, validators: [
                            Validators.EqualsValue(password, Password.SamePasswordError),
                            Validators.Required(),
                            Validators.MinLength(6, Password.MinLengthError),
                        ] },
                        React.createElement(PasswordField, null)),
                    React.createElement("div", { className: "tw-pt-10 tw-pb-10" },
                        React.createElement(IAcceptUseTerms, { handleOnChange: function (newValue) { return setIsUseTermsAccepted(newValue); }, handleOnCommunicationsChange: function (newValue) { return setIsCommunicationsAccepted(newValue); } })),
                    React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading || isLoginLoading, disabled: !isUseTermsAccepted, style: { border: '2px solid #fff', color: '#fff' } }, "ACESSAR"))),
            React.createElement("div", { className: "tw-h-36 tw-flex tw-justify-center tw-flex-col tw-items-center" },
                React.createElement(LogoFooter, null)))));
};
