var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { IconButton } from '@mui/material';
import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { H2, H3, StyledContainer } from '@atomic';
export var ThemeColorText = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var PreviewLabel = styled(H3)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  color: ", ";\n"], ["\n  font-size: 1.2rem;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, themeFontColor = _a.themeFontColor;
    return (themeFontColor ? theme.corPrimaria : '');
});
export var PreviewImage = styled(Col)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-height: 30vh;\n  padding-top: 10px;\n\n  ", " {\n    height: 100%;\n  }\n"], ["\n  max-height: 30vh;\n  padding-top: 10px;\n\n  ", " {\n    height: 100%;\n  }\n"])), StyledContainer);
export var IconColor = styled(IconButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ErrorResponse = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: red;\n  font-size: 14px;\n"], ["\n  color: red;\n  font-size: 14px;\n"])));
// export const NumberInput = styled.input`
//   width: 100%;
//   min-height: 104px;
//   border-radius: 16px;
//   padding: 16px 24px;
//   background-color: #f0f0f0;
//   border: 1px solid #d9d9d9;
//   color: #333;
//   ::-webkit-inner-spin-button,
//   ::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   -moz-appearance: textfield;
//   &::after {
//     content: '▲';
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     /* Adicione mais estilos conforme necessário */
//   }
//   &::before {
//     content: '▼';
//     position: absolute;
//     right: 10px;
//     bottom: 50%;
//     transform: translateY(50%);
//     /* Adicione mais estilos conforme necessário */
//   }
//   ::placeholder {
//     color: #999;
//   }
//   &::-webkit-input-placeholder {
//     color: #999;
//   }
//   &:-ms-input-placeholder {
//     color: #999;
//   }
// `;
export var NumberInput = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  min-height: 104px;\n  border-radius: 16px;\n  border: 1px solid #d9d9d9;\n  display: flex;\n  align-items: center;\n  padding-right: 24px;\n\n  input {\n    width: 100%;\n    min-height: 100px;\n    border: none;\n    background: transparent;\n    color: #333;\n    padding: 16px 24px;\n\n    ::-webkit-inner-spin-button,\n    ::-webkit-outer-spin-button {\n      -webkit-appearance: none;\n      margin: 0;\n    }\n\n    -moz-appearance: textfield;\n  }\n\n  button {\n    background-color: #f0f0f0;\n    border: 1px dotted #d9d9d9;\n    border-radius: 8px;\n    cursor: pointer;\n    padding: 8px;\n    margin-left: 10px;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  min-height: 104px;\n  border-radius: 16px;\n  border: 1px solid #d9d9d9;\n  display: flex;\n  align-items: center;\n  padding-right: 24px;\n\n  input {\n    width: 100%;\n    min-height: 100px;\n    border: none;\n    background: transparent;\n    color: #333;\n    padding: 16px 24px;\n\n    ::-webkit-inner-spin-button,\n    ::-webkit-outer-spin-button {\n      -webkit-appearance: none;\n      margin: 0;\n    }\n\n    -moz-appearance: textfield;\n  }\n\n  button {\n    background-color: #f0f0f0;\n    border: 1px dotted #d9d9d9;\n    border-radius: 8px;\n    cursor: pointer;\n    padding: 8px;\n    margin-left: 10px;\n  }\n"])));
export var OuterDiv = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-height: 100%;\n  overflow-y: auto;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  padding-bottom: 14vh;\n\n  ::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  max-height: 100%;\n  overflow-y: auto;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  padding-bottom: 14vh;\n\n  ::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var InputSearch = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 50px;\n  width: 100%;\n  border-style: none;\n  padding: 10px;\n  font-size: 18px;\n  letter-spacing: 2px;\n  outline: none;\n  transition: all 0.5s ease-in-out;\n  padding-right: 40px;\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 16px;\n\n  &::placeholder {\n    color: ", ";\n    font-size: 18px;\n    letter-spacing: 2px;\n    font-weight: 100;\n  }\n"], ["\n  height: 50px;\n  width: 100%;\n  border-style: none;\n  padding: 10px;\n  font-size: 18px;\n  letter-spacing: 2px;\n  outline: none;\n  transition: all 0.5s ease-in-out;\n  padding-right: 40px;\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 16px;\n\n  &::placeholder {\n    color: ", ";\n    font-size: 18px;\n    letter-spacing: 2px;\n    font-weight: 100;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
