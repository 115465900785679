var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var ListItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n  color: ", ";\n\n  padding: 15px;\n  border: 1px solid ", ";\n  border-bottom: none;\n\n  cursor: pointer;\n\n  &:first-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n    border-bottom: 1px solid ", ";\n  }\n"], ["\n  display: flex;\n  background-color: ", ";\n  color: ", ";\n\n  padding: 15px;\n  border: 1px solid ", ";\n  border-bottom: none;\n\n  cursor: pointer;\n\n  &:first-child {\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n    border-bottom: 1px solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundItem;
}, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.borderColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.borderColor;
});
var ListItemTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.3rem;\n"], ["\n  font-size: 1.3rem;\n"])));
export var ListItem = function (props) {
    return (React.createElement(ListItemContainer, { className: props.className, onClick: props.onClick },
        React.createElement(ListItemTitle, null, props.title),
        props.children));
};
var templateObject_1, templateObject_2;
