import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Modal } from '@atomic/obj.modal';
import { Subtitle, Title, Button, Container } from './checklist-approval-modal.styled';
export var ChecklistApprovalModal = function (_a) {
    var isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, checklistItemId = _a.checklistItemId, onChecklistItemAnswer = _a.onChecklistItemAnswer, isEfetivo = _a.isEfetivo;
    var handleOnApproveClick = function () {
        onChecklistItemAnswer(checklistItemId, isEfetivo);
        handleOnClose(true);
    };
    var handleOnRejectClick = function () {
        handleOnClose(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () { return handleOnClose(false); } },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null)),
            React.createElement(Subtitle, null, "Aten\u00E7\u00E3o!"),
            React.createElement("p", null, "Gostaria de responder novamente?"),
            React.createElement(Container, null,
                React.createElement(Button, { onClick: handleOnApproveClick }, "Sim"),
                React.createElement(Button, { onClick: handleOnRejectClick }, "N\u00E3o")))));
};
