var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { FlashMessageEnum, Separator } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Row, Col } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Label, Subtitle, Title } from './modal-observacao.styled';
var initialData = {
    Observacao: '',
};
var schema = yup.object({
    Observacao: yup.string().required('O campo observação é obrigatório'),
});
export var ModalObservacao = function (_a) {
    var _b = _a.isChecklistUnidade, isChecklistUnidade = _b === void 0 ? false : _b, isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, onSend = _a.onSend, currentRespostaItemId = _a.currentRespostaItemId, checklist = _a.checklist;
    var showFlashMessage = useFlashMessage()[0];
    var POST_OBSERVACAO = 'checklist/item/resposta/observacao';
    var POST_OBSERVACAO_UNIDADE = "checklistunidade/item/resposta/" + currentRespostaItemId + "/observacao";
    var _c = useMutation(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isChecklistUnidade) {
                        formattedData = __assign({}, formValues);
                    }
                    else {
                        formattedData = {
                            ChecklistRespostaItemId: checklist === null || checklist === void 0 ? void 0 : checklist.id,
                            ChecklistPreenchido: currentRespostaItemId,
                        };
                    }
                    return [4 /*yield*/, axiosClient.post(isChecklistUnidade ? POST_OBSERVACAO_UNIDADE : POST_OBSERVACAO, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Observação cadastrada com sucesso.');
            onSend();
            handleOnClose();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar a Observação');
        },
    }), mutate = _c.mutate, isLoading = _c.isLoading;
    var handleOnSubmit = function (formValues) {
        mutate(formValues);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () { return handleOnClose(); } },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null)),
            React.createElement(Subtitle, null, "Observa\u00E7\u00E3o"),
            React.createElement(Formik, { initialValues: initialData, enableReinitialize: true, validationSchema: schema, onSubmit: handleOnSubmit },
                React.createElement(Form, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Fa\u00E7a sua observa\u00E7\u00E3o:")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Field, { as: "textarea", type: "text", name: "Observacao", className: "theme-color-input", style: { width: '100%', height: 150 }, required: true }))),
                    React.createElement(Separator, null),
                    React.createElement(ButtonsRow, null,
                        React.createElement(Col, null,
                            React.createElement(Button, { type: "submit", loading: isLoading }, "Registrar"))))))));
};
