var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
export var ModalOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])));
export var ModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: white;\n  padding: 30px;\n  margin-top: 140px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 80%;\n  overflow: hidden;\n"], ["\n  background: white;\n  padding: 30px;\n  margin-top: 140px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 80%;\n  overflow: hidden;\n"])));
export var ModalContentFortyPercentPaddingTop = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: white;\n  padding: 20px;\n  margin-top: 40%;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 80%;\n  overflow: hidden;\n"], ["\n  background: white;\n  padding: 20px;\n  margin-top: 40%;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 80%;\n  overflow: hidden;\n"])));
export var ArrowIndication = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  align-self: flex-end;\n  margin-right: 10px;\n  margin-top: 30px;\n"], ["\n  position: relative;\n  align-self: flex-end;\n  margin-right: 10px;\n  margin-top: 30px;\n"])));
export var ArrowIndicationCenter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  align-self: center;\n  margin-right: 10px;\n  margin-top: 10px;\n"], ["\n  position: relative;\n  align-self: center;\n  margin-right: 10px;\n  margin-top: 10px;\n"])));
export var bounceAnimation = keyframes(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(-10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n"], ["\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(-10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n"])));
export var HiArrowCircleUp = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var TooltipUp = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: auto;\n  padding-right: 10px;\n  padding-left: 10px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: auto;\n  padding-right: 10px;\n  padding-left: 10px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var TooltipUpBubblePointer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  top: -10px;\n  left: 90%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 8px 10px;\n  border-color: transparent transparent white transparent;\n"], ["\n  position: absolute;\n  top: -10px;\n  left: 90%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 8px 10px;\n  border-color: transparent transparent white transparent;\n"])));
export var HiArrowCircleDown = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: fixed;\n  bottom: 10px;\n  left: calc(50% - 20px);\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: fixed;\n  bottom: 10px;\n  left: calc(50% - 20px);\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var TooltipBottom = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: auto;\n  padding-right: 10px;\n  padding-left: 10px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  position: fixed;\n  bottom: 30px;\n  left: calc(20%);\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: auto;\n  padding-right: 10px;\n  padding-left: 10px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  position: fixed;\n  bottom: 30px;\n  left: calc(20%);\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var TooltipBottomBubblePointer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -10px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 10px 8px 0;\n  border-color: white transparent transparent;\n"], ["\n  position: absolute;\n  bottom: -10px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 10px 8px 0;\n  border-color: white transparent transparent;\n"])));
export var TooltipBottomRight = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: auto;\n  height: 50px;\n  background-color: #ffffff;\n  padding-right: 10px;\n  padding-left: 10px;\n  border-radius: 10px;\n  position: fixed;\n  bottom: 20px;\n  right: 10px;\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: auto;\n  height: 50px;\n  background-color: #ffffff;\n  padding-right: 10px;\n  padding-left: 10px;\n  border-radius: 10px;\n  position: fixed;\n  bottom: 20px;\n  right: 10px;\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var TooltipBottomRightBubblePointer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -10px;\n  left: 90%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 10px 8px 0;\n  border-color: white transparent transparent;\n"], ["\n  position: absolute;\n  bottom: -10px;\n  left: 90%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 10px 8px 0;\n  border-color: white transparent transparent;\n"])));
export var TooltipContent = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  align-items: flex-end;\n"])));
export var HiArrowCircleDownRight = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"], ["\n  width: 50px;\n  height: 50px;\n  background-color: #ffffff;\n  border-radius: 50%;\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  animation: ", " 2s infinite;\n"])), bounceAnimation);
export var ArrowIcon = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 0;\n  height: 0;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-bottom: 15px solid #000000;\n"], ["\n  width: 0;\n  height: 0;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-bottom: 15px solid #000000;\n"])));
export var ArrowIconBottom = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 0;\n  height: 0;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-top: 15px solid #000000;\n"], ["\n  width: 0;\n  height: 0;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-top: 15px solid #000000;\n"])));
export var ButtonContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var TutorialContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var ListContainer = styled.ul(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n"])));
export var ListItem = styled.li(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n"], ["\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n"])));
export var IconWrapper = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
export var AndroidIcon = styled.img(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  margin-right: 10px;\n  background-color: lightgray;\n  padding: 6px;\n  border-radius: 10px;\n"], ["\n  margin-right: 10px;\n  background-color: lightgray;\n  padding: 6px;\n  border-radius: 10px;\n"])));
export var Icon = styled.img(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  margin-right: 10px;\n  padding-left: 3px;\n  width: 22px;\n"], ["\n  margin-right: 10px;\n  padding-left: 3px;\n  width: 22px;\n"])));
export var IconBig = styled.img(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  margin-right: 10px;\n  background-color: lightgray;\n  padding: 6px;\n  border-radius: 10px;\n"], ["\n  margin-right: 10px;\n  background-color: lightgray;\n  padding: 6px;\n  border-radius: 10px;\n"])));
export var IconSmall = styled.img(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  width: 2rem;\n"], ["\n  width: 2rem;\n"])));
export var TitleContainer = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var Text = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29;
