var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { axiosClient } from '@app/configure-app';
import { FaIcon } from '@atomic/atm.fa-icon';
import { LabelBox, RankingStarsBox } from './stars.styled';
export var RankingStars = function (_a) {
    var _b, _c;
    var points = _a.points, _d = _a.size, size = _d === void 0 ? '1rem' : _d;
    var _e = useState(false), isOpenStarInfo = _e[0], setOpenStarInfo = _e[1];
    var nivel = useQuery('nivel', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('nivel')];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var getUserLevel = React.useCallback(function () {
        var _a, _b;
        if (nivel === undefined)
            return;
        for (var i = (nivel === null || nivel === void 0 ? void 0 : nivel.length) - 1; i >= 0; i--) {
            if (points >= ((_a = nivel[i]) === null || _a === void 0 ? void 0 : _a.pontos)) {
                return { size: i + 1, levelName: (_b = nivel[i]) === null || _b === void 0 ? void 0 : _b.nome };
            }
        }
    }, [nivel, points]);
    return (React.createElement(RankingStarsBox, { onMouseEnter: function () { return setOpenStarInfo(true); }, onMouseLeave: function () { return setOpenStarInfo(false); } },
        Array.from({ length: (_b = getUserLevel()) === null || _b === void 0 ? void 0 : _b.size }).map(function (_, index) { return (React.createElement(FaIcon.Star, { key: index, style: { fontSize: size } })); }),
        isOpenStarInfo && (React.createElement(LabelBox, null,
            React.createElement("span", null, (_c = getUserLevel()) === null || _c === void 0 ? void 0 : _c.levelName)))));
};
