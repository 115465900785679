import * as React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AppPath } from '@app/routes';
import { HeaderLogoWrapperV2 } from '../atm.logo/logo.component';
import { HeaderBackButton, HeaderContainer } from './header.styled';
export var HeaderV2 = function (_a) {
    var companyLogoURL = _a.companyLogoURL, _b = _a.withBackButton, withBackButton = _b === void 0 ? true : _b, backButtonFn = _a.backButtonFn, children = _a.children, _c = _a.toMap, toMap = _c === void 0 ? false : _c;
    var history = useHistory();
    var handleOnBackButtonClick = function () {
        if (toMap)
            return history.push({ pathname: AppPath.Map });
        history.goBack();
    };
    return (React.createElement(HeaderContainer, { primaryColor: true, className: 'use-inverse-colors' },
        companyLogoURL && (React.createElement(HeaderLogoWrapperV2, null,
            React.createElement("img", { src: companyLogoURL }))),
        withBackButton && (React.createElement(HeaderBackButton, { onClick: backButtonFn || handleOnBackButtonClick },
            React.createElement(ArrowBack, { sx: { color: 'white', fontSize: '2.0rem' } }),
            React.createElement(Typography, { sx: { fontSize: '0.9rem' } }, "Voltar"))),
        children));
};
