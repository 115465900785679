var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { LogoCopyright } from '@app/components/atm.logo-copyright/logo-copyright';
import { Logo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { RegisterFinishFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { ContainerStyled, ContentStyled, FormStyled, SmallTitle, } from '@app/modules/authentication/authentication.styled';
import { AppPath } from '@app/routes';
import { removeSpecialChars } from '@app/utils/shared-functions.utils';
import { Form, Validators, Separator, TextField, Button, FlashMessageEnum } from '@atomic';
var CPF = AuthStrings.Commom.CPF;
var _a = AuthStrings.RegisterFinish, TitleStr = _a.TitleStr, ButtonTextStr = _a.ButtonTextStr;
var PUT_USUARIO_EXTERNO_URL = 'usuario/usuarioexterno';
export var RegisterFinishPage = function () {
    // const [usuario] = useStore<IUsuario>('usuario');
    var _a = useStore('usuario'), usuario = _a[0], setUsuario = _a[1];
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var company = useStore('company')[0];
    var userUrl = 'usuario';
    useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(userUrl)];
                case 1:
                    response = _b.sent();
                    setUsuario((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _b.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); });
    var putUsuarioExternoMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.put(PUT_USUARIO_EXTERNO_URL, params)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var onSuccess = function () {
        showFlashMessage(FlashMessageEnum.success, 'Registro finalizado');
        history.push(AppPath.Ranking);
    };
    var onError = function () { return showFlashMessage(FlashMessageEnum.alert, 'Falha ao finalizar cadastro.'); };
    var _b = useMutation(putUsuarioExternoMutation, {
        onSuccess: onSuccess,
        onError: onError,
    }), mutate = _b.mutate, isLoading = _b.isLoading;
    var handleSubmit = function (formData) {
        var hasFormError = Object.keys(formData.error).length > 0;
        if (!hasFormError) {
            mutate(__assign(__assign({}, usuario), { cpf: removeSpecialChars(formData.data.cpf), celular: formData.data.phone, filialIds: usuario.filiais.map(function (filial) { return filial.id; }), unidadesIds: usuario.unidades.map(function (unidade) { return unidade.id; }) }));
        }
    };
    return (React.createElement(ContainerStyled, null,
        React.createElement(ContentStyled, null,
            React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, centered: true }),
            React.createElement(SmallTitle, null, TitleStr),
            React.createElement(Separator, null),
            React.createElement(FormStyled, null, usuario && (React.createElement(Form, { onSubmit: handleSubmit },
                !(usuario === null || usuario === void 0 ? void 0 : usuario.cpf) && (React.createElement(Form.Field, { name: RegisterFinishFormFields.Cpf, label: CPF.Label, validators: [Validators.Required(), Validators.CpfPatternRegex(CPF.Error)] },
                    React.createElement(TextField, { variant: "cpf" }))),
                !(usuario === null || usuario === void 0 ? void 0 : usuario.celular) && (React.createElement(Form.Field, { name: RegisterFinishFormFields.Phone, label: 'CELULAR' },
                    React.createElement(TextField, { variant: "cel-phone" }))),
                React.createElement(Separator, null),
                React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading }, ButtonTextStr)))),
            React.createElement(LogoCopyright, null))));
};
