import * as React from 'react';
// Create context map for global store assignment
var ContextMap = new Map();
var Provider = function (_a) {
    var store = _a.store, children = _a.children;
    // initialize store hooks
    // this is required because react expects the same number
    // of hooks to be called on each render
    // so if we run init in useStore hook - it'll break on re-render
    // return provider with stores map
    var storesMap = new Map([[store, store()]]);
    // get context for specific store
    var Context = ContextMap.get(store);
    return React.createElement(Context.Provider, { value: storesMap }, children);
};
export var GlobalStore = function (_a) {
    var stores = _a.stores, children = _a.children;
    // complain if no instances provided for initialization
    if (!stores || !stores.length) {
        throw new Error('You must provide stores list to a <GlobalStore> for initialization!');
    }
    // create providers for each store
    var providersLayout;
    stores.forEach(function (store) {
        var context = ContextMap.get(store);
        if (!context) {
            context = React.createContext(null);
            ContextMap.set(store, context);
        }
        providersLayout = React.createElement(Provider, { store: store }, providersLayout || children);
    });
    return providersLayout;
};
export function useStore(storeInit) {
    var context = ContextMap.get(storeInit);
    if (!context) {
        return null;
    }
    // use store specific context
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var map = React.useContext(context);
    // complain if no map is given
    if (!map) {
        throw new Error('You must wrap your components with a <Provider>!');
    }
    var instance = map.get(storeInit);
    // complain if instance wasn't initialized
    if (!instance) {
        throw new Error('Provided store instance did not initialize correctly!');
    }
    return instance;
}
