var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Header } from '@app/components/mol.header';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AppPath } from '@app/routes';
import handPhoneSrc from '@assets/icons/hand-phone.svg';
import searchSrc from '@assets/icons/search.svg';
import { Col, FlashMessageEnum, Row, StyledContainer } from '@atomic';
import { ChecklistButton, EntcoinsHeaderWrapper, } from './checklist-lists.page.styled';
import { CheckTypes } from './components/checklist-card';
import { ChecklistsList } from './components/checklists-list';
var GET_USER_URL = 'usuario';
var GET_PENDING_CHECKLISTS_URL = 'checklist/pendente';
var GET_RECURRENCES_URL = 'checklistunidade/recorrentes';
var GET_UPCOMING_RECURRENCES_URL = 'checklistunidade/proximos';
var GET_CHECK_UNIDADES_PROGRESS_URL = 'checklistunidade/em-progresso';
var GET_PENDING_APPROVAL_URL = 'checklistunidade/associado';
var GET_DENIED_URL = 'checklistunidade/auditado-reprovados';
var GET_TODAY_DATA_URL = 'checklistunidade/pra-hoje';
var GET_CHECKLISTS_URL = 'checklist';
var GET_UNIT_CHECKLISTS_URL = 'checklistunidade';
export var FILTER_TYPES = {
    COLABORATOR: 'colaborador',
    UNIT: 'unidade',
};
var filterOptions = [
    // { label: 'Colaborador', value: FILTER_TYPES.COLABORATOR },
    { label: 'Unidade', value: FILTER_TYPES.UNIT },
];
var tabsTypes;
(function (tabsTypes) {
    tabsTypes["HISTORICO"] = "historico";
    tabsTypes["HOJE"] = "hoje";
    tabsTypes["PROXIMOS"] = "proximos";
    tabsTypes["PROGRESSO"] = "progresso";
    tabsTypes["PENDENTE"] = "pendente";
    tabsTypes["RECUSADOS"] = "recusados";
})(tabsTypes || (tabsTypes = {}));
export var ChecklistPage = function () {
    var showFlashMessage = useFlashMessage()[0];
    var _a = React.useState(false), openHistoricoModal = _a[0], setOpenHistoricoModal = _a[1];
    var _b = React.useState(filterOptions[0]), currentFilter = _b[0], setCurrentFilter = _b[1];
    var company = useStore('company')[0];
    var history = useHistory();
    var theme = useTheme();
    var handleOnFilterInputChange = function (value) {
        console.log(value);
        setCurrentFilter(value);
    };
    var _c = React.useState(tabsTypes.HOJE), activTab = _c[0], setActivTab = _c[1];
    var user = useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_USER_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnWindowFocus: false, cacheTime: 120000, staleTime: 120000 }).data;
    var tabBorderStyle = {
        borderBottom: "0.15em solid " + (company === null || company === void 0 ? void 0 : company.corPrimaria),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors theme-main-background theme-background-gradient" },
            React.createElement(Header, { withBackButton: true, title: "Checklist", backButtonFn: function () { return history.push(AppPath.Map); } },
                React.createElement(EntcoinsHeaderWrapper, null,
                    React.createElement("img", { src: handPhoneSrc, alt: "handPhone" }),
                    React.createElement("span", null, 'Checklist')))),
        React.createElement(StyledContainer, { withPadding: true, style: { padding: '1.2rem 1rem 6rem 1rem' } },
            React.createElement(Row, { style: tabBorderStyle },
                React.createElement(Col, { xs: 12, className: "!tw-flex tw-justify-around tw-text-xs" },
                    React.createElement("div", { onClick: function () { return setActivTab(tabsTypes.HISTORICO); }, className: "tw-cursor-pointer " + (activTab === tabsTypes.HISTORICO ? 'theme-main-background' : 'theme-secondary-background') + " ", style: { borderRadius: '10px 10px 0px 0px' } },
                        React.createElement("h2", { className: "tw-cursor-pointer " + (activTab === tabsTypes.HISTORICO ? 'white-color' : 'black-color') + " ", style: { marginLeft: '10px', marginRight: '10px' } }, "Hist\u00F3rico")),
                    React.createElement("div", { onClick: function () { return setActivTab(tabsTypes.HOJE); }, className: "tw-cursor-pointer " + (activTab === tabsTypes.HOJE ? 'theme-main-background' : 'theme-secondary-background') + " ", style: { borderRadius: '10px 10px 0px 0px' } },
                        React.createElement("h2", { className: "tw-cursor-pointer " + (activTab === tabsTypes.HOJE ? 'white-color' : 'black-color') + " ", style: { marginLeft: '10px', marginRight: '10px' } }, "Para Hoje")),
                    React.createElement("div", { onClick: function () { return setActivTab(tabsTypes.PROXIMOS); }, className: "tw-cursor-pointer " + (activTab === tabsTypes.PROXIMOS ? 'theme-main-background' : 'theme-secondary-background') + " ", style: { borderRadius: '10px 10px 0px 0px' } },
                        React.createElement("h2", { className: "tw-cursor-pointer " + (activTab === tabsTypes.PROXIMOS ? 'white-color' : 'black-color') + " ", style: { marginLeft: '10px', marginRight: '10px' } }, "Pr\u00F3ximos")))),
            React.createElement(Row, { style: { marginTop: '10px' } },
                React.createElement(Col, { xs: 12 },
                    React.createElement(ChecklistButton, { onClick: function () { return history.push(AppPath.ChecklistFilters); }, variant: "primary", expanded: true },
                        React.createElement("img", { src: searchSrc }),
                        React.createElement("span", null, "Checar")))),
            activTab === tabsTypes.HISTORICO && (React.createElement(Row, null,
                currentFilter.value == FILTER_TYPES.COLABORATOR && (React.createElement(Col, { xs: 12 },
                    React.createElement(ChecklistsList, { type: CheckTypes.HISTORY_COLABORATOR_TYPE, url: GET_CHECKLISTS_URL, withCardsOpened: true }))),
                currentFilter.value == FILTER_TYPES.UNIT && (React.createElement(Col, { xs: 12 },
                    React.createElement(ChecklistsList, { type: CheckTypes.HISTORY_UNIT_TYPE, url: GET_UNIT_CHECKLISTS_URL, withCardsOpened: true }))))),
            activTab === tabsTypes.HOJE && (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(ChecklistsList, { type: CheckTypes.TODAY_TYPE, url: GET_TODAY_DATA_URL })))),
            activTab === tabsTypes.PROXIMOS && (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(ChecklistsList, { type: CheckTypes.UPCOMING_TYPE, url: GET_UPCOMING_RECURRENCES_URL }))))),
        React.createElement(BottomNav, { setHistoricoOpen: function () { return setOpenHistoricoModal(true); }, isChecklist: true })));
};
