var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { VideoPlayer } from '@app/components/atm.video-player';
import { ModalPdf } from '@app/components/modal-pdf';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { ActionPlanModal } from '@app/modules/checklist/components/action-plan-modal';
import { ModalFoto } from '@app/modules/checklist/components/modal-foto';
import { ModalObservacao } from '@app/modules/checklist/components/modal-observacao';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { getUserLocation } from '@app/services/location';
import docsNewIcon from '@assets/img/new-doc-icon.svg';
import playNewIcon from '@assets/img/video-icon-new.svg';
import { ActivityIndicator, Button, FlashMessageEnum } from '@atomic';
import { Modal } from '@atomic/obj.modal';
import { QuickChecklist } from '../../quick-checklist';
import { FlexColumn } from '../../quick-checklist/styles';
import { ChecklistItem } from '../checklist-item';
import { DescriptionRow, IconRow, Span } from './checklist.styled';
export var ITEM_STATE = {
    NOT_ANSWERED: 'not_answered',
    RIGHT: 'right',
    WRONG: 'wrong',
    NULL: 'null',
};
export var Checklist = function (_a) {
    var _b, _c;
    var checklist = _a.checklist, openQuickChecklist = _a.openQuickChecklist, onClose = _a.onClose, isLoadingChecklists = _a.isLoadingChecklists, checklistUnidadePendenteId = _a.checklistUnidadePendenteId;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var userData = useStore(useUserStore).userData;
    var _d = useState(false), isModalOpened = _d[0], setIsModalOpened = _d[1];
    var _e = useState(false), isModalObservacaoOpened = _e[0], setIsModalObservacaoOpened = _e[1];
    var _f = useState(false), isModalFotoOpened = _f[0], setIsModalFotoOpened = _f[1];
    var _g = useState([]), respostasGestor = _g[0], setRespostasGestor = _g[1];
    var _h = useState([]), observacaoItemsIds = _h[0], setObservacaoItemsIds = _h[1];
    var _j = useState([]), actionPlansChecklistItemsIds = _j[0], setActionPlansChecklistItemsIds = _j[1];
    var _k = useState([]), fotosEnviadasChecklistItemsIds = _k[0], setFotosEnviadasChecklistItemsIds = _k[1];
    var _l = useState(), currentRespostaItemId = _l[0], setCurrentRespostaItemId = _l[1];
    var _m = useState(''), dataInicioResposta = _m[0], setDataInicioResposta = _m[1];
    var _o = useState(checklist), currentChecklist = _o[0], setCurrentChecklist = _o[1];
    var _p = useState(false), isAnyObjectWithRequiredPhotoSent = _p[0], setIsAnyObjectWithRequiredPhotoSent = _p[1];
    var _q = useState(undefined), loadingItem = _q[0], setLoadingItem = _q[1];
    var _r = useState([]), fotoPreviews = _r[0], setFotoPreviews = _r[1];
    var _s = useState(undefined), itemFotos = _s[0], setItemFotos = _s[1];
    var _t = useState(true), relampagoValidation = _t[0], setRelampagoValidation = _t[1];
    var _u = useState(false), isOpenVideoModal = _u[0], setIsOpenVideoModal = _u[1];
    var _v = useState(false), isModalOpen = _v[0], setIsModalOpen = _v[1];
    var closeModal = function () {
        setIsModalOpen(false);
    };
    var POST_ANSWERED_CHECKLIST = 'checklistunidade/preenchido-finalizar';
    var POST_ANSWERED_CHECKLIST_INDIVIDUAL = 'checklistunidade/preenchido-individual';
    // const sortItemsByOrder = (itens: IUnitChecklistItem[]) => {
    //   if (itens) {
    //     return itens.slice().sort((a, b) => a.ordem - b.ordem);
    //   }
    //   return [];
    // };
    var isAnyObjectWithoutIsEfetivo = respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.some(function (item) { return item.efetivo === undefined; });
    var postChecklistMutation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = {
                        id: currentChecklist.checklistPreenchidoId === 0
                            ? currentChecklist.preenchidoId
                            : currentChecklist.checklistPreenchidoId,
                        unidadeEmpresaId: checklist.unidadeEmpresaId,
                        checklistUnidadeId: checklist.id,
                        gestorResponsavelId: userData.usuarioId,
                        dataInicioResposta: dataInicioResposta,
                        checklistUnidadePendenteId: checklistUnidadePendenteId,
                        respostas: respostasGestor.map(function (resposta) {
                            return {
                                checklistUnidadeItemId: resposta.id,
                                isEfetivo: resposta.efetivo,
                                dataResposta: resposta.dataResposta,
                                latitudeResposta: resposta.latitudeResposta,
                                longitudeResposta: resposta.longitudeResposta,
                            };
                        }),
                    };
                    return [4 /*yield*/, axiosClient.put(POST_ANSWERED_CHECKLIST, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var _w = useMutation(postChecklistMutation, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Checklist finalizado com sucesso!');
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao finalizar o checklist');
        },
    }), postChecklistResponse = _w.data, mutate = _w.mutate, isLoading = _w.isLoading;
    useEffect(function () {
        if (dataInicioResposta === '') {
            setDataInicioResposta(moment().format());
        }
        setCurrentChecklist(checklist);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklist]);
    var handleOnSubmitClick = function () {
        mutate();
    };
    var idsThatNeedPhoto = (_b = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens) === null || _b === void 0 ? void 0 : _b.filter(function (obj) {
        var _a, _b;
        return obj.fotoObrigatoria &&
            (typeof ((_a = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _a === void 0 ? void 0 : _a.urlFotoRespostaList) === 'undefined' ||
                ((_b = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList.length) === 0);
    }).map(function (obj) { return obj.id; });
    useEffect(function () {
        var hasRequiredPhotoSent = idsThatNeedPhoto === null || idsThatNeedPhoto === void 0 ? void 0 : idsThatNeedPhoto.every(function (_id) { return fotosEnviadasChecklistItemsIds.includes(_id); });
        setIsAnyObjectWithRequiredPhotoSent(hasRequiredPhotoSent);
    }, [fotosEnviadasChecklistItemsIds, idsThatNeedPhoto]);
    var itemsWithPhotos = (_c = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens) === null || _c === void 0 ? void 0 : _c.filter(function (obj) {
        var _a, _b, _c;
        return ((_a = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _a === void 0 ? void 0 : _a.urlFotoRespostaList) !== undefined &&
            ((_b = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList) !== null &&
            ((_c = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _c === void 0 ? void 0 : _c.urlFotoRespostaList.length) > 0;
    }).map(function (obj) {
        var _a, _b;
        return ({
            id: obj.id,
            respostaItemId: (_a = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _a === void 0 ? void 0 : _a.id,
            fotoUrlList: (_b = obj === null || obj === void 0 ? void 0 : obj.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList,
        });
    });
    var addFotoPreview = function (newItem) {
        setFotoPreviews(function (prevFotoPreviews) {
            if (!prevFotoPreviews.some(function (item) { return item.id === newItem.id; })) {
                return __spreadArrays(prevFotoPreviews, [newItem]);
            }
            return prevFotoPreviews;
        });
    };
    useEffect(function () {
        if (itemsWithPhotos) {
            itemsWithPhotos.forEach(function (item) {
                addFotoPreview(item);
            });
        }
    }, [itemsWithPhotos]);
    var hasPreviewAnswer = function (checklistItemId) {
        return respostasGestor.findIndex(function (_resposta) { return _resposta.id === checklistItemId; }) > -1;
    };
    useEffect(function () {
        var _a;
        var RespostastoSave = (_a = checklist === null || checklist === void 0 ? void 0 : checklist.itens) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            var resposta = item.respostas[0];
            return {
                id: item.id,
                checklistId: item.checklistUnidadeId,
                efetivo: resposta === null || resposta === void 0 ? void 0 : resposta.isEfetivo,
            };
        });
        setRespostasGestor(RespostastoSave);
    }, [checklist]);
    var registerItemAnswer = function (checklistItemId, isEfetivo, coordenadas) {
        var _a;
        if (hasPreviewAnswer(checklistItemId)) {
            var RespostastoSave = respostasGestor.map(function (resposta) {
                return resposta.id === checklistItemId
                    ? __assign(__assign({}, resposta), { efetivo: isEfetivo, dataResposta: moment().format(), latitudeResposta: coordenadas ? coordenadas.latitude : null, longitudeResposta: coordenadas ? coordenadas.longitude : null }) : resposta;
            });
            setRespostasGestor(RespostastoSave);
        }
        else {
            setRespostasGestor(function (respostas) { return __spreadArrays(respostas, [
                {
                    id: checklistItemId,
                    gestorId: userData.usuarioId,
                    checklistId: checklist.id,
                    efetivo: isEfetivo,
                    dataResposta: moment().format(),
                    dataCadastro: '2022-01-01T00:00:00.000Z',
                    latitudeResposta: coordenadas ? coordenadas.latitude : null,
                    longitudeResposta: coordenadas ? coordenadas.longitude : null,
                },
            ]); });
        }
        // if (checklistItem.preenchidoId) setShowModalInfo(true);
        var itemFiltrado;
        for (var _i = 0, _b = currentChecklist.itens; _i < _b.length; _i++) {
            var item = _b[_i];
            if (((_a = item.resposta) === null || _a === void 0 ? void 0 : _a.checklistUnidadeItemId) === checklistItemId) {
                itemFiltrado = item;
                break;
            }
        }
        var formattedData = {
            id: currentChecklist.checklistPreenchidoId,
            unidadeEmpresaId: checklist.unidadeEmpresaId,
            checklistUnidadePendenteId: checklistUnidadePendenteId,
            checklistUnidadeId: checklist.id,
            gestorResponsavelId: userData.usuarioId,
            dataInicioResposta: dataInicioResposta,
            resposta: {
                id: itemFiltrado ? itemFiltrado.resposta.id : 0,
                checklistUnidadeItemId: checklistItemId,
                isEfetivo: isEfetivo,
                dataResposta: moment().format(),
                latitudeResposta: coordenadas ? coordenadas.latitude : null,
                longitudeResposta: coordenadas ? coordenadas.longitude : null,
            },
        };
        return formattedData;
    };
    var getChecklistUnidadeId = function () {
        var _a, _b, _c, _d;
        if (currentChecklist.preenchidoId > 0)
            return currentChecklist.preenchidoId;
        else if (((_b = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[((_a = currentChecklist.itens) === null || _a === void 0 ? void 0 : _a.length) - 1]) === null || _b === void 0 ? void 0 : _b.checklistUnidadePreenchidoId) > 0)
            return (_d = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens[((_c = currentChecklist.itens) === null || _c === void 0 ? void 0 : _c.length) - 1]) === null || _d === void 0 ? void 0 : _d.checklistUnidadePreenchidoId;
        else
            return currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.checklistPreenchidoId;
    };
    var handleOnChecklistItemAnswer = function (checklistItemId, isEfetivo) { return __awaiter(void 0, void 0, void 0, function () {
        var coordenadas, formattedData, response_1, error_1, formattedData, response_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingItem(checklistItemId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, getUserLocation()];
                case 2:
                    coordenadas = _a.sent();
                    formattedData = registerItemAnswer(checklistItemId, isEfetivo, coordenadas);
                    formattedData = __assign(__assign({}, formattedData), { id: getChecklistUnidadeId(), checklistUnidadePendenteId: checklistUnidadePendenteId });
                    return [4 /*yield*/, axiosClient.post(POST_ANSWERED_CHECKLIST_INDIVIDUAL, formattedData)];
                case 3:
                    response_1 = _a.sent();
                    setLoadingItem(undefined);
                    setCurrentChecklist(function (oldChecklist) {
                        var _a, _b;
                        var updatedItems = oldChecklist.itens.map(function (item) {
                            var _a, _b, _c;
                            if (item.id === checklistItemId) {
                                return __assign(__assign({}, item), { resposta: (_c = (_b = (_a = response_1 === null || response_1 === void 0 ? void 0 : response_1.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.respostas) === null || _c === void 0 ? void 0 : _c.find(function (i) { return i.checklistUnidadeItemId === item.id; }) });
                            }
                            return item;
                        });
                        return __assign(__assign({}, oldChecklist), { preenchidoId: (_b = (_a = response_1 === null || response_1 === void 0 ? void 0 : response_1.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id, itens: updatedItems });
                    });
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    formattedData = registerItemAnswer(checklistItemId, isEfetivo);
                    formattedData = __assign(__assign({}, formattedData), { checklistUnidadePendenteId: checklistUnidadePendenteId, id: getChecklistUnidadeId() });
                    return [4 /*yield*/, axiosClient.post(POST_ANSWERED_CHECKLIST_INDIVIDUAL, formattedData)];
                case 5:
                    response_2 = _a.sent();
                    setLoadingItem(undefined);
                    setCurrentChecklist(function (oldChecklist) {
                        var _a, _b;
                        var updatedItems = oldChecklist.itens.map(function (item) {
                            var _a, _b;
                            if (item.id === checklistItemId) {
                                return __assign(__assign({}, item), { resposta: (_b = (_a = response_2 === null || response_2 === void 0 ? void 0 : response_2.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.respostas.find(function (i) { return i.checklistUnidadeItemId === item.id; }) });
                            }
                            return item;
                        });
                        return __assign(__assign({}, oldChecklist), { preenchidoId: (_b = (_a = response_2 === null || response_2 === void 0 ? void 0 : response_2.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id, itens: updatedItems });
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var getItemState = function (checklistItemId) {
        var itemRespondido = respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.find(function (resposta) {
            return resposta.id == checklistItemId;
        });
        switch (itemRespondido === null || itemRespondido === void 0 ? void 0 : itemRespondido.efetivo) {
            case true:
                return ITEM_STATE.RIGHT;
            case false:
                return ITEM_STATE.WRONG;
            case null:
                return ITEM_STATE.NULL;
            default:
                return ITEM_STATE.NOT_ANSWERED;
        }
    };
    var handleOnActionPlanClick = function (checklistUnidadeItemId) {
        setCurrentRespostaItemId(checklistUnidadeItemId);
        setIsModalOpened(true);
    };
    var handleObservacaoClick = function (checklistUnidadeRespostaId) {
        setCurrentRespostaItemId(checklistUnidadeRespostaId);
        setIsModalObservacaoOpened(true);
    };
    var _x = useState(), photoToSentItemId = _x[0], setPhotoToSentItemId = _x[1];
    var handleOnEnviarFotoClick = function (checklistUnidadeRespostaId, itemId) {
        setPhotoToSentItemId(itemId);
        setCurrentRespostaItemId(checklistUnidadeRespostaId);
        setItemFotos(fotoPreviews.find(function (preview) { return (preview === null || preview === void 0 ? void 0 : preview.respostaItemId) === checklistUnidadeRespostaId; }));
        setIsModalFotoOpened(true);
    };
    var handleOnFotoModalClose = function () {
        setIsModalFotoOpened(false);
        setCurrentRespostaItemId(undefined);
        setItemFotos(undefined);
    };
    var handleObservacaoModalClose = function () {
        setIsModalObservacaoOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnActionPlanModalClose = function () {
        setIsModalOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnCloseClick = function () {
        history.push(AppPath.RoutineChecklist);
    };
    var registerActionPlan = function () {
        setActionPlansChecklistItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var registerFotoEnviada = function () {
        setFotosEnviadasChecklistItemsIds(function (old) { return __spreadArrays(old, [photoToSentItemId]); });
    };
    var registerObservacao = function () {
        setObservacaoItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var removerFotoEnviada = function (fotoItemId) {
        setFotosEnviadasChecklistItemsIds(function (prevIds) { return prevIds.filter(function (itemId) { return itemId !== fotoItemId; }); });
        setFotoPreviews(function (prevFotos) { return prevFotos.filter(function (foto) { return foto.id !== fotoItemId; }); });
    };
    var adicionarFotoPreview = function (newPreview) {
        setFotoPreviews(function (prevPreviews) {
            var existingIndex = prevPreviews.findIndex(function (item) { return item.id === newPreview.id; });
            if (existingIndex !== -1) {
                // Replace the existing item with the new item
                var updatedPreviews = __spreadArrays(prevPreviews);
                updatedPreviews[existingIndex] = newPreview;
                return updatedPreviews;
            }
            else {
                // Add the new item to the array
                return __spreadArrays(prevPreviews, [newPreview]);
            }
        });
    };
    var getFotoPreview = function (id) {
        return fotoPreviews.find(function (preview) { return (preview === null || preview === void 0 ? void 0 : preview.id) === id; });
    };
    useEffect(function () {
        var mappedRelampagoValidation = currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens.map(function (item, index) {
            if (item.hasOwnProperty('resposta')) {
                return false;
            }
            else if (Array.isArray(item.respostas) && (item.respostas.length === 0 || item.respostas[0] === null)) {
                return true;
            }
            else {
                return false;
            }
        });
        var hasTrue = mappedRelampagoValidation === null || mappedRelampagoValidation === void 0 ? void 0 : mappedRelampagoValidation.some(function (element) { return element === true; });
        setRelampagoValidation(hasTrue);
    }, [currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens, loadingItem, onClose]);
    var handleAddQuickChecklistToTheItems = function (itemRelampago) {
        var data = __assign(__assign({}, itemRelampago), { respostas: [] });
        currentChecklist.itens.push(data);
        onClose();
    };
    var handlePlayIconClick = function () {
        setIsOpenVideoModal(true);
    };
    var handleFileIconClick = function () {
        setIsModalOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionPlanModal, { isOpened: isModalOpened, onSend: registerActionPlan, handleOnClose: handleOnActionPlanModalClose, currentRespostaItemId: currentRespostaItemId, isChecklistUnidade: true, currentRespostaGestorId: userData.usuarioId, checklist: currentChecklist }),
        React.createElement(ModalFoto, { isOpened: isModalFotoOpened, onSend: registerFotoEnviada, handleOnClose: handleOnFotoModalClose, isChecklistUnidade: true, checklist: currentChecklist, currentRespostaItemId: currentRespostaItemId, setFotosPreview: adicionarFotoPreview, itemFotos: itemFotos, isPermitidoImagemDaBiblioteca: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.isPermitidoImagemDaBiblioteca }),
        React.createElement(ModalObservacao, { isOpened: isModalObservacaoOpened, onSend: registerObservacao, handleOnClose: handleObservacaoModalClose, isChecklistUnidade: true, checklist: currentChecklist, currentRespostaItemId: currentRespostaItemId }),
        React.createElement(QuickChecklist, { open: openQuickChecklist, sendItToTheChecklist: function (e) { return handleAddQuickChecklistToTheItems(e); }, onClose: onClose, checklist: currentChecklist }),
        React.createElement(Modal, { opened: isOpenVideoModal, onClose: function () { return setIsOpenVideoModal(false); }, closeOnOutsideClick: true }, (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoVideo) ? React.createElement(VideoPlayer, { videoUrl: currentChecklist.urlDoVideo }) : null),
        isModalOpen && (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoArquivo) ? (React.createElement(ModalPdf, { link: currentChecklist.urlDoArquivo, onClose: closeModal })) : null,
        ((currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoVideo) || (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoArquivo)) && (React.createElement(IconRow, null,
            (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoVideo) && React.createElement("img", { src: playNewIcon, onClick: handlePlayIconClick, alt: "Play Icon" }),
            (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.urlDoArquivo) && React.createElement("img", { src: docsNewIcon, onClick: handleFileIconClick, alt: "File Icon" }))),
        (currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.descricao) && React.createElement(DescriptionRow, null, currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.descricao),
        React.createElement(Span, null, "Itens marcados com * s\u00E3o obrigat\u00F3rios."),
        !isLoadingChecklists ? (React.createElement(React.Fragment, null, currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.itens.map(function (item, idx) { return (React.createElement(React.Fragment, null,
            React.createElement(ChecklistItem, { key: idx, checklistItem: item, handleOnClick: handleOnChecklistItemAnswer, itemState: getItemState(item.id), handleOnActionPlanClick: function () { return handleOnActionPlanClick(item.id); }, handleOnEnviarFotoClick: function () { var _a, _b; return handleOnEnviarFotoClick(((_a = item.resposta) === null || _a === void 0 ? void 0 : _a.id) || ((_b = item.respostas[0]) === null || _b === void 0 ? void 0 : _b.id), item.id); }, handleOnObservacaoClick: function () { var _a, _b; return handleObservacaoClick(((_a = item.resposta) === null || _a === void 0 ? void 0 : _a.id) || ((_b = item.respostas[0]) === null || _b === void 0 ? void 0 : _b.id)); }, isDisabled: !!postChecklistResponse, isActionPlanRegistered: !!actionPlansChecklistItemsIds.find(function (checklistItemId) { return checklistItemId === item.id; }), isFotoEnviada: !!fotosEnviadasChecklistItemsIds.find(function (checklistItemId) { return checklistItemId === item.id; }), isObservacaoEnviada: !!observacaoItemsIds.find(function (checklistItemId) { var _a, _b; return checklistItemId == ((_a = item.resposta) === null || _a === void 0 ? void 0 : _a.id) || checklistItemId == ((_b = item.respostas[0]) === null || _b === void 0 ? void 0 : _b.id); }), itemLoading: loadingItem === (item === null || item === void 0 ? void 0 : item.id), fotoPreview: getFotoPreview(item.id), removeFotoPreview: removerFotoEnviada, boxBackgroundColor: idx % 2 === 0 ? '#ECFDFD' : 'rgba(239, 255, 255, 0.42)' }))); }))) : (React.createElement(FlexColumn, { style: { paddingBlock: '2rem' } },
            React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))),
        postChecklistResponse ? (React.createElement(Button, { loading: isLoading, onClick: handleOnCloseClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Fechar")) : (React.createElement(Button, { loading: isLoading, disabled: isAnyObjectWithoutIsEfetivo || !isAnyObjectWithRequiredPhotoSent || relampagoValidation, onClick: handleOnSubmitClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Enviar"))));
};
