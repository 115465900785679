var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H4, H5 } from '@atomic';
export var Title = styled(H4)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  padding-right: 1rem;\n"], ["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  padding-right: 1rem;\n"])));
export var Subtitle = styled(H5)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 4px;\n  border: none;\n  width: 60px;\n  height: 32px;\n  font-size: 18px;\n\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  background: ", ";\n  border-radius: 4px;\n  border: none;\n  width: 60px;\n  height: 32px;\n  font-size: 18px;\n\n  &:focus {\n    outline: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-evenly;\n"], ["\n  display: flex;\n  justify-content: space-evenly;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
