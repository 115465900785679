var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Col as FlexboxCol } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Label as StyledLabel } from '../checklist/components/action-plan-modal/action-plan-modal.styled';
export var Label = styled(StyledLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 5px;\n"], ["\n  padding-bottom: 5px;\n"])));
export var Col = styled(FlexboxCol)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  padding-bottom: 1rem;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  padding-bottom: 1rem;\n"])));
var templateObject_1, templateObject_2;
