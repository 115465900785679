import React from 'react';
import { BsList, BsPlus } from 'react-icons/bs';
import { Button } from '@atomic';
import handPhone from '../../../../../assets/icons/hand-phone-small.svg';
import { ArrowIndication, ButtonContainer, IconWrapper, ListContainer, ListItem, ModalContent, ModalOverlay, Text, TitleContainer, TooltipBottomRightBubblePointer, TooltipBottomRight, TutorialContainer, } from '../../styles';
export var AddToHomeScreenPopup = function (_a) {
    var onIgnoreButton = _a.onIgnoreButton, onRemindMeLaterButton = _a.onRemindMeLaterButton;
    return (React.createElement(ModalOverlay, { style: { zIndex: 100 } },
        React.createElement(ModalContent, null,
            React.createElement(TutorialContainer, null,
                React.createElement(TitleContainer, null,
                    React.createElement("img", { src: handPhone, alt: "m\u00E3o segurando celular", style: { width: 'fitContent' } }),
                    React.createElement(Text, null,
                        React.createElement("span", { style: { fontSize: '1.5rem' } }, "Adicione o APP na sua tela inicial."),
                        React.createElement("br", null),
                        React.createElement("span", { style: { fontSize: '1rem' } }, "\u00C9 r\u00E1pido e f\u00E1cil!"))),
                React.createElement(ListContainer, null,
                    React.createElement(ListItem, null,
                        React.createElement("span", { style: { paddingRight: '5px' } }, "Passo 1 - Clique no \u00EDcone"),
                        React.createElement(IconWrapper, null,
                            React.createElement(BsList, null))),
                    React.createElement(ListItem, null,
                        React.createElement("span", null,
                            "Passo 2 - Clique em 'Adicionar a p\u00E1gina a...' no \u00EDcone ",
                            React.createElement(BsPlus, null))),
                    React.createElement(ListItem, null,
                        React.createElement("span", null, "Passo 3 - Selecione 'Tela Inicial'")),
                    React.createElement(ListItem, null,
                        React.createElement("span", null, "Passo 4 - Clique em 'Adicionar'")))),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { onClick: function () { return onRemindMeLaterButton(); }, variant: "primary", expanded: true, style: { borderRadius: 15 } }, "Lembrar mais tarde"),
                React.createElement("br", null),
                React.createElement(Button, { onClick: function () { return onIgnoreButton(); }, variant: "secondary", expanded: true, style: { borderRadius: 15 } }, "N\u00E3o quero adicionar"))),
        React.createElement(ArrowIndication, null,
            React.createElement(TooltipBottomRight, null,
                React.createElement("div", { style: { display: 'flex', alignItems: 'flex-end' } },
                    React.createElement("span", null, "Passo 1 - Clicar no \u00EDcone abaixo"),
                    React.createElement(IconWrapper, null,
                        React.createElement("span", { style: { paddingLeft: '4px' } },
                            React.createElement(BsList, null)))),
                React.createElement(TooltipBottomRightBubblePointer, null)))));
};
