var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Container from 'typedi';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { formatToPtBrDate, getUsuarioIdFromClaims } from '@app/utils/shared-functions.utils';
import { Button, H2, StyledContainer } from '@atomic';
import { HtmlBoxStyled } from './html-box.styled';
import { IsUpdatedTermsAlertText } from './use-terms.page.styled';
var termsUrl = function (id) { return "termos/" + id; };
export var UseTermsPage = function (props) {
    var _a, _b, _c, _d;
    var history = useHistory();
    // const [isUseTermsReaded, setIsUseTermsReaded] = React.useState(false);
    var _e = React.useState(false), isAccepted = _e[0], setIsAccepted = _e[1];
    var showFlashMessage = useFlashMessage()[0];
    var authStorageService = Container.get(AuthStorageService);
    var termoDeUso = (_d = (_c = (_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.userToken) === null || _d === void 0 ? void 0 : _d.termosDeUso;
    var userData = useStore(useUserStore).userData;
    var isTutorialReaded = function () {
        var isTutorialReadedToken = localStorage.getItem('is-tutorial-readed');
        return isTutorialReadedToken === "is-tutorial-readed-" + (userData === null || userData === void 0 ? void 0 : userData.usuarioId);
    };
    var mutate = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(termsUrl(termoDeUso === null || termoDeUso === void 0 ? void 0 : termoDeUso.id))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            authStorageService.login({
                id: (_c = (_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.userToken.id,
                email: (_f = (_e = (_d = props.location) === null || _d === void 0 ? void 0 : _d.state) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.userToken.email,
                usuarioId: getUsuarioIdFromClaims((_j = (_h = (_g = props.location) === null || _g === void 0 ? void 0 : _g.state) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.userToken.claims),
            }, (_m = (_l = (_k = props.location) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.accessToken);
            // Suggest the user to change his password to improve security.
            history.push({
                pathname: AppPath.Configurations,
                state: {
                    hideBackButton: true,
                    onSuccessGoToPath: AppPath.Map,
                },
            });
        },
        onError: function () { return showFlashMessage('alert', 'Erro, tente novamente mais tarde'); },
    }).mutate;
    var handleOnBackButtonClick = function () {
        authStorageService.logout();
        history.push({ pathname: AppPath.Login });
    };
    // Funcionalidade de verificação de scroll até o fim do componente desativada
    // por apresentar alguns erros em alguns dispositivos
    // const listInnerRef = React.useRef();
    // const onScroll = () => {
    //   if (listInnerRef.current) {
    //     const { scrollTop, scrollHeight, clientHeight }: any = listInnerRef.current;
    //     if (scrollTop + clientHeight === scrollHeight) {
    //       setIsUseTermsReaded(true);
    //     }
    //   }
    // };
    var handleOnIAcceptBtnClick = function () {
        mutate();
        //disabled={!isAccepted}
        /*
        if (isAccepted) {
          mutate();
        } else {
          showFlashMessage('alert', 'Você deve aceitar os termos de uso para acessar a plataforma.');
        }
        */
    };
    var handleOnTermsChecked = function (checkboxId, newValue) {
        // Funcionalidade de verificação de scroll até o fim do componente desativada
        // por apresentar alguns erros em alguns dispositivos
        // if (newValue && !isUseTermsReaded) {
        //   showFlashMessage('alert', 'Você deve ler os termos de uso até o fim antes de aceitá-lo.');
        //   return false;
        // }
        //setIsAccepted(newValue);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { withBackButton: true, backButtonFn: handleOnBackButtonClick }),
        React.createElement(StyledContainer, { withHPadding: true },
            React.createElement("div", { className: "header" }, (termoDeUso === null || termoDeUso === void 0 ? void 0 : termoDeUso.isUsuarioNovo) ? (React.createElement(H2, null, termoDeUso === null || termoDeUso === void 0 ? void 0 : termoDeUso.titulo)) : (React.createElement(React.Fragment, null,
                React.createElement(H2, null,
                    "ATUALIZA\u00C7\u00C3O DOS ", termoDeUso === null || termoDeUso === void 0 ? void 0 :
                    termoDeUso.titulo),
                React.createElement(IsUpdatedTermsAlertText, null,
                    "Esta \u00E9 uma nova vers\u00E3o dos Termos de Uso, de ",
                    formatToPtBrDate(termoDeUso === null || termoDeUso === void 0 ? void 0 : termoDeUso.dataCadastro))))),
            React.createElement(HtmlBoxStyled, { className: "list-inner", dangerouslySetInnerHTML: { __html: termoDeUso === null || termoDeUso === void 0 ? void 0 : termoDeUso.conteudo } }),
            React.createElement("div", { className: "footer" },
                React.createElement(Button, { style: { marginTop: '0.8rem' }, expanded: true, onClick: handleOnIAcceptBtnClick }, "EU ACEITO")))));
};
