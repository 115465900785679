var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { formatToPtBrDateTime } from '@app/utils/shared-functions.utils';
import { Summary, StyledChamadoCard } from './card.styled';
export var CardDeChamado = function (_a) {
    var chamado = _a.chamado, rest = __rest(_a, ["chamado"]);
    var _b = useState(false), isOpened = _b[0], setIsOpened = _b[1];
    var handleOnCardClick = function () {
        setIsOpened(!isOpened);
    };
    return (React.createElement(StyledChamadoCard, __assign({ onClick: handleOnCardClick }, rest),
        React.createElement(Summary, null,
            React.createElement("span", { className: "theme-color" },
                "#",
                chamado.id),
            " - ",
            chamado.categoria.descricao,
            " - (",
            formatToPtBrDateTime(chamado.dataCadastro),
            ")"),
        ' ',
        React.createElement("p", null,
            React.createElement("b", { className: "theme-color" }, "DESCRI\u00C7\u00C3O:"),
            " ",
            chamado.descricao)));
};
