import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { RankingTable } from '@app/modules/ranking/components/ranking-table/ranking-table.component';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import { Button, FontFamily, Separator } from '@atomic';
import { StyledContainer, H4 } from '@atomic/atm.typography/typography.component.style';
import { QuizResult } from '../components/quiz-result';
export var QuizResultPage = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var history = useHistory();
    var currentCourseCode = useStore('currentCourseCode')[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _g = React.useContext(QuizContext), _ = _g[0], __ = _g[1], ___ = _g[2], setCurrentQuizzIndex = _g[3];
    var handlePlayAgainClick = function () {
        var _a, _b;
        setCurrentQuizzIndex(0);
        history.push({
            pathname: "" + AppPath.Quiz,
            state: { tarefa: (_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.tarefa },
        });
    };
    var handleOnConcluirClick = function () {
        history.push(AppPath.Track.replace(':courseCode', "" + currentCourseCode));
    };
    var isSuccessfulAttempt = ((_b = (_a = props.location.state) === null || _a === void 0 ? void 0 : _a.quizResult) === null || _b === void 0 ? void 0 : _b.tarefaAcertoPercentual) >= 50;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { withPadding: true, primaryColor: true, className: "use-inverse-colors" },
            React.createElement(QuizResult, { score: (_d = (_c = props.location.state) === null || _c === void 0 ? void 0 : _c.quizResult) === null || _d === void 0 ? void 0 : _d.pontos }),
            React.createElement(DottedSeparator, { style: { marginTop: '2.1rem' } }),
            React.createElement(H4, { style: { fontFamily: FontFamily.Secondary, textAlign: 'center', marginBottom: 0 } }, "Ranking")),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(RankingTable, { data: (_e = props.location.state) === null || _e === void 0 ? void 0 : _e.quizResult, tarefa: (_f = props.location.state) === null || _f === void 0 ? void 0 : _f.tarefa }),
            React.createElement(Separator, null),
            !isSuccessfulAttempt && (React.createElement(Button, { expanded: true, onClick: handlePlayAgainClick, style: { marginBottom: '1rem' } }, "TENTAR DE NOVO")),
            React.createElement(Button, { expanded: true, onClick: handleOnConcluirClick, variant: isSuccessfulAttempt ? 'primary' : 'link' }, "CONCLUIR"))));
};
