import * as React from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { VideoPlayer } from '@app/components/atm.video-player';
import { ModalPdf } from '@app/components/modal-pdf';
import { Header } from '@app/components/mol.header';
import { AppPath } from '@app/routes';
import handMessageSrc from '@assets/img/hand-message.png';
import { Button, Separator, StyledContainer } from '@atomic';
import { GrayText } from '@atomic/atm.typography/typography.component.style';
import { EntHeaderWrapper, LearningContent, StyledText } from './learning.page.style';
export var LearningPage = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var quiz = (_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.quiz;
    var video = quiz === null || quiz === void 0 ? void 0 : quiz.video;
    var history = useHistory();
    var _m = React.useState(false), isModalOpen = _m[0], setIsModalOpen = _m[1];
    var _o = React.useState(''), modalLink = _o[0], setModalLink = _o[1];
    var openModal = function (link) {
        setModalLink(link);
        setIsModalOpen(true);
    };
    var closeModal = function () {
        setModalLink('');
        setIsModalOpen(false);
    };
    var handleNavigateToQuizClick = function () {
        history.goBack();
    };
    var handleNavigateBack = function () {
        history.goBack();
    };
    var handleNavigateToMap = function () {
        history.push({ pathname: AppPath.Map });
    };
    if (((_d = (_c = props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.goToQuiz) && !localStorage.getItem("userSawHelp" + quiz.id)) {
        localStorage.setItem("userSawHelp" + quiz.id, 'true');
    }
    history.listen(function () {
        var currentDate = format(new Date(), 'uuuu-M-dd');
        localStorage.setItem("retorno-ajuda-" + currentDate, 'true');
    });
    console.log('aqui', quiz);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors theme-main-background theme-background-gradient" },
            React.createElement(Header, { withBackButton: true, title: "Comunicados", backButtonFn: function () { return history.push(AppPath.Map); } },
                React.createElement(EntHeaderWrapper, { backgroundColor: "#FFEFE8" },
                    React.createElement("img", { src: handMessageSrc, alt: "figura dos comunicados" }),
                    React.createElement("span", null, 'Comunicados')))),
        quiz ? (React.createElement(React.Fragment, null,
            React.createElement(StyledContainer, { alignCenter: true, withPadding: true, noPaddingTop: true },
                React.createElement(StyledText, null, quiz === null || quiz === void 0 ? void 0 : quiz.titulo),
                (quiz === null || quiz === void 0 ? void 0 : quiz.conteudoHtml) && (React.createElement(LearningContent, null,
                    React.createElement("div", { className: "list-inner", dangerouslySetInnerHTML: { __html: quiz === null || quiz === void 0 ? void 0 : quiz.conteudoHtml } }))),
                ((_e = quiz === null || quiz === void 0 ? void 0 : quiz.arquivo) === null || _e === void 0 ? void 0 : _e.url) && (React.createElement(Button, { onClick: function () { var _a; return openModal((_a = quiz === null || quiz === void 0 ? void 0 : quiz.arquivo) === null || _a === void 0 ? void 0 : _a.url); }, variant: "secondary", expanded: true, style: { borderRadius: 15 } }, "Visualizar PDF")),
                video && React.createElement(GrayText, null, video === null || video === void 0 ? void 0 : video.titulo),
                video && (React.createElement("span", { style: { marginBottom: '10px' } },
                    React.createElement(VideoPlayer, { videoUrl: video === null || video === void 0 ? void 0 : video.url })))),
            React.createElement(DottedSeparator, null),
            !((_g = (_f = props.location) === null || _f === void 0 ? void 0 : _f.state) === null || _g === void 0 ? void 0 : _g.hideBackToQuizButton) && (((_h = quiz === null || quiz === void 0 ? void 0 : quiz.quizzes) === null || _h === void 0 ? void 0 : _h.length) > 0 || ((_j = quiz === null || quiz === void 0 ? void 0 : quiz.perguntas) === null || _j === void 0 ? void 0 : _j.length) > 0) && (React.createElement(StyledContainer, { withPadding: true },
                React.createElement(Button, { expanded: true, onClick: handleNavigateToQuizClick }, "Ir para o quiz!"))),
            React.createElement(DottedSeparator, null),
            React.createElement(StyledContainer, { withPadding: true }, (((_k = quiz === null || quiz === void 0 ? void 0 : quiz.quizzes) === null || _k === void 0 ? void 0 : _k.length) == 0 || ((_l = quiz === null || quiz === void 0 ? void 0 : quiz.perguntas) === null || _l === void 0 ? void 0 : _l.length) == 0) && (React.createElement(React.Fragment, null,
                React.createElement(Separator, null),
                React.createElement(Button, { expanded: true, onClick: handleNavigateToMap }, "CONCLUIR")))))) : (React.createElement(React.Fragment, null,
            React.createElement(StyledContainer, { withPadding: true },
                React.createElement(Button, { expanded: true, onClick: handleNavigateBack }, "Voltar")))),
        isModalOpen && React.createElement(ModalPdf, { link: modalLink, onClose: closeModal })));
};
