import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory, useParams } from 'react-router-dom';
import { Logo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { AppPath } from '@app/routes';
import { Separator, FlashMessageEnum, H4 } from '@atomic';
import { FlexCol } from '../submenus/components/submenu-content-card/styles';
import { ChangePassword } from './change-password.component';
import { LogoWrapper, TelaDeLogin } from './login.page-v2.styled';
var Title = AuthStrings.NewPassword.Title;
export var NewPasswordPage = function () {
    var history = useHistory();
    var company = useStore('company')[0];
    var showFlashMessage = useFlashMessage()[0];
    var _a = useParams(), usuarioId = _a.usuarioId, token = _a.token;
    var NEW_PASSWORD_URL = "usuario/" + usuarioId + "/recuperar-senha?token=" + token;
    return (React.createElement(React.Fragment, null,
        React.createElement(TelaDeLogin, { style: { justifyContent: 'flex-start', gap: '32px', paddingTop: '16px' } },
            React.createElement(FlexCol, { style: { alignItems: 'center' } },
                React.createElement(LogoWrapper, { style: { height: '100%' } },
                    React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro })),
                React.createElement(H4, null, Title)),
            React.createElement(Separator, null),
            React.createElement(FlexCol, { style: { alignItems: 'center', paddingTop: '16px' } },
                React.createElement(ChangePassword, { endpointUrl: NEW_PASSWORD_URL, onError: function () {
                        showFlashMessage(FlashMessageEnum.alert, 'Seu token expirou. Solicite nova recuperação de senha.');
                        history.push(AppPath.RecoverPassword);
                    }, requestExtraParams: { token: token }, usuarioId: usuarioId })))));
};
