var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { BsCheckLg } from 'react-icons/bs';
import styled from 'styled-components';
import { RoundLogo } from '@app/components/atm.logo/logo.component';
import { RoundBox } from '@app/components/round-box/round-box';
import { CenterModal } from '@app/modules/quiz/components/phase-completed/components/avaliation-modal/avaliation-modal.styled';
import { H1, H2 } from '@atomic';
import { Company, getImageByCompany } from '../avaliation/logos';
export var Thanks = function () {
    var company = useStore('company')[0];
    var image = '';
    if ((company === null || company === void 0 ? void 0 : company.id) === Company.BigUltra) {
        image = getImageByCompany(Company.BigUltra);
    }
    else {
        image = company === null || company === void 0 ? void 0 : company.imgLogo;
    }
    return (React.createElement(CenterModal, null,
        React.createElement(RoundLogo, { src: image }),
        React.createElement(H1, { style: { fontWeight: 'bold', fontSize: 22 } }, "A sua opini\u00E3o \u00E9 importante para n\u00F3s!"),
        React.createElement(RoundBox, null,
            React.createElement(H2, { style: { fontWeight: 'bold', fontSize: 20 } }, "Sua avalia\u00E7\u00E3o foi enviada com sucesso!"),
            React.createElement(StyledIconWrapper, null,
                React.createElement(DashedCircle, null,
                    React.createElement(BsCheckLg, { size: 150, color: "#008425" }))),
            React.createElement(H2, { style: { fontWeight: 'bold', fontSize: 20 } }, "Agradecemos a sua opini\u00E3o!"))));
};
var StyledIconWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  width: 100%;\n"], ["\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  width: 100%;\n"])));
var DashedCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 200px;\n  height: 200px;\n  border: 3px dashed #008425;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 200px;\n  height: 200px;\n  border: 3px dashed #008425;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;
