var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic';
export var RewardInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .coin,\n  .time {\n    margin-left: 5px;\n  }\n  img {\n    margin: 5px 5px 0 5px;\n    width: 18px;\n    height: 18px;\n  }\n\n  span {\n    font-family: ", ";\n    font-size: 18px;\n    vertical-align: middle;\n    height: 31px;\n    display: inline-table;\n  }\n"], ["\n  .coin,\n  .time {\n    margin-left: 5px;\n  }\n  img {\n    margin: 5px 5px 0 5px;\n    width: 18px;\n    height: 18px;\n  }\n\n  span {\n    font-family: ", ";\n    font-size: 18px;\n    vertical-align: middle;\n    height: 31px;\n    display: inline-table;\n  }\n"])), FontFamily.Primary);
export var PointsAmount = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  width: 100%;\n  justify-content: flex-end;\n\n  span {\n    font-family: ", ";\n    opacity: 0.8;\n    font-size: 18px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  width: 100%;\n  justify-content: flex-end;\n\n  span {\n    font-family: ", ";\n    opacity: 0.8;\n    font-size: 18px;\n  }\n"])), FontFamily.Primary);
export var QuestionTitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.5rem;\n  font-family: ", "; //'Ubuntu500';\n  font-size: 1.1rem;\n"], ["\n  padding: 0.5rem;\n  font-family: ", "; //'Ubuntu500';\n  font-size: 1.1rem;\n"])), FontFamily.Primary);
var templateObject_1, templateObject_2, templateObject_3;
