var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { ListItem } from '@app/modules/submenus/components/list-item';
var ListItemOverride = styled(ListItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  padding: 15px 20px;\n"], ["\n  flex-direction: column;\n  padding: 15px 20px;\n"])));
var ListItemFooter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 10px;\n  height: 30px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 10px;\n  height: 30px;\n"])));
var ListItemLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.9rem;\n  border-radius: 15px;\n  padding: 5px 10px;\n  max-width: 50%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  font-size: 0.9rem;\n  border-radius: 15px;\n  padding: 5px 10px;\n  max-width: 50%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
var ListItemCategoryLabel = styled(ListItemLabel)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n"], ["\n  color: ", ";\n  background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundItem;
}, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
});
var ListItemStatusLabel = styled(ListItemLabel)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: white;\n"], ["\n  color: white;\n"])));
export var ListItemStatus = function (props) {
    return (React.createElement(ListItemOverride, __assign({}, props),
        React.createElement(ListItemFooter, null,
            React.createElement(ListItemCategoryLabel, null, props.categoryLabel),
            React.createElement(ListItemStatusLabel, { style: { backgroundColor: props.statusLabelColor } }, props.statusLabel))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
