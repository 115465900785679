var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import { useStore } from 'react-context-hook';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import { ReactSelect } from '@app/components/atm.react-select';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { FlashMessageEnum, Separator } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Row, Col } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Label, Subtitle, Title } from './action-plan-modal.styled';
var schema = yup.object({
    descricaoResolucao: yup.string().required('O campo descricaoResolucao é obrigatório'),
    // nomeResponsavelResolucao: yup.string().required('O campo nomeResponsavelResolucao é obrigatório'),
    dataLimiteResolucao: yup.date().typeError('required'),
});
export var ActionPlanModal = function (_a) {
    var _b = _a.isChecklistUnidade, isChecklistUnidade = _b === void 0 ? false : _b, isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, onSend = _a.onSend, currentPreenchidoId = _a.currentPreenchidoId, currentRespostaGestorId = _a.currentRespostaGestorId, item = _a.item, planoAcao = _a.planoAcao, respostaId = _a.respostaId;
    var showFlashMessage = useFlashMessage()[0];
    var company = useStore('company')[0];
    var POST_PLANO_ACAO_URL = 'checklist/planoacao';
    var POST_PLANO_ACAO_UNIDADE_URL = 'checklistunidade/planoacaounidade';
    var getDate = function () {
        var currentDate = new Date(planoAcao === null || planoAcao === void 0 ? void 0 : planoAcao.dataLimiteResolucao);
        return currentDate.getFullYear() + "-" + String(currentDate.getMonth() + 1).padStart(2, '0') + "-" + String(currentDate.getDate()).padStart(2, '0');
    };
    var initialData = {
        descricaoResolucao: '',
        nomeResponsavelResolucao: '',
        dataLimiteResolucao: getDate(),
        usuarioAssociadoId: {
            value: planoAcao === null || planoAcao === void 0 ? void 0 : planoAcao.usuarioAssociadoId,
            label: planoAcao === null || planoAcao === void 0 ? void 0 : planoAcao.nomeResponsavelResolucao,
        },
    };
    var GET_USERS_AND_UNITS = function () { return "checklistunidade/usuarios"; };
    var options = useQuery('getChecklistUnidadePreenchidoById', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, mappedOptions, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_USERS_AND_UNITS())];
                case 1:
                    response = _c.sent();
                    mappedOptions = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (itemLocal) { return ({
                        value: itemLocal.usuarioId,
                        label: itemLocal.nome + " - " + itemLocal.unidadeNome,
                    }); });
                    return [2 /*return*/, mappedOptions];
                case 2:
                    error_1 = _c.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var _c = useMutation(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var nomeResponsavelResolucao, formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (formValues.usuarioAssociadoId)
                        nomeResponsavelResolucao = formValues.usuarioAssociadoId.label;
                    if ((formValues === null || formValues === void 0 ? void 0 : formValues.usuarioAssociadoId.value) === 0) {
                        throw Error;
                    }
                    formattedData = __assign(__assign({}, formValues), { checklistUnidadeItemId: item.id, checklistUnidadeId: item.checklistUnidadeId, respostaGestorId: currentRespostaGestorId, checklistUnidadeRespostaItemId: respostaId, nomeResponsavelResolucao: nomeResponsavelResolucao, usuarioAssociadoId: formValues.usuarioAssociadoId.value, checklistUnidadePreenchidoId: currentPreenchidoId });
                    return [4 /*yield*/, axiosClient.post(isChecklistUnidade ? POST_PLANO_ACAO_UNIDADE_URL : POST_PLANO_ACAO_URL, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Plano de Ação cadastrado com sucesso.');
            onSend();
            handleOnClose();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar o Plano de Ação');
        },
    }), mutate = _c.mutate, isLoading = _c.isLoading;
    var handleSubmit = function (formValues) {
        if (moment(formValues.dataLimiteResolucao).format().split('-')[0].replace(/^0+/, '').length !== 4) {
            showFlashMessage(FlashMessageEnum.alert, 'Verifique a data que foi digitada');
        }
        else {
            mutate(formValues);
        }
    };
    var dataAtualFormatada = moment().local().format('YYYY-MM-DD');
    var customStyles = {
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '40px', borderColor: company === null || company === void 0 ? void 0 : company.corPrimaria, borderWidth: '2px', boxShadow: company === null || company === void 0 ? void 0 : company.corPrimaria, '&:hover': {
                borderColor: company === null || company === void 0 ? void 0 : company.corPrimaria,
            } })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isSelected ? company === null || company === void 0 ? void 0 : company.corPrimaria : 'white', '&:hover': {
                backgroundColor: company === null || company === void 0 ? void 0 : company.corPrimaria,
            } })); },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () { return handleOnClose(); } },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null)),
            React.createElement(Subtitle, null, "Plano de A\u00E7\u00E3o"),
            React.createElement(Formik, { initialValues: initialData, enableReinitialize: true, validationSchema: schema, onSubmit: handleSubmit },
                React.createElement(Form, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Como ser\u00E1 resolvido?")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Field, { as: "textarea", type: "text", name: "descricaoResolucao", className: "theme-color-input", style: { width: '100%', height: 150 }, required: true }))),
                    React.createElement(Separator, null),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Quem ir\u00E1 resolver?")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Field, { name: "usuarioAssociadoId", component: ReactSelect, options: options, styles: customStyles }))),
                    React.createElement(Separator, null),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Quando estar\u00E1 pronto?")),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Field, { type: "date", name: "dataLimiteResolucao", min: dataAtualFormatada, style: { backgroundColor: 'transparent' }, className: "theme-color-input" }))),
                    React.createElement(Separator, null),
                    React.createElement(ButtonsRow, null,
                        React.createElement(Col, null,
                            React.createElement(Button, { type: "submit", loading: isLoading }, "Registrar"))))))));
};
