var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import { useStore } from '@app/core/services';
import { LoggedPage } from '@app/modules/logged/pages/logged.page';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes/app-path';
export var GuardedRoute = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var logged = useStore(useUserStore).logged;
    var renderComponent = React.useCallback(function (innerProps) {
        var pathName = {
            pathname: logged ? AppPath.Map : AppPath.Login,
            state: { from: innerProps.location },
        };
        return logged ? (React.createElement(LoggedPage, null,
            React.createElement(Component, __assign({}, innerProps)))) : (React.createElement(Redirect, { to: pathName }));
    }, [logged, Component]);
    return React.createElement(ReactDOMRoute, __assign({ render: renderComponent }, rest));
};
