import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useStore } from 'react-context-hook';
import { Logo } from './authentication.styled';
import { LoginPage } from './login.page';
import { LoadingScreen } from './login.page-v2.styled';
var VARIANTE_TEMPO_DE_CARREGAMENTO = 1;
var SplashScreen = function () {
    var _a = useState(0), counter = _a[0], setCounter = _a[1];
    var company = useStore('company')[0];
    var handleCounter = function () {
        setCounter(function (old) { return (old += VARIANTE_TEMPO_DE_CARREGAMENTO); });
    };
    useEffect(function () {
        setInterval(handleCounter, 1);
        return function () {
            console.log('Carregamento Completo');
        };
    }, []);
    if (counter >= 200) {
        return React.createElement(LoginPage, null);
    }
    return (React.createElement("div", { className: "tw-flex tw-flex-col tw-justify-between tw-h-screen" },
        React.createElement(LoadingScreen, { cor: company === null || company === void 0 ? void 0 : company.corPrimaria },
            React.createElement("div", { className: "tw-flex tw-justify-center tw-flex-col tw-items-center tw-h-2/5" },
                React.createElement(Fade, null,
                    React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro }))))));
};
export default SplashScreen;
