var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
var HboxStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-content: flex-start;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-content: flex-start;\n"])));
export var Hbox = HboxStyled;
Hbox.displayName = 'Hbox';
Hbox.Item = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: column;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  ", "\n  justify-content: ", ";\n  align-items: ", ";\n"], ["\n  flex-direction: column;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  ", "\n  justify-content: ", ";\n  align-items: ", ";\n"])), function (props) { return (!props.noGrow ? 'flex: 1;' : null); }, function (props) { return (props.vAlign ? props.vAlign : 'flex-start'); }, function (props) { return (props.hAlign ? props.hAlign : 'stretch'); });
Hbox.Item.displayName = 'Hbox.Item';
Hbox.Separator = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), Spacing.Small);
Hbox.Separator.displayName = 'Hbox.Separator';
export var Separator = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), function (_a) {
    var isSmall = _a.isSmall;
    return (isSmall ? Spacing.Small : Spacing.Large);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
