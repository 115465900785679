var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var FlexRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px;\n"])));
export var FlexRowTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  column-gap: 12px;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  height: 40px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  column-gap: 12px;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px;\n  height: 40px;\n"])));
export var FlexRowBot = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  column-gap: 16px;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 16px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  column-gap: 16px;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 16px;\n"])));
export var FlexColumn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
export var QuickChecklistTitle = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Ubuntu';\n  font-weight: '500';\n  font-size: 16px;\n"], ["\n  font-family: 'Ubuntu';\n  font-weight: '500';\n  font-size: 16px;\n"])));
export var ImagePreview = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-height: 90px;\n  margin-left: 5%;\n"], ["\n  max-height: 90px;\n  margin-left: 5%;\n"])));
export var CloseCircleIcon = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 23px;\n  height: 23px;\n  background: #c20000;\n  border-radius: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 700;\n  color: white;\n  position: relative;\n  left: -10px;\n  top: -10px;\n  border: 1px solid white;\n  font-size: 13px;\n"], ["\n  cursor: pointer;\n  width: 23px;\n  height: 23px;\n  background: #c20000;\n  border-radius: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 700;\n  color: white;\n  position: relative;\n  left: -10px;\n  top: -10px;\n  border: 1px solid white;\n  font-size: 13px;\n"])));
export var ButtonWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid RGB(160, 160, 160);\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n  transition: all ease-in-out 0.3s;\n  background-color: RGB(214, 214, 214);\n\n  &:hover {\n    cursor: pointer;\n    background-color: RGB(160, 160, 160);\n    transform: scale(1.03);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid RGB(160, 160, 160);\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n  transition: all ease-in-out 0.3s;\n  background-color: RGB(214, 214, 214);\n\n  &:hover {\n    cursor: pointer;\n    background-color: RGB(160, 160, 160);\n    transform: scale(1.03);\n  }\n"])));
export var TextareaImpossibilita = styled.textarea(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  resize: none;\n  padding: 5px;\n  margin-top: 10px;\n  width: 100%;\n  height: 7rem;\n  border-radius: 12px;\n  font-size: 16px;\n"], ["\n  resize: none;\n  padding: 5px;\n  margin-top: 10px;\n  width: 100%;\n  height: 7rem;\n  border-radius: 12px;\n  font-size: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
