var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Card = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  height: 100px;\n  width: auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 15px;\n  margin: 5px 10px;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);\n  border: 1px solid #dbdbdb;\n  padding-left: 25px;\n  background-color: ", ";\n  color: ", ";\n\n  ", ";\n\n  ", ";\n\n  :hover {\n    cursor: pointer;\n  }\n"], ["\n  position: relative;\n  height: 100px;\n  width: auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 15px;\n  margin: 5px 10px;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);\n  border: 1px solid #dbdbdb;\n  padding-left: 25px;\n  background-color: ", ";\n  color: ", ";\n\n  ",
    ";\n\n  ",
    ";\n\n  :hover {\n    cursor: pointer;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundItem;
}, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, function (props) {
    return props.gridMode && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      height: 115px;\n      width: 100%;\n      flex-direction: column;\n      justify-content: center;\n      margin: 5px;\n      padding-left: 0;\n    "], ["\n      height: 115px;\n      width: 100%;\n      flex-direction: column;\n      justify-content: center;\n      margin: 5px;\n      padding-left: 0;\n    "])));
}, function (_a) {
    var theme = _a.theme;
    return theme.darkMode && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border: none;\n    "], ["\n      border: none;\n    "])));
});
export var CardContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  ",
    ";\n"])), function (props) {
    return props.gridMode && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      align-items: center;\n      padding-top: 5px;\n    "], ["\n      align-items: center;\n      padding-top: 5px;\n    "])));
});
export var Title = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: Inter400;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: ", ";\n  text-align: ", ";\n  font-weight: bold;\n  font-size: 16px;\n  z-index: 1;\n"], ["\n  font-family: Inter400;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: ", ";\n  text-align: ", ";\n  font-weight: bold;\n  font-size: 16px;\n  z-index: 1;\n"])), function (props) { return (props.gridMode ? '0' : '20px'); }, function (props) { return (props.gridMode ? 'center' : 'left'); });
export var PendingQuantity = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: lighter;\n  font-size: 0.8rem;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  background-color: ", ";\n  color: ", ";\n  padding: 1px 7px;\n  border-radius: 10px;\n"], ["\n  font-weight: lighter;\n  font-size: 0.8rem;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  background-color: ", ";\n  color: ", ";\n  padding: 1px 7px;\n  border-radius: 10px;\n"])), function (props) { return (props.gridMode ? '5px' : '20px'); }, function (props) { return (props.gridMode ? '5px' : 'auto'); }, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
});
export var ImgContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 35px;\n  width: 35px;\n\n  svg {\n    color: ", ";\n    height: 35px;\n    width: 35px;\n  }\n"], ["\n  height: 35px;\n  width: 35px;\n\n  svg {\n    color: ", ";\n    height: 35px;\n    width: 35px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
});
export var ImgMenuItem = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
export var BlurOverlay = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 50%;\n  min-height: 50px;\n  backdrop-filter: blur(5px);\n  border-top: 1px solid white;\n  border-radius: 0 0 30px 30px;\n"], ["\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 50%;\n  min-height: 50px;\n  backdrop-filter: blur(5px);\n  border-top: 1px solid white;\n  border-radius: 0 0 30px 30px;\n"])));
export var ButtonSection = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: end;\n"], ["\n  display: flex;\n  justify-content: end;\n"])));
export var RoundedButton = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  padding-right: 10px;\n  padding-left: 10px;\n  font-size: 1.2rem;\n  border-radius: 50px;\n  background-color: #fff7d9;\n  font-color: #9b3800;\n  border: 1px solid #ffbb8a;\n  width: auto;\n  heigth: auto;\n  margin-top: 7px;\n  margin-right: 15px;\n  cursor: pointer;\n"], ["\n  display: flex;\n  flex-direction: row;\n  padding-right: 10px;\n  padding-left: 10px;\n  font-size: 1.2rem;\n  border-radius: 50px;\n  background-color: #fff7d9;\n  font-color: #9b3800;\n  border: 1px solid #ffbb8a;\n  width: auto;\n  heigth: auto;\n  margin-top: 7px;\n  margin-right: 15px;\n  cursor: pointer;\n"])));
export var Circle = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  border-radius: 50%;\n  width: 35px;\n  height: 30px;\n  padding: 6px;\n  margin-right: 16px;\n  background: ", ";\n  color: #ffffff;\n  text-align: center;\n  font: 16px Arial, sans-serif;\n"], ["\n  border-radius: 50%;\n  width: 35px;\n  height: 30px;\n  padding: 6px;\n  margin-right: 16px;\n  background: ", ";\n  color: #ffffff;\n  text-align: center;\n  font: 16px Arial, sans-serif;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ContainerCard = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n\n  ", ";\n"], ["\n  width: 100%;\n\n  ",
    ";\n"])), function (props) {
    return props.gridMode && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      width: 33.3%;\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      margin: 0;\n    "], ["\n      width: 33.3%;\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      margin: 0;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
