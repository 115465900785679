var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { H5, StyledContainer } from '@atomic';
export var GroupSection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & :first-child {\n    padding-top: 0;\n  }\n"], ["\n  & :first-child {\n    padding-top: 0;\n  }\n"])));
export var GroupTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  width: 100%;\n  span {\n    font-size: 23px;\n    color: ", ";\n    font-weight: bold;\n  }\n"], ["\n  text-align: center;\n  width: 100%;\n  span {\n    font-size: 23px;\n    color: ", ";\n    font-weight: bold;\n  }\n"])), function (props) { return props.color; });
export var DottedLine = styled(DottedSeparator)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 460px;\n  margin-left: 10px;\n  @media screen and (max-width: 480px) {\n    & {\n      width: 90%;\n      margin-left: 5%;\n    }\n  }\n"], ["\n  width: 460px;\n  margin-left: 10px;\n  @media screen and (max-width: 480px) {\n    & {\n      width: 90%;\n      margin-left: 5%;\n    }\n  }\n"])));
export var Conteudo = styled(H5)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: center;\n  opacity: 0.7;\n  font-size: 18px;\n"], ["\n  text-align: center;\n  opacity: 0.7;\n  font-size: 18px;\n"])));
export var BtnsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
export var ImageWrapper = styled(StyledContainer)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  img {\n    max-width: 50%;\n  }\n"], ["\n  img {\n    max-width: 50%;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
