import React from 'react';
import { useHistory } from 'react-router-dom';
import entCoinsIcon from '@assets/icons/ENTCoins.svg';
import { FaIcon } from '@atomic';
import { CoinBox, CoinIcon, Header, ReturnButton, ReturnButtonBox } from './store-header.styled';
export var StoreHeader = function (_a) {
    var coins = _a.coins;
    var history = useHistory();
    return (React.createElement(Header, null,
        React.createElement(ReturnButtonBox, null,
            React.createElement(ReturnButton, { onClick: function () { return history.goBack(); } },
                React.createElement(FaIcon.ArrowLeft, { style: { color: 'white' } }))),
        React.createElement("h1", null, "Loja"),
        React.createElement(CoinBox, null,
            React.createElement("div", null,
                React.createElement(CoinIcon, { src: entCoinsIcon }),
                React.createElement("span", null, coins)))));
};
