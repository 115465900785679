import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { monitorLocation } from '@app/services/location';
export var LocationMonitor = function () {
    var userData = useStore(useUserStore).userData;
    var location = useLocation();
    useEffect(function () {
        monitorLocation(userData.usuarioId);
    }, [location, userData.usuarioId]);
    return React.createElement(React.Fragment, null);
};
