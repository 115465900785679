var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { DottedSeparatorWithMargin } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { QuizContext } from '@app/providers/quiz.store';
import { FlashMessageEnum, H4, StyledContainer } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Row, Col } from '@atomic/obj.grid';
import { Label } from './report-error.page.styled';
var initialData = {
    descricaoOcorrencia: '',
    url: '',
};
var schema = yup.object({
    descricaoOcorrencia: yup.string().required('O campo Descricao Ocorrência é obrigatório'),
});
export var ReportErrorPage = function () {
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var currentCourse = useStore('currentCourse')[0];
    var usuario = useStore('usuario')[0];
    var _a = React.useContext(QuizContext), questions = _a[0], currentQuizzIndex = _a[1];
    var lastUrlLocation = useStore('lastUrlLocation')[0];
    var errorReportPostURL = 'usuario/informativoerro';
    var _b = useMutation(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = __assign(__assign({}, formValues), { descricaoOcorrencia: JSON.stringify({
                            userText: formValues.descricaoOcorrencia,
                            url: lastUrlLocation,
                            course: currentCourse,
                            usuario: usuario,
                            questions: questions,
                            currentQuizzIndex: currentQuizzIndex,
                        }) });
                    return [4 /*yield*/, axiosClient.post(errorReportPostURL, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Notificação de erro registrada com sucesso.');
            history.goBack();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao registrar a notificação de erro.');
        },
    }), mutate = _b.mutate, isLoading = _b.isLoading;
    var handleSubmit = function (formValues) {
        mutate(formValues);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { withBackButton: true }),
        React.createElement(StyledContainer, { withHPadding: true },
            React.createElement(H4, { style: { textAlign: 'center' } }, "Use este espa\u00E7o para nos contar sobre o problema que voc\u00EA est\u00E1 tendo."),
            React.createElement(Formik, { initialValues: initialData, enableReinitialize: true, validationSchema: schema, onSubmit: handleSubmit },
                React.createElement(Form, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                            React.createElement(Label, null, "Descri\u00E7\u00E3o do erro:")),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Field, { type: "text", name: "descricaoOcorrencia", className: "theme-color-input", style: { width: '100%' } }),
                            React.createElement(ErrorMessage, { name: "descricaoOcorrencia" }))),
                    React.createElement(DottedSeparatorWithMargin, null),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Button, { type: "submit", loading: isLoading, expanded: true }, "Registrar"))))))));
};
