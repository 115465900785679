import * as React from 'react';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';
import { ButtonAndamento, ButtonBox, Circle, MoneyIconBox, Points, QuizWinContainer, QuizWinContent, RewardIconBox, TextBox, } from '@app/modules/quiz/components/quiz-win/quiz-win.styled';
import { QuestionnaireContext } from '@app/providers/questionnaire.store';
import { AppPath } from '@app/routes';
import ENTCoins from '@assets/icons/ENTCoins.svg';
import happy from '@assets/img/happy.png';
import { FontFamily } from '@atomic';
export var QuestionnaireCompletedPage = function (props) {
    var _a;
    var history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _b = React.useContext(QuestionnaireContext), questionnaire = _b[0], _ = _b[1];
    return (React.createElement(QuizWinContainer, { Background: true },
        React.createElement(QuizWinContent, null,
            React.createElement("div", null,
                (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.valePontos) && (React.createElement("div", { style: { display: 'flex', marginBottom: '15px' } },
                    React.createElement(MoneyIconBox, null,
                        React.createElement("img", { src: ENTCoins, alt: "moeda" })),
                    React.createElement(Points, { style: { fontFamily: FontFamily.Secondary, marginLeft: '15px' } },
                        "+", (_a = props.location.state) === null || _a === void 0 ? void 0 :
                        _a.coins))),
                React.createElement(RewardIconBox, null,
                    React.createElement(Circle, { Background: true },
                        React.createElement("img", { src: happy }))))),
        React.createElement(TextBox, { style: { fontFamily: FontFamily.Primary } },
            React.createElement("h2", null, "Obrigado por participar!")),
        React.createElement(ButtonBox, null,
            React.createElement("div", null,
                React.createElement(ButtonAndamento, { Background: true, onClick: function () { return history.push({ pathname: AppPath.Map }); } },
                    "Voltar para o Menu",
                    React.createElement(Countdown, { date: Date.now() + 10000, renderer: function (_a) {
                            var seconds = _a.seconds;
                            return React.createElement("span", null,
                                " (",
                                seconds,
                                "s)");
                        }, onComplete: function () { return history.push({ pathname: AppPath.Map }); } }))))));
};
