import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { ImagePreview } from '@app/modules/quiz/components/quiz/quiz.styled';
import { Button, FaIcon } from '@atomic';
import { ActionPlanLabel, CompliantLabel, ItemButtonsWrapper, LateLabel, NonCompliantLabel, PendingLabel, Question, RightAnswer, } from './checklist-item.styled';
export var CHECK_STATUS;
(function (CHECK_STATUS) {
    CHECK_STATUS["CONFORME"] = "CONFORME";
    CHECK_STATUS["ATRASADO"] = "ATRASADO";
    CHECK_STATUS["PENDENTE"] = "PENDENTE";
    CHECK_STATUS["NAO_CONFORME"] = "NAO_CONFORME";
})(CHECK_STATUS || (CHECK_STATUS = {}));
var getPlanoAcaoStatusLabel = function (resposta) {
    switch (resposta === null || resposta === void 0 ? void 0 : resposta.planoAcaoStatus) {
        case CHECK_STATUS.PENDENTE:
            return (React.createElement(PendingLabel, null,
                React.createElement("span", null, "Pendente")));
        case CHECK_STATUS.ATRASADO:
            return (React.createElement(LateLabel, null,
                React.createElement("span", null, "Atrasado")));
        case CHECK_STATUS.CONFORME:
            return (React.createElement(CompliantLabel, null,
                React.createElement("span", null, "Conforme")));
        case CHECK_STATUS.NAO_CONFORME:
            return (React.createElement(NonCompliantLabel, null,
                React.createElement("span", null, "N\u00E3o Conforme")));
    }
};
export var ChecklistDetailsItem = function (_a) {
    var _b;
    var pergunta = _a.pergunta, respostaCerta = _a.respostaCerta, isEfetivo = _a.isEfetivo, _c = _a.actionPlanId, actionPlanId = _c === void 0 ? null : _c, urlFotoResposta = _a.urlFotoResposta, urlFotoRespostaList = _a.urlFotoRespostaList, respostaItem = _a.respostaItem;
    return (React.createElement(React.Fragment, null,
        React.createElement(Question, null, respostaItem === null || respostaItem === void 0 ? void 0 : respostaItem.checklistUnidadeItem.titulo),
        respostaCerta && React.createElement(RightAnswer, null, respostaCerta),
        React.createElement(ItemButtonsWrapper, null,
            React.createElement(Button, { disabled: true, variant: "neutral", style: {
                    backgroundColor: !isEfetivo ? 'red' : '#00000029',
                    minWidth: '120px',
                    minHeight: '37px',
                    textAlign: 'center',
                    borderColor: 'transparent',
                } },
                React.createElement(FaIcon.Close, { style: { paddingLeft: '10px', color: 'white' } })),
            React.createElement(Button, { disabled: true, variant: "neutral", style: {
                    backgroundColor: isEfetivo ? '#11C728' : '#00000029',
                    minWidth: '120px',
                    minHeight: '37px',
                    textAlign: 'center',
                    borderColor: 'transparent',
                } },
                React.createElement(FaIcon.Check, { style: { paddingLeft: '10px', color: 'white' } }))),
        (urlFotoRespostaList === null || urlFotoRespostaList === void 0 ? void 0 : urlFotoRespostaList.length) > 0 && (React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement("h4", { style: { textAlign: 'center' } }, "Fotos Enviada"), urlFotoRespostaList === null || urlFotoRespostaList === void 0 ? void 0 :
                urlFotoRespostaList.map(function (url, idx) {
                    return (React.createElement(ImagePreview, { key: idx },
                        React.createElement("img", { src: url })));
                })))),
        !!((_b = respostaItem === null || respostaItem === void 0 ? void 0 : respostaItem.planoAcaoChecklistUnidade) === null || _b === void 0 ? void 0 : _b.id) && (React.createElement("div", { style: {
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '8px',
                alignItems: 'center',
                justifyContent: 'center',
            } },
            React.createElement(ActionPlanLabel, null,
                React.createElement(FaIcon.Check, { style: { color: '#008425', marginLeft: '5px' } }),
                React.createElement("span", null, "Plano de A\u00E7\u00E3o")),
            getPlanoAcaoStatusLabel(respostaItem))),
        React.createElement(DottedSeparator, { style: { marginBottom: '1.2rem', marginTop: '1.2rem' } })));
};
