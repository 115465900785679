var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var HelpMenuItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-top: 20px;\n  padding-bottom: 20px;\n  border-bottom: 4px dotted #cdcdcd;\n  cursor: pointer;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-top: 20px;\n  padding-bottom: 20px;\n  border-bottom: 4px dotted #cdcdcd;\n  cursor: pointer;\n"])));
export var ItemIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n\n  div {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    border: 4px solid ", ";\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      font-size: 1.6rem;\n      color: #000000bd;\n    }\n  }\n\n  img {\n    width: 25px;\n    height: 25px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n\n  div {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    border: 4px solid ", ";\n    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n    display: -ms-flexbox; /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Chrome */\n    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      font-size: 1.6rem;\n      color: #000000bd;\n    }\n  }\n\n  img {\n    width: 25px;\n    height: 25px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ItemText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 20px;\n  text-transform: uppercase;\n  width: calc(100% - 130px);\n  h3 {\n    margin: 0;\n  }\n  p {\n    margin: 0;\n    font-size: 14px;\n  }\n"], ["\n  margin-left: 20px;\n  text-transform: uppercase;\n  width: calc(100% - 130px);\n  h3 {\n    margin: 0;\n  }\n  p {\n    margin: 0;\n    font-size: 14px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
