var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color } from '@atomic';
export var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding: 0 0 1.6rem 0;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding: 0 0 1.6rem 0;\n"])));
export var ResponseFooter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  H4 {\n    color: ", ";\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  svg {\n    fontsize: '1.5rem';\n    marginright: '0.4rem';\n    color: ", ";\n  }\n\n  Button {\n    background: ", ";\n    bordercolor: ", ";\n  }\n"], ["\n  H4 {\n    color: ", ";\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  svg {\n    fontsize: '1.5rem';\n    marginright: '0.4rem';\n    color: ", ";\n  }\n\n  Button {\n    background: ", ";\n    bordercolor: ", ";\n  }\n"])), function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
}, function (_a) {
    var rightAnswer = _a.rightAnswer;
    return (rightAnswer ? Color.RightAnswerGreenText : Color.WrongAnswerRedText);
});
var templateObject_1, templateObject_2;
