var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Container from 'typedi';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { FlexCol, FlexRow } from '@app/modules/submenus/components/submenu-content-card/styles';
import { AppPath } from '@app/routes';
import avatarIcon from '@assets/img/avatar.png';
import playNewIcon from '@assets/img/video-icon-new.svg';
import { Button, FaIcon, FlashMessageEnum, FontFamily } from '@atomic';
import { Modal } from '@atomic/obj.modal';
import docsNewIcon from '../../../assets/img/new-doc-icon.svg';
import { VideoPlayer } from '../atm.video-player';
import { ModalPdf } from '../modal-pdf';
import { GameInfo } from '../obj.game-info/game-info.component';
import { ButtonChevron, CourseName, LeftContent, RightContent, TrackHeaderContent, UserImage } from './styles';
var userUrl = 'usuario';
var GET_RANKING_URL = 'ranking';
export var TrackHeader = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var noTitle = _a.noTitle;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var loadedCourse = useStore('currentCourse')[0];
    var authStorageService = Container.get(AuthStorageService);
    var _k = React.useState(false), isVideoPlayerModalOpen = _k[0], setIsVideoPlayerModalOpen = _k[1];
    var _l = React.useState(false), openTop = _l[0], setOpenTop = _l[1];
    var _m = useState(false), isModalOpen = _m[0], setIsModalOpen = _m[1];
    var _o = useState(''), modalLink = _o[0], setModalLink = _o[1];
    var openModal = function (link) {
        setModalLink(link);
        setIsModalOpen(true);
    };
    var closeModal = function () {
        setModalLink('');
        setIsModalOpen(false);
    };
    var loggedUser = authStorageService === null || authStorageService === void 0 ? void 0 : authStorageService.getUser();
    var user = useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(userUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnWindowFocus: false }).data;
    var getDeadlineText = function () {
        var timeLeft;
        if (!!(loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.prazo)) {
            var deadline = new Date(loadedCourse.prazo);
            timeLeft = ((deadline.getTime() - Date.now()) / (60 * 60 * 24 * 1000)) % 365;
            timeLeft = Math.round(timeLeft);
            if (timeLeft > 1) {
                return "Prazo: " + timeLeft + " dias restantes";
            }
            else if (timeLeft == 1) {
                return "Prazo: \u00DAltimo dia";
            }
            else if (timeLeft <= 0) {
                return "Voc\u00EA est\u00E1 fora do prazo";
            }
        }
        else {
            return '';
        }
    };
    var ranking = useQuery('track-ranking', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_RANKING_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, error_2 === null || error_2 === void 0 ? void 0 : error_2.stack);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var handleOnSignOut = function () {
        Swal.fire({
            title: 'Deseja realmente sair?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#ef5350',
            cancelButtonColor: '#757575',
            denyButtonColor: '#757575',
        }).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (result.isConfirmed) {
                    authStorageService.logout();
                }
                return [2 /*return*/];
            });
        }); });
    };
    var userRank = (_b = ranking === null || ranking === void 0 ? void 0 : ranking.data) === null || _b === void 0 ? void 0 : _b.find(function (rankingEntry) { return loggedUser.usuarioId === rankingEntry.id; });
    return (React.createElement(TrackHeaderContent, null,
        React.createElement(FlexCol, { style: { width: '100%' } },
            React.createElement(FlexRow, { style: { padding: '0px 16px 16px 16px', justifyContent: 'space-between', height: 'fit-content' } },
                openTop && (React.createElement(LeftContent, null,
                    React.createElement(UserImage, { src: ((_c = user === null || user === void 0 ? void 0 : user.data) === null || _c === void 0 ? void 0 : _c.imagem) || avatarIcon, alt: "user", onClick: function () { return history.push(AppPath.PlayerProfile); } }),
                    React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '14px', color: '#49443C' }, variant: "h2", component: "div" }, (_d = user === null || user === void 0 ? void 0 : user.data) === null || _d === void 0 ? void 0 : _d.nome))),
                React.createElement(RightContent, null,
                    React.createElement(GameInfo, { open: openTop, userRank: userRank, qtdeEntCoins: (_e = user === null || user === void 0 ? void 0 : user.data) === null || _e === void 0 ? void 0 : _e.moedas, qtdePontos: (_f = user === null || user === void 0 ? void 0 : user.data) === null || _f === void 0 ? void 0 : _f.pontos, qtdeEnergias: (_g = user === null || user === void 0 ? void 0 : user.data) === null || _g === void 0 ? void 0 : _g.energias })),
                React.createElement(ButtonChevron, { onClick: function () { return setOpenTop(!openTop); } }, openTop ? React.createElement(FaIcon.ChevronDown, { fontSize: 16 }) : React.createElement(FaIcon.ChevronUp, null))),
            React.createElement(CourseName, null, !noTitle ? (React.createElement(React.Fragment, null,
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '20px', color: 'rgba(0, 0, 0, 0.88)' }, variant: "h2", component: "div" }, loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.nome),
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '14px', color: 'rgba(0, 0, 0, 0.88)' }, variant: "h2", component: "div" }, (loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.concluida) ? 'Você já finalizou este curso!' : getDeadlineText()),
                ((_h = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.video) === null || _h === void 0 ? void 0 : _h.url) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { expanded: true, variant: "link", onClick: function () { return setIsVideoPlayerModalOpen(!isVideoPlayerModalOpen); } },
                        React.createElement("img", { style: { width: '35px', height: '35px' }, src: playNewIcon, alt: "playIcon" })),
                    React.createElement(Modal, { opened: isVideoPlayerModalOpen, onClose: function () { return setIsVideoPlayerModalOpen(false); } },
                        React.createElement(VideoPlayer, { videoUrl: loadedCourse.video.url })))),
                ((_j = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.arquivo) === null || _j === void 0 ? void 0 : _j.url) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { expanded: true, variant: "link", target: '_blank', onClick: function () { var _a; return openModal((_a = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.arquivo) === null || _a === void 0 ? void 0 : _a.url); } },
                        React.createElement("img", { style: { width: '35px', height: '35px' }, src: docsNewIcon, alt: "docsNewIcon" })))))) : null)),
        isModalOpen && React.createElement(ModalPdf, { link: modalLink, onClose: closeModal })));
};
