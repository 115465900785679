var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { LoginFormPlaceholders } from '@app/modules/authentication/authentication.form-data';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Clickable } from '@atomic/obj.clickable';
import { PasswordFieldToggleStyled, PasswordHintsListStyled, PasswordHintsItemStyled, } from './password-field.component.style';
export var PasswordFieldV2 = function (props) {
    var _a = useState(true), hidden = _a[0], setHidden = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, __assign({}, props, { placeholder: LoginFormPlaceholders.Password, autoComplete: "current-password", type: hidden ? 'password' : 'normal', addon: React.createElement(PasswordAddon, { setHidden: setHidden, hidden: hidden }), style: { borderRadius: 15 } })),
        props.enableHints && (React.createElement(PasswordHintsListStyled, null,
            React.createElement(PasswordHintsItemStyled, null, "M\u00EDnimo de 6 caracteres"),
            React.createElement(PasswordHintsItemStyled, null, "Deve conter pelo menos 1 n\u00FAmero e 1 letra")))));
};
var PasswordAddon = function (_a) {
    var setHidden = _a.setHidden, hidden = _a.hidden;
    return (React.createElement(Clickable, { onClick: function () { return setHidden(!hidden); }, title: hidden ? 'Mostrar senha' : 'Esconder senha' },
        React.createElement(PasswordFieldToggleStyled, null, hidden ? React.createElement(FaIcon.Eye, { className: 'no-invert-colors' }) : React.createElement(FaIcon.EyeClosed, { className: 'no-invert-colors' }))));
};
