import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { ModalPdf } from '@app/components/modal-pdf';
import { ImageFromUser, NewIcon, NonClicableIcon } from '@app/components/mol.track-header/styles';
import { FlexCol, FlexRow } from '@app/modules/submenus/components/submenu-content-card/styles';
import playNewIcon from '@assets/img/video-icon-new.svg';
import { FontFamily } from '@atomic';
import lockNewIcon from '../../../../../assets/img/lock-icon-new.svg';
import checkNewIcon from '../../../../../assets/img/new-check-icon.svg';
import docsNewIcon from '../../../../../assets/img/new-doc-icon.svg';
import { InnerContent, LabelBox, PhaseCircleWrapper, PhaseWrapper } from './track-phase.styled';
export var TrackPhase = function (_a) {
    var _b;
    var phase = _a.phase, handleOnPlayVideo = _a.handleOnPlayVideo, handleOnClick = _a.handleOnClick, icone = _a.icone, phaseNumber = _a.phaseNumber;
    var isLocked = !phase.tarefas.find(function (t) { return !t.bloqueada; });
    var isCompleted = phase.concluida;
    var hasVideo = !!((_b = phase.video) === null || _b === void 0 ? void 0 : _b.url);
    var hasArquivo = !!phase.arquivo;
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(''), modalLink = _d[0], setModalLink = _d[1];
    var openModal = function (link) {
        setModalLink(link);
        setIsModalOpen(true);
    };
    var closeModal = function () {
        setModalLink('');
        setIsModalOpen(false);
    };
    var PhaseImageIcon = function () { return (React.createElement("div", { style: { marginTop: '20px' } },
        React.createElement(FlexRow, null,
            React.createElement(FlexCol, null,
                isLocked && React.createElement(NonClicableIcon, { src: lockNewIcon, alt: "lock-icon" }),
                isCompleted && React.createElement(NonClicableIcon, { src: checkNewIcon, alt: "check-icon" })),
            React.createElement(ImageFromUser, { src: icone === null || icone === void 0 ? void 0 : icone.link }),
            React.createElement(FlexCol, null,
                hasArquivo && !isLocked && (React.createElement(NewIcon, { src: docsNewIcon, alt: "docsNewIcon", onClick: function () { var _a; return openModal((_a = phase.arquivo) === null || _a === void 0 ? void 0 : _a.url); } })),
                hasVideo && !isLocked && (React.createElement(NewIcon, { src: playNewIcon, alt: "playIcon", onClick: !isLocked ? function () { var _a; return handleOnPlayVideo((_a = phase.video) === null || _a === void 0 ? void 0 : _a.url); } : undefined })))),
        isModalOpen && React.createElement(ModalPdf, { link: modalLink, onClose: closeModal }))); };
    var PhaseCircle = function () { return (React.createElement(React.Fragment, null,
        React.createElement(FlexRow, null,
            React.createElement(FlexCol, { style: { marginRight: '-24px', justifyContent: 'space-between' } },
                isLocked && React.createElement(NonClicableIcon, { src: lockNewIcon, alt: "lock-icon" }),
                isCompleted && React.createElement(NonClicableIcon, { src: checkNewIcon, alt: "check-icon" })),
            React.createElement(PhaseCircleWrapper, { isLocked: isLocked },
                React.createElement(InnerContent, null,
                    React.createElement("span", { style: { textAlign: 'center' } },
                        React.createElement(React.Fragment, null,
                            "F.",
                            phaseNumber)))),
            React.createElement(FlexCol, { style: { width: '43px', marginLeft: '-24px', justifyContent: 'space-between' } },
                hasArquivo && !isLocked && (React.createElement(NewIcon, { src: docsNewIcon, alt: "docsNewIcon", onClick: function () { var _a; return openModal((_a = phase.arquivo) === null || _a === void 0 ? void 0 : _a.url); } })),
                hasVideo && !isLocked && (React.createElement(NewIcon, { src: playNewIcon, alt: "playIcon", onClick: !isLocked ? function () { var _a; return handleOnPlayVideo((_a = phase.video) === null || _a === void 0 ? void 0 : _a.url); } : undefined })))),
        isModalOpen && React.createElement(ModalPdf, { link: modalLink, onClose: closeModal }))); };
    return (React.createElement(PhaseWrapper, { onClick: handleOnClick },
        (icone === null || icone === void 0 ? void 0 : icone.isFase) ? React.createElement(PhaseImageIcon, null) : React.createElement(PhaseCircle, null),
        React.createElement(LabelBox, { className: isLocked && 'tw-opacity-50' },
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '16px', color: 'rgba(73, 68, 60, 1)' }, variant: "h2", component: "div" }, phase.titulo))));
};
