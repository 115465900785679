var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { ImpossibilidadeResposta } from '@app/modules/routine-checklist/impossibilidade-resposta';
import { FlexRow } from '@app/modules/routine-checklist/quick-checklist/styles';
import { ActivityIndicator, FlashMessageEnum } from '@atomic';
import { ChecklistCard, CheckTypes } from '../checklist-card';
import { LoadMoreInfo } from './checklists-list.styled';
var isTodayOrPast = function (dateToCheck) {
    return moment(dateToCheck).isSameOrBefore(moment(), 'day');
};
var isInProgress = function (dateToCheck) {
    var diffInDays = moment(dateToCheck).diff(moment(), 'days', true);
    return Math.ceil(diffInDays) >= 1 && Math.ceil(diffInDays) <= 3;
};
export var ChecklistsList = function (_a) {
    var type = _a.type, url = _a.url, types = _a.types, urls = _a.urls, _b = _a.isExpandedByDefault, isExpandedByDefault = _b === void 0 ? false : _b, _c = _a.withCardsOpened, withCardsOpened = _c === void 0 ? false : _c;
    var showFlashMessage = useFlashMessage()[0];
    var _d = useState(isExpandedByDefault), isExpanded = _d[0], setExpanded = _d[1];
    var _e = useState([]), checklists = _e[0], setChecklists = _e[1];
    var _f = useState(4), defaultCardQuantity = _f[0], setDefaultCardQuantity = _f[1];
    // const QTDE_DEFAULT_DE_CARDS_NA_LISTA = 4;
    var _g = useQuery(['get-checklists', type], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, denied, inProgress, recurrences, actionPlan, result, recurrences, actionPlan, result, pendingToday, pendingTodayUnit, pendingTodayNext, general, err_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return __generator(this, function (_v) {
            switch (_v.label) {
                case 0:
                    _v.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(url)];
                case 1:
                    response = _v.sent();
                    if (type === CheckTypes.TODAY_TYPE) {
                        denied = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.denied;
                        denied.forEach(function (item) { return (item.type = CheckTypes.DENIED_TYPE); });
                        inProgress = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.inProgress;
                        inProgress.forEach(function (item) { return (item.type = CheckTypes.PROGRESS_UNIT_TYPE); });
                        recurrences = (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.pendingRecurrences.filter(function (c) {
                            return isTodayOrPast(c.dataPrevista.substring(0, 10));
                        });
                        recurrences.forEach(function (item) { return (item.type = CheckTypes.PENDING_TODAY_UNIT_TYPE); });
                        actionPlan = (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.pending;
                        actionPlan.forEach(function (item) { return (item.type = CheckTypes.PENDING_APPROVAL_TYPE); });
                        result = __spreadArrays(denied, inProgress, recurrences, actionPlan);
                        setChecklists(result);
                        // result.push(response.data?.data?..filter((c: any) => isTodayOrPast(c.planoAcao?.dataLimiteResolucao.substring(0, 10))));
                        // PENDING_TODAY_UNIT_TYPE
                    }
                    else if (type === CheckTypes.UPCOMING_TYPE) {
                        recurrences = (_k = (_j = response.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.recurrences;
                        recurrences.forEach(function (item) { return (item.type = CheckTypes.UPCOMING_TYPE); });
                        actionPlan = (_m = (_l = response.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.pending;
                        actionPlan.forEach(function (item) { return (item.type = CheckTypes.PENDING_APPROVAL_TYPE); });
                        result = __spreadArrays(recurrences, actionPlan);
                        setChecklists(result);
                    }
                    else if (type === CheckTypes.PENDING_TODAY_COLAB_TYPE) {
                        pendingToday = (_p = (_o = response.data) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.filter(function (c) { var _a; return isTodayOrPast((_a = c.planoDeAcaoPai) === null || _a === void 0 ? void 0 : _a.dataLimiteResolucao.substring(0, 10)); });
                        pendingToday.forEach(function (item) { return (item.type = CheckTypes.PENDING_TODAY_COLAB_TYPE); });
                        setChecklists(pendingToday);
                    }
                    else if (type === CheckTypes.PENDING_TODAY_UNIT_TYPE) {
                        pendingTodayUnit = (_r = (_q = response.data) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.filter(function (c) { var _a; return isTodayOrPast((_a = c.planoAcao) === null || _a === void 0 ? void 0 : _a.dataLimiteResolucao.substring(0, 10)); });
                        pendingTodayUnit.forEach(function (item) { return (item.type = CheckTypes.PENDING_TODAY_UNIT_TYPE); });
                        setChecklists(pendingTodayUnit);
                    }
                    else if (type === CheckTypes.PENDING_NEXT3D_COLAB_TYPE) {
                        pendingTodayNext = (_t = (_s = response.data) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.filter(function (c) { var _a; return isInProgress((_a = c.planoDeAcaoPai) === null || _a === void 0 ? void 0 : _a.dataLimiteResolucao); });
                        pendingTodayNext.forEach(function (item) { return (item.type = CheckTypes.PENDING_NEXT3D_COLAB_TYPE); });
                        setChecklists(pendingTodayNext);
                    }
                    else {
                        general = (_u = response.data) === null || _u === void 0 ? void 0 : _u.data;
                        general.forEach(function (item) { return (item.type = type); });
                        setChecklists(general);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _v.sent();
                    showFlashMessage(FlashMessageEnum.alert, 'Erro ao carregar Histórico de Checklists');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: true,
    }), isLoading = _g.isLoading, isFetching = _g.isFetching, refetch = _g.refetch;
    if (type === CheckTypes.PROGRESS_UNIT_TYPE) {
        checklists === null || checklists === void 0 ? void 0 : checklists.sort(function (a, b) {
            // Certifique-se de que 'dataInicioResposta' seja uma data válida
            var dateA = new Date(a.dataInicioResposta);
            var dateB = new Date(b.dataInicioResposta);
            // Use a função 'getTime()' para comparar as datas
            return dateB.getTime() - dateA.getTime();
        });
    }
    var handleCardQuantityRender = function (height) {
        if (height < 400) {
            setDefaultCardQuantity(1);
        }
        else if (height < 500) {
            setDefaultCardQuantity(2);
        }
        else if (height < 650) {
            setDefaultCardQuantity(2);
        }
        else if (height < 780) {
            setDefaultCardQuantity(3);
        }
        else if (height < 820) {
            setDefaultCardQuantity(4);
        }
        else {
            setDefaultCardQuantity(5);
        }
    };
    useEffect(function () {
        handleCardQuantityRender(window.innerHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerHeight]);
    var _h = useState(false), openImpossibilita = _h[0], setOpenImpossibilita = _h[1];
    var _j = useState(false), impossibilitaChecklist = _j[0], setImpossibilitaChecklist = _j[1];
    var handleImpossibilitarRespostaChecklist = function (e, checklist) {
        e.stopPropagation();
        setImpossibilitaChecklist(checklist);
        setOpenImpossibilita(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ImpossibilidadeResposta, { open: openImpossibilita, onClose: function () { return setOpenImpossibilita(false); }, checklist: impossibilitaChecklist, checklistImpossibilitado: refetch }), checklists === null || checklists === void 0 ? void 0 :
        checklists.map(function (checklist, idx) {
            return (isExpanded || idx < defaultCardQuantity) && (React.createElement(ChecklistCard, { key: "" + checklist.id + idx, checklist: checklist, isFirst: idx === 0, isLast: isExpanded || (checklists === null || checklists === void 0 ? void 0 : checklists.length) < defaultCardQuantity
                    ? idx === checklists.length - 1
                    : idx === defaultCardQuantity - 1, type: checklist.type, onClickImpossibilitar: handleImpossibilitarRespostaChecklist }));
        }),
        isLoading || isFetching ? (React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))) : null,
        (checklists === null || checklists === void 0 ? void 0 : checklists.length) > defaultCardQuantity && (React.createElement(LoadMoreInfo, { onClick: function () { return setExpanded(!isExpanded); } }, isExpanded ? 'Ver menos' : 'Ver mais'))));
};
