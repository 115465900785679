var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var PendingQuantity = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: lighter;\n  font-size: 0.8rem;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  background-color: ", ";\n  color: ", ";\n  padding: 1px 7px;\n  border-radius: 10px;\n"], ["\n  font-weight: lighter;\n  font-size: 0.8rem;\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  background-color: ", ";\n  color: ", ";\n  padding: 1px 7px;\n  border-radius: 10px;\n"])), function (props) { return (props.gridMode ? '5px' : '20px'); }, function (props) { return (props.gridMode ? '5px' : 'auto'); }, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
});
export var Circle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 50%;\n  width: 35px;\n  height: 30px;\n  padding: 6px;\n  margin-right: 16px;\n  background: ", ";\n  color: #ffffff;\n  text-align: center;\n  font: 16px Arial, sans-serif;\n"], ["\n  border-radius: 50%;\n  width: 35px;\n  height: 30px;\n  padding: 6px;\n  margin-right: 16px;\n  background: ", ";\n  color: #ffffff;\n  text-align: center;\n  font: 16px Arial, sans-serif;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2;
