import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { CloseIconStyled } from '@app/modules/track/modal/modal.component.style';
import { Button } from '@atomic';
var styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
export var ModalConformidade = function (_a) {
    var open = _a.open, onClose = _a.onClose, conformidade = _a.conformidade, imagemConformidade = _a.imagemConformidade;
    return (React.createElement(Modal, { open: open, onClose: onClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
        React.createElement(Box, { sx: styleModal },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(CloseIconStyled, { onClick: onClose })),
            React.createElement(Typography, { id: "modal-modal-title", variant: "h6", component: "h2", style: { display: 'flex', justifyContent: 'center' } }, "Informa\u00E7\u00F5es Adicionais"),
            React.createElement(Typography, { id: "modal-modal-description", sx: { mt: 2 } }, conformidade),
            React.createElement("img", { src: imagemConformidade }),
            React.createElement(Button, { onClick: onClose }, "Ok"))));
};
