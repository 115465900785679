var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var CustomRadioBtnStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 50%;\n  height: 3rem;\n  width: 3rem;\n  padding: 0.8rem;\n  cursor: pointer;\n  border: 3px solid white;\n  box-shadow: 0 0 0 3pt grey;\n"], ["\n  border-radius: 50%;\n  height: 3rem;\n  width: 3rem;\n  padding: 0.8rem;\n  cursor: pointer;\n  border: 3px solid white;\n  box-shadow: 0 0 0 3pt grey;\n"])));
export var CheckedCustomRadioBtnStyled = styled(CustomRadioBtnStyled)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n"], ["\n  background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2;
