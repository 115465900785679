import React from 'react';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { FlashMessageTargetName } from '@app/providers';
import { Body } from '@atomic/atm.typography';
import { FlashMessage } from '@atomic/mol.flash-message';
import { FlashWrapperStyled } from './flash-wrapper.component.style';
export var FlashWrapper = function () {
    var _a = useFlashMessage(FlashMessageTargetName.APP), hide = _a[1], data = _a[2];
    var showFlashMessageContent = !(data === null || data === void 0 ? void 0 : data.hidden) && (data === null || data === void 0 ? void 0 : data.content);
    return (showFlashMessageContent && (React.createElement(FlashWrapperStyled, null,
        React.createElement(FlashMessage, { type: data.type, onClose: hide, dismissible: true },
            React.createElement(Body, null, data.content)))));
};
