var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
export var FlashMessageTargetName;
(function (FlashMessageTargetName) {
    FlashMessageTargetName["APP"] = "app";
})(FlashMessageTargetName || (FlashMessageTargetName = {}));
export var useFlashStore = function () {
    var _a = React.useState({}), flash = _a[0], setFlash = _a[1];
    var showMessage = function (key, type, content) {
        var newFlash = __assign({}, flash);
        newFlash[key] = { type: type, content: content, hidden: false };
        setFlash(newFlash);
    };
    var hideMessage = function (key) {
        var newFlash = __assign({}, flash);
        newFlash[key] = { hidden: true };
        setFlash(newFlash);
    };
    return { flash: flash, showMessage: showMessage, hideMessage: hideMessage };
};
