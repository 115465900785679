var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Row } from 'react-styled-flexboxgrid';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { Button, FlashMessageEnum, StyledContainer } from '@atomic';
import { Col, Label } from './routine-checklists-filters.page.styled';
var CHECKLIST_TYPE = {
    UNIDADE: 'unidade',
};
var CHECKLIST_TYPE_ROUTINE = { value: 'unidade', label: 'Unidade Diária' };
export var RoutineChecklistFiltersPage = function () {
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var userData = useStore(useUserStore).userData;
    var _a = React.useState(null), filteredCompanyUnit = _a[0], setFilteredCompanyUnit = _a[1];
    var _b = React.useState(null), filteredChecklist = _b[0], setFilteredChecklist = _b[1];
    var GET_COMPANY_UNITS_URL = "unidade-empresa/unidadeEmpresa-checklist-tipo/" + CHECKLIST_TYPE_ROUTINE.value;
    var GET_ROUTINE_CHECKLISTS_URL = "checklistunidade/unidade/" + (filteredCompanyUnit === null || filteredCompanyUnit === void 0 ? void 0 : filteredCompanyUnit.value);
    var customStyles = {
        option: function (provided, state) { return (__assign(__assign({}, provided), { color: state.label.includes('Quinzenal') ? 'red' : 'black' })); },
    };
    var formatarOptionComQuantidade = function (item) {
        return {
            label: item.nome + " (" + item.quantidade + ")",
            value: item.id,
        };
    };
    var unidadesQueryKey = userData.id + "-getUnidades-" + (CHECKLIST_TYPE_ROUTINE === null || CHECKLIST_TYPE_ROUTINE === void 0 ? void 0 : CHECKLIST_TYPE_ROUTINE.value);
    var companyUnits = useQuery(unidadesQueryKey, function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_COMPANY_UNITS_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data.filter(function (u) { return u.quantidade > 0; }).map(formatarOptionComQuantidade)];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        enabled: !!CHECKLIST_TYPE_ROUTINE,
    }).data;
    var routinesQueryKey = userData.id + "-getRoutineChecklists-" + (filteredCompanyUnit === null || filteredCompanyUnit === void 0 ? void 0 : filteredCompanyUnit.value);
    var routineChecklistsOptions = useQuery(routinesQueryKey, function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_ROUTINE_CHECKLISTS_URL)];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                            var label = item.tipoRecorrencia !== null
                                ? item.titulo + " - " + item.tipoRecorrencia
                                : item.titulo + " - Desconhecida";
                            return {
                                label: label,
                                value: item.id,
                            };
                        })];
                case 2:
                    error_2 = _c.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_2 === null || error_2 === void 0 ? void 0 : error_2.stack) || error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        enabled: (CHECKLIST_TYPE_ROUTINE === null || CHECKLIST_TYPE_ROUTINE === void 0 ? void 0 : CHECKLIST_TYPE_ROUTINE.value) == CHECKLIST_TYPE.UNIDADE && !!(filteredCompanyUnit === null || filteredCompanyUnit === void 0 ? void 0 : filteredCompanyUnit.value),
    }).data;
    var handleOnUnidadeChange = function (value) {
        // localStorage.setItem('companyUnitUserSelect', JSON.stringify(value));
        setFilteredCompanyUnit(value);
        setFilteredChecklist(null);
    };
    var isDisabledBtn = !((CHECKLIST_TYPE_ROUTINE === null || CHECKLIST_TYPE_ROUTINE === void 0 ? void 0 : CHECKLIST_TYPE_ROUTINE.value) == CHECKLIST_TYPE.UNIDADE && filteredChecklist);
    var handleOnSubmitClick = function () {
        history.push({
            pathname: AppPath.NewUnitChecklist,
            state: {
                idChecklist: filteredChecklist.value,
                unidadeEmpresaId: filteredCompanyUnit === null || filteredCompanyUnit === void 0 ? void 0 : filteredCompanyUnit.value,
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors" },
            React.createElement(Header, { withBackButton: true, title: "Filtros - Checklist" })),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(Row, null,
                CHECKLIST_TYPE_ROUTINE && (React.createElement(Col, { xs: 12 },
                    React.createElement(Label, null, "Unidade + Qtde de Checklists"),
                    React.createElement(Select, { value: filteredCompanyUnit, onChange: handleOnUnidadeChange, options: companyUnits }))),
                (CHECKLIST_TYPE_ROUTINE === null || CHECKLIST_TYPE_ROUTINE === void 0 ? void 0 : CHECKLIST_TYPE_ROUTINE.value) == CHECKLIST_TYPE.UNIDADE && !!filteredCompanyUnit && (React.createElement(Col, { xs: 12 },
                    React.createElement(Label, null, "Checklist"),
                    React.createElement(Select, { value: filteredChecklist, onChange: setFilteredChecklist, options: routineChecklistsOptions, styles: customStyles })))),
            React.createElement(StyledContainer, { alignCenter: true },
                React.createElement(Button, { onClick: handleOnSubmitClick, disabled: isDisabledBtn }, "CHECAR")),
            React.createElement(DottedSeparator, { className: "tw-mt-5" }))));
};
