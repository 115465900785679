import * as React from 'react';
import { Card, CardContent, ContainerCard, ImgContainer, Title } from './module-card.component.styled';
export var ModuleCard = function (props) {
    return (React.createElement(ContainerCard, { gridMode: props.gridMode },
        React.createElement(Card, { onClick: function (e) { return props.onClick(e); }, gridMode: props.gridMode },
            React.createElement(ImgContainer, null, props.iconComponent),
            React.createElement(CardContent, { gridMode: props.gridMode },
                React.createElement(Title, { gridMode: props.gridMode }, props.title)),
            props.children)));
};
