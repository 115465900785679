var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var StarBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n"])));
export var LabelBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  min-height: 2.3rem;\n  text-align: center;\n  padding: 10px 25px;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  background: #fff;\n  transition: all 2s ease-out;\n  margin-top: 20px;\n\n  span {\n    color: ", " !important;\n  }\n"], ["\n  position: absolute;\n  min-height: 2.3rem;\n  text-align: center;\n  padding: 10px 25px;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  background: #fff;\n  transition: all 2s ease-out;\n  margin-top: 20px;\n\n  span {\n    color: ", " !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-left: ", ";\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  padding-left: ", ";\n"])), function (_a) {
    var isSpaced = _a.isSpaced;
    return (isSpaced ? '20px' : 'none');
});
export var RankingStarsBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: right;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
