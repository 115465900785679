import * as React from 'react';
import { useStore } from 'react-context-hook';
import useSound from 'use-sound';
import handBalance from '../../../assets/img/hand-balance.png';
import handCoinReverse from '../../../assets/img/hand-coin-reverse.png';
import { NotificationContainer, MessageNotification, NotificationContainerFail, MessageNotificationFail, } from './styles';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
// eslint-disable-next-line @typescript-eslint/no-var-requires
var wrongAnswerSound = require('@assets/sounds/wrong-answer-sound.mp3');
export var NotificationDrawer = function (_a) {
    var show = _a.show, _b = _a.isSuccess, isSuccess = _b === void 0 ? true : _b, successTitle = _a.successTitle, successMessage = _a.successMessage, failTitle = _a.failTitle, failMessage = _a.failMessage;
    var isMute = useStore('mute')[0];
    var playRightAnswerSound = useSound(rightAnswerSound)[0];
    var playWrongAnswerSound = useSound(wrongAnswerSound, { volume: 0.5 })[0];
    if (!isMute && show) {
        if (isSuccess) {
            playRightAnswerSound();
        }
        else {
            playWrongAnswerSound();
        }
    }
    return (React.createElement(React.Fragment, null, isSuccess ? (React.createElement(NotificationContainer, { show: show },
        React.createElement(MessageNotification, null,
            React.createElement("span", null, successTitle),
            React.createElement("span", null, successMessage)),
        React.createElement("img", { src: handCoinReverse }))) : (React.createElement(NotificationContainerFail, { show: show },
        React.createElement(MessageNotificationFail, null,
            React.createElement("span", null, failTitle),
            React.createElement("span", null, failMessage)),
        React.createElement("img", { src: handBalance })))));
};
