var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    display: none;\n  }\n  label {\n    padding: 5px;\n    font-size: 14px;\n    cursor: pointer;\n  }\n  button {\n    background: none;\n    border: none;\n    color: #fff;\n    font-size: 14px;\n    padding: 5px;\n    cursor: pointer;\n  }\n"], ["\n  input {\n    display: none;\n  }\n  label {\n    padding: 5px;\n    font-size: 14px;\n    cursor: pointer;\n  }\n  button {\n    background: none;\n    border: none;\n    color: #fff;\n    font-size: 14px;\n    padding: 5px;\n    cursor: pointer;\n  }\n"])));
export var Option = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 5px;\n"], ["\n  padding: 5px;\n"])));
export var FormOption = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 5px;\n  border-bottom: 1px solid #fff;\n"], ["\n  padding: 5px;\n  border-bottom: 1px solid #fff;\n"])));
export var ModalContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 30px;\n  z-index: 1;\n  position: absolute;\n  background: ", ";\n  color: #fff;\n  width: 150px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n  -webkit-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n  -moz-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n"], ["\n  margin-left: 30px;\n  z-index: 1;\n  position: absolute;\n  background: ", ";\n  color: #fff;\n  width: 150px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n  -webkit-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n  -moz-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
