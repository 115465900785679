var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color, Spacing } from '@atomic/obj.constants';
export var ModalStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  visibility: ", ";\n"], ["\n  position: absolute;\n  visibility: ", ";\n"])), function (props) { return (props.opened ? 'visible' : 'hidden'); });
var slideDown = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"], ["\n  0% {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
export var ModalOpacityStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  min-width: 100vw;\n  min-height: 100vh;\n  background-color: rgba(11, 11, 11, 0.5);\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  min-width: 100vw;\n  min-height: 100vh;\n  background-color: rgba(11, 11, 11, 0.5);\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n"])), function (props) { return (props.opened ? 'visible' : 'hidden'); });
export var ModalBoxStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  height: 500px;\n  width: 400px;\n  display: flex;\n  align-items: center;\n  padding: ", ";\n  background-color: ", ";\n  border-radius: 10px;\n  animation: ", " 0.3s ease-out;\n"], ["\n  position: fixed;\n  height: 500px;\n  width: 400px;\n  display: flex;\n  align-items: center;\n  padding: ", ";\n  background-color: ", ";\n  border-radius: 10px;\n  animation: ", " 0.3s ease-out;\n"])), Spacing.Medium, Color.White, slideDown);
export var ModalCloseStyled = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: ", ";\n  color: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: ", ";\n  color: ", ";\n"])), Spacing.Large, Color.GrayDark);
export var CloseIconStyled = styled(FaIcon.Close)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
