var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import circuloSVG from '@assets/img/circulocurso.svg';
import { FaIcon, FontWeight } from '@atomic';
export var PhaseCircleWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  /* border: 2px solid black; */\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.5s ease;\n  background-image: url(", ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  ", "\n"], ["\n  position: relative;\n  /* border: 2px solid black; */\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.5s ease;\n  background-image: url(", ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  ", "\n"])), circuloSVG, function (_a) {
    var _b = _a.isLocked, isLocked = _b === void 0 ? false : _b;
    return (isLocked ? 'opacity: 0.5' : 'opacity: 1');
});
export var InnerContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-flow: column;\n  text-align: center;\n  color: white;\n\n  span {\n    margin-bottom: 5px;\n  }\n\n  span:first-child {\n    font-size: 1rem;\n    font-weight: bold;\n  }\n\n  span:last-child {\n    font-size: 1.3rem;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-flow: column;\n  text-align: center;\n  color: white;\n\n  span {\n    margin-bottom: 5px;\n  }\n\n  span:first-child {\n    font-size: 1rem;\n    font-weight: bold;\n  }\n\n  span:last-child {\n    font-size: 1.3rem;\n  }\n"])));
export var LabelBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n  padding: 6px;\n  margin-top: 12px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  z-index: 2;\n  background: #fff;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n  padding: 6px;\n  margin-top: 12px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  z-index: 2;\n  background: #fff;\n"])));
export var Label = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1rem;\n  font-weight: ", ";\n  color: #000;\n  letter-spacing: 2px;\n  text-align: center;\n"], ["\n  font-size: 1rem;\n  font-weight: ", ";\n  color: #000;\n  letter-spacing: 2px;\n  text-align: center;\n"])), FontWeight.Bold);
export var PhaseWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin-top: 20px;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 22px;\n  position: relative;\n  margin-right: 30%;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin-top: 20px;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 22px;\n  position: relative;\n  margin-right: 30%;\n"])));
export var LockedPhaseIcon = styled(FaIcon.Lock)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: white;\n"], ["\n  color: white;\n"])));
export var VideoIcon = styled(FaIcon.Play)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: white;\n  position: relative;\n  right: -2px;\n"], ["\n  color: white;\n  position: relative;\n  right: -2px;\n"])));
export var IconCircle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  /* background: ", "; */\n  border-radius: 50px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  cursor: ", ";\n\n  svg {\n    color: white;\n  }\n\n  .locked-phase {\n    // color: green !important;\n    border-color: rgb(189, 189, 189) !important;\n  }\n"], ["\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  /* background: ", "; */\n  border-radius: 50px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  cursor: ", ";\n\n  svg {\n    color: white;\n  }\n\n  .locked-phase {\n    // color: green !important;\n    border-color: rgb(189, 189, 189) !important;\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.isLocked, isLocked = _b === void 0 ? false : _b;
    return (isLocked ? '#bdbdbd' : theme.corPrimaria);
}, function (_a) {
    var _b = _a.isLocked, isLocked = _b === void 0 ? false : _b;
    return (isLocked ? 'inherit' : 'pointer');
});
export var IconCircle2 = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  background: none;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  cursor: ", ";\n\n  .locked-phase {\n    // color: green !important;\n    border-color: rgb(189, 189, 189) !important;\n  }\n"], ["\n  position: absolute;\n  background: none;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  cursor: ", ";\n\n  .locked-phase {\n    // color: green !important;\n    border-color: rgb(189, 189, 189) !important;\n  }\n"])), function (_a) {
    var _b = _a.isLocked, isLocked = _b === void 0 ? false : _b;
    return (isLocked ? 'inherit' : 'pointer');
});
export var LeftTopIcon = styled(IconCircle)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  top: -5px;\n  left: -40px;\n"], ["\n  top: -5px;\n  left: -40px;\n"])));
export var RightTopIcon = styled(IconCircle2)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  top: -5px;\n  right: -60px;\n"], ["\n  top: -5px;\n  right: -60px;\n"])));
export var RightBottomIcon = styled(IconCircle2)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  bottom: -5px;\n  right: -40px;\n"], ["\n  bottom: -5px;\n  right: -40px;\n"])));
export var BottomIconCircle = styled(IconCircle)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  z-index: 3;\n  top: 66px;\n"], ["\n  z-index: 3;\n  top: 66px;\n"])));
export var LeftIconCircle = styled(IconCircle)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  left: -18px;\n"], ["\n  left: -18px;\n"])));
export var RightIconCircle = styled(IconCircle)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  right: -18px;\n"], ["\n  right: -18px;\n"])));
export var AproveitamentoIcon = styled.span(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: red;\n  font-size: 0.6rem !important;\n  width: 24px;\n  text-align: center;\n"], ["\n  color: red;\n  font-size: 0.6rem !important;\n  width: 24px;\n  text-align: center;\n"])));
export var IconWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  margin-left: -7px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  background: white;\n  border: ", ";\n\n  svg {\n    width: 0.9rem !important;\n  }\n"], ["\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  margin-left: -7px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  background: white;\n  border: ", ";\n\n  svg {\n    width: 0.9rem !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "2px solid " + theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
