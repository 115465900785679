var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { DottedSeparator } from '@app/components/atm.dotted-separator/dotted-separator.styled';
import { FontFamily } from '@atomic';
import closedLockIcon from 'assets/img/lock-closed.svg';
import openedLockIcon from 'assets/img/lock-open.svg';
export var OpenedLockIconStyled = styled.img.attrs(function () { return ({ src: openedLockIcon }); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.3rem;\n"], ["\n  margin-top: 0.3rem;\n"])));
export var ClosedLockIconStyled = styled.img.attrs(function () { return ({ src: closedLockIcon }); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0.3rem;\n"], ["\n  margin-top: 0.3rem;\n"])));
export var WrapperCourseList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  z-index: 30;\n  padding: 0 0.2rem 0 0.5rem;\n  @media (max-width: 380px) {\n    width: 375px;\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  z-index: 30;\n  padding: 0 0.2rem 0 0.5rem;\n  @media (max-width: 380px) {\n    width: 375px;\n  }\n"])));
export var ButtonPlay = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  height: auto;\n  float: right;\n  border-radius: 12px;\n  background-color: #fff;\n  border: 1px solid #333;\n  filter: drop-shadow(0px 1px 0.2px #afafaf);\n  transition: all 0.5s ease;\n  :hover {\n    opacity: 0.7;\n    cursor: pointer;\n    transform: scale(1.12);\n  }\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  height: auto;\n  float: right;\n  border-radius: 12px;\n  background-color: #fff;\n  border: 1px solid #333;\n  filter: drop-shadow(0px 1px 0.2px #afafaf);\n  transition: all 0.5s ease;\n  :hover {\n    opacity: 0.7;\n    cursor: pointer;\n    transform: scale(1.12);\n  }\n"])));
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  padding: 1rem 0;\n  cursor: pointer;\n  z-index: 2;\n  position: sticky;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  padding: 1rem 0;\n  cursor: pointer;\n  z-index: 2;\n  position: sticky;\n"])));
export var DottedLine = styled(DottedSeparator)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 460px;\n  margin-left: 10px;\n  @media screen and (max-width: 480px) {\n    & {\n      width: 90%;\n      margin-left: 5%;\n    }\n  }\n"], ["\n  width: 460px;\n  margin-left: 10px;\n  @media screen and (max-width: 480px) {\n    & {\n      width: 90%;\n      margin-left: 5%;\n    }\n  }\n"])));
export var CoursePercentage = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-top: 5px;\n  font-size: 18px;\n  font-weight: bold;\n  margin: 0 10px;\n  font-family: ", ";\n  text-align: center;\n"], ["\n  padding-top: 5px;\n  font-size: 18px;\n  font-weight: bold;\n  margin: 0 10px;\n  font-family: ", ";\n  text-align: center;\n"])), FontFamily.Secondary);
export var CircleIconBox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  min-width: 45px;\n  height: 45px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  margin-top: -2px;\n  font-weight: bold;\n  font-size: 1.1rem;\n"], ["\n  min-width: 45px;\n  height: 45px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  margin-top: -2px;\n  font-weight: bold;\n  font-size: 1.1rem;\n"])));
export var CourseIndex = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 0.9rem;\n"], ["\n  font-size: 0.9rem;\n"])));
export var CircleIconEnergy = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: absolute;\n  margin-left: 37px;\n  margin-top: 33px;\n\n  svg {\n    font-size: 21px;\n  }\n"], ["\n  position: absolute;\n  margin-left: 37px;\n  margin-top: 33px;\n\n  svg {\n    font-size: 21px;\n  }\n"])));
export var IconsBox = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding-right: 3px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n"], ["\n  padding-right: 3px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: column;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
