export var AuthPath = {
    Login: '/entrar',
    LoginWithCPF: '/entrar-com-cpf',
    SignUp: '/cadastrar',
    ChangePassword: '/alterar-senha',
    RecoverPassword: '/recuperar-senha',
    NewPassword: '/nova-senha/:usuarioId/:token',
    FirstAccess: '/primeiro-acesso',
    ExternalLogin: '/acesso-qr-code/:grupoId/:unidadeId',
    RegisterFinish: '/finalizar-cadastro',
    PhoneRegister: '/cadastro-celular/:grupoId/:unidadeId',
    PersonalDataUpdate: '/atualizar-dados-pessoais',
};
