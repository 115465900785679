var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color } from '@atomic';
export var QuizWinContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  min-height: 100vh;\n  background: ", ";\n"], ["\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  min-height: 100vh;\n  background: ", ";\n"])), function (props) { return (props.Background ? Color.RightAnswerGreenBg : Color.WrongAnswerRedBg); });
export var QuizWinContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 90%;\n  min-height: calc(100vh - 250px);\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin: auto;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 90%;\n  min-height: calc(100vh - 250px);\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  margin: auto;\n  align-items: center;\n  justify-content: center;\n"])));
export var Points = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  text-align: center;\n  font-size: 70px;\n"], ["\n  margin: 0;\n  text-align: center;\n  font-size: 70px;\n"])));
export var RewardIconBox = styled.picture(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"])));
export var MoneyIconBox = styled.picture(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 10px;\n  height: 70px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"], ["\n  margin-top: 10px;\n  height: 70px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n"])));
export var TextBox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 90%;\n  margin: auto;\n  height: 100px;\n  h2 {\n    font-size: 24px;\n    font-weight: semi-bold;\n    margin: 0;\n  }\n  p {\n    font-size: 18px;\n  }\n"], ["\n  width: 90%;\n  margin: auto;\n  height: 100px;\n  h2 {\n    font-size: 24px;\n    font-weight: semi-bold;\n    margin: 0;\n  }\n  p {\n    font-size: 18px;\n  }\n"])));
export var ButtonBox = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 90%;\n  margin: auto;\n  border-top: 4px dotted #000;\n  height: 150px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  div {\n    width: 100%;\n    button:first-child {\n      margin-bottom: 10px;\n    }\n  }\n"], ["\n  width: 90%;\n  margin: auto;\n  border-top: 4px dotted #000;\n  height: 150px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  div {\n    width: 100%;\n    button:first-child {\n      margin-bottom: 10px;\n    }\n  }\n"])));
export var Circle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 200px;\n  width: 200px;\n  border-radius: 50%;\n  padding: 35px;\n  background: ", ";\n"], ["\n  height: 200px;\n  width: 200px;\n  border-radius: 50%;\n  padding: 35px;\n  background: ",
    ";\n"])), function (props) {
    return props.Background ? 'radial-gradient(#d7fec800, #00ce00)' : 'radial-gradient(circle, white, red)';
});
export var ButtonAndamento = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  height: 45px;\n  border-radius 30px;\n  border: none;\n  cursor: pointer;\n  color: #fff;\n  background : ", ";\n\n  :hover{\n    background:", ";\n  }\n"], ["\n  width: 100%;\n  height: 45px;\n  border-radius 30px;\n  border: none;\n  cursor: pointer;\n  color: #fff;\n  background : ", ";\n\n  :hover{\n    background:", ";\n  }\n"])), function (props) { return (props.Background ? 'green' : 'red'); }, function (props) { return (props.Background ? '#008000b5' : '#ff0000ba'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
