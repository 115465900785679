var _a;
import bigUltra from './big-ultra.png';
export var Company;
(function (Company) {
    Company[Company["BigUltra"] = 165] = "BigUltra";
})(Company || (Company = {}));
var companyImageMapping = (_a = {},
    _a[Company.BigUltra] = bigUltra,
    _a);
export var getImageByCompany = function (company) {
    return companyImageMapping[company] || undefined;
};
