export var LoginFormFields = {
    Login: 'login',
    Password: 'password',
};
export var LoginFormPlaceholders = {
    Login: 'CPF ou E-mail',
    Password: 'Senha',
};
export var SignupFormFields = {
    Email: 'email',
    Password: 'password',
    Name: 'name',
    Address: 'adress',
    Phone: 'phone',
};
export var ExternalRegisterFormFields = {
    FullName: 'fullname',
    Email: 'email',
};
export var RegisterFinishFormFields = {
    Phone: 'phone',
    Cpf: 'cpf',
    Email: 'email',
};
export var PhoneRegisterFormFields = {
    FullName: 'fullname',
    Password: 'password',
    Phone: 'phone',
    Email: 'email',
    Cpf: 'cpf',
};
export var RecoverPasswordFormFields = { Email: 'email' };
export var ResetPasswordFormFields = { Password: 'password' };
export var FirstAccessFormFields = { Cpf: 'cpf' };
