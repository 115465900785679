var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { FaBolt } from 'react-icons/fa';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { TextField, Button, Divider } from '@atomic';
import { Switch } from '@atomic/atm.switch';
import { Modal } from '@atomic/obj.modal/modal.component';
import { CloseCircleIcon, FlexColumn, FlexRowBot, FlexRowTop, ImagePreview, QuickChecklistTitle } from './styles';
export var QuickChecklist = function (_a) {
    var _b, _c, _d;
    var open = _a.open, onClose = _a.onClose, checklist = _a.checklist, sendItToTheChecklist = _a.sendItToTheChecklist;
    var _e = React.useState(false), requiredPhoto = _e[0], setRequiredPhoto = _e[1];
    var _f = React.useState(false), includesImage = _f[0], setIncludesImage = _f[1];
    var _g = React.useState({
        checklistUnidadePreenchidoId: (_c = checklist === null || checklist === void 0 ? void 0 : checklist.itens[((_b = checklist.itens) === null || _b === void 0 ? void 0 : _b.length) - 1]) === null || _c === void 0 ? void 0 : _c.checklistUnidadePreenchidoId,
        checklistUnidadeId: checklist === null || checklist === void 0 ? void 0 : checklist.id,
        titulo: '',
        conformidade: '',
        imagemConformidade: '',
        fotoObrigatoria: false,
    }), formState = _g[0], setFormState = _g[1];
    var _h = React.useState(''), base64Image = _h[0], setBase64Image = _h[1];
    var _j = React.useState(false), loading = _j[0], setLoading = _j[1];
    var showFlashMessage = useFlashMessage()[0];
    var handleWichPropShouldPass = function (checklistNew) {
        var _a, _b, _c, _d;
        if (checklistNew.preenchidoId > 0)
            return checklistNew.preenchidoId;
        else if (((_b = checklistNew === null || checklistNew === void 0 ? void 0 : checklistNew.itens[((_a = checklistNew.itens) === null || _a === void 0 ? void 0 : _a.length) - 1]) === null || _b === void 0 ? void 0 : _b.checklistUnidadePreenchidoId) > 0)
            return (_d = checklistNew === null || checklistNew === void 0 ? void 0 : checklistNew.itens[((_c = checklistNew.itens) === null || _c === void 0 ? void 0 : _c.length) - 1]) === null || _d === void 0 ? void 0 : _d.checklistUnidadePreenchidoId;
        else
            return checklistNew === null || checklistNew === void 0 ? void 0 : checklistNew.checklistPreenchidoId;
    };
    var addNewQuickChecklist = function () { return __awaiter(void 0, void 0, void 0, function () {
        var checklistItem, response, newRelampago, error_1;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    setLoading(true);
                    checklistItem = {
                        checklistUnidadePreenchidoId: handleWichPropShouldPass(checklist),
                        checklistUnidadeId: checklist === null || checklist === void 0 ? void 0 : checklist.id,
                        titulo: formState === null || formState === void 0 ? void 0 : formState.titulo,
                        conformidade: formState === null || formState === void 0 ? void 0 : formState.conformidade,
                        imagemConformidade: includesImage ? base64Image : '',
                        fotoObrigatoria: requiredPhoto,
                    };
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axiosClient.post('checklistunidade/item', checklistItem)];
                case 2:
                    response = _g.sent();
                    if (!!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.succeeded)) {
                        newRelampago = __assign(__assign({}, checklistItem), { id: (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.id, checklistUnidadePreenchidoId: (_e = (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.checklistUnidadePreenchidoId });
                        sendItToTheChecklist(newRelampago);
                        setFormState(__assign(__assign({}, formState), { titulo: '', conformidade: '', imagemConformidade: '', fotoObrigatoria: false }));
                        setBase64Image(null);
                        setIncludesImage(false);
                        onClose();
                        setLoading(false);
                    }
                    else {
                        showFlashMessage('warning', (_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.errors[0]);
                        setLoading(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _g.sent();
                    if (error_1.response && error_1.response.status == 400) {
                        showFlashMessage('warning', error_1.response.data.errors);
                    }
                    else {
                        showFlashMessage('warning', error_1);
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleInputChange = function (event) {
        var files = event.target.files;
        if (!files || files.length < 1) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function (e) {
            setBase64Image(e.target.result);
        };
    };
    return (React.createElement(Modal, { opened: open, closeOnOutsideClick: false, onClose: onClose },
        React.createElement(FlexRowTop, { style: { marginBottom: '1.2rem' } },
            React.createElement(FaBolt, { fontSize: 20 }),
            React.createElement(QuickChecklistTitle, null,
                React.createElement("h2", null, "Checklist Rel\u00E2mpago"))),
        React.createElement(Divider, null),
        React.createElement("p", null, "Item a checar *"),
        React.createElement(TextField, { type: "text", style: { borderRadius: 20 }, onValueChange: function (e) {
                return setFormState(__assign(__assign({}, formState), { titulo: e }));
            } }),
        React.createElement("p", null, "Explique aqui como deve estar"),
        React.createElement(TextField, { type: "text", style: { borderRadius: 20 }, onValueChange: function (e) {
                return setFormState(__assign(__assign({}, formState), { conformidade: e }));
            } }),
        React.createElement(FlexRowBot, null,
            React.createElement(FlexColumn, null,
                React.createElement("strong", { style: { fontSize: '0.8rem', color: '#333', marginBottom: '5px' }, className: "mb-2" },
                    "Incluir ",
                    React.createElement("br", null),
                    "imagem?"),
                React.createElement(Switch, { onChange: function () { return setIncludesImage(function (oldValue) { return !oldValue; }); }, checked: includesImage })),
            React.createElement(FlexColumn, null,
                React.createElement("strong", { style: { fontSize: '0.8rem', color: '#333', marginBottom: '5px' }, className: "mb-2" },
                    "Foto obrigat\u00F3ria ",
                    React.createElement("br", null),
                    " para checagem?"),
                React.createElement(Switch, { onChange: function () { return setRequiredPhoto(function (oldValue) { return !oldValue; }); }, checked: requiredPhoto }))),
        includesImage && (React.createElement("input", { style: { marginBottom: '16px' }, type: "file", name: "imagemConformidade", onChange: handleInputChange })),
        base64Image && (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Confira aqui:"),
            React.createElement(FlexRowBot, null,
                React.createElement(ImagePreview, { src: base64Image, alt: "Pre-visualizacao da imagem" }),
                React.createElement(CloseCircleIcon, { onClick: function () { return setBase64Image(undefined); } }, "X")))),
        React.createElement(Button, { type: "submit", variant: "primary", expanded: true, disabled: ((_d = formState === null || formState === void 0 ? void 0 : formState.titulo) === null || _d === void 0 ? void 0 : _d.length) < 5, onClick: addNewQuickChecklist, loading: loading }, "Adicionar")));
};
