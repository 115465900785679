var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Logo = styled.img.attrs(function (props) { return ({
    src: props.src,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var small = _a.small;
    return (small ? 'width: 40%;' : 'width: 60%;');
}, function (_a) {
    var centered = _a.centered;
    return centered && 'text-align:center;';
});
export var RoundLogo = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  width: 40%;\n  border-radius: 50%;\n  margin-top: 20px;\n"], ["\n  text-align: center;\n  width: 40%;\n  border-radius: 50%;\n  margin-top: 20px;\n"])));
/**
 * Use as a wrapper for a logo.
 * Should be used like this:
 * <HeaderLogoArea>
 *    <img src="" />
 * </HeaderLogoArea>
 */
export var HeaderLogoWrapper = styled('div')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 5px;\n\n  img {\n    width: 20%;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 5px;\n\n  img {\n    width: 20%;\n  }\n"])));
export var HeaderLogoWrapperV2 = styled('div')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 5px;\n\n  img {\n    width: 20%;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 5px;\n\n  img {\n    width: 20%;\n  }\n"])));
export var HeaderLogoSmall = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 50px;\n"], ["\n  width: 50px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
