import React from 'react';
import { MenuItem } from '@mui/material';
import { TitleModule } from '@app/components/atm.title.module/title.module';
import { ListItemStatus } from '@app/components/mol.list-item-status';
import { SelectContainer, SelectStyled } from '@app/components/mol.select-ui-styled';
import { SubmenuContainer, SubmenuHeader } from '@app/modules/submenus/components/styles';
export var ListItemComponent = function (props) {
    var _a, _b;
    var _c = React.useState((_b = (_a = props.filters) === null || _a === void 0 ? void 0 : _a.defaultFilter) !== null && _b !== void 0 ? _b : 0), filter = _c[0], setFilter = _c[1];
    var hasFilter = function () {
        var _a;
        return ((_a = props.filters) === null || _a === void 0 ? void 0 : _a.filtersLabels) && Object.keys(props.filters.filtersLabels).length > 0;
    };
    var buildMenuItem = function () {
        var allFilterType = Object.keys(props.filters.filtersLabels).map(Number);
        console.log(props.filters.filtersLabels, allFilterType, props.filters.filtersLabels);
        return allFilterType.map(function (filterType, index) { return (React.createElement(MenuItem, { key: index, value: filterType }, props.filters.filtersLabels[filterType])); });
    };
    var buildListComponent = function () {
        return props.items
            .filter(function (item) {
            return filter == props.filters.defaultFilter || item.filterType === filter;
        })
            .map(function (item, index) {
            return (React.createElement(ListItemStatus, { key: index, title: item.title, categoryLabel: item.status.categoryLabel, statusLabel: item.status.statusLabel, statusLabelColor: item.status.statusLabelColor, onClick: item.onClick }));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SubmenuHeader, null,
            React.createElement(TitleModule, null, props.title)),
        hasFilter() && (React.createElement(SelectContainer, null,
            React.createElement(SelectStyled, { value: filter, onChange: function (e) { return setFilter(e.target.value); }, inputProps: { 'arial-label': 'Without label' } }, buildMenuItem()))),
        React.createElement(SubmenuContainer, null, buildListComponent())));
};
