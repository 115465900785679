var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/display-name */
import React from 'react';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faBan, faBus, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faDownload, faTimes, faUpload, faTimesCircle, faInfoCircle, faCheckCircle, faExclamationCircle, faHeart, faImage, faBars, faMicrophone, faStar, faStarHalf, faMinus, faPlus, faExclamationTriangle, faSearch, faCheckSquare, faDotCircle, faDog, faDrumstickBite, faHome, faChalkboard, faSignOutAlt, faUserCircle, faEye, faEyeSlash, faPencilRuler, faPlusCircle, faLightbulb, faArrowLeft, faPlayCircle, faArrowRight, faLock, faClipboardCheck, faShoppingBasket, faBook, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var FaIcon = {
    ArrowLeft: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faArrowLeft })); },
    Ban: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBan })); },
    Bus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBus })); },
    Circle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCircle })); },
    Check: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheck })); },
    CheckSquare: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckSquare })); },
    ChevronDown: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronDown })); },
    ChevronLeft: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronLeft })); },
    ChevronRight: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronRight })); },
    ChevronUp: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronUp })); },
    Close: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimes })); },
    DotCircle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDotCircle })); },
    Dog: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDog })); },
    Download: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDownload })); },
    Eye: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faEye })); },
    EyeClosed: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faEyeSlash })); },
    FileUpload: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faUpload })); },
    FlashAlert: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimesCircle })); },
    FlashInfo: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faInfoCircle })); },
    FlashSuccess: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckCircle })); },
    FlashWarning: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationCircle })); },
    Heart: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faHeart })); },
    Home: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faHome })); },
    Image: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faImage })); },
    Onboard: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChalkboard })); },
    UserCircle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faUserCircle })); },
    Menu: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBars })); },
    Meat: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDrumstickBite })); },
    Microphone: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMicrophone })); },
    PencilRuler: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPencilRuler })); },
    PlusCircle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPlusCircle })); },
    LightBulb: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faLightbulb })); },
    Search: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSearch })); },
    SignOut: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSignOutAlt })); },
    Square: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSquare })); },
    Star: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStar })); },
    StarHalf: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStarHalf })); },
    StepperMinus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMinus })); },
    StepperPlus: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPlus })); },
    Warning: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationTriangle })); },
    PlayCircle: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPlayCircle })); },
    ArrowRight: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faArrowRight })); },
    Play: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faYoutube })); },
    Lock: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faLock })); },
    ErroX: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimes })); },
    ClipboardCheck: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faClipboardCheck })); },
    ShoppingBasket: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faShoppingBasket })); },
    Book: function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBook })); },
};
