/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Typography } from '@mui/material';
import { FontFamily } from '@atomic';
import { CardBadge, SubmenuContentCardContainer } from './styles';
export var SubmenuContentCard = function (_a) {
    var _b, _c, _d;
    var content = _a.content, onClick = _a.onClick;
    return (React.createElement(SubmenuContentCardContainer, { onClick: function () { var _a; return onClick((_a = content === null || content === void 0 ? void 0 : content.codigo) !== null && _a !== void 0 ? _a : content === null || content === void 0 ? void 0 : content.id); } },
        React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '20px' }, variant: "h5", component: "div", textAlign: "left" }, (_b = content === null || content === void 0 ? void 0 : content.nome) !== null && _b !== void 0 ? _b : content === null || content === void 0 ? void 0 : content.titulo),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' } },
            ((_c = content === null || content === void 0 ? void 0 : content.categoria) === null || _c === void 0 ? void 0 : _c.nome) && (React.createElement(CardBadge, null,
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px', color: 'white' }, variant: "h5", component: "div" }, (_d = content === null || content === void 0 ? void 0 : content.categoria) === null || _d === void 0 ? void 0 : _d.nome))),
            !!(content === null || content === void 0 ? void 0 : content.permanente) && (React.createElement(CardBadge, { color: "#008425" },
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px', color: 'white' }, variant: "h5", component: "div" }, "Permanente"))),
            (content === null || content === void 0 ? void 0 : content.totalFasesConcluidas) === 0 && (React.createElement(CardBadge, { color: "#42A4FF" },
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px', color: 'white' }, variant: "h5", component: "div" }, "Iniciar"))))));
};
