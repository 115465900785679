var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic';
export var Brain = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 25px;\n  padding-left: 8px;\n"], ["\n  height: 25px;\n  padding-left: 8px;\n"])));
export var Question = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  font-weight: bold;\n  font-family: ", "; //'Ubuntu500';\n  font-size: 1.5rem;\n"], ["\n  text-align: center;\n  font-weight: bold;\n  font-family: ", "; //'Ubuntu500';\n  font-size: 1.5rem;\n"])), FontFamily.Secondary);
export var TitleElement = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", "; //'Ubuntu300';\n  font-size: 0.7rem;\n"], ["\n  font-family: ", "; //'Ubuntu300';\n  font-size: 0.7rem;\n"])), FontFamily.Primary);
var templateObject_1, templateObject_2, templateObject_3;
