var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { BsFillPlusCircleFill } from 'react-icons/bs';
import Select from 'react-select';
import styled from 'styled-components';
import { Button, Row } from '@atomic';
export var EntcoinsHeaderWrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: start;\n  margin-right: 0;\n  margin-left: 0;\n  padding: 0.5rem;\n  background: #d1fcff;\n  min-height: 60px;\n  align-items: center;\n  border-radius: 20px;\n\n  img {\n    width: 30px;\n    margin-left: 15px;\n  }\n\n  span {\n    margin-left: 0.6rem;\n    color: #49443c !important;\n    font-size: 20px;\n    font-weight: 700;\n  }\n"], ["\n  justify-content: start;\n  margin-right: 0;\n  margin-left: 0;\n  padding: 0.5rem;\n  background: #d1fcff;\n  min-height: 60px;\n  align-items: center;\n  border-radius: 20px;\n\n  img {\n    width: 30px;\n    margin-left: 15px;\n  }\n\n  span {\n    margin-left: 0.6rem;\n    color: #49443c !important;\n    font-size: 20px;\n    font-weight: 700;\n  }\n"])));
export var AddIcon = styled(BsFillPlusCircleFill)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 50px;\n  color: #62d21d;\n  transition: color 0.3s ease-in-out;\n\n  &:hover {\n    color: #4da119;\n  }\n"], ["\n  font-size: 50px;\n  color: #62d21d;\n  transition: color 0.3s ease-in-out;\n\n  &:hover {\n    color: #4da119;\n  }\n"])));
export var StyledButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var Filter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  -webkit-box-pack: start;\n  -webkit-justify-content: start;\n  justify-content: start;\n  margin-top: 8px;\n"], ["\n  width: 100%;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  -webkit-box-pack: start;\n  -webkit-justify-content: start;\n  justify-content: start;\n  margin-top: 8px;\n"])));
export var ChecklistPontuation = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  float: right;\n  color: #000;\n  font-size: 20px;\n"], ["\n  float: right;\n  color: #000;\n  font-size: 20px;\n"])));
export var BottomWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  max-width: 480px;\n  height: 60px;\n  padding-inline: 16px;\n  flex-direction: column;\n  justify-content: center;\n  background-color: white;\n"], ["\n  display: flex;\n  width: 100%;\n  max-width: 480px;\n  height: 60px;\n  padding-inline: 16px;\n  flex-direction: column;\n  justify-content: center;\n  background-color: white;\n"])));
export var StyledSelect = styled(Select)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-radius: 8px;\n  /* background-color: ", "; */\n  border-color: ", ";\n  border-radius: 4px;\n  border-style: solid;\n  border-width: 2px;\n  box-sizing: border-box;\n\n  span {\n    color: ", ";\n  }\n"], ["\n  border-radius: 8px;\n  /* background-color: ", "; */\n  border-color: ", ";\n  border-radius: 4px;\n  border-style: solid;\n  border-width: 2px;\n  box-sizing: border-box;\n\n  span {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ChecklistButton = styled(Button)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: 8px;\n  background-color: ", ";\n  border: 2px solid ", ";\n  color: ", ";\n  max-width: 140px;\n\n  img {\n    color: ", ";\n    fill: ", ";\n    color-fill: ", ";\n    width: 20px;\n    height: 20px;\n    margin: auto;\n  }\n\n  span {\n    color: ", ";\n  }\n"], ["\n  border-radius: 8px;\n  background-color: ", ";\n  border: 2px solid ", ";\n  color: ", ";\n  max-width: 140px;\n\n  img {\n    color: ", ";\n    fill: ", ";\n    color-fill: ", ";\n    width: 20px;\n    height: 20px;\n    margin: auto;\n  }\n\n  span {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
