var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { BlackText, Row } from '@atomic';
export var LearningContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 40px;\n"], ["\n  padding: 0 40px;\n"])));
export var StyledText = styled(BlackText)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-size: x-large;\n  justify-content: center;\n  margin-bottom: 0;\n"], ["\n  display: flex;\n  font-size: x-large;\n  justify-content: center;\n  margin-bottom: 0;\n"])));
export var EntHeaderWrapper = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  justify-content: start;\n  margin-right: 0;\n  margin-left: 0;\n  padding: 0.5rem;\n  background: ", ";\n  min-height: 60px;\n  align-items: center;\n  border-radius: 20px;\n\n  img {\n    width: 30px;\n    margin-left: 15px;\n  }\n\n  span {\n    margin-left: 0.6rem;\n    color: #49443c !important;\n    font-size: 20px;\n    font-weight: 700;\n  }\n"], ["\n  justify-content: start;\n  margin-right: 0;\n  margin-left: 0;\n  padding: 0.5rem;\n  background: ", ";\n  min-height: 60px;\n  align-items: center;\n  border-radius: 20px;\n\n  img {\n    width: 30px;\n    margin-left: 15px;\n  }\n\n  span {\n    margin-left: 0.6rem;\n    color: #49443c !important;\n    font-size: 20px;\n    font-weight: 700;\n  }\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor || '#FFEFE8';
});
var templateObject_1, templateObject_2, templateObject_3;
