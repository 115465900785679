var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import moneyIcon from 'assets/img/money_icon.svg';
// import trophyIcon from 'assets/img/trophy_icon.svg';
export var MoneyImage = styled.img.attrs(function () { return ({ src: moneyIcon }); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 5rem;\n  opacity: 100%;\n"], ["\n  width: 5rem;\n  opacity: 100%;\n"])));
var templateObject_1;
// export const TrophyImage = styled.img.attrs(() => ({ src: trophyIcon }))`
//   width: 45%;
//   opacity: 75%;
// `;
// export const AmountReceived = styled.span`
//   font-size: 7rem;
//   font-family: ${FontFamily.Secondary};
//   line-height: 9rem;
// `;
// export const QuizResultTitle = styled.span`
//   font-size: 1.6rem;
//   font-family: ${FontFamily.Primary};
//   font-weight: 600;
//   text-transform: uppercase;
// `;
