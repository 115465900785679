var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var SubmenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 12px 32px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 12px 32px;\n"])));
export var SubmenuHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  padding-inline: 22px;\n  align-items: center;\n  justify-content: ", "; // Default to 'left' if not specified\n"], ["\n  display: flex;\n  flex-direction: row;\n  padding-inline: 22px;\n  align-items: center;\n  justify-content: ", "; // Default to 'left' if not specified\n"])), function (_a) {
    var justifyContent = _a.justifyContent;
    return justifyContent || 'left';
});
export var SubmenuIcon = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 50px;\n  height: auto;\n  margin-bottom: 8px;\n"], ["\n  width: 50px;\n  height: auto;\n  margin-bottom: 8px;\n"])));
export var RoundedButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  border-radius: 50px;\n  background-color: #fff;\n  border: 1px solid #d9d9d9;\n  width: fit-content;\n  margin-top: 16px;\n  align-items: center;\n  justify-content: center;\n  align-self: center;\n  cursor: pointer;\n  padding: 10px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  border-radius: 50px;\n  background-color: #fff;\n  border: 1px solid #d9d9d9;\n  width: fit-content;\n  margin-top: 16px;\n  align-items: center;\n  justify-content: center;\n  align-self: center;\n  cursor: pointer;\n  padding: 10px;\n"])));
export var SubmenuTitle = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 1.5rem;\n  z-index: 1;\n"], ["\n  font-weight: bold;\n  font-size: 1.5rem;\n  z-index: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
