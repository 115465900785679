import React from 'react';
import { useStore } from 'react-context-hook';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { LibraryHeader } from './components/course-library-header';
import { CourseLibraryList } from './components/course-library-list';
import { CoursePhaseList } from './components/course-phase-list';
export var CourseLibraryPage = function () {
    var currentLibraryCourse = useStore('currentLibraryCourse')[0];
    return (React.createElement(React.Fragment, null,
        React.createElement(LibraryHeader, { title: currentLibraryCourse ? "Fases -  " + currentLibraryCourse.nome : 'Desafios' }),
        currentLibraryCourse ? React.createElement(CoursePhaseList, { course: currentLibraryCourse }) : React.createElement(CourseLibraryList, null),
        React.createElement(BottomNav, null)));
};
