import * as React from 'react';
import { CloseIconStyled, ModalBoxStyled, ModalCloseStyled, ModalOpacityStyled, ModalStyled, } from './modal.component.style';
export var Modal = function (_a) {
    var opened = _a.opened, onClose = _a.onClose, closeOnOutsideClick = _a.closeOnOutsideClick, children = _a.children, _b = _a.small, small = _b === void 0 ? true : _b;
    return (React.createElement(ModalStyled, { opened: opened },
        React.createElement(ModalOpacityStyled, { opened: opened, onClick: closeOnOutsideClick ? onClose : undefined }, opened && (React.createElement(ModalBoxStyled, { small: small },
            React.createElement(ModalCloseStyled, null,
                React.createElement(CloseIconStyled, { onClick: onClose })),
            children)))));
};
