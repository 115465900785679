import * as React from 'react';
import { useEffect } from 'react';
import { useStore } from 'react-context-hook';
import Countdown, { zeroPad } from 'react-countdown';
import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import { QuestionBodyComponent } from '@app/components/atm.question-component/question-body.component';
import { QuestionHeaderComponent } from '@app/components/atm.question-component/question-header.component';
import { axiosClient } from '@app/configure-app';
import { Footer } from '@app/modules/questionnarie/organizational-climate.style';
import { AppPath } from '@app/routes';
import { Button, Separator, StyledContainer } from '@atomic';
import { ThemeColorText } from '../questionnarie/questionnaire.styled';
import { Question } from '../quiz/components/quiz/quiz.styled';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
var CLIMATE_URL = '/perguntacomportamental';
export var BehavioralQuestionsPage = function () {
    var _a, _b;
    var isMute = useStore('mute')[0];
    var playRightAnswerSound = useSound(rightAnswerSound)[0];
    var _c = React.useState(0), currentQuestionIndex = _c[0], setCurrentQuestionIndex = _c[1];
    var _d = React.useState(), currentQuestion = _d[0], setCurrentQuestion = _d[1];
    var _e = React.useState(0), currentAnswerId = _e[0], setCurrentAnswerId = _e[1];
    var _f = React.useState(), behavioral = _f[0], setBehavioral = _f[1];
    var _g = React.useState(false), loading = _g[0], setLoading = _g[1];
    var _h = React.useState(false), answered = _h[0], setAnswered = _h[1];
    var history = useHistory();
    useEffect(function () {
        axiosClient
            .get(CLIMATE_URL)
            .then(function (response) {
            setBehavioral(response.data.data);
        })
            .catch(function (error) {
            console.error(error);
        });
    }, []);
    useEffect(function () {
        if (behavioral && behavioral.length > 0) {
            setCurrentQuestion(behavioral[currentQuestionIndex]);
        }
    }, [behavioral, currentQuestionIndex]);
    var hasNextQuestion = function () {
        return currentQuestionIndex + 1 < (behavioral === null || behavioral === void 0 ? void 0 : behavioral.length);
    };
    var nextQuestion = function () {
        if (hasNextQuestion()) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setAnswered(false);
            setCurrentAnswerId(0);
        }
        if (currentQuestionIndex + 1 === (behavioral === null || behavioral === void 0 ? void 0 : behavioral.length)) {
            history.push({
                pathname: AppPath.QuestionnaireCompleted,
                state: { coins: (behavioral === null || behavioral === void 0 ? void 0 : behavioral.length) * 30 },
            });
        }
    };
    var handleAnswer = function () {
        setLoading(true);
        var data = {
            perguntaId: currentQuestion.id,
            alternativaEscolhidaId: currentAnswerId,
        };
        axiosClient.post(CLIMATE_URL, data).then(function (response) {
            var _a;
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.succeeded) == true) {
                if (!isMute) {
                    playRightAnswerSound();
                }
                setLoading(false);
                setAnswered(true);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(QuestionHeaderComponent, { title: "Perguntas da Plataforma", currentQuestion: currentQuestionIndex, totalQuestions: (_a = behavioral === null || behavioral === void 0 ? void 0 : behavioral.length) !== null && _a !== void 0 ? _a : 1 }),
        React.createElement("div", { style: { padding: '0 1rem' } },
            React.createElement(StyledContainer, { withPadding: true, noPaddingBottom: true },
                React.createElement(ThemeColorText, null, "Pergunta:")),
            React.createElement(Question, null, currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.pergunta),
            React.createElement(Separator, null),
            React.createElement(QuestionBodyComponent, { alternatives: (_b = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.alternativas) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                    var id = _a.id, descricao = _a.descricao;
                    return { id: id, title: descricao };
                }), onSelectAlternative: function (id) { return setCurrentAnswerId(id); } }),
            React.createElement(Separator, null),
            React.createElement(Footer, null,
                React.createElement(Button, { expanded: true, onClick: function () {
                        if (answered) {
                            nextQuestion();
                        }
                        else {
                            handleAnswer();
                        }
                    }, loading: loading, disabled: !currentAnswerId }, answered ? (React.createElement(React.Fragment, null,
                    React.createElement("span", null, hasNextQuestion() ? 'PRÓXIMA' : 'CONCLUIR'),
                    React.createElement(Countdown, { date: Date.now() + 3000, renderer: function (_a) {
                            var seconds = _a.seconds;
                            return React.createElement("span", null,
                                "(",
                                zeroPad(seconds),
                                ")");
                        }, onComplete: function () { return nextQuestion(); } }))) : ('CONFIRMAR'))))));
};
