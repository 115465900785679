var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FontFamily } from '@atomic';
export var TitleModule = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 1.5rem;\n  color: ", ";\n"], ["\n  font-family: ", ";\n  font-size: 1.5rem;\n  color: ", ";\n"])), FontFamily.Inter700, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
});
export var SubTitleModuleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-top: 10px;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-top: 10px;\n  justify-content: center;\n"])));
export var SubTitleModule = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 1.3rem;\n  color: ", ";\n"], ["\n  font-family: ", ";\n  font-size: 1.3rem;\n  color: ", ";\n"])), FontFamily.Inter600, function (_a) {
    var theme = _a.theme;
    return theme.itemColor;
});
var templateObject_1, templateObject_2, templateObject_3;
