var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ErrorMessage } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import Select from 'react-select';
import { Container, Error } from './react-select.styled';
export function ReactSelect(_a) {
    var options = _a.options, field = _a.field, form = _a.form, isRequerid = _a.isRequerid, isMulti = _a.isMulti, label = _a.label, rest = __rest(_a, ["options", "field", "form", "isRequerid", "isMulti", "label"]);
    var selectStyle = {
        control: function (provided, state) {
            var borderColor = 'rgba(1, 1, 1, 0.2)';
            if (state.isFocused) {
                borderColor = 'gray'; //theme.colors.main;
            }
            return __assign(__assign({}, provided), { borderStyle: 'solid', borderWidth: '1.5px', borderColor: borderColor, boxShadow: '0 transparent', outline: 'none', background: '#fff', minHeight: '2.6rem', borderRadius: '5px', '&:hover': {
                    boxShadow: '0 transparent',
                } });
        },
    };
    return (React.createElement(Container, null,
        label && (React.createElement("label", { htmlFor: field.name }, isRequerid ? (React.createElement("span", null,
            label,
            " ",
            React.createElement("strong", { className: "text-danger" }, "*"))) : (React.createElement("span", null, label)))),
        React.createElement(Select, __assign({ options: options, name: field.name, styles: selectStyle, isMulti: isMulti }, rest, { placeholder: "Selecione", className: "react-select-container", classNamePrefix: "react-select", onChange: function (option) { return form.setFieldValue(field.name, option); }, onBlur: field.onBlur, value: isMulti ? field.value : (options ? options.find(function (option) { return option.value === field.value; }) : '') })),
        React.createElement(ErrorMessage, { name: field.name }, function (msg) { return (React.createElement(Error, { className: "d-flex align-items-center mt-1" },
            React.createElement(RiErrorWarningFill, { color: "red", size: 16 }),
            React.createElement("span", null, msg))); })));
}
