var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ButtonNext, ButtonBack } from 'pure-react-carousel';
import styled from 'styled-components';
export var ButtonNextSlide = styled(ButtonNext)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  background: none;\n  border: none;\n  float: right;\n  padding-right: 15px;\n  svg {\n    path {\n      color: ", ";\n    }\n  }\n"], ["\n  color: ", ";\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  background: none;\n  border: none;\n  float: right;\n  padding-right: 15px;\n  svg {\n    path {\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonBackSlide = styled(ButtonBack)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: left;\n  cursor: pointer;\n  background: none;\n  border: none;\n  color: ", ";\n  font-size: 20px;\n\n  svg {\n    path {\n      color: ", ";\n      font-size: 22px;\n    }\n  }\n"], ["\n  float: left;\n  cursor: pointer;\n  background: none;\n  border: none;\n  color: ", ";\n  font-size: 20px;\n\n  svg {\n    path {\n      color: ", ";\n      font-size: 22px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var BoxInterregocao = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  right: 6px;\n  bottom: 45px;\n"], ["\n  position: absolute;\n  right: 6px;\n  bottom: 45px;\n"])));
export var ImagemInterrogacao = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  position: relative;\n  top: -45px;\n  right: 8px;\n"], ["\n  background-color: ", ";\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  position: relative;\n  top: -45px;\n  right: 8px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var TutorialContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  h2 {\n    margin: 0;\n    padding-top: 20px;\n    text-align: center;\n  }\n  h2,\n  h3,\n  h4 {\n    font-weight: bold;\n    color: ", ";\n  }\n"], ["\n  h2 {\n    margin: 0;\n    padding-top: 20px;\n    text-align: center;\n  }\n  h2,\n  h3,\n  h4 {\n    font-weight: bold;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var VideoPlayerWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1rem 0.4rem;\n"], ["\n  padding: 1rem 0.4rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
