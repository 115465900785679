var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ItemCircle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 53px;\n  height: 50px;\n  border-radius: 50%;\n  border: 4px solid ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 53px;\n  height: 50px;\n  border-radius: 50%;\n  border: 4px solid ", ";\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1;
