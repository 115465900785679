import { getStorage, StorageType } from './storage.provider';
var localStorage = getStorage(StorageType.Local);
var sessionStorage = getStorage(StorageType.Session);
/**
 * Decorator to make a property automatically bound to a browser local storage.
 * The first argument is the storage key.
 *
 * ```typescript
 * import { LocalStorage } from '@app/data/storage'
 *
 * class NeedsLocalStorage {
 *   @LocalStorage('my-prop-stored-in-local-storage')
 *   private myProp: any;
 *
 *   constructor() {}
 * }
 * ```
 */
export var LocalStorage = function (key) {
    return decorateWithStorage(key, localStorage);
};
/**
 * Decorator to make a property automatically bound to a browser session storage.
 * The first argument is the storage key.
 *
 * ```typescript
 * import { SessionStorage } from '@app/data/storage'
 *
 * class NeedsSessionStorage {
 *   @SessionStorage('my-prop-stored-in-session-storage')
 *   private myProp: any;
 *
 *   constructor() {}
 * }
 * ```
 */
export function SessionStorage(key) {
    return decorateWithStorage(key, sessionStorage);
}
var decorateWithStorage = function (key, storage) {
    return function (_target, _propKey) {
        Object.defineProperty(_target, _propKey, {
            get: function () { return storage.get(key); },
            set: function (value) { return storage.put(key, value); },
        });
    };
};
