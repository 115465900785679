import { ApolloClient } from '@apollo/client';
import axios from 'axios';
import { Container } from 'typedi';
import { ApolloClientBuilderService } from '@app/core/graphql';
import { AuthStorageService } from './data/storage/auth.storage';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
export function configure() {
    configApolloClient(REACT_APP_ENDPOINT);
}
function configApolloClient(url) {
    var client = Container.get(ApolloClientBuilderService).build(url);
    Container.set(ApolloClient, client);
}
var authStorageService = Container.get(AuthStorageService);
var axiosClient = axios.create({
    baseURL: REACT_APP_ENDPOINT + "api/v1/jogo/",
    headers: {
        Authorization: "Bearer " + authStorageService.getToken(),
    },
});
axiosClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        window.location.href = 'entrar';
    }
    return Promise.reject(error);
});
export { axiosClient };
