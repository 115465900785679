import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router';
import { axiosClient } from '@app/configure-app';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import { Button, FaIcon, H4 } from '@atomic';
import { isApprovedOnTask } from '../../pages/quiz-win.page';
import { ResponseFooter, Title } from './response-footer.styled';
export var randomMessage = function () {
    var RIGHT_ANSWER_MSG = [
        'Uau, você é muito inteligente!',
        'Parabéns, você é incrível!',
        'Yes, eu acredito em você!',
        'Você tem capacidade infinita!',
        'Eu acredito em você, parabéns!',
        'Que orgulho!',
        'Fez bonito, parabéns!',
        'Você tem talento para isto, ein?!',
    ];
    var max = RIGHT_ANSWER_MSG.length;
    var randomNumber = Math.floor(Math.random() * max);
    return RIGHT_ANSWER_MSG[randomNumber];
};
export var QuizResponseFooter = function (_a) {
    var _b, _c;
    var isFinalQuestionBtn = _a.isFinalQuestionBtn, answerResponse = _a.answerResponse, resetAnswerQuestion = _a.resetAnswerQuestion, tarefa = _a.tarefa;
    var history = useHistory();
    var usuario = useStore('usuario')[0];
    var loadedCourse = useStore('currentCourse')[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _d = React.useContext(QuizContext), _ = _d[0], currentQuizIndex = _d[1], ___ = _d[2], setCurrentQuizzIndex = _d[3];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = useStore('choosedAlternativeId'), _1 = _e[0], setChoosedAlternativeId = _e[1];
    var handleNextQuestionClick = function () {
        setCurrentQuizzIndex(currentQuizIndex + 1);
        setChoosedAlternativeId(null);
        resetAnswerQuestion();
    };
    var currentTaskId = tarefa.id;
    var lastTaskIdOnPhase = function () {
        var currentPhase = loadedCourse.fases.filter(function (fase) {
            return fase.id === tarefa.faseId;
        })[0];
        var lastTask = currentPhase.tarefas.slice(-1)[0];
        return lastTask.id;
    };
    var lastPhaseId = function () {
        return loadedCourse.fases[loadedCourse.fases.length - 1].id;
    };
    var isLastTask = currentTaskId === lastTaskIdOnPhase();
    var isLastPhase = tarefa.faseId === lastPhaseId();
    var isExternalUserWithPendentRegistration = (usuario === null || usuario === void 0 ? void 0 : usuario.isUsuarioExterno) && (!usuario.cpf || !usuario.celular);
    var navigateToPhaseCompletedPage = function () {
        if (isLastPhase) {
            axiosClient.get("curso/" + (loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.id)).then(function () {
                if (isExternalUserWithPendentRegistration) {
                    history.push(AppPath.RegisterFinish);
                }
                else {
                    history.push({
                        pathname: AppPath.PhaseCompleted,
                        state: {
                            quizResult: answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data,
                            tarefa: tarefa,
                        },
                    });
                }
            });
        }
        else {
            history.push({
                pathname: AppPath.PhaseCompleted,
                state: {
                    quizResult: answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data,
                    tarefa: tarefa,
                },
            });
        }
    };
    var navigateToRankingPage = function () {
        history.push({
            pathname: AppPath.Ranking,
            state: {
                quizResult: answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data,
                tarefa: tarefa,
            },
        });
    };
    var handleCompleteClick = function () {
        if (isLastTask && isApprovedOnTask(answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data.tarefaAcertoPercentual)) {
            navigateToPhaseCompletedPage();
        }
        else {
            navigateToRankingPage();
        }
        resetAnswerQuestion();
        setChoosedAlternativeId(null);
    };
    return (React.createElement(ResponseFooter, { rightAnswer: (_b = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _b === void 0 ? void 0 : _b.acertou },
        React.createElement(Title, null, ((_c = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _c === void 0 ? void 0 : _c.acertou) ? (React.createElement(React.Fragment, null,
            React.createElement(FaIcon.Check, { style: { fontSize: '60px' } }),
            React.createElement(H4, { style: { paddingLeft: '20px' } }, randomMessage()))) : (React.createElement(React.Fragment, null,
            React.createElement(FaIcon.ErroX, { style: { fontSize: '60px' } }),
            React.createElement("div", null,
                React.createElement(H4, { style: { fontWeight: 'bold', paddingLeft: '20px', paddingBottom: '10px' } }, "A resposta certa \u00E9:"),
                React.createElement(H4, { style: { fontSize: '18px', paddingLeft: '20px' } }, answerResponse.data.alternativaCorreta.descricao))))),
        React.createElement(Button, { expanded: true, onClick: isFinalQuestionBtn ? handleCompleteClick : handleNextQuestionClick },
            React.createElement("span", null, isFinalQuestionBtn ? 'CONCLUIR' : 'PRÓXIMA'))));
};
