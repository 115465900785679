export var BrandColor;
(function (BrandColor) {
    BrandColor["Blue"] = "#16AAE4";
    BrandColor["Pink"] = "#FF388B";
    BrandColor["Purple"] = "#663AE7";
})(BrandColor || (BrandColor = {}));
// We used class rather than enums since enums don't support computed values
var Color = /** @class */ (function () {
    function Color() {
    }
    Color.Black = 'black';
    Color.White = 'white';
    Color.GrayXLight = '#F8F8F8';
    Color.GrayLight = '#EBEBEB';
    Color.Gray = '#BDBDBD';
    Color.GrayDark = '#757575';
    Color.GrayXDark = '#525252';
    Color.GreenDark = '#355C26';
    Color.RedDark = '#7C051F';
    Color.Primary = Color.Black;
    Color.Secondary = Color.Black;
    Color.Accessory = '#ee7b10';
    Color.Neutral = Color.GrayDark;
    Color.CallToAction = BrandColor.Pink;
    Color.Alert = 'rgb(179, 0, 3)';
    Color.Warning = '#F5A623';
    Color.Success = '#1ABC9C';
    Color.RightAnswerGreenBg = '#D7FFB8';
    Color.RightAnswerGreenText = '#58A700';
    Color.WrongAnswerRedBg = '#FFDFE0';
    Color.WrongAnswerRedText = '#EA2B2B';
    Color.BgApp = '#FFFCFB';
    return Color;
}());
export { Color };
export var TextAlign;
(function (TextAlign) {
    TextAlign["Center"] = "center";
})(TextAlign || (TextAlign = {}));
export var FontFamily;
(function (FontFamily) {
    // Primary = 'San Francisco',
    // Secondary = 'Visitor TT1 BRK',
    FontFamily["Primary"] = "Ubuntu300";
    FontFamily["Secondary"] = "Ubuntu500";
    FontFamily["Third"] = "UniNeueRegular";
    FontFamily["Inter400"] = "Inter400";
    FontFamily["Inter600"] = "Inter600";
    FontFamily["Inter700"] = "Inter700";
})(FontFamily || (FontFamily = {}));
export var FontWeight;
(function (FontWeight) {
    FontWeight["Bold"] = "bold";
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight["Normal"] = "normal";
    FontWeight[FontWeight["Lighter"] = 300] = "Lighter";
    FontWeight[FontWeight["XLighter"] = 100] = "XLighter";
})(FontWeight || (FontWeight = {}));
export var FontSize;
(function (FontSize) {
    FontSize["NewXLarge"] = "2.3rem";
    FontSize["NewLarge"] = "2rem";
    FontSize["NewMedium"] = "1.8rem";
    FontSize["NewSmall"] = "1.5rem";
    FontSize["NewXSmall"] = "1.2rem";
    // discontinued:
    FontSize["XLarge"] = "24px";
    FontSize["Large"] = "18px";
    FontSize["Medium"] = "16px";
    FontSize["Small"] = "14px";
    FontSize["XSmall"] = "12px";
})(FontSize || (FontSize = {}));
export var Spacing;
(function (Spacing) {
    Spacing["NewNone"] = "0rem";
    Spacing["NewXSmall"] = "0.6rem";
    Spacing["NewSmall"] = "1rem";
    Spacing["NewMedium"] = "1.8rem";
    Spacing["NewLarge"] = "24px";
    Spacing["NewXLarge"] = "32px";
    // descontinued
    Spacing["XSmall"] = "4px";
    Spacing["Small"] = "8px";
    Spacing["Medium"] = "16px";
    Spacing["Large"] = "24px";
    Spacing["XLarge"] = "32px";
})(Spacing || (Spacing = {}));
export var HeaderHeight = {
    Mobile: '64px',
    Desk: '80px',
};
export var IconSize;
(function (IconSize) {
    IconSize["Small"] = "12px";
    IconSize["Medium"] = "16px";
    IconSize["Large"] = "20px";
})(IconSize || (IconSize = {}));
export var FaIconSize;
(function (FaIconSize) {
    FaIconSize["XSmall"] = "xs";
    FaIconSize["Large"] = "lg";
    FaIconSize["Small"] = "sm";
    FaIconSize["X1"] = "1x";
    FaIconSize["X2"] = "2x";
    FaIconSize["X3"] = "3x";
    FaIconSize["X4"] = "4x";
    FaIconSize["X5"] = "5x";
    FaIconSize["X6"] = "6x";
    FaIconSize["X7"] = "7x";
    FaIconSize["X8"] = "8x";
    FaIconSize["X9"] = "9x";
    FaIconSize["X10"] = "10x";
})(FaIconSize || (FaIconSize = {}));
export var DrawerWidth = '256px';
export var Navbar = {
    a: '1',
};
export var FieldHeight = '48px';
export var TransitionDuration = '.3s';
export var Border = {
    Color: Color.GrayLight,
    Width: '1px',
    Radius: '4px',
    RadiusLarge: '24px',
};
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export var PasswordLength;
(function (PasswordLength) {
    PasswordLength[PasswordLength["Max"] = 18] = "Max";
    PasswordLength[PasswordLength["Min"] = 7] = "Min";
})(PasswordLength || (PasswordLength = {}));
export var AspectRatio;
(function (AspectRatio) {
    AspectRatio[AspectRatio["Square"] = 1] = "Square";
})(AspectRatio || (AspectRatio = {}));
export var Breakpoint = {
    xs: 0,
    sm: 32,
    md: 48,
    lg: 64,
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ground"] = 0] = "ground";
    ZIndex[ZIndex["first"] = 1] = "first";
    ZIndex[ZIndex["second"] = 2] = "second";
    ZIndex[ZIndex["third"] = 3] = "third";
    ZIndex[ZIndex["top"] = 4] = "top";
    ZIndex[ZIndex["supertop"] = 5] = "supertop";
})(ZIndex || (ZIndex = {}));
// Based on https://material.io/design/environment/light-shadows.html#shadows
var BoxShadow;
(function (BoxShadow) {
    BoxShadow["Depth2"] = "0 1px 2px 1px";
    BoxShadow["Depth6"] = "0 3px 6px 0";
    BoxShadow["Depth12"] = "0 6px 12px 3px";
})(BoxShadow || (BoxShadow = {}));
export var HexaOpacity = '33'; // 20%
export var Shadow = {
    Small: '0px 2px 2px 0px rgba(0,0,0,0.2)',
    Depth2: BoxShadow.Depth2 + " " + (Color.GrayDark + HexaOpacity),
    Depth6: BoxShadow.Depth6 + " " + (Color.GrayDark + HexaOpacity),
    Depth12: BoxShadow.Depth12 + " " + (Color.GrayDark + HexaOpacity),
    GradientDepth1: '0px 1px 1px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.12)',
    GradientDepth4: '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12), 0px -2px 16px rgba(0, 0, 0, 0.05)',
};
export var Lighten;
(function (Lighten) {
    Lighten[Lighten["Success"] = 2.2] = "Success";
    Lighten[Lighten["Alert"] = 1.4] = "Alert";
    Lighten[Lighten["Warning"] = 0.9] = "Warning";
})(Lighten || (Lighten = {}));
