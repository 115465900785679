var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { CloseIconStyled } from '@atomic/obj.modal/modal.component.style';
var ModalOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var ModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 95%;\n  height: 95%;\n  overflow: hidden;\n"], ["\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  width: 95%;\n  height: 95%;\n  overflow: hidden;\n"])));
export var ModalPdf = function (_a) {
    var link = _a.link, onClose = _a.onClose;
    function replaceEditWithPreview(inputString) {
        var driveGoogleIndex = inputString.indexOf('drive.google.');
        if (driveGoogleIndex !== -1) {
            var viewIndex = inputString.indexOf('/view?', driveGoogleIndex);
            console.log('viewIndex', viewIndex);
            if (viewIndex !== -1) {
                var updatedString = inputString.substring(0, viewIndex) + '/preview' + inputString.substring(viewIndex + 5);
                return updatedString;
            }
        }
        return inputString;
    }
    return (React.createElement(ModalOverlay, { onClick: onClose, style: { zIndex: 100 } },
        React.createElement(ModalContent, null,
            React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(CloseIconStyled, { onClick: onClose, style: { width: '30px', height: '30px' } })),
            React.createElement("iframe", { title: "Visualiza\u00E7\u00E3o", src: replaceEditWithPreview(link), width: "100%", height: "100%", style: { border: 0 } }))));
};
var templateObject_1, templateObject_2;
