import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { FlexCol, FlexRow } from '@app/modules/submenus/components/submenu-content-card/styles';
import { FontFamily } from '@atomic';
import { QuestionCard, Textarea } from './new-question-body.style';
export var NewQuestionBodyComponent = function (props) {
    var _a;
    var _b = useState(0), currentAnswerId = _b[0], setCurrentAnswerId = _b[1];
    var _c = useState(false), isOpcaoOutrosEscolhida = _c[0], setIsOpcaoOutrosEscolhida = _c[1];
    var handleOnSelectAlternative = function (id) {
        setIsOpcaoOutrosEscolhida(false);
        setCurrentAnswerId(id);
        props.onSelectAlternative(id);
    };
    var onSelectOpcaoOutros = function () {
        setIsOpcaoOutrosEscolhida(true);
        setCurrentAnswerId(0);
        props.onSelectAlternative(0);
    };
    var onOpcaoOutrosTextValueChange = function (e) {
        props.onOpcaoOutrosTextValueChange(e.currentTarget.value);
    };
    return (React.createElement(React.Fragment, null, (_a = props.alternatives) === null || _a === void 0 ? void 0 :
        _a.slice().sort(function (a, b) { return a.order - b.order; }).map(function (question) { return (React.createElement(QuestionCard, { active: question.id === currentAnswerId, key: question.id, onClick: function () {
                if (!props.disabled) {
                    handleOnSelectAlternative(question.id);
                }
            } },
            React.createElement("input", { type: "radio", value: question.id, checked: question.id === currentAnswerId }),
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "span" }, question.title))); }),
        props.incluirOpcaoOutros && (React.createElement(React.Fragment, null,
            React.createElement(QuestionCard, { active: isOpcaoOutrosEscolhida, onClick: function () {
                    if (!props.disabled) {
                        onSelectOpcaoOutros();
                    }
                } },
                React.createElement(FlexCol, { style: { width: '100%', gap: '10px' } },
                    React.createElement(FlexRow, { style: { alignItems: 'center', gap: '16px' } },
                        React.createElement("input", { type: "radio", checked: isOpcaoOutrosEscolhida }),
                        React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "span" }, "Outro(a)")),
                    isOpcaoOutrosEscolhida && React.createElement(Textarea, { placeholder: "Descreva...", onChange: onOpcaoOutrosTextValueChange })))))));
};
