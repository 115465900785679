import * as React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { useHistory } from 'react-router-dom';
import { Header } from '@app/components/mol.header';
import { TitleElement } from '@app/modules/quiz/components/header/header.styled';
import { AppPath } from '@app/routes';
import ENTCoins from '@assets/icons/ENTCoins.svg';
import { StyledContainer } from '@atomic';
import { PointsAmount, RewardInfo } from './question-header.style';
export var QuestionHeaderComponent = function (props) {
    var history = useHistory();
    var toCorrectPath = function () {
        if (props.isPesquisa) {
            return history.push({ pathname: AppPath.Research });
        }
        return history.push({ pathname: AppPath.Map });
    };
    return (React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
        React.createElement(Header, { withBackButton: true, backButtonColor: "#fff", backButtonFn: toCorrectPath, titleElement: React.createElement(TitleElement, null, props === null || props === void 0 ? void 0 : props.title) }),
        React.createElement(StyledContainer, { withHPadding: true },
            props.valePontos && (React.createElement(PointsAmount, null,
                React.createElement(RewardInfo, null,
                    React.createElement("img", { src: ENTCoins, alt: "moeda" }),
                    React.createElement("span", null,
                        React.createElement("b", null, "+30"))))),
            React.createElement("div", { style: { padding: '1rem 0 1rem 0' } },
                React.createElement(ProgressBar, { baseBgColor: "#c0c0c0", labelColor: "#525252", bgColor: "#fff", completed: "" + (props.currentQuestion + 1), maxCompleted: props.totalQuestions, customLabel: props.currentQuestion + 1 + "/" + props.totalQuestions })))));
};
