import { PasswordMinLength } from './authentication.rules';
export var AuthStrings = {
    Commom: {
        Login: {
            Error: 'O login precisa ser um e-mail ou CPF válido',
        },
        Email: {
            Email: 'email',
            Label: 'EMAIL',
            Error: 'O e-mail é inválido',
        },
        Password: {
            Label: 'SENHA',
            SamePasswordLabel: 'CONFIRMAR SENHA',
            NewPasswordLabel: 'NOVA SENHA',
            MinLengthError: "M\u00EDnimo de " + PasswordMinLength + " caracteres",
            RegexError: 'Pelo menos 1 número e 1 letra',
            SamePasswordError: 'SENHAS INCOMPATÍVEIS',
        },
        FullName: {
            Label: 'NOME COMPLETO',
            Error: 'Digite nome e sobrenome',
        },
        CPF: {
            Cpf: 'cpf',
            Label: 'CPF',
            Error: 'CPF inválido',
        },
        Phone: {
            Label: 'TELEFONE',
            Error: 'Telefone inválido',
        },
    },
    Login: {
        Title: 'Faça o seu login',
        RecoverPasswordLink: 'Esqueci a senha',
        SignupLink: 'PRIMEIRO ACESSO',
        ButtonText: 'ENTRAR',
        FlashMessage: {
            Error: 'Email e/ou senha errados, por favor tente novamente.',
        },
    },
    ExternalLogin: {
        TitleStr: 'Vamos jogar?',
        SubTitleStr: 'Insira seu e-mail:',
        AlreadyRegisteredStr: 'Já tenho cadastro',
        WithoutEmailStr: 'Não possuo e-mail',
        ButtonTextStr: 'Entrar',
        FlashMessageStr: {
            Error: 'Email e/ou senha errados, por favor tente novamente.',
        },
    },
    RegisterFinish: {
        TitleStr: 'Complete seus dados e veja sua posição no ranking!',
        ButtonTextStr: 'Ver Ranking',
        FlashMessageStr: {
            Error: 'Email e/ou senha errados, por favor tente novamente.',
        },
    },
    CelphonRegister: {
        TitleStr: 'Faça login e aproveite!',
        ButtonTextStr: 'JOGAR',
        FlashMessageStr: {
            Error: 'Email e/ou senha errados, por favor tente novamente.',
        },
    },
    Signup: {
        Title: 'NOVO JOGADOR',
        LoginLink: 'Fazer login',
        ButtonText: 'Cadastrar',
        FlashMessage: {
            Success: 'Cadastro efetuado com sucesso!.',
            Error: 'Não foi possível efetuar o cadastro, por favor tente novamente.',
        },
    },
    FirstAccess: {
        Title: 'Validar CPF',
        ButtonText: 'Validar',
    },
    ForgotPassword: {
        Title: 'Recuperar Senha',
        ButtonText: 'Recuperar',
    },
    NewPassword: {
        Title: 'Escolha sua nova senha',
    },
};
