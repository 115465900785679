import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from 'react-context-hook';
import { useHistory } from 'react-router-dom';
import { Logo } from '@app/modules/authentication/authentication.styled';
import { AppPath } from '@app/routes';
import { TelaDeCarregamento, Line } from './styles';
//const sound = require('../../../../assets/sounds/right-answer-sound2.mp3');
var Counter = function () {
    var _a = useState(3), counter = _a[0], setCounter = _a[1];
    var company = useStore('company')[0];
    //const [playSound] = useSound(sound);
    var history = useHistory();
    var handleCounter = function () {
        //playSound();
        setCounter(function (old) { return (old -= 1); });
    };
    useEffect(function () {
        setInterval(handleCounter, 650);
        return function () {
            console.log('Carregamento Completo');
        };
    }, []);
    if (counter === 0) {
        history.push(AppPath.Map);
    }
    return (React.createElement(TelaDeCarregamento, { className: "tw-flex tw-flex-col tw-justify-between tw-h-screen " },
        React.createElement("div", { className: "tw-flex tw-justify-center tw-flex-col tw-items-center tw-h-2/5" },
            React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogo })),
        React.createElement(Line, null),
        React.createElement("div", { className: "tw-justify-center tw-flex tw-h-3/5 " },
            React.createElement("h1", { style: { fontSize: '150px', color: company === null || company === void 0 ? void 0 : company.corSecundaria } }, counter)),
        React.createElement(Line, null),
        React.createElement("div", { className: "tw-justify-center tw-flex tw-h-1/5", style: { color: company === null || company === void 0 ? void 0 : company.corSecundaria, fontSize: '20px' } }, "Preparando uma experi\u00EAncia incr\u00EDvel...")));
};
export default Counter;
