/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useStore } from 'react-context-hook';
import { FlexColumn, FlexRow } from '@app/modules/routine-checklist/quick-checklist/styles';
import { AppPath } from '@app/routes';
import bxHomeSrc from '@assets/icons/bx-home.svg';
import bxProfileSrc from '@assets/icons/bx-profile.svg';
import { Container, StyledActiveLink } from './styles';
export var BottomNav = function (_a) {
    var setHistoricoOpen = _a.setHistoricoOpen, isChecklist = _a.isChecklist;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _b = useStore('lastUrlLocation'), lastUrlLocation = _b[0], setLastUrlLocation = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { style: { height: '10vh', boxShadow: '0 -5px 7px rgba(185, 185, 185, 0.5)' } },
            React.createElement(FlexColumn, { style: { width: '100%' } },
                React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', height: '100%' } },
                    React.createElement(StyledActiveLink, { to: AppPath.Map, exact: true, 
                        // activeStyle={{ borderBottom: '8px solid', borderColor: 'white', textDecoration: 'none' }}
                        style: { textDecoration: 'none' } },
                        React.createElement("img", { src: bxHomeSrc, alt: "bxHome", style: { width: '32px', height: '32px' } }),
                        React.createElement("h3", { style: { marginTop: '10px', fontSize: '1.0rem' } }, "In\u00EDcio")),
                    React.createElement(StyledActiveLink, { to: AppPath.PlayerProfile, exact: true, 
                        // activeStyle={{ borderBottom: '8px solid', borderColor: 'white', textDecoration: 'none' }}
                        style: { textDecoration: 'none' } },
                        React.createElement("img", { src: bxProfileSrc, alt: "bxProfile", style: { width: '32px', height: '32px' } }),
                        React.createElement("h3", { style: { marginTop: '10px', fontSize: '1.0rem' } }, "Perfil")))))));
};
