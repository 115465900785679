var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H4, H5 } from '@atomic';
import { Row } from '@atomic/obj.grid';
export var Title = styled(H4)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  padding-right: 1rem;\n"], ["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  padding-right: 1rem;\n"])));
export var Subtitle = styled(H5)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"], ["\n  color: ", ";\n  padding-bottom: 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonsRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  margin-top: 1rem;\n"])));
export var Textarea = styled.textarea(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  resize: none;\n  padding: 5px;\n  margin-top: 10px;\n  width: 100%;\n  height: 7rem;\n"], ["\n  resize: none;\n  padding: 5px;\n  margin-top: 10px;\n  width: 100%;\n  height: 7rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
