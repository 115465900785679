var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthPath } from '@app/modules/authentication/auth-path';
import { FirstAccessFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { ArrowLeftStyled, ContainerStyled, ContentStyled, FormStyled, } from '@app/modules/authentication/authentication.styled';
import { Form, Validators, Separator, TextField, Button, FaIcon, FlashMessageEnum, WhiteText } from '@atomic';
import { TelaDeLogin, Titulo } from './login.page-v2.styled';
var _a = AuthStrings.FirstAccess, Title = _a.Title, ButtonText = _a.ButtonText;
var CPF = AuthStrings.Commom.CPF;
var POST_PRIMEIRO_ACESSO_URL = 'auth/PrimeiroAcesso';
export var FirstAccessPage = function () {
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var domain = useStore('domain')[0];
    var _a = React.useState(''), cpf = _a[0], setCpf = _a[1];
    var _b = useMutation(function (axiosData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(POST_PRIMEIRO_ACESSO_URL, {
                        cpf: axiosData.cpf,
                        empresaIdentificador: domain,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (response) {
            if (!response) {
                showFlashMessage(FlashMessageEnum.alert, 'CPF não encontrado ou primeiro acesso já realizado.');
            }
            console.log('🚀 ~ file: first-access.page.tsx ~ line 60 ~ FirstAccessPage ~ response', response);
            if ((response === null || response === void 0 ? void 0 : response.possuiEmail) == false) {
                history.push({
                    pathname: AuthPath.LoginWithCPF,
                    state: {
                        usuarioId: response.usuarioId,
                        usuarioCpf: cpf,
                    },
                });
            }
            else {
                showFlashMessage(FlashMessageEnum.success, response.data);
                history.push({ pathname: AuthPath.Login });
            }
        },
        onError: function (error) {
            showFlashMessage(FlashMessageEnum.alert, error.response.data.errors[0]);
        },
    }), mutate = _b.mutate, isLoading = _b.isLoading;
    var handleSubmit = function (formData) {
        var hasFormError = Object.keys(formData.error).length > 0;
        if (!hasFormError) {
            setCpf(formData.data.cpf.replaceAll('.', '').replaceAll('-', ''));
            mutate({
                cpf: formData.data.cpf,
            });
        }
    };
    var COR_DA_SETA = 'white';
    return (React.createElement(TelaDeLogin, null,
        React.createElement(ArrowLeftStyled, null,
            React.createElement(Link, { to: AuthPath.Login },
                React.createElement(FaIcon.ArrowLeft, { style: { color: COR_DA_SETA } }))),
        React.createElement(ContainerStyled, null,
            React.createElement(ContentStyled, { top: true },
                React.createElement(Titulo, null, Title),
                React.createElement(WhiteText, null, "Por favor, insira o seu CPF para efetuar o primeiro acesso."),
                React.createElement(Separator, null),
                React.createElement(FormStyled, null,
                    React.createElement(Form, { onSubmit: handleSubmit },
                        React.createElement(Form.Field, { name: FirstAccessFormFields.Cpf, label: CPF.Label, validators: [Validators.Required(), Validators.CpfPatternRegex(CPF.Error)], onValueChange: function (e) { return console.log(e); } },
                            React.createElement(TextField, { variant: "cpf" })),
                        React.createElement(Separator, null),
                        React.createElement(Button, { type: "submit", variant: "primary", expanded: true, loading: isLoading }, ButtonText),
                        React.createElement(Separator, null)))))));
};
